import React from 'react';

export default function SkipPopUp({ formikSkipBrief }) {

  const handleSubmit = () => {
    formikSkipBrief.setFieldValue('action', 'skipped');
    formikSkipBrief.handleSubmit();
  };

  return (
    <>
      <form>
        <div
          className="modal"
          id="modalSkip"
          tabIndex={-1}
          aria-labelledby="modalSkip"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content shadow-3">
              <div className="modal-body">
                <div className="ms-auto btn-close cursor-pointer" data-bs-dismiss="modal" aria-label="Close"></div>
                {/* Text */}
                <div className="d-flex align-items-center mb-5">
                  <div className="form-group w-full">
                    <h2 className="ls-tight font-bolder mt-6 text-center mb-10">
                                            Why you want to Skip ?
                    </h2>
                    <label className='mb-5' htmlFor="">Enter your reason for skipping this brief.</label>
                    <textarea
                      className='form-control'
                      name='comment'
                      value={formikSkipBrief?.values?.comment}
                      rows="5"
                      onChange={formikSkipBrief?.handleChange}
                    />
                    <div className='text-center mt-5'>
                      <button type='button' className='btn btn_primary_black btn-primary text-center px-5' data-bs-dismiss="modal" onClick={() => handleSubmit()}>Submit</button>
                    </div>
                  </div>
                </div>
                {/* Form group */}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
