import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import GmailPopup from './GmailPopup';
import { getAllSkills, getOtherSkillsApi } from '../../apis/index';
import ReferCreativepopup from '../ReferCreativepopup';
import AdminLayout from '../../components/admin-layout';

import './MasherProfile.css';
import { Linkedin } from '../../components/Logos';
import Instagram from '../../components/Logos/Instagram';
import Twitter from '../../components/Logos/Twitter';
import Tiktok from '../../components/Logos/Tiktok';
import Facebook from '../../components/Logos/Facebook';

import { useContext } from 'react';
import AppContext from '../../appContext';
import { InputErrorMessage } from '../../components/errorMessages';
import { Loader, EditProfileLoader } from '../../components/loader/loader';
import {
  otherMasherProfileApi,
  MasherProfileUploadApi,
  getRollsbySkills,
  updateMasherProfile,
  updateMasheruserProfile,
  createProfileApi,
} from '../../apis';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import { ProfileEditValidation } from '../../components/validation/Validation';
import LANGUAGES from '../../Assets/docs/languages.json';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { FileUploadComponent } from '../../components/loader/loader';
import { XCircleFill } from 'react-bootstrap-icons';
import ToggleSwitch from '../../components/button/Togglebtn';
export default function Index() {
  const appContext = useContext(AppContext);
  const { state, fetchMasherProfile } = appContext;
  const { loggedInUser } = state;
  const [masherData, setMasherData] = useState({ data: null, loading: false });
  const [loader, setLoader] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const loading = masherData?.loading;
  const [skills, setSkills] = useState([]);
  const [Role, setRole] = useState([]);
  const [privateAccess, setprivateAccess] = useState(false);
  async function getSkills() {
    const c = await getAllSkills();
    // setSkills(
    //   c.data?.allSkills.map((el, i) => ({
    //     value: el.id,
    //     label: el.name,
    //   }))
    // );
    const skillsData = c.data?.allSkills;
    const uniqueSkills = Array.from(
      new Set(skillsData.map((el) => el.name))
    ).map((name) => ({
      value: skillsData.find((skill) => skill.name === name).id,
      label: name,
    }));
    setSkills(uniqueSkills);
  }
  const fetchOtherMasherProfile = async () => {
    setMasherData({ loading: true });
    try {
      const res = await otherMasherProfileApi(params?.masherId);
      if (res.status === 200) {
        // toast.success(, { id : "002" })
        setMasherData({ data: res?.data, loading: false });
        // setRole(res?.data?.masherProfile?.roles ?? []);
        const rolesData = res?.data?.masherProfile?.roles ?? [];
        const uniqueRoles = rolesData.filter(
          (role, index, self) =>
            index === self.findIndex((r) => r.value === role.value)
        );
        setRole(uniqueRoles);
      }
    } catch (error) {
      setMasherData({ loading: false });
      if (error?.response?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };
  // const getrollbyskill = async (skill_name) => {
  //   const skills = skill_name.split(',');
  //   Promise.all(skills.map(async (skill) => {
  //     try {
  //       const c = await getRollsbySkills(skill);
  //       return c.data?.Roles.map((el, i) => ({
  //         value: el.id,
  //         label: el.roles,
  //       }));
  //     } catch (error) {
  //       console.error('Error fetching roles for skill:', skill, error);
  //       return []; // Return an empty array or handle as needed
  //     }
  //   })).then((result) => {
  //     setRole(result.flat()); // Assuming you want to flatten the array of arrays
  //   }).catch(error => {
  //     console.error('Error with Promise.all:', error);
  //   });
  // };
  const getrollbyskill = async (skill_name) => {
    try {
      // Fetch roles for the given skill_name
      const response = await getRollsbySkills(skill_name);
      const newRolesData = response.data?.Roles || [];

      // Get the existing roles from the state
      const existingRoles = Role;

      // Merge the existing roles with the new roles
      const mergedRoles = [
        ...existingRoles,
        ...newRolesData.map((el) => ({
          value: el.id,
          label: el.roles,
        })),
      ];

      // Filter out duplicate roles based on their id
      const uniqueRoles = mergedRoles.filter(
        (role, index, self) =>
          self.findIndex((r) => r.value === role.value) === index
      );

      // Update the role state with the unique roles
      setRole(uniqueRoles);
      formik.setFieldValue('roles', uniqueRoles);
    } catch (error) {
      console.error('Error fetching roles for skills:', error);
      // Handle the error as needed
    }
    if (skill_name === undefined) {
      setRole([]);
      formik.setFieldValue('roles', []);
    }
  };
  useEffect(() => {
    setprivateAccess(masherData?.data?.masherProfile?.isVisibletoOthers);
  }, [masherData]);
  useEffect(() => {
    if (params?.masherId !== 'create') {
      fetchMasherProfile();
      fetchOtherMasherProfile();
    }
    getSkills();
  }, []);
  const MAX_CHARACTERS = 1500;
  const languagesOptions = LANGUAGES;
  const clearmasherProfile = async () => {
    const res = await updateMasheruserProfile({
      id: masherData?.data?.masherProfile?.userId?._id || '',
      profilePic: '',
    });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      user_id: masherData?.data?.masherProfile?.userId?._id || '',
      profilePic: masherData?.data?.masherProfile?.userId?.profilePic || '',
      roles: masherData?.data?.masherProfile?.roles || [],
     // skills: masherData?.data?.masherProfile?.skills?.filter(item => item && item.value && item?.value?.trim() !== '')  || [],
      // language: masherData?.data?.masherProfile?.language || [],
      skills:Array.isArray(masherData?.data?.masherProfile?.skills) &&
      masherData?.data?.masherProfile?.skills.every(skill => skill && typeof skill === 'object' && 'value' in skill && 'label' in skill)
      ? masherData.data.masherProfile.skills
      : [],
      language:masherData?.data?.masherProfile?.language?.filter(item => item && item.value && item?.value?.trim() !== '') || [],
      websiteUrl: masherData?.data?.masherProfile?.websiteUrls?.[0]?.url || '',
      facebook: masherData?.data?.masherProfile?.facebook || '',
      instagram: masherData?.data?.masherProfile?.instagram || '',
      twitter: masherData?.data?.masherProfile?.twitter || '',
      linkedin: masherData?.data?.masherProfile?.linkedin || '',
      tiktok: masherData?.data?.masherProfile?.tiktok || '',
      biography: masherData?.data?.masherProfile?.biography || '',
      experience:
				masherData?.data?.masherProfile?.workDetails?.experience || '',
      workedForYourself:
				masherData?.data?.masherProfile?.workDetails?.workedForYourself || '',
      minDayRate:
				masherData?.data?.masherProfile?.workDetails?.minDayRate || '',
      currency: masherData?.data?.masherProfile?.workDetails?.currency || '',
      countryISO:
				masherData?.data?.masherProfile?.workDetails?.countryISO || '',
      brand: masherData?.data?.masherProfile?.brand?.[0]?.label || '',
      traits: masherData?.data?.masherProfile?.traits?.[0] || '',
    },
   validationSchema: ProfileEditValidation,
    onSubmit: async (values) => {
      const {
        user_id,
        profilePic,
        roles,
        skills,
        experience,
        language,
        websiteUrl,
        facebook,
        instagram,
        twitter,
        linkedin,
        tiktok,
        biography,
        workedForYourself,
        minDayRate,
        currency,
        countryISO,
        brand,
        traits,
      } = values;
      try {
        setLoader(true);
        if (params?.masherId === 'create') {
          try {
            setLoader(true);
            const res = await createProfileApi({
              websiteUrls: [{ url: websiteUrl }] || [],
              facebook: facebook || '',
              instagram: instagram || '',
              twitter: twitter || '',
              linkedin: linkedin || '',
              tiktok: tiktok || '',
              biography: biography || '',
              roles: Role || [],
              skills: skills || [],
              language: language || [],
              workDetails: {
                experience: experience || '',
                workedForYourself: workedForYourself || '',
                minDayRate: minDayRate || '',
                currency: currency || '',
                countryISO: countryISO || '',
              },
              brand: [{ label: brand }] || [],
              traits: traits || '',
            });
            if (res.status === 200 || 201) {
              setLoader(false);
              toast.success(res.data.message, { id: 'succcess' });
              setTimeout(() => {
                navigate('/');
              }, 2000);
            }
          } catch (error) {
            setLoader(false);
            toast.error(error.response.data.message, { id: '001' });
          }
        } else {
          const res = await updateMasheruserProfile({
            id: user_id || '',
            profilePic: profilePic || '',
          });
          if (res.status === 200 || 201) {
            try {
              setLoader(true);
              const res = await updateMasherProfile({
                id: params?.masherId || '',
                websiteUrls: [{ url: websiteUrl }] || [],
                facebook: facebook || '',
                instagram: instagram || '',
                twitter: twitter || '',
                linkedin: linkedin || '',
                tiktok: tiktok || '',
                biography: biography || '',
                roles: Role || [],
                skills: skills || [],
                language: language || [],
                workDetails: {
                  experience: experience || '',
                  workedForYourself: workedForYourself || '',
                  minDayRate: minDayRate || '',
                  currency: currency || '',
                  countryISO: countryISO || '',
                },
                brand: [{ label: brand }] || [],
                traits: traits || '',
              });
              if (res.status === 200 || 201) {
                setLoader(false);
                toast.success(res.data.message, { id: 'succcess' });
                setTimeout(() => {
                  navigate(`/masher-view/${params?.masherId}`);
                }, 2000);
              }
            } catch (error) {
              setLoader(false);
              toast.error(error.response.data.message, { id: '001' });
            }
          }
        }
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    },
  });
  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        try {
          // Create a FileReader to read the contents of the file
          const reader = new FileReader();

          // Define a callback for when the file is loaded
          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
            img.onload = () => {
              const width = img.width;
              const height = img.height;
              // fileupload(file);
              if (width >= 200) {
                fileupload(file);
              } else {
                // Display an error message if dimensions exceed the limit
                toast.error('File width must be at least 200 pixels', {
                  id: '002',
                });
              }
            };
          };
          // Read the contents of the file as a data URL
          reader.readAsDataURL(file);
        } catch (error) {
          // Handle errors, e.g., if the FileReader fails
          toast.error('Error processing file dimensions', { id: '004' });
        }
      }
    },
    [formik]
  );
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [filename, setfileName] = useState('');
  const [filesize, setfilesize] = useState('');

  const [isfile, setisfile] = useState(false);
  function formatFileSize(sizeInBytes) {
    const sizeInKB = sizeInBytes / 1024;
    const sizeInMB = sizeInKB / 1024;

    if (sizeInMB >= 1) {
      return `${sizeInMB.toFixed(2)} MB`;
    } else if (sizeInKB >= 1) {
      return `${sizeInKB.toFixed(2)} KB`;
    } else {
      return `${sizeInBytes} bytes`;
    }
  }
  const onUploadProgress = (progressEvent, file_value) => {
    setfileName((prev) => {
      return file_value.name;
    });
    setfilesize((prev) => {
      return formatFileSize(file_value.size);
    });
    setUploadPercentage((prevPercentage) => {
      const progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      return progress;
    });
  };
  const fileupload = async (file) => {
    setisfile(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('masherId', params?.masherId);
    MasherProfileUploadApi(formData, onUploadProgress, file)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success(res.data.message, { id: 'success' });
          formik.setFieldValue('profilePic', res.data.filePath);
          setisfile(false);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, { id: '001' });
        setisfile(false);
      });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif'],
    },
    onDrop,
  });
  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };
  const handleLanguageChange = (selectedOptions) => {
    formik.setFieldValue('language', selectedOptions);
  };
  const handleskills = (selectedOptions) => {
    formik.setFieldValue('skills', selectedOptions);

    const selectedLabel = selectedOptions[selectedOptions.length - 1]?.label;
    const labels = selectedOptions.map((item) => item.label).join(',');
    getrollbyskill(selectedLabel);
  };
  const handleroles = (selectedOptions) => {
    formik.setFieldValue('roles', selectedOptions);
  };
  const updatevisibality = async (state) => {
    try {
      const res = await updateMasherProfile({
        id: params?.masherId || '',
        isVisibletoOthers: state,
      });
      if (res.status === 200 || 201) {
        toast.success(res.data.message, { id: 'succcess' });
      }
    } catch (error) {
      toast.error(error.response.data.message, { id: '001' });
    }
  };

  const uniqueRoleLabels = new Set();
  const removeRole = (roleValueToRemove) => {
    const updatedRoles = Role.filter(
      (role) => role.value !== roleValueToRemove
    );
    setRole(updatedRoles);
  };

  const submitForm = () => {
    console.log(formik.errors)
    if (Object.keys(formik.errors).length > 0) {
      // If there are errors, find the first one
      const firstErrorField = Object.keys(formik.errors)[0];
      // Scroll to the first error field
      const errorFieldElement = document.getElementById(firstErrorField);
      if (errorFieldElement) {
        errorFieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };
  return (
    <AdminLayout>
      <div className="d-flex flex-column h-lg-full masher-profile-container">
        <header>
          <div className="masher-profile-page-header w-full flex flex-row justify-between items-center">
            <p className="font-semibold text-[#101828] text-3xl">Profile</p>
            <p className="font-semibold text-[#101828] text-1xl">
              <ToggleSwitch
                togglefunction={updatevisibality}
                checked={privateAccess}
                setChecked={setprivateAccess}
              />{' '}
							Visible to Mashers
            </p>
          </div>
          <div className="masher-profile-navbar gap-4">
            <Link to={`/account/${loggedInUser?.data?._id || 'create'}`}>
              <div>Account</div>
            </Link>
            <Link
              className="masher-profile-navbar-selected"
              to={`/mashers-profile/${loggedInUser?.data?._id || 'create'}`}
            >
              <div>Profile</div>
            </Link>
            <Link to={`/portfolio/${loggedInUser?.data?._id || 'create'}`}>
              <div>Portfolio</div>
            </Link>
          </div>
        </header>
        {loading ? (
          <div className="text-center mt-10">
            <Loader />
          </div>
        ) : (
          <div>
            <div>
              <form
                className="masher-profile-form"
                onSubmit={formik.handleSubmit}
              >
                <div className="w-full hidden md:flex justify-between items-start md:items-center pb-6 mb-6 border-b border-[#EAECF0]">
                  <div>Update your photo and personal details here.</div>
                  {/* <div className='flex gap-4 mt-2 md:mt-0'>
                    <button className='text-lg font-semibold text-[#00A46F] w-full py-2 px-4' type='button'>Cancel</button>
                    <button className='text-lg font-semibold text-white bg-[#00A46F] w-full py-2 px-4' type='submit'>
                    {!loader ? ('Save') : (<div className="text-xs"><EditProfileLoader /></div>)}
                      </button>
                  </div> */}
                </div>

                <div>
                  <label>
										Your photo
                    <p className="font-normal text-[#667085]">
											This will be displayed on your profile.
                    </p>
                  </label>
                  <div>
                    <div className="flex gap-4 input-group">
                      {formik.values.profilePic && (
                        <div className="edit-profile">
                          <div className="file-name">
                            {formik.values.profilePic.substring(
                              formik.values.profilePic.lastIndexOf('/') + 1
                            )}{' '}
                          </div>
                          <XCircleFill
                            onClick={() => {
                              formik.setFieldValue('profilePic', '');
                              clearmasherProfile();
                            }}
                          />
                        </div>
                      )}
                      <div
                        className="w-full rounded-lg border border-[#D0D5DD] p-8 flex items-center justify-center mt-0"
                        {...getRootProps()}
                      >
                        {formik.values.profilePic && (
                          <img src={formik.values.profilePic} />
                        )}
                        {!formik.values.profilePic && (
                          <>
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <div className="flex flex-col items-center text-center">
                                <div className="flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]">
                                  <i className="bi bi-cloud-arrow-up text-2xl text-[#475467]" />
                                </div>
                                <p className="font-semibold text-[#00A46F] mt-6">
																	Drop here
                                </p>
                                <p>PNG, JPG or GIF (min. 200px)</p>
                              </div>
                            ) : (
                              <div className="flex flex-col items-center text-center">
                                <div className="flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]">
                                  <i className="bi bi-cloud-arrow-up text-2xl text-[#475467]" />
                                </div>
                                <p className="font-semibold text-[#00A46F] mt-6">
																	Click to upload{' '}
                                  <span className="text-[#667085] font-normal hidden md:block">
																		order drag and drop
                                  </span>
                                </p>
                                <p>PNG, JPG or GIF (min. 200px)</p>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {isfile && (
                      <FileUploadComponent
                        name={filename}
                        filesize={filesize}
                        percentage={uploadPercentage}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <label>Skills*</label>
                  <div>
                    <div className="input-group z-700">
                      <Select
                        isMulti
                        className={
                          Boolean(
                            formik.touched?.skills && formik.errors?.skills
                          )
                            ? 'form-control border-danger p-0'
                            : 'form-control p-0'
                        }
                        closeMenuOnSelect={true}
                        name="skills"
                        options={skills.filter(
                          (skill) =>
                            !formik.values.skills.some(
                              (selectedSkill) =>
                                selectedSkill.value === skill.value
                            )
                        )}
                        placeHolder="Your top skills"
                        onChange={handleskills}
                        value={formik.values.skills}
                      />
                      {/* <select
                        name='skills'
                        id='skills'
                        className={Boolean(formik.touched?.skills && formik.errors?.skills) ? 'form-select border-danger' : 'form-select'}
                        value={formik.values.skills}
                        onChange={(event) => {
                          formik.handleChange(event); // Use Formik's handleChange function
                          getrollbyskill(event.target.value);
                        }}
                      >
                        <option selected>Select</option>
                        {
                          skills.map((skill, index) => {
                            return (
                              <option key={index} value={skill.name}>{skill.name}</option>
                            );
                          })
                        }
                      </select> */}
                    </div>
                    <InputErrorMessage
                      error={formik.touched.skills && formik.errors.skills}
                    />
                  </div>
                </div>
                <div>
                  <label>Role*</label>
                  <div>
                    <div>
                      <div className="flex gap-4 flex-wrap">
                        {
                          <>
                            {Role?.map((role) => {
                              // Check if the label is not already in the set before rendering
                              if (!uniqueRoleLabels.has(role.label)) {
                                uniqueRoleLabels.add(role.label);

                                return (
                                  <span
                                    key={`Role-${role.value}`}
                                    className="bg-[#76C6DD] text-white px-2 py-1 flex items center rounded-2xl break-word"
                                  >
                                    {role.label}{' '}
                                    <button
                                      type="button"
                                      onClick={() => {
                                        removeRole(role.value);
                                        // setDelRole(prevDelRole => [...prevDelRole, role]);
                                      }}
                                      className="ml-4"
                                    >
																			x
                                    </button>
                                  </span>
                                );
                              }

                              return null;
                            })}
                          </>
                        }
                      </div>

                      {/* <Select
                        isMulti
                        className={Boolean(formik.touched?.roles && formik.errors?.roles) ? 'form-control border-danger p-0' : 'form-control p-0'}
                        closeMenuOnSelect={false}
                        name="roles"
                        options={Role}
                        placeHolder="Your top skills"
                        onChange={handleroles}
                        value={formik.values.roles}
                      /> */}
                      {/* <select
                        name='roles'
                        id='roles'
                        className={Boolean(formik.touched?.roles && formik.errors?.roles) ? 'form-select border-danger' : 'form-select'}
                        onChange={handleChange}
                        value={formik.values.roles}
                      >
                        <option selected>Select</option>
                        {
                          Role.map((roles, index) => {
                            return (
                              <option key={index} value={roles.id}>{roles.roles}</option>
                            );
                          })
                        }
                      </select> */}
                    </div>
                    <InputErrorMessage
                      error={formik.touched.roles && formik.errors.roles}
                    />
                  </div>
                </div>

                <div>
                  <label>Years Of Experience*</label>
                  <div>
                    <div className="input-group">
                      <input
                        type="number"
                        onKeyDown={(e) =>
                          ['+', '-'].includes(e.key) && e.preventDefault()
                        }
                        className={
                          Boolean(
                            formik.touched?.experience &&
															formik.errors?.experience
                          )
                            ? 'form-control border-danger'
                            : 'form-control'
                        }
                        id="experience"
                        name="experience"
                        value={formik.values.experience}
                        onChange={handleChange}
                      />
                    </div>
                    <InputErrorMessage
                      error={
                        formik.touched.experience && formik.errors.experience
                      }
                    />
                  </div>
                </div>
                <div>
                  <label>Languages*</label>
                  <div>
                    <div className="input-group z-600">
                      <CreatableSelect
                        isMulti
                        className={
                          Boolean(
                            formik.touched?.language && formik.errors?.language
                          )
                            ? 'form-control border-danger p-0'
                            : 'form-control p-0'
                        }
                        name="language"
                        id="language"
                        options={languagesOptions}
                        closeMenuOnSelect={false}
                        placeHolder="Languages you know"
                        onChange={handleLanguageChange}
                        value={formik.values.language}
                      />
                    </div>

                    <InputErrorMessage
                      error={formik.touched.language && formik.errors.language}
                    />
                  </div>
                </div>
                <div>
                  <label>Website</label>
                  <div>
                    <div className="input-group">
                      <input
                        type="text"
                        onKeyDown={(e) =>
                          ['+', '-'].includes(e.key) && e.preventDefault()
                        }
                        className={
                          Boolean(
                            formik.touched?.websiteUrl &&
															formik.errors?.websiteUrl
                          )
                            ? 'form-control border-danger'
                            : 'form-control'
                        }
                        id="websiteUrl"
                        name="websiteUrl"
                        value={formik.values.websiteUrl}
                        onChange={handleChange}
                      />
                    </div>
                    <InputErrorMessage
                      error={
                        formik.touched.websiteUrl && formik.errors.websiteUrl
                      }
                    />
                  </div>
                </div>
                <div className="w-full border-b border-[#EAECF0]"></div>
                <div>
                  <label>LinkedIn</label>
                  <div>
                    <div className="input-group">
                      <div className="form-control-icon">
                        <Linkedin />
                      </div>
                      <input
                        type="text"
                        onKeyDown={(e) =>
                          ['+', '-'].includes(e.key) && e.preventDefault()
                        }
                        className={
                          Boolean(
                            formik.touched?.linkedin && formik.errors?.linkedin
                          )
                            ? 'form-control input-social border-danger'
                            : 'form-control input-social'
                        }
                        id="linkedin"
                        name="linkedin"
                        value={formik.values.linkedin}
                        onChange={handleChange}
                      />
                    </div>
                    <InputErrorMessage
                      error={formik.touched.linkedin && formik.errors.linkedin}
                    />
                  </div>
                </div>
                <div>
                  <label>Facebook</label>
                  <div>
                    <div className="input-group">
                      <div className="form-control-icon">
                        <Facebook />
                      </div>
                      <input
                        type="text"
                        onKeyDown={(e) =>
                          ['+', '-'].includes(e.key) && e.preventDefault()
                        }
                        className={
                          Boolean(
                            formik.touched?.facebook && formik.errors?.facebook
                          )
                            ? 'form-control input-social border-danger'
                            : 'form-control input-social'
                        }
                        id="facebook"
                        name="facebook"
                        value={formik.values.facebook}
                        onChange={handleChange}
                      />
                    </div>
                    <InputErrorMessage
                      error={formik.touched.facebook && formik.errors.facebook}
                    />
                  </div>
                </div>
                <div>
                  <label>Instagram</label>
                  <div>
                    <div className="input-group">
                      <div className="form-control-icon">
                        <Instagram />
                      </div>
                      <input
                        type="text"
                        onKeyDown={(e) =>
                          ['+', '-'].includes(e.key) && e.preventDefault()
                        }
                        className={
                          Boolean(
                            formik.touched?.instagram &&
															formik.errors?.instagram
                          )
                            ? 'form-control input-social border-danger'
                            : 'form-control input-social'
                        }
                        id="instagram"
                        name="instagram"
                        value={formik.values.instagram}
                        onChange={handleChange}
                      />
                    </div>
                    <InputErrorMessage
                      error={
                        formik.touched.instagram && formik.errors.instagram
                      }
                    />
                  </div>
                </div>
                <div>
                  <label>Tiktok</label>
                  <div>
                    <div className="input-group">
                      <div className="form-control-icon">
                        <Tiktok />
                      </div>
                      <input
                        type="text"
                        onKeyDown={(e) =>
                          ['+', '-'].includes(e.key) && e.preventDefault()
                        }
                        className={
                          Boolean(
                            formik.touched?.tiktok && formik.errors?.tiktok
                          )
                            ? 'form-control input-social border-danger'
                            : 'form-control input-social'
                        }
                        id="tiktok"
                        name="tiktok"
                        value={formik.values.tiktok}
                        onChange={handleChange}
                      />
                    </div>
                    <InputErrorMessage
                      error={formik.touched.tiktok && formik.errors.tiktok}
                    />
                  </div>
                </div>
                <div>
                  <label>X</label>
                  <div>
                    <div className="input-group">
                      <div className="form-control-icon">
                        <Twitter />
                      </div>
                      <input
                        type="text"
                        onKeyDown={(e) =>
                          ['+', '-'].includes(e.key) && e.preventDefault()
                        }
                        className={
                          Boolean(
                            formik.touched?.twitter && formik.errors?.twitter
                          )
                            ? 'form-control input-social border-danger'
                            : 'form-control input-social'
                        }
                        id="twitter"
                        name="twitter"
                        value={formik.values.twitter}
                        onChange={handleChange}
                      />
                    </div>
                    <InputErrorMessage
                      error={formik.touched.twitter && formik.errors.twitter}
                    />
                  </div>
                </div>
                <div className="w-full border-b border-[#EAECF0]"></div>
                <div>
                  <label>
										Bio*
                    <p className="font-normal text-[#667085]">
											Write a short introduction.
                    </p>
                  </label>
                  <div>
                    <div className="input-group shadow-none">
                      <textarea
                        type="text"
                        rows="7"
                        onKeyDown={(e) =>
                          ['+', '-'].includes(e.key) && e.preventDefault()
                        }
                        className={
                          Boolean(
                            formik.touched?.biography &&
															formik.errors?.biography
                          )
                            ? 'form-control border-danger'
                            : 'form-control'
                        }
                        id="biography"
                        name="biography"
                        value={formik.values.biography}
                        onChange={handleChange}
                      />
                    </div>
                    <span className="mt-2">
                      {Math.max(
                        0,
                        MAX_CHARACTERS - formik.values.biography.length
                      )}{' '}
											characters left
                    </span>
                    <InputErrorMessage
                      error={
                        formik.touched.biography && formik.errors.biography
                      }
                    />
                  </div>
                </div>
                <div>
                  <label>Personality Traits</label>
                  <div>
                    <div className="input-group shadow-none">
                      <textarea
                        type="text"
                        rows="7"
                        name="traits"
                        id="traits"
                        onKeyDown={(e) =>
                          ['+', '-'].includes(e.key) && e.preventDefault()
                        }
                        className={
                          Boolean(
                            formik.touched?.traits && formik.errors?.traits
                          )
                            ? 'form-control border-danger'
                            : 'form-control'
                        }
                        value={formik.values.traits}
                        onChange={handleChange}
                      />
                    </div>
                    <span className="mt-2">
                      {Math.max(
                        0,
                        MAX_CHARACTERS - formik.values.traits.length
                      )}{' '}
											characters left
                    </span>
                    <InputErrorMessage
                      error={formik.touched.traits && formik.errors.traits}
                    />
                  </div>
                </div>
                <div>
                  <label>Brands I love</label>
                  <div>
                    <div className="input-group shadow-none">
                      <textarea
                        type="text"
                        onKeyDown={(e) =>
                          ['+', '-'].includes(e.key) && e.preventDefault()
                        }
                        className={
                          Boolean(formik.touched?.brand && formik.errors?.brand)
                            ? 'form-control border-danger'
                            : 'form-control'
                        }
                        rows="7"
                        name="brand"
                        id="brand"
                        value={formik.values.brand}
                        onChange={handleChange}
                      />
                    </div>
                    <span className="mt-2">
                      {Math.max(0, MAX_CHARACTERS - formik.values.brand.length)}{' '}
											characters left
                    </span>
                    <InputErrorMessage
                      error={formik.touched.brand && formik.errors.brand}
                    />
                  </div>
                </div>

                <div className="w-full border-b border-[#EAECF0]"></div>
                <div className="w-full flex justify-end items-end md:items-center mt-0">
                  <div className="flex gap-4">
                    <button
                      className="text-lg font-semibold text-[#00A46F] w-full py-2 px-4"
                      type="button"
                    >
											Cancel
                    </button>
                    <button
                      className="text-lg font-semibold text-white bg-[#00A46F] w-full py-2 px-4"
                      onClick={submitForm}
                      type="submit"
                    >
                      {!loader ? (
                        'Save'
                      ) : (
                        <div className="text-xs">
                          <EditProfileLoader />
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <GmailPopup />
      <ReferCreativepopup />
    </AdminLayout>
  );
}
