import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const LoginLinkedIn = () => {
  const data = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (data && data.search && data.search.startsWith('?token=')) {
      const token = data.search.replace('?token=', '').split('____');
      localStorage.setItem('token', token[0]);
      localStorage.setItem('ltoken', token[1]);
      // navigate('/create-profile');
    } else {
      navigate('/sign-in');
    }
  }, []);
  return (
    <div>
      <div>Please wait redirecting ...</div>
    </div>
  );
};

export default LoginLinkedIn;