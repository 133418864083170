import React, {useEffect, useState} from 'react';
import { getMasherByIdApi } from '../../apis';

import './avatar.css';

function Avatar({name, url, borderColor, borderWidth, id}) {
  const [avatarColour, setAvatarColour] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');

  function stringToHslColor(str, s, l) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + (hash << 7) - hash;
      hash = hash & hash;
      hash = Math.abs(hash);
    }
    var h = hash % 360;
    h += (hash % 30); // Add a smaller, secondary rotation based on the hash

    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  const getUserDetails = async () => {
    if ( !id && !url ) {
      setAvatarColour(stringToHslColor(name, 50, 65));
      setAvatarUrl('');
      return;
    }
    if (id){
      try {
        const user = await getMasherByIdApi(id);
        const avatar = user?.data?.masherProfile?.userId?.profilePic;
        if ( avatar ) { 
          setAvatarUrl(avatar);
          return;
        }
      } catch(e) {
        if (e) {
          setAvatarColour(stringToHslColor(id, 50, 65));
        }
      }
    }
    if (url) {
      setAvatarUrl(url);
      return;
    }
    setAvatarColour(stringToHslColor(id, 50, 65));
  };

  function getInitials(str) {
    const firstLetters = str
      .split(' ')
      .map(word => word.charAt(0))
      .join('')
      .toUpperCase();
    return firstLetters;
  }

  useEffect(() => {
    getUserDetails();
  }, []);
  
  return (
    <>
      {
        avatarUrl ?
          <div className='avatar-container' style={{border: `${borderWidth || '2px'} solid ${borderColor || 'white'}`, backgroundColor: 'white'}}>
            <div className='avatar-url'>
              <img src={avatarUrl}/>
            </div>
          </div>
          :
          <div className='avatar-container' style={{color: '#FFF', backgroundColor: `${avatarColour}` || 'rgb(212 212 212)', border: `2px solid ${borderColor || 'white'}`}}>
            <div className='avatar-no-url' >
              <span>{getInitials(name)}</span>
            </div>
          </div>
      } 
    </>
  );
}

export default Avatar;