import { Dialog } from '@headlessui/react';

const WorkModal = ({isOpen=false, setIsOpen = () => {}, setDetails = () => {}, details={}, items={}, index={}}) => {
  const onNext = () => {
    setDetails({
      details: items[index + 1],
      index: index + 1
    });
  };
  const onPrev = () => {
    setDetails({
      details: items[index - 1],
      index: index - 1
    });
  };
  const downloadFile = (
    filePath,
    fileName = 'example-image.jpg',
  ) => {
    fetch('https://storage.googleapis.com/mashapp-live.appspot.com/Masher/workAttach/file/6583420f38b10e0f2ebd604e_hacker533x400.jpg', {
      method: 'GET',
      headers: {
        'Content-Type': 'image/jpeg', // Assuming the file is a JPEG image
      },
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link); // Changed from parentNode.removeChild to document.body.removeChild
        window.URL.revokeObjectURL(url); // Clean up the URL object to release memory
      })
      .catch(error => {
        console.error('Error downloading file:', error);
      });
  };
  
  
  return (
    <div
    className="modal"
    id="workmodalwork"
    tabIndex={-1}
    aria-labelledby="workmodalwork"
    aria-hidden="true"
  >
      <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content shadow-3">
        <div className="modal-body">
        <div className="d-flex justify-content-start align-items-start btn-close cursor-pointer" data-bs-dismiss="modal" aria-label="Close"></div>

         
          <div className='flex flex-col items-center'> 
          {
            details.filePath && (
              <>
               <button style={{display:'none'}}
        onClick={() => downloadFile('https://storage.googleapis.com/mashapp-live.appspot.com/Masher/workAttach/file/6583420f38b10e0f2ebd604e_hacker533x400.jpg')}
      >
        Download image
      </button>
              <a className='font-semibold ml-auto p-2' href={details.filePath} rel='noreferrer' target="_blank"><p>Download</p> </a>
             <img src={details.filePath} className='w-full' />
              </>
            )
          }
            
            <div className='w-full flex justify-between mt-2 font-bold'>
              <div>{index !== 0 && <button onClick={onPrev}><i className='bi bi-chevron-left'></i></button>}</div>
              <div>{index !== items.length - 1 && <button onClick={onNext}><i className='bi bi-chevron-right'></i></button>}</div>
              
            </div>
            <div className='flex justify-between items-center gap-2 w-full text-black mt-2 text-md'>
              <div>
                {details.company && <p className='font-semibold'>Company: <span className='font-normal'>{details.company}</span></p>}
                {details.company && <p className='font-semibold'>My Role: <span className='font-normal'>{details.role}</span></p>}
                {details.company && <p className='font-semibold pre-wrap'>Description: <span className='font-normal'>{details.description}</span></p>}
              </div>
             
            </div>
            <div className='d-flex ml-auto'>
                {
                  details.link && (
                    <a
                      className='font-bold underline text-[#00A46F]'
                      target='_blank'
                      rel='noreferrer'
                      href={
                        details.link.startsWith('http') ? details.link : `https://${details.link}`
                      }
                    >
                      Link
                    </a>
                  )
                }
              </div>
           
          </div>
          
        </div>

      </div>
    </div>
  </div>

    
  );
};
export default WorkModal;