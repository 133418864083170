import { useCallback,useState } from 'react';
import { EditProfileLoader } from '../../components/loader/loader';
import {mashMyworkRemoveApi } from '../../apis';
import toast from 'react-hot-toast';


export const DeletePopup = ({details = {},masherId,fetchOtherMasherProfile}) => {
    const closeModal = (id) => {
        const closeButton = document.getElementById(id);
    
        if (closeButton) {
          closeButton.click();
        }
      };
      const [loader, setLoader] = useState(false);
    //Delete MyWork...............
    const DeleteMywork = async (id) => {
        setLoader(true);
        try {
    
          const res = await mashMyworkRemoveApi(masherId || '', id || '');
          if (res.status === 200 || 201) {
            setLoader(false);
            toast.success(res.data.message, { id: 'succcess' });
            fetchOtherMasherProfile();
            closeModal('modaldeleteCloseButton');
    
          }
        } catch (error) {
            setLoader(false);
          toast.error(error.response.data.message, { id: '001' });
        }
    
      }
    return (
      <div
        className="modal"
        id="Deletemodalwork"
        tabIndex={-1}
        aria-labelledby="Deletemodalwork"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content shadow-3">
            <div className="modal-body">
              
              {/* <div className="ms-auto btn-close cursor-pointer" data-bs-dismiss="modal" aria-label="Close"></div> */}

               <div className="d-flex align-items-center mb-5">
                {
                  details.filePath &&
                  <div className="form-group w-full">
                <img src={details.filePath} className='w-full' />
                </div>
                }
                
              </div>
              <div className='dl-con'>
              <p className='dl-pop-high'>Delete portfolio item</p>
                 <p className='dl-pop-sub'>Are you sure you want to delete this item? This action cannot be undone.</p>
                
              </div>
             
              <div className='w-full flex justify-between mt-10 gap-10'>
                    <button className='text-lg font-semibold  w-full py-2 del-cancel'
                      type='button'
                      data-bs-dismiss="modal" aria-label="Close"
                      id="modaldeleteCloseButton"
                     >Cancel</button>
                    <button className='text-lg font-semibold text-white del-btn w-full py-2' onClick={()=> {DeleteMywork(details._id)}}>
                    {!loader ? ('Delete') : (<div className="text-xs"><EditProfileLoader /></div>)}
                    </button>
                  </div>
            </div>

          </div>
        </div>
      </div>
    )
  }