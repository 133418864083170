import React, { useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import AppContext from '../../appContext';
import ThankYouPopUp from '../../Pages/setting/ThankYuouPopup';
import { InputErrorMessage } from '../errorMessages';
import Currency from '../../../src/utils/Currency.json';

export default function ApplyPopup({ formikApplyBrief, briefData }) {
  const appContext = useContext(AppContext);
  const { state } = appContext;
  const { loggedInUser } = state;

  const [data, setData] = useState();


  const currencyOptions = Currency?.map((el, i) => {
    const container = {};
    container['value'] = el.currency.code;
    container['label'] = el.currency.code;
    return container;
  });


  const handleSubmit2 = (e) => {
    e.preventDefault();
    formikApplyBrief.setFieldValue('action', 'applied');
    setTimeout(() => {
      formikApplyBrief.handleSubmit();
    }, 100);
  };

  const handleAmountChange = (e, index, type) => {
    formikApplyBrief.setFieldValue(`masherQuotedAmount.${index}.amount`, e.target.value);  
  };

  useEffect(() => {
    if (briefData?.data?.briefId?.deliverableDetails) {
      setData(briefData?.data?.briefId?.deliverableDetails);
    }
  }, [briefData?.data?.briefId?.deliverableDetails]);

  useEffect(() => {
    if (briefData?.data?.briefId?.deliverableDetails?.length && loggedInUser?.data?.workDetails?.currency) {
      const m = briefData?.data?.briefId?.deliverableDetails.map((item) => ({ deliverableDetails: {...item}, amount: '', currency: loggedInUser?.data?.workDetails?.currency }));
      formikApplyBrief?.setFieldValue('masherQuotedAmount', m);
    }
  }, [briefData?.data?.briefId?.deliverableDetails, loggedInUser?.data?.workDetails?.currency]);

  return (
    <>
      <form >
        <div className='modal fade' id='modalApplySetting' tabIndex={-1} aria-labelledby='modalApplySetting' aria-hidden='true'>
          <div className='modal-dialog modal-dialog-centered modal-lg'>
            <div className='modal-content shadow-3'>
              <div className='modal-header'>
                <h3 className='mb-0 col-10'>{briefData?.data?.briefId?.briefName}</h3>
                <div className='ms-auto'>
                  <button type='button' id="close-modal" className='btn-close col-2' data-bs-dismiss='modal' aria-label='Close' />
                </div>
              </div>
              <div className='modal-body'>
                {/* Text */}
                <div className='row'>
                  <div className='input'>
                    <div className='row'>
                      <div className='col-sm-12 mb-3'>
                        <label htmlFor='exampleFormControlInput1' className='form-label'>
                          Email address
                        </label>
                        <input
                          type='email'
                          name='email'
                          value={loggedInUser?.data?.userId?.email}
                          disabled={true}
                          className='form-control'
                          id='exampleFormControlInput1'
                          placeholder='email@example.com'
                        />
                      </div>
                      <div className='col-sm-12 mb-3'>
                        <label htmlFor='exampleFormControlInput1' className='form-label'>
                          Availability for work<span className="text-danger"> *</span>
                        </label>


                        <input
                          type='text'
                          name='availability'
                          value={formikApplyBrief?.values?.availability}
                          onChange={formikApplyBrief?.handleChange}
                          className={`form-control mb-1 ${formikApplyBrief?.touched?.availability && formikApplyBrief?.errors?.availability ? 'red-border1' : ''}`}
                          id='exampleFormControlInput1'
                          placeholder='Your availability for work'
                        />
                        <InputErrorMessage error={formikApplyBrief?.touched?.availability && formikApplyBrief?.errors?.availability} marginBottom={-5} />
                        <small className='d-block text-xs text-muted pt-1'>
                          Please let us know any specific days / times you are not available to work on this project
                        </small>


                      </div>
                      <label htmlFor='exampleFormControlInput1' className='form-label'>
                        Quote per deliverable
                      </label>
                      {data
                        ? data?.map((el, i) => {

                          return (
                            <React.Fragment key={`apply-${i}`}>
                              <div className='col-sm-12 mb-3'>
                                <div className=''>
                                  <div className='row'>
                                    <div className='col-6'>
                                      <label htmlFor='exampleFormControlInput1' className='form-label'>
                                        Title
                                      </label>
                                    </div>
                                    <div className='col-6'>
                                      <span className='text-muted fs-14' style={{ overflowWrap: 'break-word' }} >{el?.name}</span>
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <div className='col-6'>
                                      <label htmlFor='exampleFormControlInput1' className='form-label'>
                                        Lead Masher
                                      </label>
                                    </div>
                                    <div className='col-6'>
                                      <span htmlFor='exampleFormControlInput1' className='text-muted fs-14 '>
                                        <td>{(el?.masherRole ? (Array.isArray(el.masherRole) ? (el.masherRole[0].label):(el.masherRole.label)):('--') )}</td>
                                      </span>
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <div className='col-6'>
                                      <label htmlFor='exampleFormControlInput1' className='form-label mb-3'>
                                        Expected completion date
                                      </label>
                                    </div>
                                    <div className='col-6'>
                                      <span htmlFor='exampleFormControlInput1' className='text-muted fs-14'>
                                        <Moment format='DD-MM-YYYY' date={el?.dueDate} />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-sm-12 mb-3'>
                                <h3 className='form-label'>Description</h3>
                                <p className='d-block text-sm text-muted expandToggle'>{el?.description}</p>
                              </div>

                              {/* trying multiple amounts... */}
                              <label className='form-label'>Amount for above deliverable<span className="text-danger"> *</span></label>
                              <div
                                className={`d-flex mb-2 ${(formikApplyBrief.touched?.masherQuotedAmount?.[i]?.amount && formikApplyBrief.errors?.masherQuotedAmount?.[i]?.amount) || (formikApplyBrief.touched?.masherQuotedAmount?.[i]?.currency && formikApplyBrief.errors?.masherQuotedAmount?.[i]?.currency) ? 'red-border' : ''} `}>
                               
                                <div className='input-group-addon currency-addon'>
                                  
                                  <select className='currency-selector' name='currency' onChange={(e) => {
                                    formikApplyBrief.setFieldValue(`masherQuotedAmount.${i}.deliverableDetails`, data[i]);
                                     
                                  }}>
                                    <option value={`${loggedInUser?.data?.workDetails?.currency}`} selected disabled hidden>
                                      {`${loggedInUser?.data?.workDetails?.currency}`}
                                    </option>
                                    <option data-symbol='$' value={'USD'} data-placeholder='0.00' >
                                      USD
                                    </option>
                                    <option data-symbol='$' value={'AUD'} data-placeholder='0.00'>
                                      AUD
                                    </option>
                                    <option data-symbol='£' value={'GBP'} data-placeholder='0.00'>
                                      GBP
                                    </option>
                                    <option data-symbol='¥' value={'EUR'} data-placeholder='0.00'>
                                      EUR
                                    </option>
                                    <option data-symbol='$' value={'SGD'} data-placeholder='0.00'>
                                      SGD
                                    </option>
                                  </select>
                                </div>
                                <input
                                  type='number'
                                  className={Boolean((formikApplyBrief.touched?.masherQuotedAmount?.[i]?.amount && formikApplyBrief.errors?.masherQuotedAmount?.[i]?.amount) && (formikApplyBrief.touched?.masherQuotedAmount?.[i]?.currency && formikApplyBrief.errors?.masherQuotedAmount?.[i]?.currency)) ? 'form-control no-border' : 'form-control'}
                                  id='email'
                                  name={`masherQuotedAmount?.${[i]}?.amount`}
                                  value={formikApplyBrief.values?.masherQuotedAmount?.[i]?.amount}
                                  onChange={(e) => handleAmountChange(e, i)}
                                  placeholder='Enter amount'
                                />
                              </div>
                              {
                                formikApplyBrief.errors?.masherQuotedAmount?.[i]?.amount ?
                                  <InputErrorMessage error={
                                    (formikApplyBrief.touched?.masherQuotedAmount?.[i]?.amount && formikApplyBrief.errors?.masherQuotedAmount?.[i]?.amount)
                                  } marginBottom={-5} />
                                  :
                                  <InputErrorMessage error={
                                    (formikApplyBrief.touched?.masherQuotedAmount?.[i]?.currency && formikApplyBrief.errors?.masherQuotedAmount?.[i]?.currency)
                                  } marginBottom={-5} />
                              }
                              <hr className='mt-2' />
                            </React.Fragment>
                          );
                        })
                        : 'N/A'}
                      <div className='col-sm-12 text-center mt-3'>
                        <button type="button"
                          onClick={(e) => {
                            handleSubmit2(e);
                          
                          }}
                          className='btn btn_primary_black btn-primary text-center px-5'
                        >
                          Apply
                        </button> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ThankYouPopUp />
    </>
  );
}
