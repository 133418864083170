import { useCallback,useState } from 'react';
import { Dialog } from '@headlessui/react';
import {useDropzone} from 'react-dropzone';
import { EditProfileLoader } from '../../components/loader/loader';
import {fileUploadwithprogressApi, masherworkattach } from '../../apis';
import { FileUploadComponent } from '../../components/loader/loader';
import toast from 'react-hot-toast';
import {XCircleFill } from 'react-bootstrap-icons';
const AddWorkModal = ({isOpen=false, setIsOpen = () => {}, onUpdate = () => {},formik,loader,masherId}) => {
  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      try {
        // Create a FileReader to read the contents of the file
        const reader = new FileReader();

        // Define a callback for when the file is loaded
        reader.onload = (e) => {
       
            // Handle other image types
            const img = new Image();
            img.src = e.target.result;
            // Check the image dimensions
            img.onload = () => {
              const width = img.width;
              const height = img.height;
              if (width >= 800) {
                fileupload(file)

              } else {
                // Display an error message if dimensions exceed the limit
                toast.error('File width must be at least 800 pixels', { id: '002' });
              }
            };
          
          
        };
        // Read the contents of the file as a data URL
        reader.readAsDataURL(file);
      } catch (error) {
        // Handle errors, e.g., if the FileReader fails
        toast.error('Error processing file dimensions', { id: '004' });
      }
    }
  }, [formik]);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [filename,setfileName]=useState('');
  const [filesize,setfilesize]=useState('');

  const[isfile,setisfile]=useState(false);
  function formatFileSize(sizeInBytes) {
    const sizeInKB = sizeInBytes / 1024;
    const sizeInMB = sizeInKB / 1024;
  
    if (sizeInMB >= 1) {
      return `${sizeInMB.toFixed(2)} MB`;
    } else if (sizeInKB >= 1) {
      return `${sizeInKB.toFixed(2)} KB`;
    } else {
      return `${sizeInBytes} bytes`;
    }
  }
 
  const onUploadProgress = (progressEvent,file_value) => {
   
    setfileName((prev) => {
      return file_value.name;
    });
    setfilesize((prev) => {
      return formatFileSize(file_value.size);
    });
    setUploadPercentage((prevPercentage) => {
      const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      return progress;
    });
  };
  const fileupload =async(file)=>{
     setisfile(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('masherId', masherId);
    fileUploadwithprogressApi(formData, onUploadProgress,file)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success(res.data.message, { id: 'success' });
          formik.setFieldValue('files', res.data.filePath);
          setisfile(false);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, { id: '001' });
        setisfile(false);
      });
  }
  
  
 
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif'],
    },
    onDrop,
  });
  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };
  
  


  return (
    <div
    className="modal"
    id="Addmodalwork"
    tabIndex={-1}
    aria-labelledby="Addmodalwork"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content shadow-3">
        <div className="modal-body">
          {/* <div className="ms-auto btn-close cursor-pointer" data-bs-dismiss="modal" aria-label="Close"></div> */}
         
            <div className="d-flex align-items-center mb-5">
              <div className="form-group w-full">
                <h2 className="ls-tight font-bolder text-left mb-10">
                Upload and attach files
                </h2>
                <form onSubmit={formik.handleSubmit}>
                <div className='flex gap-4 file-upload-div'>
                {formik.values.files && <div className='edit-profile' > 
                    <div className='file-name'>{formik.values.files.substring(formik.values.files.lastIndexOf('/') + 1)} </div>
                    <XCircleFill onClick={()=>{ formik.setFieldValue('files','');}} />
                   </div>}
                  <div className='w-full rounded-lg border border-[#D0D5DD] p-9 flex items-center justify-center mt-6' {...getRootProps()}>
                 
                   {
                        formik.values.files && <img src={formik.values.files}  />
                      }
                      {
                        !formik.values.files && 
                        <>
                  
                    <input {...getInputProps()} />
                    {isDragActive  ? <div className='flex flex-col items-center text-center'>
                      <div className='flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]'><i className="bi bi-cloud-arrow-up text-2xl text-[#475467]" /></div>
                      <p className='font-semibold text-[#00A46F] mt-6'>Drop here</p>
                      <p>PNG, JPG or GIF (min. 800px)</p>
                    </div> : <div className='flex flex-col items-center text-center'>
                      <div className='flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]'><i className="bi bi-cloud-arrow-up text-2xl text-[#475467]" /></div>
                      <p className='font-semibold text-[#00A46F] mt-6'>Click to upload <span className='text-[#667085] font-normal hidden md:block'>order drag and drop</span></p>
                      <p>PNG, JPG or GIF (min. 800px)</p>
                    </div>}
                    </>
                     }
                  </div>
                   </div>
                  {
                    isfile &&    <FileUploadComponent name={filename} filesize={filesize} percentage={uploadPercentage} />
                  }
                
                  <div className='w-full mt-2'>
                    <p className='font-medium text-[#344054]'>Company</p>
                    <input type='text' className='px-4 py-2 border border-[#D0D5DD] rounded-lg w-full mt-2' id="company"
                     name="company"
                     value={formik.values.company}
                     onChange={handleChange} />
                  </div>
                  <div className='w-full mt-2'>
                    <p className='font-medium text-[#344054]'>My Role</p>
                    <input type='text' className='px-4 py-2 border border-[#D0D5DD] rounded-lg w-full mt-2' id="role"
                     name="role"
                     value={formik.values.role}
                     onChange={handleChange}   />
                  </div>
                  <div className='w-full mt-2'>
                    <p className='font-medium text-[#344054]'>Description</p>
                    {/* <input type='text' className='px-4 py-2 border border-[#D0D5DD] rounded-lg w-full mt-2' id="description"
                    name="description"
                    value={formik.values.description}
                    onChange={handleChange}
                    /> */}
                     <textarea type='text' className='px-4 py-2 border border-[#D0D5DD] rounded-lg w-full mt-2' id="description"
                    rows="7"
                    name="description"
                    value={formik.values.description}
                    onChange={handleChange}
                    />
                  </div>
                  <div className='w-full mt-2'>
                    <p className='font-medium text-[#344054]'>Link</p>
                    <input type='text' className='px-4 py-2 border border-[#D0D5DD] rounded-lg w-full mt-2'  id="link"
                     name="link"
                     value={formik.values.link}
                     onChange={handleChange}
                     />
                  </div>
                  <div className='w-full flex justify-between mt-10'>
                    <button className='text-lg font-semibold text-[#00A46F] w-full py-2'
                    type='button'
                    data-bs-dismiss="modal" aria-label="Close"
                    id="modalworkCloseButton"
                    //onClick={() => setIsOpen(false)}
                    >Cancel</button>
                    <button className='text-lg font-semibold text-white bg-[#00A46F] w-full py-2' type="submit">
                    {!loader ? ('Attach files') : (<div className="text-xs"><EditProfileLoader /></div>)}
                    </button>
                  </div>
                  </form>
              </div>
            </div>
          
        </div>

      </div>
    </div>
  </div>
  );
};
export default AddWorkModal;