import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../appContext';
import { preventBack } from '../../utils/helperFunctions';

export default function Index() {

  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { logout } = appContext;

  const handleLogout = () => {
    logout(navigate);
  };
 
  useEffect(() => {
    preventBack();
  }, []);

  return (
    <>
      <div className="thankyou_wrapper pt-5">
        <div className="container">
          <div className="row justify-content-center align-content-center">
            <div className="col-xl-6 mt-20">
              <div className="card shadow-3">
                <div className="card-body bg_yellow_color">
                  <div className="d-flex align-items-center justify-content-center mb-5">
                    <div className="px-5">
                      <div className="my-5">
                        {' '}
                        <span className="display-5">🚫</span>
                        <h1 className="fs-1 ls-tight font-bolder my-4">Sorry this masher has not registered yet</h1>
                        {/* <p>Over the next few days, we’ll take time to review your profile and (hopefully!) approve it. </p>
                                                <p>There’s great things ahead, and we can’t wait to be a part of it with you, together.</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="position-lg-absolute bottom-0 end-0 my-8 mx-12 text-center text-lg-end"><small>We'll notify you once your profile gets approved. </small><a className="text-warning text-sm font-semibold" href="/sign-in" onClick={() => { handleLogout() }} ><a className="si-hover-2">Log Out</a></a></div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
