import { toast } from 'react-hot-toast';
import { fileUploadApi } from '../apis';
import { API_BASE_URL } from './baseurl';

export const getToken = () => localStorage.getItem('token');
export const setToken = (token) => localStorage.setItem('token', token);
export const removeToken = () => localStorage.removeItem('token');
export const isAuth = () => Boolean(getToken());

export const tokenLength = () => {
  const x = getToken();
  return x.length;
};

export const getLastElementId = () => {
  const path = window.location.pathname.split('/');
  return path[path.length - 1];
};

export const getLastElementId3 = () => {
  const path = window.location.pathname.split('/');

  let obj = { videoId: path[path.length - 1], userId: path[path.length - 2] };
  return obj;
};

export const get2LastElementId = () => {
  const path = window.location.pathname.split('/');

  return path[path.length - 2];
};

export const reactSelectCustomStyle = {
  control: (provided, state) => ({
    ...provided,
    minWidth: 200,
    boxShadow: 'none',
    borderColor: state.isFocused ? 'rgb(33,33,33)' : '#ced4da',
    '&:hover': {
      borderColor: state.isFocused ? 'rgb(33,33,33)' : '#ced4da',
    },
  }),
};

export const preventBack = () => {
  return window.history.forward();
};

export const formatDiffTimeFromNowHHMMfor48hours = (validFrom, time) => {
  let __time = getTimeRemaining(validFrom, time);
  if (__time === -1) {
    return '00D // 00H';
  }
  return `${__time?.Days}D // ${__time?.Hours}H`;
};

export const logoutOnJwtExpire = () => {
  localStorage.clear();
};

export const assignBucketUrl = (path) => {
  return encodeURI(path?.startsWith('uploads/') ? API_BASE_URL + '/' + path : path);
};

export const handleFileUpload = async (event, formik, setLoader) => {

  const toastLoader = toast.loading('Uploading...');

  const logoImg = event.target.files[0];
  if (!logoImg) {
    return;
  }

  const imgFile = new FormData();
  imgFile.append('file', logoImg);

  if (logoImg.size > 15000000) {
    toast.error('Please upload file less than 15 mb');
    toast.dismiss(toastLoader);
    return;
  }
  try {
    if (logoImg.name.match(/\.(jpg|jpeg|png|jfif|webp|JPG|JPEG|PNG|JFIF|WEBP)$/)) {
      const res = await fileUploadApi(imgFile);
      if (res.status === 200) {
        formik.setFieldValue(event.target.name, res?.data?.filePath);
        toast.dismiss(toastLoader);
        toast.success(res?.data?.message, { id: '001' });
      }
    } else {
      toast.dismiss(toastLoader);
      toast.error('Unsupported Image Format', { id: 'err01' });
    }
  } catch (error) {
    toast.dismiss(toastLoader);
    console.log(error);
  }
};

export const handleVideoUpload = async (event, formik, setLoader) => {

  const toastLoader = toast.loading('Uploading...');
  const video = event.target.files[0];
  if (!video) {
    return;
  }

  const videoFile = new FormData();
  videoFile.append('file', video);

  if (video.size > 104857600) {
    toast.dismiss(toastLoader);
    toast.error('Please upload file less than 100 mb');
    return;
  }
  try {
    if (video.name.match(/\.(3gp|mp4|webm|mkv|mov|MOV|MP4|3GP)$/)) {
      const res = await fileUploadApi(videoFile);
      if (res.status === 200) {
        formik.setFieldValue(event.target.name, res?.data?.filePath);
        toast.dismiss(toastLoader);
        toast.success(res?.data?.message, { id: '001' });
      }
    } else {
      toast.dismiss(toastLoader);
      toast.error('Unsupported Video Format', { id: 'err01' });
    }
  } catch (error) {
    toast.dismiss(toastLoader);
    console.log(error);
  }
};

export const getTimeRemaining = (validFrom, validTill) => {
  validFrom = new Date(validFrom);
  const validTillDate = validFrom.addHours(validTill);
  let timeRemaining = (validTillDate - new Date()) / 3600 / 1000;
  return timeRemaining > 0 ? SplitTime(timeRemaining) : -1;
};

Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

function SplitTime(numberOfHours) {
  let Days = Math.floor(numberOfHours / 24);
  let Remainder = numberOfHours % 24;
  let Hours = Math.ceil(Remainder);
  // let Minutes = Math.floor(60 * (Remainder - Hours));
  if (Hours == 24) {
    Days = Days + 1;
    Hours = 0;
  }
  // console.log(Days,Hours)
  return { Days, Hours };
}
const date1 = new Date('April 10, 2023 17:24:00');


const decrypt = value => {
  try {
    return window.atob(value);
  } catch (error) {
    return value;
  }
};

const encrypt = value => {
  try {
    return window.btoa(value);
  } catch (error) {
    return value;
  }
};

export const setStorageITem = async (itemKey, itemValue) => {
  const res = await localStorage.setItem(itemKey, encrypt(itemValue));
  return res;
};

export const getStorageItem = async (itemKey) => {
  if (localStorage.getItem(itemKey)) {
    const res = decrypt(localStorage.getItem(itemKey));
    if (res) {
      return res;
    }
    return false;
  }
  return false;
};

export const redirectToReferralPortal = () => {
  window.open('https://mash.applytojob.com/apply/w0nsIyN5Wa/Refer-A-Masher?source=Referral');
};
