import React, { useContext } from 'react';
import { useState } from 'react';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import AppContext from '../../../appContext';
import { registerMasherApi } from '../../../apis';
import { API_BASE_URL } from '../../../utils/baseurl';
import ImgTwo from '../../../Assets/img/social/google.png';
// import { SignInWithGoogle } from "../../../utils/firebase";
import ImgOne from '../../../Assets/img/social/linkdin.png';
import Cover from '../../../Assets/img/covers/loginBack.jpg';
import LogoImage from '../../../Assets/img/logos/mash_logo.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { InputErrorMessage } from '../../../components/errorMessages';
import { EditProfileLoader } from '../../../components/loader/loader';
import { SignUpValidation } from '../../../components/validation/Validation';

import '../index.scss';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
  gtmId: 'GTM-KTNLX4FF'
};
TagManager.initialize(tagManagerArgs);
ReactGA.initialize('G-VL3PFPXMSE');
export default function Index() {

  const appContext = useContext(AppContext);
  const { setIsLoggedIn } = appContext;

  const location = useLocation();

  const referId = location?.search.split('=')[1];

  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      keepLoggedIn: false,
    },

    validationSchema: SignUpValidation,

    onSubmit: async (values) => {
      const { email, keepLoggedIn } = values;
      try {
        setLoader(true);
        const res = await registerMasherApi({ email, referId: referId || '' });
        if (res.status === 200 || 201) {
          navigate('/otp', { state: { email, keepLoggedIn, referId: referId || '' } });
          toast.success(res.data.message, { id: 'succcess' });
        }
      } catch (error) {
        setLoader(false);
        toast.error(error.response.data.message, { id: '001' });
      }
    }
  });

  //remove google and linkdln signup for temporary(11 july 2023) as per client request
  // const googleLogin = async () => {

  //   const token = await SignInWithGoogle();

  //   localStorage.setItem("token", token.token.token)
  //   if (token?.token?.flag == "login") {
  //     setIsLoggedIn(true)
  //     window.location.href = "/"
  //   } else {
  //     window.location.href = "/create-profile"
  //   }
  // }

  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };

  return (
    <>
      <div className="min-h-screen bg-surface-secondary flex flex-col justify-center items-center sign-in-up !h-screen w-full bg-[url('../public/login.jpg')] bg-cover">
        <div className='col-lg-3 p-6 overflow-y-auto bg-white sm:h-fit flex flex-col justify-center md:justify-start rounded-xl'>
          <img src={LogoImage} width="150" />
          <div className='mt-10'>
            <h1 className='ls-tight font-bolder display-5 text-dark mb-5'>Welcome</h1>
            <p className="text-dark h4">We couldn't be happier to have you. </p>
            <div className="col-lg-12 mt-8">
              <form onSubmit={formik.handleSubmit} className="sign-in-up">
                <h2 className='font-bolder h-4 text-dark mb-8'>Sign up</h2>
                <div className="mb-5">
                  <label className="form-label" htmlFor="email">
                      Email address
                  </label>
                  <input
                    type="text"
                    name="email"
                    className={Boolean(formik.touched.email) && Boolean(formik.errors.email) ? 'form-control border-danger transparent-input-border' : 'form-control transparent-input-border'}
                    id="email"
                    placeholder="Your email address"
                    value={formik.values.email}
                    onChange={handleChange}
                  />
                </div>
                <InputErrorMessage error={formik.touched.email && formik.errors.email} />
                <div className="mb-5">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="keepLoggedIn"
                      onChange={e => formik.setFieldValue('keepLoggedIn', e.target.checked ? true : false)}
                      value={formik.values.keepLoggedIn}
                      id="check_example"
                      // onClick={()=>{formik.setFieldValue(formik.values.keepLoggedIn, true)}}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="check_example"
                    >
                        Keep me Logged In
                    </label>
                  </div>
                </div>
                <div>
                  <button to="" className="btn btn_primary_black w-full" type="submit">
                    {!loader ? ('Send Verification Code') : (<div className="text-xs"><EditProfileLoader /></div>)}
                  </button>
                </div>
              </form>
              {/* <div className="py-3 text-center">
                  <span className="text-xs text-uppercase font-semibold">
                    or
                  </span>
                </div> 
                <div className="hstack gap-4 justify-content-center">
                  <a href={API_BASE_URL + "/api/v1/common/linkedin/"} className="btn btn-sm btn-square btn-neutral">
                    <span className="icon icon-sm">
                      <img alt="..." src={ImgOne} width="20" />
                    </span>
                  </a>
                  <button className="btn btn-sm btn-square btn-neutral" onClick={googleLogin} >
                    <span className="icon icon-sm">
                      <img alt="..." src={ImgTwo} width="20" />
                    </span>

                  </button>
                </div> */}
              <div className="my-8 mx-12 text-center"><small>Already have an account? </small>
                <Link className="text-dark text-sm font-semibold si-hover-2" to="/sign-in">Sign in</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
