import React, { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import AppContext from '../../appContext';
import { Loader, Loader2 } from '../loader/loader';
import ApplyPopup from './ApplyPopup';
import SkipPopUp from './SkipPopUp';
import { validationApplyBrief } from '../validation/Validation';
import { formatDiffTimeFromNowHHMMfor48hours, getTimeRemaining } from '../../utils/helperFunctions';

export default function HeaderSecond({ briefData, invitationId, briefDetails, masherQuotedObj }) {
  const appContext = useContext(AppContext);
  const { state, fetchMasherProfile } = appContext;
  const { loggedInUser } = state;

  const dateCreated = Boolean(briefData?.data?.validTill) ? briefData?.data?.validTill : '';
  let colorForTimer = getTimeRemaining(briefData?.data?.validFrom, briefData?.data?.validTill);
  const urlPathLocation = window.location.pathname;

  const formikApplyBrief = useFormik({
    initialValues: {
      availability: '',
      masherQuotedAmount: '',
      senderId: '',
      recieverId: '',
      convoId: '',
      action: '', // applied, skipped, rejected
      briefInvitationId: '',
      comment: '',
      currency: '',
      adminEmail: ''
    },
    validationSchema: validationApplyBrief,
    onSubmit: async (values) => {
      try {
        const messageObj = values;
        window.socket.emit('brief_action', messageObj);
        setTimeout(() => {
          briefDetails();
          fetchMasherProfile();
          formikApplyBrief.resetForm();
          const close = document.getElementById('close-modal');
          if (formikApplyBrief.isValid === true) {
            close.click();
          }
        }, 1000);
        toast.success('Brief Applied Sucessfully');

      } catch (error) {
        toast.error(error.resposne.data.message);
      }
    },
  });

  const formikSkipBrief = useFormik({
    initialValues: {
      availability: '',
      masherQuotedAmount: '',
      senderId: '',
      recieverId: '',
      convoId: '',
      action: '', // applied, skipped, rejected
      briefInvitationId: '',
      comment: '',
      currency: '',
      adminEmail: ''
    },
    onSubmit: async (values) => {
      try {
        const messageObj = values;
        window.socket.emit('brief_action', messageObj);
        setTimeout(() => {
          briefDetails();
          fetchMasherProfile();
          formikSkipBrief.resetForm();
        }, 1000);

      } catch (error) {
        toast.error(error.resposne.data.message);
      }
    },
  });

  useEffect(() => {
    if (masherQuotedObj) {
      formikApplyBrief.setFieldValue('masherQuotedAmount', masherQuotedObj);
    }
    if (loggedInUser?.data && briefData?.data && invitationId) {
      formikApplyBrief.setFieldValue('senderId', loggedInUser.data.userId._id);
      formikApplyBrief.setFieldValue('recieverId', briefData?.data?.adminId._id);
      const convoId = briefData?.data?.briefId?._id + '_' + loggedInUser?.data?.userId?._id;
      formikApplyBrief.setFieldValue('convoId', convoId);
      formikApplyBrief.setFieldValue('briefInvitationId', invitationId);
      formikApplyBrief.setFieldValue('adminEmail', briefData?.data?.adminId?.email);

      formikSkipBrief.setFieldValue('senderId', loggedInUser.data.userId._id);
      formikSkipBrief.setFieldValue('recieverId', briefData?.data?.adminId._id);
      formikSkipBrief.setFieldValue('convoId', convoId);
      formikSkipBrief.setFieldValue('briefInvitationId', invitationId);
      formikSkipBrief.setFieldValue('adminEmail', briefData?.data?.adminId?.email);
    }
  }, [loggedInUser, briefData, invitationId]);

  return (
    <>
      {/* <ApplyPopup /> */}
      <ApplyPopup briefData={briefData} invitationId={invitationId} formikApplyBrief={formikApplyBrief} />
      {/* <SkipPopup /> */}
      <SkipPopUp invitationId={invitationId} formikSkipBrief={formikSkipBrief} />
    </>
  );
}
