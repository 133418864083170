import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import toast from 'react-hot-toast';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import AsyncSelect from 'react-select/async';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link, useNavigate } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Tiktok from '../../../Assets/img/icons/tik-tok.png';
import Twitter from '../../../Assets/img/icons/twitter.png';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Facebook from '../../../Assets/img/icons/facebook.png';
import Linkedin from '../../../Assets/img/icons/linkedin.png';
import LogoImage from '../../../Assets/img/logos/mash_logo.png';
import Instagram from '../../../Assets/img/icons/instagram.png';
import { logoutOnJwtExpire } from '../../../utils/helperFunctions';
import { InputErrorMessage } from '../../../components/errorMessages';
import { fileUploadApi, getCountryCode, getLocationInputApi, getAllClientsApi, getOtherSkillsApi, getTimeZoneLocationApi, fetchClientApi, fetchIndustryApi } from '../../../apis';
import { countryCodeOptions, countryNameCode } from '../../../utils/countryCode';
import Autocomplete from 'react-google-autocomplete';
import { GOOGLE_API } from '../../../utils/const';
import LANGUAGES from '../../../Assets/docs/languages.json';

export default function CreateProfile(
  {
    portfolioVideoFieldError,
    setPortfolioVideoFieldError,
    cvFieldError,
    setCvFieldError,
    formik1,
    skillD,
    industriesD,
    setIndustriesD,
    setSkillD,
    languagesD,
    setLanguagesD,
    countryCodeD,
    setCountryCodeD,
    portfolioClass,
    setPortfolioClass,
    timezoneD,
    setTimezoneD,
    isVisibletoOthersD,
    setisVisibletoOthersD,
    handleInputChange
  }
) {

  const [skills, setSkills] = useState({ data: null, loading: false });
  const [timezoneArrayD, setTimezoneArrayD] = useState({ data: null, loading: false });
  const [countryCodeArr, setCountryCodeArr] = useState({ data: null });

  const [select, setSelect] = useState({ industryType: '', clients: '' });
  const [selected, setSelected] = useState({ industryType: { value: '', label: '' }, clientsWorkedFor: { value: '', label: '' } });
  const navigate = useNavigate();

  const handleLocationChange = async (search) => {
    try {
      const res = await getLocationInputApi(search);
      if (res.status === 200) {
        return res?.data?.length ? res.data.map(e => ({ label: e.formatted_address, value: e.formatted_address, latitude: e.geometry.location.lat, longitude: e.geometry.location.lng })) : null;
      }
    } catch (error) {
      return null;
    }
  };

  const fetchLocationArray = useCallback(
    debounce((inputText, callback) => {
      if (callback) {
        handleLocationChange(inputText).then((options) => callback(options));
      }
    }, 700),
    []
  );
  const otherSkillsData = async () => {
    try {
      const res = await getOtherSkillsApi();
      if (res.status === 200) {
        setSkills({ data: res.data });
      }
    } catch (error) {
      if (error?.res?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };

  const getAllTimeZones = async (country, latitude, longitude) => {
    const res = await getTimeZoneLocationApi(country, latitude, longitude);
    if (res.status === 201) {
      setTimezoneArrayD({ data: res?.data });
    }
  };

  const fetchAllCountryCode = async () => {
    const res = await getCountryCode();
    if (res.status === 200) {
      setCountryCodeArr({ data: res?.data });
    }
  };

  const fetchSelectRegion = async () => {
    const industryRes = await fetchIndustryApi();
    const typeRes = await getAllClientsApi();
    setSelect({ industry: industryRes.data, clients: typeRes.data?.allClients });
  };

  const handleCheckThreeValue = () => {
    if (formik1.values.websiteUrls[0].url == '' && formik1.values.cv == '' && formik1.values.portfolioVideo == '') {
      setPortfolioClass('text-danger mb-5');
    } else {
      setPortfolioClass('text-muted mb-5');
    }
  };

  const handleChange = (e) => {
    formik1.setFieldValue(e.target.name, e.target.value);
    handleInputChange();
    e.preventDefault();
    formik1.handleChange(e);
  };


  const indOptions =
    select?.industry &&
    select?.industry?.map((el, i) => {
      const container = {};
      container['value'] = el.id;
      container['label'] = el?.name || '--';
      return container;
    });

  const typOptions =
    select?.clients &&
    select?.clients?.map((el, i) => {
      const container = {};
      container['value'] = el._id;
      container['label'] = el?.brandName || '--';
      return container;
    });

  const handleChangeInstagram = (e) => {
    if (e.target.value[0] == '@') {
      formik1.setFieldValue(e.target.name, e.target.value);
      e.preventDefault();
      formik1.handleChange(e);
    } else {
      toast.error('Please include an @ at the start', { id: '011' });
      formik1.setFieldError('instagram', 'Please include an @ at the start');
      e.preventDefault();
    }
  };

  const handleChangeBirth = (e) => {
    formik1.setFieldValue('birthDate', new Date(e).toISOString());
  };
  const handleCvUpload = async (event) => {
    const loadingToast = toast.loading('Uploading...');
    try {
      setCvFieldError(false);
      let cv = event.target.files[0];
      const formdata = new FormData();
      formdata.append('file', cv);
      const type = cv.name;

      const videoMp4 = /pdf/g;
      const videoMov = /docx/g;
      const video3gp = /pdf/g;

      let fistD = videoMp4.test(type);
      let secondD = videoMov.test(type);
      let thirdD = video3gp.test(type);

      if (fistD || secondD || thirdD) {
        setCvFieldError(false);
        const resCvUpload = await fileUploadApi(formdata);
        if (resCvUpload.status === 200) {
          toast.dismiss(loadingToast);
          formik1.setFieldValue('cv', resCvUpload?.data?.filePath);
          toast.success(resCvUpload.data.message, { id: 'succcess' });
        }
      } else {
        toast.dismiss(loadingToast);
        setCvFieldError('Please upload a .pdf of .docx');
      }
    } catch (error) {
      toast.dismiss(loadingToast);

      toast.error(error.response.data.message, { id: 'failed' });
    }
  };
  const handlePortfolioVideoUpload = async (e) => {
    const loadingToast = toast.loading('Uploading...');
    try {
      setPortfolioVideoFieldError(false);
      let portfolioVideo = e.target.files[0];
      if (!portfolioVideo) {
        return;
      }
      const type = portfolioVideo.name;

      const videoMp4 = /mp4/g;
      const videoMov = /mov/g;
      const video3gp = /3gp/g;

      let fistD = videoMp4.test(type);
      let secondD = videoMov.test(type);
      let thirdD = video3gp.test(type);
      if (fistD || secondD || thirdD) {
        setPortfolioVideoFieldError(false);
      } else {
        toast.dismiss(loadingToast);
        setPortfolioVideoFieldError('Upload a valid video : mp4, nov, 3gp');
        return;
      }
      if (portfolioVideo.size > 104857600) {
        toast.dismiss(loadingToast);
        toast.error('Please upload file less than 100 mb');
        return;
      }

      const formdata = new FormData();
      formdata.append('file', portfolioVideo);
      const resVideoUpload = await fileUploadApi(formdata);
      if (resVideoUpload?.status === 200) {
        toast.dismiss(loadingToast);
        formik1.setFieldValue('portfolioVideo', resVideoUpload.data.filePath);
        toast.success(resVideoUpload.data.message, { id: 'succcess' });
      }

    } catch (err) {
      toast.dismiss(loadingToast);
      toast.error(err.response.data.message, { id: 'failed' });
      if (err?.resVideoUpload?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };
  const options = skills?.data?.map((el, i) => {
    const container = {};
    container['value'] = el.id;
    container['label'] = el.name;
    return container;
  });
  const timezoneOptions = timezoneArrayD?.data?.listedTimeZonesWithTime?.map((el, i) => {
    const container = {};
    container['value'] = el.name;
    container['label'] = el.value;
    return container;
  });

  const languagesOptions = LANGUAGES;
  const phoneNoCode = countryCodeArr?.data?.countries?.map(el => {
    let container = {};
    container['label'] = `${el?.name} (${el?.code})`;
    container['value'] = el?.code;
    return container;
  });

  const visibletoOthers = [{ value: true, label: 'Yes' }, { value: false, label: 'No' }];

  const handleAddFields = (formik) => {
    const websiteUrls = [...formik.values.websiteUrls, { url: '' }];
    formik.setFieldValue('websiteUrls', websiteUrls);
  };

  const handleRemoveFields = (formik, i) => {
    const webId = formik.values.websiteUrls[i];
    const websiteUrls = formik.values.websiteUrls.filter((el) => el !== webId);
    formik.setFieldValue('websiteUrls', websiteUrls);
  };

  useEffect(() => {
    if (formik1.values.websiteUrls !== '' || formik1.values.cv !== '' || formik1.values.portfolioVideo !== '') {
      setPortfolioClass('text-muted mb-5');
    }
  }, [formik1.values.portfolioVideo, formik1.values.cv, formik1.values.websiteurl]);

  const getCountryExist = (location) => {
    let data = countryCodeOptions.find(item => item.label.toLowerCase() == location.trim().toLowerCase());
    return data;
    // let data = null;
    // if (location?.trim() === "USA") {
    //   let rgex = new RegExp(`\\b${"United States"}\\b`, 'gi');
    //   data = countryCodeOptions?.filter(item => item?.label.match(rgex));
    // } else {
    //   let rgex = new RegExp(`\\b${location?.trim()}\\b`, 'gi');
    //   data = countryCodeOptions?.filter(item => item?.label.match(rgex) || item?.iso.match(rgex));
    // }
    // return data?.[0]
  };
  const handleLocationSet = (e) => {
    formik1.setFieldValue('location', e.formatted_address);
    getAllTimeZones(e.formatted_address, e.geometry.location.lat(), e.geometry.location.lng());
  };

  useEffect(() => {
    if (formik1.values.location === '') {
      console.log('null');
    } else {
      console.log(' formik1.values.location?', formik1.values.location);
      let location = null;
      if (formik1.values.location.includes(',') && formik1.values.location.split(',').length <= 3) {
        let rec = formik1.values.location?.split(',');
        console.log(rec[rec.length - 1].trim().split(' ')[0]);
        if (rec?.[rec.length - 1]?.trim()?.split(' ')) {
          let loc = rec?.[rec.length - 1]?.trim();
          location = loc.match(/\d/) ? loc.split(' ')?.[0] : loc;
        } else {
          location = formik1.values.location?.split(',')[1];
        }
      } else {
        location = formik1.values.location?.split(' ')?.length > 2 ? formik1.values.location.split(' ')?.[formik1.values.location?.split(' ')?.length - 1] : formik1.values.location;
      }

      const obj = getCountryExist(location);
      const c_code = {};
      c_code['label'] = obj?.label;
      c_code['value'] = obj?.value;
      c_code['iso'] = obj?.iso;
      if (c_code !== null) {
        let _countrCode = (formik1.values.countryCodeD && formik1.values.countryCodeD.value ? formik1.values.countryCodeD.value : formik1.values.countryCodeD);
        if (formik1.values.countryCodeD && _countrCode != c_code['iso']) {
          formik1.setFieldValue('phoneNo', '');
          formik1.setFieldValue('timezone', '');
          setTimezoneD('');
        }
        formik1.setFieldValue('countryCode', c_code);
        formik1.setFieldValue('countryCodeD', c_code.iso);
        setCountryCodeD(c_code);
      }
    }
  }, [formik1.values.location]);

  useEffect(() => {
    otherSkillsData();

    fetchLocationArray('');
    fetchAllCountryCode();
    fetchSelectRegion();
  }, []);

  useEffect(() => {
    formik1.setValues((e) => ({
      ...e,
      skills: skillD,
      language: languagesD,
      countryCode: countryCodeD,
      timezone: timezoneD,
      isVisibletoOthers: isVisibletoOthersD,
      industryType: industriesD
    }));
  }, [skillD, languagesD, countryCodeD, timezoneD, isVisibletoOthersD, industriesD]);
  return (
    <>
      <div className="min-h-screen bg-surface-secondary container-fluid">
        <div className="row mx-0">
          <div className="col-lg-3 p-xl-10 position-fixed start-0 top-0 h-screen overflow-y-hidden bg_yellow_color d-none d-lg-flex flex-column">
            <img src={LogoImage} width="150" alt="" />
            <div className='mt-10'>
              <h1 className='ls-tight font-bolder display-5 text-dark mb-5'>Our office is only 148 billion square metres</h1>
              <p className="text-dark h4 mb-2">A place for you to connect, collaborate and grow in our community. </p>
              <small className="font-semibold" style={{ 'color': 'rgb(95,95,95)' }}>Already signed up? </small>
              <Link to="/sign-in" className="text-sm font-semibold si-hover-2" onClick={logoutOnJwtExpire}>
                <p
                  className=""
                >
                  Login here.
                </p>
              </Link>
              <div className="position-lg-absolute bottom-0 end-0 my-8 mx-12 text-center text-lg-end">
              </div>
            </div>
          </div>
          <div className="col-md-9 col-lg-9 offset-lg-3 py-4 flex-column  position-relative">
            <form onSubmit={formik1.handleSubmit} className="py-lg-10 px-lg-20">
              <div className="d-flex mb-5 align-items-center justify-content-between">
                <div className="heading-new">
                  <h1 className="h4 ls-tight">Create Profile</h1>
                </div>
                <div className="col-auto">
                  <div className="hstack gap-2 justify-content-end">
                  </div>
                </div>
              </div>
              <div className="d-flex mb-2 align-items-center justify-content-between">
                <h5>You can always change it. But first impressions last, let’s make it special.</h5>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-label">First Name</label>
                  <span className="text-danger"> *</span>
                  <input
                    type="text"
                    className={Boolean(formik1.touched?.firstName && formik1.errors?.firstName) ? 'form-control border-danger' : 'form-control'}
                    name="firstName"
                    value={formik1.values.firstName}
                    onChange={handleChange}
                    placeholder="First Name"
                  />
                  <InputErrorMessage error={formik1.touched.firstName && formik1.errors.firstName} marginBottom={-5} />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Last Name</label>
                  <span className="text-danger"> *</span>
                  <input
                    type="text"
                    className={Boolean(formik1.touched?.lastName && formik1.errors?.lastName) ? 'form-control border-danger' : 'form-control'}
                    name="lastName"
                    value={formik1.values.lastName}
                    onChange={handleChange}
                    placeholder="Last Name"
                  />
                  <InputErrorMessage error={formik1.touched.lastName && formik1.errors.lastName} marginBottom={-5} />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Select up to 3 of your strongest skillsets</label>
                  <span className="text-danger"> *</span>
                  <Select
                    isMulti
                    className={Boolean(formik1.touched?.skills && formik1.errors?.skills) ? 'form-control border-danger p-0' : 'form-control p-0'}
                    closeMenuOnSelect={false}
                    name="skills"
                    options={options}
                    placeHolder="Your top skills"
                    onChange={(e) => setSkillD(e)}
                    value={skillD}
                  />
                  <InputErrorMessage error={formik1.touched.skills && formik1.errors.skills} marginBottom={-5} />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Industry</label>
                  <Select
                    options={indOptions}
                    closeMenuOnSelect={false}
                    isMulti
                    name='industryType'
                    className={Boolean(formik1.touched?.industryType && formik1.errors?.industryType) ? 'form-control border-danger p-0' : 'form-control p-0'}
                    classNamePrefix='select'
                    value={industriesD}
                    onChange={(e) => setIndustriesD(e)}
                  />

                  <InputErrorMessage error={formik1.touched.industryType && formik1.errors.industryType} marginBottom={-5} />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Clients worked for</label>

                  <Select
                    isMulti
                    className="form-control p-0"
                    name="clientsWorkedFor"
                    closeMenuOnSelect={false}
                    options={typOptions}
                    classNamePrefix='select'
                    placeHolder="Client Type"
                    onChange={(e) => {
                      formik1.setFieldValue('clientsWorkedFor', e);
                    }}
                    value={formik1.values.clientsWorkedFor}
                  />
                  <InputErrorMessage error={formik1.touched.clientsWorkedFor && formik1.errors.clientsWorkedFor} marginBottom={-5} />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Languages you work in</label>
                  <span className="text-danger"> *</span>
                  <CreatableSelect
                    isMulti
                    className={Boolean(formik1.touched?.language && formik1.errors?.language) ? 'form-control border-danger p-0' : 'form-control p-0'}
                    name="language"
                    options={languagesOptions}
                    closeMenuOnSelect={false}
                    placeHolder="Languages you know"
                    onChange={(e) => setLanguagesD(e)}
                    value={languagesD}
                  />
                  <InputErrorMessage error={formik1.touched.language && formik1.errors.language} marginBottom={-5} />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Full Address</label>
                  <span className="text-danger"> *</span>
                  <Autocomplete
                    fetchDetails={true} // you need this to fetch the details object onPress
                    apiKey={GOOGLE_API}
                    placeholder={''}
                    name='location'
                    className={Boolean(formik1.touched?.location && formik1.errors?.location) ? 'form-control border-danger' : 'form-control'}
                    defaultValue={formik1.values.location}
                    onPlaceSelected={(s) => handleLocationSet(s)}
                    options={{ types: ['geocode', 'establishment'] }} />
                  {/* <AsyncSelect
                    cacheOptions
                    loadOptions={fetchLocationArray}
                    onChange={(e) => { handleLocationSet(e) }}
                    name="location"
                    defaultInputValue={formik1.values.location}
                    className={Boolean(formik1.touched?.location && formik1.errors?.location) ? "form-control border-danger p-0" : "form-control p-0"}
                    noOptionsMessage={({ inputValue }) => !inputValue ? "Please Search Full Address" : "No results found"}
                  /> */}
                  <InputErrorMessage error={formik1.touched.location && formik1.errors.location} marginBottom={-5} />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Time Zone</label>
                  <span className="text-danger"> *</span>
                  <Select
                    className={Boolean(formik1.touched?.timezone && formik1.errors?.timezone) ? 'form-control border-danger p-0' : 'form-control p-0'}
                    name="timezone"
                    options={timezoneOptions}
                    placeHolder="Your timezone"
                    onChange={(e) => setTimezoneD(e)}
                    value={timezoneD}
                    noOptionsMessage={({ inputValue }) => !inputValue ? 'Please Select Full Address' : 'No results found'}
                  />
                  <InputErrorMessage error={formik1.touched.timezone && formik1.errors.timezone} marginBottom={-5} />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Birthday <small><em>(for birthday celebration purpose only)</em></small></label>
                  <Flatpickr
                    className='form-control cursor-pointer flatpickr-month'
                    options={{ dateFormat: 'd M' }}
                    // altInput={true}
                    name="birthDate"
                    value={formik1.values.birthDate}
                    onChange={(event) => {
                      handleChangeBirth(event);
                    }}
                  />
                  <InputErrorMessage error={formik1.touched.birthDate && formik1.errors.birthDate} marginBottom={-5} />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Phone No.</label>
                  <span className="text-danger"> *</span>
                  <div className="input-group position-relative">
                    <PhoneInput
                      placeholder="Enter phone number"
                      defaultCountry={countryCodeD?.iso}
                      international
                      value={formik1?.values?.phoneNo}
                      rules={{ required: true }}
                      onChange={(e) => {
                        if (e) {
                          setCountryCodeD({ label: countryCodeOptions.filter(item => item.value == e.trim())?.[0]?.iso, value: e });
                        }
                        formik1.setFieldValue('phoneNo', e || '');
                      }}
                    />
                  </div>
                  <InputErrorMessage error={formik1.touched.phoneNo && formik1.errors.phoneNo} marginBottom={-5} />
                </div>
                <div className="col-md-12 mb-6">
                  <label className="form-label">Do you want to be seen by other mashers?</label>
                  {/* <span className="text-danger"> *</span> */}
                  <div className="position-relative">
                    <Select
                      name="isVisibletoOthers"
                      className={Boolean(formik1.touched.isVisibletoOthers && formik1.errors.isVisibletoOthers ? 'form-control border-danger p-0' : 'form-control p-0')}
                      options={visibletoOthers}
                      // selected={{ label: 'Australia (+61)', value: '+61' }}
                      onChange={(e) => { setisVisibletoOthersD(e); }}
                      value={isVisibletoOthersD}
                      isClearable={false}
                    />
                  </div>
                  <InputErrorMessage error={formik1.touched.isVisibletoOthers && formik1.errors.isVisibletoOthers} marginBottom={-5} />
                </div>
                <div className="col-md-12">
                  <h4 className="mb-2">Your Portfolio</h4>
                  <p className={portfolioClass}>Please complete minimum 1 field below</p>
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label className="form-label"> URL</label>
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                          <Tooltip {...props}>
                            <strong>{'This will show on your public profile'}</strong>
                          </Tooltip>)}
                        placement="top">
                        <i className="bi bi-info-circle ms-1" />
                      </OverlayTrigger>
                      {formik1.values.websiteUrls?.map((el, i, initArr) => {
                        return (
                          <div key={i}>
                            <div className='row' style={{ marginBottom: '20px' }}>
                              <div className='col-12 '>
                                <input
                                  type='text'
                                  className={' form-control'}
                                  placeholder="Website URL"
                                  name={`websiteUrls.${[i]}.url`}
                                  value={formik1.values.websiteUrls?.[i]?.url}
                                  onChange={handleChange}
                                />
                              </div>

                            </div>

                            {Boolean(formik1.values.websiteUrls?.length - 1 === i) ? (
                              formik1.values.websiteUrls?.length < 5 ?
                                <div className='mb-2 col-2 p-0 btn-area-details d-inline mt-5 mx-1 ' style={{ marginLeft: '0px' }}>
                                  <span
                                    className='btn btn-sm btn-neutral border-base py-2 buton-width-del mb-5 mx-1'
                                    onClick={() => handleAddFields(formik1)}
                                  >
                                    <i className='bi bi-plus-lg'></i> Add More
                                  </span>
                                </div> : ''
                            ) : null}
                            {Boolean(initArr?.length > 1) && (
                              <div className='mb-2 col-2 p-0 btn-area-details d-inline mt-5 mx-1'>
                                <span
                                  className='btn btn-sm btn-neutral-rmv btn-neutral border-base py-2 buton-width-del mb-5 text-danger  mx-1 '
                                  onClick={() => handleRemoveFields(formik1, i)}
                                >
                                  <i className='bi bi-dash-lg text-danger'></i> Remove
                                </span>
                              </div>
                            )}
                          </div>
                        );
                      })}

                      <InputErrorMessage error={formik1.touched.websiteUrls && formik1.errors.websiteUrls} marginBottom={-5} />
                    </div>

                    <div className="col-md-12 mb-3">
                      <label className="form-label">Upload CV<span className="ms-2 text-sm">{'(File types- .pdf, .doc or .docx)'}</span></label>
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                          <Tooltip {...props}>
                            <strong>{'This will show on your public profile'}</strong>
                          </Tooltip>)}
                        placement="top">
                        <i className="bi bi-info-circle ms-1" />
                      </OverlayTrigger>
                      <div className="">
                        {
                          formik1.values.cv ? (
                            <a className='blackOnHover' href={`${formik1?.values?.cv}`} target='_blank'>
                              <div
                                style={{
                                  width: '40px',
                                  height: '40px',
                                  border: '1px solid rgb(25, 19, 19, 0.25)',
                                  borderRadius: '50%',
                                  boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                                  marginRight: '10px',
                                }}
                                className='icon icon-shape text-xl bg-black text-white'>
                                <i className='bi bi-file-earmark-fill'></i>
                              </div>
                              <span className='si-hover-2'>Click to view</span>
                            </a>
                          ) : (
                            <>
                              <div className="input-group position-relative">
                                <input
                                  type="file"
                                  className={Boolean(formik1.touched?.cv && formik1.errors?.cv) ? 'form-control border-danger' : 'form-control'}
                                  name="cv"
                                  onChange={handleCvUpload}
                                />
                              </div>
                              <InputErrorMessage error={cvFieldError} marginBottom={-5} />
                            </>
                          )
                        }
                        {formik1.values?.cv !== '' ? (
                          <div
                            className='removeFileBtn d-inline-block ms-5 '
                            onClick={() => {
                              formik1.setFieldValue('cv', '');
                            }}>
                            Remove
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12 mb-3">
                      <label className="form-label">Upload Video<span className="ms-2 text-sm">{'(File types- .mp4, .3gp)'}</span></label>
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                          <Tooltip {...props}>
                            <strong>{'This will show on your public profile'}</strong>
                          </Tooltip>)}
                        placement="top">
                        <i className="bi c ms-1" />
                      </OverlayTrigger>
                      <div className="">
                        {formik1.values.portfolioVideo ? (
                          <a className='blackOnHover' href={`${formik1?.values?.portfolioVideo}`} target='_blank'>
                            <div
                              style={{
                                width: '40px',
                                height: '40px',
                                border: '1px solid rgb(25, 19, 19, 0.25)',
                                borderRadius: '50%',
                                boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                                marginRight: '10px',
                              }}
                              className='icon icon-shape text-xl bg-black text-white'>
                              <i className='bi-camera-video-fill'></i>
                            </div>
                            <span className='si-hover-2'>Click to view</span>
                          </a>
                        ) : (
                          <>
                            <div className="input-group position-relative">
                              <input
                                type="file"
                                name="portfolioVideo"
                                className={Boolean(formik1.touched?.portfolioVideo && formik1.errors?.portfolioVideo) ? 'form-control border-danger' : 'form-control'}
                                onChange={handlePortfolioVideoUpload}
                                placeholder=""
                              />
                            </div>
                            <InputErrorMessage error={portfolioVideoFieldError} marginBottom={-5} />
                          </>
                        )
                        }
                        {formik1.values?.portfolioVideo !== '' ? (
                          <div
                            className='removeFileBtn d-inline-block ms-5 '
                            onClick={() => {
                              formik1.setFieldValue('portfolioVideo', '');
                            }}>
                            Remove
                          </div>
                        ) : (
                          <div></div>
                        )}

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row">
                    <h4 className="mb-5">Social Links
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                          <Tooltip {...props}>
                            This will show on your public profile
                          </Tooltip>)}
                        placement="top">
                        <i className="bi bi-info-circle ms-2" />
                      </OverlayTrigger>
                    </h4>
                    <div className="col-md-6 mb-2">
                      <div className="input-group">
                        <span className="input-group-text"><img src={Linkedin} width="22" /></span>
                        <input
                          type="text"
                          className={Boolean(formik1.touched?.linkedin && formik1.errors?.linkedin) ? 'form-control border-danger' : 'form-control'}
                          name="linkedin"
                          value={formik1.values.linkedin}
                          onChange={handleChange}
                          placeholder="LinkedIn"
                        />
                      </div>
                      <InputErrorMessage error={formik1.touched.linkedin && formik1.errors.linkedin} marginBottom={-5} />
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="input-group">
                        <span className="input-group-text"><img src={Facebook} width="22" /></span>
                        <input
                          type="text"
                          className={Boolean(formik1.touched?.facebook && formik1.errors?.facebook) ? 'form-control border-danger' : 'form-control'}
                          name="facebook"
                          value={formik1.values.facebook}
                          onChange={handleChange}
                          placeholder="Facebook"
                        />
                      </div>
                      <InputErrorMessage error={formik1.touched.facebook && formik1.errors.facebook} marginBottom={-5} />
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="input-group">
                        <span className="input-group-text"><img src={Twitter} width="22" /></span>
                        <input
                          type="text"
                          className={Boolean(formik1.touched?.twitter && formik1.errors?.twitter) ? 'form-control border-danger' : 'form-control'}
                          name="twitter"
                          value={formik1.values.twitter}
                          onChange={handleChange}
                          placeholder="Twitter"
                        />
                      </div>
                      <InputErrorMessage error={formik1.touched.twitter && formik1.errors.twitter} marginBottom={-5} />
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="input-group">
                        <span className="input-group-text"><img src={Instagram} width="22" /></span>
                        <input
                          type="text"
                          className={Boolean(formik1.touched?.instagram && formik1.errors?.instagram) ? 'form-control border-danger' : 'form-control'}
                          name="instagram"
                          value={formik1.values.instagram}
                          onChange={handleChangeInstagram}
                          placeholder="Instagram"
                        />
                      </div>
                      <InputErrorMessage error={formik1.touched.instagram && formik1.errors.instagram} marginBottom={-5} />
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="input-group">
                        <span className="input-group-text"><img src={Tiktok} width="22" /></span>
                        <input
                          type="text"
                          className={Boolean(formik1.touched?.tiktok && formik1.errors?.tiktok) ? 'form-control border-danger' : 'form-control'}
                          name="tiktok"
                          value={formik1.values.tiktok}
                          onChange={handleChange}
                          placeholder="Tiktok"
                        />
                      </div>
                      <InputErrorMessage error={formik1.touched.tiktok && formik1.errors.tiktok} marginBottom={-5} />
                    </div>
                  </div>
                </div>
                <div>
                  <button type="submit" className="btn btn-sm btn_primary_black d-inline" onClick={handleCheckThreeValue} >
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
