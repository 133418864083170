import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { referCreativeApi } from '../../apis';
import { useNavigate } from 'react-router-dom';
import { logoutOnJwtExpire } from '../../utils/helperFunctions';
import { EditProfileLoader } from '../../components/loader/loader';
import { InputErrorMessage } from '../../components/errorMessages';
import { validationReferCerative } from '../../components/validation/Validation';
import AppContext from '../../appContext';


export default function ReferCreativepopup() {

  const appContext = useContext(AppContext);
  const { state } = appContext;
  const { loggedInUser } = state;
 
  const [loader, setLoader] = useState(false);
 
  const navigate = useNavigate();

  let invitation = 'Hey, you are invited to register as a Masher ';
  const { REACT_APP_API_URL } = process.env;
  const modal = document.querySelector('#modalRefer');
  const URL = REACT_APP_API_URL ? (REACT_APP_API_URL?.indexOf('stage') > 0) ? 'https://stage-masher.thisismash.com' : 'https://masher.thisismash.com' : 'https://stage-masher.thisismash.com';
  const formikReferCreative = useFormik({
    initialValues: {
      msg: `${URL}/sign-up?ref=${loggedInUser?.data?.userId?._id}`,
      email: '',
      firstName: '',
      lastName: '',
      refereefirstName: loggedInUser?.data?.userId?.firstName,
      refereelastName: loggedInUser?.data?.userId?.lastName
    },
    validationSchema: validationReferCerative,
    onSubmit: async (values) => {
      try {
        setLoader(true);
        const res = await referCreativeApi({ ...values, msg: invitation + values.msg });
        if (res.status === 200) {
          modal.click();
          setLoader(false);
          toast.success(res.data.message, { id: 'succcess' });
          formikReferCreative.resetForm();
        }
      } catch (error) {
        setLoader(false);
        toast.error(error.response.data.message, { id: 'error' });
        if (error?.response?.data?.status === 401) {
          logoutOnJwtExpire();
          navigate('/sign-in');
        }
      }
    }
  });

  const handleChange = (e) => {
    e.preventDefault();
    formikReferCreative.handleChange(e);
    formikReferCreative.setFieldValue(e.target.name, e.target.value);
  };

  useEffect(() => {
    formikReferCreative.setFieldValue('refereefirstName', loggedInUser?.data?.userId?.firstName);
    formikReferCreative.setFieldValue('refereelastName', loggedInUser?.data?.userId?.lastName);
    formikReferCreative.setFieldValue('msg', `${URL}/sign-up?ref=${loggedInUser?.data?.userId?._id}`);
  }, [loggedInUser]);


  return (
    <>
      <div
        className="modal"
        id="modalRefer"
        tabIndex={-1}
        aria-labelledby="modalRefer"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content shadow-3">
            <div className="modal-body">
              <div className="ms-auto btn-close cursor-pointer" data-bs-dismiss="modal" aria-label="Close">
              </div>
              {/* Text */}
              <div className="d-flex align-items-center mb-5">
                <div className="form-group w-full">

                  <div className="check-img mb-5 text-center mt-10">
                    {' '}
                    <span className="display-4">💞</span>
                  </div>
                  <h2 className="ls-tight font-bolder text-center mb-10">
                    Refer a Creative
                  </h2>

                  <form onSubmit={formikReferCreative.handleSubmit}>
                    <div className="input-group input-group-inline mb-5">
                      <input
                        type="email"
                        name="msg"
                        className="form-control"
                        value={formikReferCreative.values.msg}
                        disabled
                      />
                      <span className="input-group-text">
                        <i className="bi bi-clipboard cursor-pointer" onClick={async () => {
                      try {
                      await navigator.clipboard.writeText(formikReferCreative.values.msg);
                       toast.success('Link copied to clipboard!', { id: 'succcess' });
                      } catch (error) {
                      toast.error('Failed to copy link to clipboard', { id: 'error' });
                       }
                      }} />
                      </span>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="firstName">
                        First name
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        className="form-control"
                        value={formikReferCreative.values.firstName}
                        onChange={handleChange}
                        id="firstName"
                        placeholder="Enter their first name"
                      />
                    </div>
                    <InputErrorMessage error={formikReferCreative.touched.firstName && formikReferCreative.errors.firstName} marginBottom={-5} />
                    <div className="form-group">
                      <label className="form-label" htmlFor="lastName">
                        Last name
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        className="form-control"
                        value={formikReferCreative.values.lastName}
                        onChange={handleChange}
                        id="lastName"
                        placeholder="Enter their last name"
                      />
                    </div>
                    <InputErrorMessage error={formikReferCreative.touched.lastName && formikReferCreative.errors.lastName} marginBottom={-5} />
                    <div className="form-group">
                      <label className="form-label" htmlFor="email">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        value={formikReferCreative.values.email}
                        onChange={handleChange}
                        id="email"
                        placeholder="Enter their email"
                      />
                    </div>
                    <InputErrorMessage error={formikReferCreative.touched.email && formikReferCreative.errors.email} marginBottom={-5} />
                    <button type="submit" className="btn btn_primary_black d-block  mt-5 mx-auto w-full">
                      {!loader ? ('Send') : (<div className="text-xs"><EditProfileLoader /></div>)}
                    </button>
                  </form>
                </div>
              </div>
              {/* Form group */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
