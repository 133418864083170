import React from 'react';
export default function ThankYouPopUp() {
  return (
    <>
      <div
        className="modal"
        id="ThankYouPopUp"
        tabIndex={-1}
        aria-labelledby="ThankYouPopUp"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content shadow-3">
            <div className="modal-body">
              <div className="ms-auto btn-close cursor-pointer" data-bs-dismiss="modal" aria-label="Close"></div>
              {/* Text */}
              <div className="d-flex align-items-center mb-5">
                <div className="form-group w-full">
                  <h2 className="ls-tight font-bolder mt-6 text-center mb-10">
                                        Thank you for applying
                  </h2>
                  <p>We’ll be in touch with you shortly to ask any questions or give you the green light.</p>
                </div>
              </div>
              {/* Form group */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
