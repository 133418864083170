import React from 'react';
import { ExclamationTriangle } from 'react-bootstrap-icons';
import { EditProfileLoader } from '../loader/loader';
import { useNavigate, Link, useParams } from 'react-router-dom';

export default function RequiredFieldPopup({ data, FieldCheck, masherID }) {
  const navigate = useNavigate();
  return (
    <>
      <form>
        <div
          className="modal"
          id="RequiredField"
          tabIndex={-1}
          aria-labelledby="RequiredField"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content shadow-3">
              <div className="modal-body">
                <div className="main-container ">
                  <div className="exclamation-icon-container">
                    <ExclamationTriangle size={25} color="#DC6803" />
                  </div>
                  <h1 className="ls-tight font-bolder text-left mb-10 heading">
										Fill Out Your Profile
                  </h1>
                  <div className="heading-2">You are missing the following</div>
                  <div className="w-fit items-start flex flex-col mx-auto justify-start">
                    {FieldCheck && (
                      <>
                        {data.map((field, index) => (
                          <ul className="nofield-ul" key={index}>
                            <li className="nofield-li">{field.name}</li>
                          </ul>
                        ))}
                      </>
                    )}
                  </div>
                  {}
                </div>

                <div className="w-full flex justify-between mt-10 gap-10">
                  <button
                    className="text-lg font-semibold  w-full py-2 del-cancel border-high"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="RequiredFieldbtn"
                  >
										Later
                  </button>
                  <button
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    className="text-lg font-semibold text-white p-1 bg-[#00A46F] w-full py-2 "
                    onClick={() => {
                      navigate(`/mashers-profile/${masherID || 'create'}`);
                    }}
                  >
										Complete Profile
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
