export const countryCodeOptions =  [{ 'label': 'Afghanistan', 'value': '+93', 'iso': 'AF' },
  { 'label': 'Albania', 'value': '+355', 'iso': 'AL' },
  { 'label': 'Algeria', 'value': '+213', 'iso': 'DZ' },
  { 'label': 'American Samoa', 'value': '+1-684', 'iso': 'AS' },
  { 'label': 'Andorra', 'value': '+376', 'iso': 'AD' },
  { 'label': 'Angola', 'value': '+244', 'iso': 'AO' },
  { 'label': 'Anguilla', 'value': '+1-264', 'iso': 'AI' },
  { 'label': 'Antarctica', 'value': '+672', 'iso': 'AQ' },
  { 'label': 'Antigua and Barbuda', 'value': '+1-268', 'iso': 'AG' },
  { 'label': 'Argentina', 'value': '+54', 'iso': 'AR' },
  { 'label': 'Armenia', 'value': '+374', 'iso': 'AM' },
  { 'label': 'Aruba', 'value': '+297', 'iso': 'AW' },
  { 'label': 'Australia', 'value': '+61', 'iso': 'AU' },
  { 'label': 'Austria', 'value': '+43', 'iso': 'AT' },
  { 'label': 'Azerbaijan', 'value': '+994', 'iso': 'AZ' },
  { 'label': 'Bahamas', 'value': '+1-242', 'iso': 'BS' },
  { 'label': 'Bahrain', 'value': '+973', 'iso': 'BH' },
  { 'label': 'Bangladesh', 'value': '+880', 'iso': 'BD' },
  { 'label': 'Barbados', 'value': '+1-246', 'iso': 'BB' },
  { 'label': 'Belarus', 'value': '+375', 'iso': 'BY' },
  { 'label': 'Belgium', 'value': '+32', 'iso': 'BE' },
  { 'label': 'Belize', 'value': '+501', 'iso': 'BZ' },
  { 'label': 'Benin', 'value': '+229', 'iso': 'BJ' },
  { 'label': 'Bermuda', 'value': '+1-441', 'iso': 'BM' },
  { 'label': 'Bhutan', 'value': '+975', 'iso': 'BT' },
  { 'label': 'Bolivia', 'value': '+591', 'iso': 'BO' },
  { 'label': 'Bosnia and Herzegovina', 'value': '+387', 'iso': 'BA' },
  { 'label': 'Botswana', 'value': '+267', 'iso': 'BW' },
  { 'label': 'Brazil', 'value': '+55', 'iso': 'BR' },
  { 'label': 'British Indian Ocean Territory', 'value': '+246', 'iso': 'IO' },
  { 'label': 'British Virgin Islands', 'value': '+1-284', 'iso': 'VG' },
  { 'label': 'Brunei', 'value': '+673', 'iso': 'BN' },
  { 'label': 'Bulgaria', 'value': '+359', 'iso': 'BG' },
  { 'label': 'Burkina Faso', 'value': '+226', 'iso': 'BF' },
  { 'label': 'Burundi', 'value': '+257', 'iso': 'BI' },
  { 'label': 'Cambodia', 'value': '+855', 'iso': 'KH' },
  { 'label': 'Cameroon', 'value': '+237', 'iso': 'CM' },
  { 'label': 'Canada', 'value': '+1', 'iso': 'CA' },
  { 'label': 'Cape Verde', 'value': '+238', 'iso': 'CV' },
  { 'label': 'Cayman Islands', 'value': '+1-345', 'iso': 'KY' },
  { 'label': 'Central African Republic', 'value': '+236', 'iso': 'CF' },
  { 'label': 'Chad', 'value': '+235', 'iso': 'TD' },
  { 'label': 'Chile', 'value': '+56', 'iso': 'CL' },
  { 'label': 'China', 'value': '+86', 'iso': 'CN' },
  { 'label': 'Christmas Island', 'value': '+61', 'iso': 'CX' },
  { 'label': 'Cocos Islands', 'value': '+61', 'iso': 'CC' },
  { 'label': 'Colombia', 'value': '+57', 'iso': 'CO' },
  { 'label': 'Comoros', 'value': '+269', 'iso': 'KM' },
  { 'label': 'Cook Islands', 'value': '+682', 'iso': 'CK' },
  { 'label': 'Costa Rica', 'value': '+506', 'iso': 'CR' },
  { 'label': 'Croatia', 'value': '+385', 'iso': 'HR' },
  { 'label': 'Cuba', 'value': '+53', 'iso': 'CU' },
  { 'label': 'Curacao', 'value': '+599', 'iso': 'CW' },
  { 'label': 'Cyprus', 'value': '+357', 'iso': 'CY' },
  { 'label': 'Czech Republic', 'value': '+420', 'iso': 'CZ' },
  { 'label': 'Democratic Republic of the Congo', 'value': '+243', 'iso': 'CD' },
  { 'label': 'Denmark', 'value': '+45', 'iso': 'DK' },
  { 'label': 'Djibouti', 'value': '+253', 'iso': 'DJ' },
  { 'label': 'Dominica', 'value': '+1-767', 'iso': 'DM' },
  { 'label': 'Dominican Republic', 'value': '+1-809, 1-829, 1-849', 'iso': 'DO' },
  { 'label': 'East Timor', 'value': '+670', 'iso': 'TL' },
  { 'label': 'Ecuador', 'value': '+593', 'iso': 'EC' },
  { 'label': 'Egypt', 'value': '+20', 'iso': 'EG' },
  { 'label': 'El Salvador', 'value': '+503', 'iso': 'SV' },
  { 'label': 'Equatorial Guinea', 'value': '+240', 'iso': 'GQ' },
  { 'label': 'Eritrea', 'value': '+291', 'iso': 'ER' },
  { 'label': 'Estonia', 'value': '+372', 'iso': 'EE' },
  { 'label': 'Ethiopia', 'value': '+251', 'iso': 'ET' },
  { 'label': 'Falkland Islands', 'value': '+500', 'iso': 'FK' },
  { 'label': 'Faroe Islands', 'value': '+298', 'iso': 'FO' },
  { 'label': 'Fiji', 'value': '+679', 'iso': 'FJ' },
  { 'label': 'Finland', 'value': '+358', 'iso': 'FI' },
  { 'label': 'France', 'value': '+33', 'iso': 'FR' },
  { 'label': 'French Polynesia', 'value': '+689', 'iso': 'PF' },
  { 'label': 'Gabon', 'value': '+241', 'iso': 'GA' },
  { 'label': 'Gambia', 'value': '+220', 'iso': 'GM' },
  { 'label': 'Georgia', 'value': '+995', 'iso': 'GE' },
  { 'label': 'Germany', 'value': '+49', 'iso': 'DE' },
  { 'label': 'Ghana', 'value': '+233', 'iso': 'GH' },
  { 'label': 'Gibraltar', 'value': '+350', 'iso': 'GI' },
  { 'label': 'Greece', 'value': '+30', 'iso': 'GR' },
  { 'label': 'Greenland', 'value': '+299', 'iso': 'GL' },
  { 'label': 'Grenada', 'value': '+1-473', 'iso': 'GD' },
  { 'label': 'Guam', 'value': '+1-671', 'iso': 'GU' },
  { 'label': 'Guatemala', 'value': '+502', 'iso': 'GT' },
  { 'label': 'Guernsey', 'value': '+44-1481', 'iso': 'GG' },
  { 'label': 'Guinea', 'value': '+224', 'iso': 'GN' },
  { 'label': 'Guinea-Bissau', 'value': '+245', 'iso': 'GW' },
  { 'label': 'Guyana', 'value': '+592', 'iso': 'GY' },
  { 'label': 'Haiti', 'value': '+509', 'iso': 'HT' },
  { 'label': 'Honduras', 'value': '+504', 'iso': 'HN' },
  { 'label': 'Hong Kong', 'value': '+852', 'iso': 'HK' },
  { 'label': 'Hungary', 'value': '+36', 'iso': 'HU' },
  { 'label': 'Iceland', 'value': '+354', 'iso': 'IS' },
  { 'label': 'India', 'value': '+91', 'iso': 'IN' },
  { 'label': 'Indonesia', 'value': '+62', 'iso': 'ID' },
  { 'label': 'Iran', 'value': '+98', 'iso': 'IR' },
  { 'label': 'Iraq', 'value': '+964', 'iso': 'IQ' },
  { 'label': 'Ireland', 'value': '+353', 'iso': 'IE' },
  { 'label': 'Isle of Man', 'value': '+44-1624', 'iso': 'IM' },
  { 'label': 'Israel', 'value': '+972', 'iso': 'IL' },
  { 'label': 'Italy', 'value': '+39', 'iso': 'IT' },
  { 'label': 'Ivory Coast', 'value': '+225', 'iso': 'CI' },
  { 'label': 'Jamaica', 'value': '+1-876', 'iso': 'JM' },
  { 'label': 'Japan', 'value': '+81', 'iso': 'JP' },
  { 'label': 'Jersey', 'value': '+44-1534', 'iso': 'JE' },
  { 'label': 'Jordan', 'value': '+962', 'iso': 'JO' },
  { 'label': 'Kazakhstan', 'value': '+7', 'iso': 'KZ' },
  { 'label': 'Kenya', 'value': '+254', 'iso': 'KE' },
  { 'label': 'Kiribati', 'value': '+686', 'iso': 'KI' },
  { 'label': 'Kosovo', 'value': '+383', 'iso': 'XK' },
  { 'label': 'Kuwait', 'value': '+965', 'iso': 'KW' },
  { 'label': 'Kyrgyzstan', 'value': '+996', 'iso': 'KG' },
  { 'label': 'Laos', 'value': '+856', 'iso': 'LA' },
  { 'label': 'Latvia', 'value': '+371', 'iso': 'LV' },
  { 'label': 'Lebanon', 'value': '+961', 'iso': 'LB' },
  { 'label': 'Lesotho', 'value': '+266', 'iso': 'LS' },
  { 'label': 'Liberia', 'value': '+231', 'iso': 'LR' },
  { 'label': 'Libya', 'value': '+218', 'iso': 'LY' },
  { 'label': 'Liechtenstein', 'value': '+423', 'iso': 'LI' },
  { 'label': 'Lithuania', 'value': '+370', 'iso': 'LT' },
  { 'label': 'Luxembourg', 'value': '+352', 'iso': 'LU' },
  { 'label': 'Macao', 'value': '+853', 'iso': 'MO' },
  { 'label': 'Macedonia', 'value': '+389', 'iso': 'MK' },
  { 'label': 'Madagascar', 'value': '+261', 'iso': 'MG' },
  { 'label': 'Malawi', 'value': '+265', 'iso': 'MW' },
  { 'label': 'Malaysia', 'value': '+60', 'iso': 'MY' },
  { 'label': 'Maldives', 'value': '+960', 'iso': 'MV' },
  { 'label': 'Mali', 'value': '+223', 'iso': 'ML' },
  { 'label': 'Malta', 'value': '+356', 'iso': 'MT' },
  { 'label': 'Marshall Islands', 'value': '+692', 'iso': 'MH' },
  { 'label': 'Mauritania', 'value': '+222', 'iso': 'MR' },
  { 'label': 'Mauritius', 'value': '+230', 'iso': 'MU' },
  { 'label': 'Mayotte', 'value': '+262', 'iso': 'YT' },
  { 'label': 'Mexico', 'value': '+52', 'iso': 'MX' },
  { 'label': 'Micronesia', 'value': '+691', 'iso': 'FM' },
  { 'label': 'Moldova', 'value': '+373', 'iso': 'MD' },
  { 'label': 'Monaco', 'value': '+377', 'iso': 'MC' },
  { 'label': 'Mongolia', 'value': '+976', 'iso': 'MN' },
  { 'label': 'Montenegro', 'value': '+382', 'iso': 'ME' },
  { 'label': 'Montserrat', 'value': '+1-664', 'iso': 'MS' },
  { 'label': 'Morocco', 'value': '+212', 'iso': 'MA' },
  { 'label': 'Mozambique', 'value': '+258', 'iso': 'MZ' },
  { 'label': 'Myanmar', 'value': '+95', 'iso': 'MM' },
  { 'label': 'Namibia', 'value': '+264', 'iso': 'NA' },
  { 'label': 'Nauru', 'value': '+674', 'iso': 'NR' },
  { 'label': 'Nepal', 'value': '+977', 'iso': 'NP' },
  { 'label': 'Netherlands', 'value': '+31', 'iso': 'NL' },
  { 'label': 'Netherlands Antilles', 'value': '+599', 'iso': 'AN' },
  { 'label': 'New Caledonia', 'value': '+687', 'iso': 'NC' },
  { 'label': 'New Zealand', 'value': '+64', 'iso': 'NZ' },
  { 'label': 'Nicaragua', 'value': '+505', 'iso': 'NI' },
  { 'label': 'Niger', 'value': '+227', 'iso': 'NE' },
  { 'label': 'Nigeria', 'value': '+234', 'iso': 'NG' },
  { 'label': 'Niue', 'value': '+683', 'iso': 'NU' },
  { 'label': 'North Korea', 'value': '+850', 'iso': 'KP' },
  { 'label': 'Northern Mariana Islands', 'value': '+1-670', 'iso': 'MP' },
  { 'label': 'Norway', 'value': '+47', 'iso': 'NO' },
  { 'label': 'Oman', 'value': '+968', 'iso': 'OM' },
  { 'label': 'Pakistan', 'value': '+92', 'iso': 'PK' },
  { 'label': 'Palau', 'value': '+680', 'iso': 'PW' },
  { 'label': 'Palestine', 'value': '+970', 'iso': 'PS' },
  { 'label': 'Panama', 'value': '+507', 'iso': 'PA' },
  { 'label': 'Papua New Guinea', 'value': '+675', 'iso': 'PG' },
  { 'label': 'Paraguay', 'value': '+595', 'iso': 'PY' },
  { 'label': 'Peru', 'value': '+51', 'iso': 'PE' },
  { 'label': 'Philippines', 'value': '+63', 'iso': 'PH' },
  { 'label': 'Pitcairn', 'value': '+64', 'iso': 'PN' },
  { 'label': 'Poland', 'value': '+48', 'iso': 'PL' },
  { 'label': 'Portugal', 'value': '+351', 'iso': 'PT' },
  { 'label': 'Puerto Rico', 'value': '+1-787, 1-939', 'iso': 'PR' },
  { 'label': 'Qatar', 'value': '+974', 'iso': 'QA' },
  { 'label': 'Republic of the Congo', 'value': '+242', 'iso': 'CG' },
  { 'label': 'Reunion', 'value': '+262', 'iso': 'RE' },
  { 'label': 'Romania', 'value': '+40', 'iso': 'RO' },
  { 'label': 'Russia', 'value': '+7', 'iso': 'RU' },
  { 'label': 'Rwanda', 'value': '+250', 'iso': 'RW' },
  { 'label': 'Saint Barthelemy', 'value': '+590', 'iso': 'BL' },
  { 'label': 'Saint Helena', 'value': '+290', 'iso': 'SH' },
  { 'label': 'Saint Kitts and Nevis', 'value': '+1-869', 'iso': 'KN' },
  { 'label': 'Saint Lucia', 'value': '+1-758', 'iso': 'LC' },
  { 'label': 'Saint Martin', 'value': '+590', 'iso': 'MF' },
  { 'label': 'Saint Pierre and Miquelon', 'value': '+508', 'iso': 'PM' },
  { 'label': 'Saint Vincent and the Grenadines', 'value': '+1-784', 'iso': 'VC' },
  { 'label': 'Samoa', 'value': '+685', 'iso': 'WS' },
  { 'label': 'San Marino', 'value': '+378', 'iso': 'SM' },
  { 'label': 'Sao Tome and Principe', 'value': '+239', 'iso': 'ST' },
  { 'label': 'Saudi Arabia', 'value': '+966', 'iso': 'SA' },
  { 'label': 'Senegal', 'value': '+221', 'iso': 'SN' },
  { 'label': 'Serbia', 'value': '+381', 'iso': 'RS' },
  { 'label': 'Seychelles', 'value': '+248', 'iso': 'SC' },
  { 'label': 'Sierra Leone', 'value': '+232', 'iso': 'SL' },
  { 'label': 'Singapore', 'value': '+65', 'iso': 'SG' },
  { 'label': 'Sint Maarten', 'value': '+1-721', 'iso': 'SX' },
  { 'label': 'Slovakia', 'value': '+421', 'iso': 'SK' },
  { 'label': 'Slovenia', 'value': '+386', 'iso': 'SI' },
  { 'label': 'Solomon Islands', 'value': '+677', 'iso': 'SB' },
  { 'label': 'Somalia', 'value': '+252', 'iso': 'SO' },
  { 'label': 'South Africa', 'value': '+27', 'iso': 'ZA' },
  { 'label': 'Africa', 'value': '+27', 'iso': 'ZA' },
  { 'label': 'South Korea', 'value': '+82', 'iso': 'KR' },
  { 'label': 'South Sudan', 'value': '+211', 'iso': 'SS' },
  { 'label': 'Spain', 'value': '+34', 'iso': 'ES' },
  { 'label': 'Sri Lanka', 'value': '+94', 'iso': 'LK' },
  { 'label': 'Sudan', 'value': '+249', 'iso': 'SD' },
  { 'label': 'Suriname', 'value': '+597', 'iso': 'SR' },
  { 'label': 'Svalbard and Jan Mayen', 'value': '+47', 'iso': 'SJ' },
  { 'label': 'Swaziland', 'value': '+268', 'iso': 'SZ' },
  { 'label': 'Sweden', 'value': '+46', 'iso': 'SE' },
  { 'label': 'Switzerland', 'value': '+41', 'iso': 'CH' },
  { 'label': 'Syria', 'value': '+963', 'iso': 'SY' },
  { 'label': 'Taiwan', 'value': '+886', 'iso': 'TW' },
  { 'label': 'Tajikistan', 'value': '+992', 'iso': 'TJ' },
  { 'label': 'Tanzania', 'value': '+255', 'iso': 'TZ' },
  { 'label': 'Thailand', 'value': '+66', 'iso': 'TH' },
  { 'label': 'Togo', 'value': '+228', 'iso': 'TG' },
  { 'label': 'Tokelau', 'value': '+690', 'iso': 'TK' },
  { 'label': 'Tonga', 'value': '+676', 'iso': 'TO' },
  { 'label': 'Trinidad and Tobago', 'value': '+1-868', 'iso': 'TT' },
  { 'label': 'Tunisia', 'value': '+216', 'iso': 'TN' },
  { 'label': 'Turkey', 'value': '+90', 'iso': 'TR' },
  { 'label': 'Turkmenistan', 'value': '+993', 'iso': 'TM' },
  { 'label': 'Turks and Caicos Islands', 'value': '+1-649', 'iso': 'TC' },
  { 'label': 'Tuvalu', 'value': '+688', 'iso': 'TV' },
  { 'label': 'U.S. Virgin Islands', 'value': '+1-340', 'iso': 'VI' },
  { 'label': 'Uganda', 'value': '+256', 'iso': 'UG' },
  { 'label': 'Ukraine', 'value': '+380', 'iso': 'UA' },
  { 'label': 'United Arab Emirates', 'value': '+971', 'iso': 'AE' },
  { 'label': 'United Kingdom', 'value': '+44', 'iso': 'GB' },
  { 'label': 'UK', 'value': '+44', 'iso': 'GB' },
  { 'label': 'United States', 'value': '+1', 'iso': 'US' },
  { 'label': 'US', 'value': '+1', 'iso': 'US' },
  { 'label': 'USA', 'value': '+1', 'iso': 'US' },
  { 'label': 'Uruguay', 'value': '+598', 'iso': 'UY' },
  { 'label': 'Uzbekistan', 'value': '+998', 'iso': 'UZ' },
  { 'label': 'Vanuatu', 'value': '+678', 'iso': 'VU' },
  { 'label': 'Vatican', 'value': '+379', 'iso': 'VA' },
  { 'label': 'Venezuela', 'value': '+58', 'iso': 'VE' },
  { 'label': 'Vietnam', 'value': '+84', 'iso': 'VN' },
  { 'label': 'Wallis and Futuna', 'value': '+681', 'iso': 'WF' },
  { 'label': 'Western Sahara', 'value': '+212', 'iso': 'EH' },
  { 'label': 'Yemen', 'value': '+967', 'iso': 'YE' },
  { 'label': 'Zambia', 'value': '+260', 'iso': 'ZM' },
  { 'label': 'Zimbabwe', 'value': '+263', 'iso': 'ZW' }];

export const countryNameCode = {
  'Afghanistan': 'AF',
  'Albania': 'AL',
  'Algeria': 'DZ',
  'American Samoa': 'AS',
  'Andorra': 'AD',
  'Angola': 'AO',
  'Anguilla': 'AI',
  'Antarctica': 'AQ',
  'Antigua and Barbud': 'AG',
  'Argentina': 'AR',
  'Armenia': 'AM',
  'Aruba': 'AW',
  'Australia': 'AU',
  'Austria': 'AT',
  'Azerbaijan': 'AZ',
  'Bahamas': 'BS',
  'Bahrain': 'BH',
  'Bangladesh': 'BD',
  'Barbados': 'BB',
  'Belarus': 'BY',
  'Belgium': 'BE',
  'Belize': 'BZ',
  'Benin': 'BJ',
  'Bermuda': 'BM',
  'Bhutan': 'BT',
  'Bolivia': 'BO',
  'Bosnia and Herzegovin': 'BA',
  'Botswana': 'BW',
  'Brazil': 'BR',
  'British Indian Ocea Territory': 'IO',
  'British Virgin Island': 'VG',
  'Brunei': 'BN',
  'Bulgaria': 'BG',
  'Burkina Faso': 'BF',
  'Burundi': 'BI',
  'Cambodia': 'KH',
  'Cameroon': 'CM',
  'Canada': 'CA',
  'Cape Verde': 'CV',
  'Cayman Islands': 'KY',
  'Central African Republi': 'CF',
  'Chad': 'TD',
  'Chile': 'CL',
  'China': 'CN',
  'Christmas Island': 'CX',
  'Cocos Islands': 'CC',
  'Colombia': 'CO',
  'Comoros': 'KM',
  'Cook Islands': 'CK',
  'Costa Rica': 'CR',
  'Croatia': 'HR',
  'Cuba': 'CU',
  'Curacao': 'CW',
  'Cyprus': 'CY',
  'Czech Republic': 'CZ',
  'Democratic Republic othe Congo': 'CD',
  'Denmark': 'DK',
  'Djibouti': 'DJ',
  'Dominica': 'DM',
  'Dominican Republic': 'DO',
  'East Timor': 'TL',
  'Ecuador': 'EC',
  'Egypt': 'EG',
  'El Salvador': 'SV',
  'Equatorial Guinea': 'GQ',
  'Eritrea': 'ER',
  'Estonia': 'EE',
  'Ethiopia': 'ET',
  'Falkland Islands': 'FK',
  'Faroe Islands': 'FO',
  'Fiji': 'FJ',
  'Finland': 'FI',
  'France': 'FR',
  'French Polynesia': 'PF',
  'Gabon': 'GA',
  'Gambia': 'GM',
  'Georgia': 'GE',
  'Germany': 'DE',
  'Ghana': 'GH',
  'Gibraltar': 'GI',
  'Greece': 'GR',
  'Greenland': 'GL',
  'Grenada': 'GD',
  'Guam': 'GU',
  'Guatemala': 'GT',
  'Guernsey': 'GG',
  'Guinea': 'GN',
  'Guinea-Bissau': 'GW',
  'Guyana': 'GY',
  'Haiti': 'HT',
  'Honduras': 'HN',
  'Hong Kong': 'HK',
  'Hungary': 'HU',
  'Iceland': 'IS',
  'India': 'IN',
  'Indonesia': 'ID',
  'Iran': 'IR',
  'Iraq': 'IQ',
  'Ireland': 'IE',
  'Isle of Ma': 'IM',
  'Israel': 'IL',
  'Italy': 'IT',
  'Ivory Coast': 'CI',
  'Jamaica': 'JM',
  'Japan': 'JP',
  'Jersey': 'JE',
  'Jordan': 'JO',
  'Kazakhstan': 'KZ',
  'Kenya': 'KE',
  'Kiribati': 'KI',
  'Kosovo': 'XK',
  'Kuwait': 'KW',
  'Kyrgyzstan': 'KG',
  'Laos': 'LA',
  'Latvia': 'LV',
  'Lebanon': 'LB',
  'Lesotho': 'LS',
  'Liberia': 'LR',
  'Libya': 'LY',
  'Liechtenstein': 'LI',
  'Lithuania': 'LT',
  'Luxembourg': 'LU',
  'Macao': 'MO',
  'Macedonia': 'MK',
  'Madagascar': 'MG',
  'Malawi': 'MW',
  'Malaysia': 'MY',
  'Maldives': 'MV',
  'Mali': 'ML',
  'Malta': 'MT',
  'Marshall Islands': 'MH',
  'Mauritania': 'MR',
  'Mauritius': 'MU',
  'Mayotte': 'YT',
  'Mexico': 'MX',
  'Micronesia': 'FM',
  'Moldova': 'MD',
  'Monaco': 'MC',
  'Mongolia': 'MN',
  'Montenegro': 'ME',
  'Montserrat': 'MS',
  'Morocco': 'MA',
  'Mozambique': 'MZ',
  'Myanmar': 'MM',
  'Namibia': 'NA',
  'Nauru': 'NR',
  'Nepal': 'NP',
  'Netherlands': 'NL',
  'Netherlands Antilles': 'AN',
  'New Caledonia': 'NC',
  'New Zealand': 'NZ',
  'Nicaragua': 'NI',
  'Niger': 'NE',
  'Nigeria': 'NG',
  'Niue': 'NU',
  'North Korea': 'KP',
  'Northern Mariana Island': 'MP',
  'Norway': 'NO',
  'Oman': 'OM',
  'Pakistan': 'PK',
  'Palau': 'PW',
  'Palestine': 'PS',
  'Panama': 'PA',
  'Papua New Guine': 'PG',
  'Paraguay': 'PY',
  'Peru': 'PE',
  'Philippines': 'PH',
  'Pitcairn': 'PN',
  'Poland': 'PL',
  'Portugal': 'PT',
  'Puerto Rico': 'PR',
  'Qatar': 'QA',
  'Republic of th Congo': 'CG',
  'Reunion': 'RE',
  'Romania': 'RO',
  'Russia': 'RU',
  'Rwanda': 'RW',
  'Saint Barthelemy': 'BL',
  'Saint Helena': 'SH',
  'Saint Kitts an Nevis': 'KN',
  'Saint Lucia': 'LC',
  'Saint Martin': 'MF',
  'Saint Pierre anMiquelon': 'PM',
  'Saint Vincent anthe Grenadines': 'VC',
  'Samoa': 'WS',
  'San Marino': 'SM',
  'Sao Tome anPrincipe': 'ST',
  'Saudi Arabia': 'SA',
  'Senegal': 'SN',
  'Serbia': 'RS',
  'Seychelles': 'SC',
  'Sierra Leone': 'SL',
  'Singapore': 'SG',
  'Sint Maarten': 'SX',
  'Slovakia': 'SK',
  'Slovenia': 'SI',
  'Solomon Islands': 'SB',
  'Somalia': 'SO',
  'South Africa': 'ZA',
  'South Korea': 'KR',
  'South Sudan': 'SS',
  'Spain': 'ES',
  'Sri Lanka': 'LK',
  'Sudan': 'SD',
  'Suriname': 'SR',
  'Svalbard and JaMayen': 'SJ',
  'Swaziland': 'SZ',
  'Sweden': 'SE',
  'Switzerland': 'CH',
  'Syria': 'SY',
  'Taiwan': 'TW',
  'Tajikistan': 'TJ',
  'Tanzania': 'TZ',
  'Thailand': 'TH',
  'Togo': 'TG',
  'Tokelau': 'TK',
  'Tonga': 'TO',
  'Trinidad and Tobag': 'TT',
  'Tunisia': 'TN',
  'Turkey': 'TR',
  'Turkmenistan': 'TM',
  'Turks and Caico Islands': 'TC',
  'Tuvalu': 'TV',
  'U.S Virgin Islands': 'VI',
  'Uganda': 'UG',
  'Ukraine': 'UA',
  'United Arab Emirates': 'AE',
  'United Kingdom': 'GB',
  'United States': 'US',
  'Uruguay': 'UY',
  'Uzbekistan': 'UZ',
  'Vanuatu': 'VU',
  'Vatican': 'VA',
  'Venezuela': 'VE',
  'Vietnam': 'VN',
  'Wallis and Futun': 'WF',
  'Western Sahara': 'EH',
  'Yemen': 'YE',
  'Zambia': 'ZM',
  'Zimbabwe': 'ZW'
};
export const currencysymbols={
	"USD": {
		"symbol": "$",
		"name": "US Dollar",
		"symbol_native": "$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "USD",
		"name_plural": "US dollars"
	},
	"CAD": {
		"symbol": "CA$",
		"name": "Canadian Dollar",
		"symbol_native": "$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "CAD",
		"name_plural": "Canadian dollars"
	},
	"EUR": {
		"symbol": "€",
		"name": "Euro",
		"symbol_native": "€",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "EUR",
		"name_plural": "euros"
	},
	"AED": {
		"symbol": "AED",
		"name": "United Arab Emirates Dirham",
		"symbol_native": "د.إ.",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "AED",
		"name_plural": "UAE dirhams"
	},
	"AFN": {
		"symbol": "Af",
		"name": "Afghan Afghani",
		"symbol_native": "؋",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "AFN",
		"name_plural": "Afghan Afghanis"
	},
	"ALL": {
		"symbol": "ALL",
		"name": "Albanian Lek",
		"symbol_native": "Lek",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "ALL",
		"name_plural": "Albanian lekë"
	},
	"AMD": {
		"symbol": "AMD",
		"name": "Armenian Dram",
		"symbol_native": "դր.",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "AMD",
		"name_plural": "Armenian drams"
	},
	"ARS": {
		"symbol": "AR$",
		"name": "Argentine Peso",
		"symbol_native": "$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "ARS",
		"name_plural": "Argentine pesos"
	},
	"AUD": {
		"symbol": "AU$",
		"name": "Australian Dollar",
		"symbol_native": "$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "AUD",
		"name_plural": "Australian dollars"
	},
	"AZN": {
		"symbol": "man.",
		"name": "Azerbaijani Manat",
		"symbol_native": "ман.",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "AZN",
		"name_plural": "Azerbaijani manats"
	},
	"BAM": {
		"symbol": "KM",
		"name": "Bosnia-Herzegovina Convertible Mark",
		"symbol_native": "KM",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "BAM",
		"name_plural": "Bosnia-Herzegovina convertible marks"
	},
	"BDT": {
		"symbol": "Tk",
		"name": "Bangladeshi Taka",
		"symbol_native": "৳",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "BDT",
		"name_plural": "Bangladeshi takas"
	},
	"BGN": {
		"symbol": "BGN",
		"name": "Bulgarian Lev",
		"symbol_native": "лв.",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "BGN",
		"name_plural": "Bulgarian leva"
	},
	"BHD": {
		"symbol": "BD",
		"name": "Bahraini Dinar",
		"symbol_native": "د.ب.",
		"decimal_digits": 3,
		"rounding": 0,
		"code": "BHD",
		"name_plural": "Bahraini dinars"
	},
	"BIF": {
		"symbol": "FBu",
		"name": "Burundian Franc",
		"symbol_native": "FBu",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "BIF",
		"name_plural": "Burundian francs"
	},
	"BND": {
		"symbol": "BN$",
		"name": "Brunei Dollar",
		"symbol_native": "$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "BND",
		"name_plural": "Brunei dollars"
	},
	"BOB": {
		"symbol": "Bs",
		"name": "Bolivian Boliviano",
		"symbol_native": "Bs",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "BOB",
		"name_plural": "Bolivian bolivianos"
	},
	"BRL": {
		"symbol": "R$",
		"name": "Brazilian Real",
		"symbol_native": "R$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "BRL",
		"name_plural": "Brazilian reals"
	},
	"BWP": {
		"symbol": "BWP",
		"name": "Botswanan Pula",
		"symbol_native": "P",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "BWP",
		"name_plural": "Botswanan pulas"
	},
	"BYN": {
		"symbol": "Br",
		"name": "Belarusian Ruble",
		"symbol_native": "руб.",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "BYN",
		"name_plural": "Belarusian rubles"
	},
	"BZD": {
		"symbol": "BZ$",
		"name": "Belize Dollar",
		"symbol_native": "$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "BZD",
		"name_plural": "Belize dollars"
	},
	"CDF": {
		"symbol": "CDF",
		"name": "Congolese Franc",
		"symbol_native": "FrCD",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "CDF",
		"name_plural": "Congolese francs"
	},
	"CHF": {
		"symbol": "CHF",
		"name": "Swiss Franc",
		"symbol_native": "CHF",
		"decimal_digits": 2,
		"rounding": 0.05,
		"code": "CHF",
		"name_plural": "Swiss francs"
	},
	"CLP": {
		"symbol": "CL$",
		"name": "Chilean Peso",
		"symbol_native": "$",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "CLP",
		"name_plural": "Chilean pesos"
	},
	"CNY": {
		"symbol": "CN¥",
		"name": "Chinese Yuan",
		"symbol_native": "CN¥",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "CNY",
		"name_plural": "Chinese yuan"
	},
	"COP": {
		"symbol": "CO$",
		"name": "Colombian Peso",
		"symbol_native": "$",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "COP",
		"name_plural": "Colombian pesos"
	},
	"CRC": {
		"symbol": "₡",
		"name": "Costa Rican Colón",
		"symbol_native": "₡",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "CRC",
		"name_plural": "Costa Rican colóns"
	},
	"CVE": {
		"symbol": "CV$",
		"name": "Cape Verdean Escudo",
		"symbol_native": "CV$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "CVE",
		"name_plural": "Cape Verdean escudos"
	},
	"CZK": {
		"symbol": "Kč",
		"name": "Czech Republic Koruna",
		"symbol_native": "Kč",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "CZK",
		"name_plural": "Czech Republic korunas"
	},
	"DJF": {
		"symbol": "Fdj",
		"name": "Djiboutian Franc",
		"symbol_native": "Fdj",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "DJF",
		"name_plural": "Djiboutian francs"
	},
	"DKK": {
		"symbol": "Dkr",
		"name": "Danish Krone",
		"symbol_native": "kr",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "DKK",
		"name_plural": "Danish kroner"
	},
	"DOP": {
		"symbol": "RD$",
		"name": "Dominican Peso",
		"symbol_native": "RD$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "DOP",
		"name_plural": "Dominican pesos"
	},
	"DZD": {
		"symbol": "DA",
		"name": "Algerian Dinar",
		"symbol_native": "د.ج.",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "DZD",
		"name_plural": "Algerian dinars"
	},
	"EEK": {
		"symbol": "Ekr",
		"name": "Estonian Kroon",
		"symbol_native": "kr",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "EEK",
		"name_plural": "Estonian kroons"
	},
	"EGP": {
		"symbol": "EGP",
		"name": "Egyptian Pound",
		"symbol_native": "ج.م.",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "EGP",
		"name_plural": "Egyptian pounds"
	},
	"ERN": {
		"symbol": "Nfk",
		"name": "Eritrean Nakfa",
		"symbol_native": "Nfk",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "ERN",
		"name_plural": "Eritrean nakfas"
	},
	"ETB": {
		"symbol": "Br",
		"name": "Ethiopian Birr",
		"symbol_native": "Br",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "ETB",
		"name_plural": "Ethiopian birrs"
	},
	"GBP": {
		"symbol": "£",
		"name": "British Pound Sterling",
		"symbol_native": "£",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "GBP",
		"name_plural": "British pounds sterling"
	},
	"GEL": {
		"symbol": "GEL",
		"name": "Georgian Lari",
		"symbol_native": "GEL",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "GEL",
		"name_plural": "Georgian laris"
	},
	"GHS": {
		"symbol": "GH₵",
		"name": "Ghanaian Cedi",
		"symbol_native": "GH₵",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "GHS",
		"name_plural": "Ghanaian cedis"
	},
	"GNF": {
		"symbol": "FG",
		"name": "Guinean Franc",
		"symbol_native": "FG",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "GNF",
		"name_plural": "Guinean francs"
	},
	"GTQ": {
		"symbol": "GTQ",
		"name": "Guatemalan Quetzal",
		"symbol_native": "Q",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "GTQ",
		"name_plural": "Guatemalan quetzals"
	},
	"HKD": {
		"symbol": "HK$",
		"name": "Hong Kong Dollar",
		"symbol_native": "$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "HKD",
		"name_plural": "Hong Kong dollars"
	},
	"HNL": {
		"symbol": "HNL",
		"name": "Honduran Lempira",
		"symbol_native": "L",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "HNL",
		"name_plural": "Honduran lempiras"
	},
	"HRK": {
		"symbol": "kn",
		"name": "Croatian Kuna",
		"symbol_native": "kn",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "HRK",
		"name_plural": "Croatian kunas"
	},
	"HUF": {
		"symbol": "Ft",
		"name": "Hungarian Forint",
		"symbol_native": "Ft",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "HUF",
		"name_plural": "Hungarian forints"
	},
	"IDR": {
		"symbol": "Rp",
		"name": "Indonesian Rupiah",
		"symbol_native": "Rp",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "IDR",
		"name_plural": "Indonesian rupiahs"
	},
	"ILS": {
		"symbol": "₪",
		"name": "Israeli New Sheqel",
		"symbol_native": "₪",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "ILS",
		"name_plural": "Israeli new sheqels"
	},
	"INR": {
		"symbol": "Rs",
		"name": "Indian Rupee",
		"symbol_native": "টকা",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "INR",
		"name_plural": "Indian rupees"
	},
	"IQD": {
		"symbol": "IQD",
		"name": "Iraqi Dinar",
		"symbol_native": "د.ع.",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "IQD",
		"name_plural": "Iraqi dinars"
	},
	"IRR": {
		"symbol": "IRR",
		"name": "Iranian Rial",
		"symbol_native": "﷼",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "IRR",
		"name_plural": "Iranian rials"
	},
	"ISK": {
		"symbol": "Ikr",
		"name": "Icelandic Króna",
		"symbol_native": "kr",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "ISK",
		"name_plural": "Icelandic krónur"
	},
	"JMD": {
		"symbol": "J$",
		"name": "Jamaican Dollar",
		"symbol_native": "$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "JMD",
		"name_plural": "Jamaican dollars"
	},
	"JOD": {
		"symbol": "JD",
		"name": "Jordanian Dinar",
		"symbol_native": "د.أ.",
		"decimal_digits": 3,
		"rounding": 0,
		"code": "JOD",
		"name_plural": "Jordanian dinars"
	},
	"JPY": {
		"symbol": "¥",
		"name": "Japanese Yen",
		"symbol_native": "￥",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "JPY",
		"name_plural": "Japanese yen"
	},
	"KES": {
		"symbol": "Ksh",
		"name": "Kenyan Shilling",
		"symbol_native": "Ksh",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "KES",
		"name_plural": "Kenyan shillings"
	},
	"KHR": {
		"symbol": "KHR",
		"name": "Cambodian Riel",
		"symbol_native": "៛",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "KHR",
		"name_plural": "Cambodian riels"
	},
	"KMF": {
		"symbol": "CF",
		"name": "Comorian Franc",
		"symbol_native": "FC",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "KMF",
		"name_plural": "Comorian francs"
	},
	"KRW": {
		"symbol": "₩",
		"name": "South Korean Won",
		"symbol_native": "₩",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "KRW",
		"name_plural": "South Korean won"
	},
	"KWD": {
		"symbol": "KD",
		"name": "Kuwaiti Dinar",
		"symbol_native": "د.ك.",
		"decimal_digits": 3,
		"rounding": 0,
		"code": "KWD",
		"name_plural": "Kuwaiti dinars"
	},
	"KZT": {
		"symbol": "KZT",
		"name": "Kazakhstani Tenge",
		"symbol_native": "тңг.",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "KZT",
		"name_plural": "Kazakhstani tenges"
	},
	"LBP": {
		"symbol": "L.L.",
		"name": "Lebanese Pound",
		"symbol_native": "ل.ل.",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "LBP",
		"name_plural": "Lebanese pounds"
	},
	"LKR": {
		"symbol": "SLRs",
		"name": "Sri Lankan Rupee",
		"symbol_native": "SL Re",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "LKR",
		"name_plural": "Sri Lankan rupees"
	},
	"LTL": {
		"symbol": "Lt",
		"name": "Lithuanian Litas",
		"symbol_native": "Lt",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "LTL",
		"name_plural": "Lithuanian litai"
	},
	"LVL": {
		"symbol": "Ls",
		"name": "Latvian Lats",
		"symbol_native": "Ls",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "LVL",
		"name_plural": "Latvian lati"
	},
	"LYD": {
		"symbol": "LD",
		"name": "Libyan Dinar",
		"symbol_native": "د.ل.",
		"decimal_digits": 3,
		"rounding": 0,
		"code": "LYD",
		"name_plural": "Libyan dinars"
	},
	"MAD": {
		"symbol": "MAD",
		"name": "Moroccan Dirham",
		"symbol_native": "د.م.",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "MAD",
		"name_plural": "Moroccan dirhams"
	},
	"MDL": {
		"symbol": "MDL",
		"name": "Moldovan Leu",
		"symbol_native": "MDL",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "MDL",
		"name_plural": "Moldovan lei"
	},
	"MGA": {
		"symbol": "MGA",
		"name": "Malagasy Ariary",
		"symbol_native": "MGA",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "MGA",
		"name_plural": "Malagasy Ariaries"
	},
	"MKD": {
		"symbol": "MKD",
		"name": "Macedonian Denar",
		"symbol_native": "MKD",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "MKD",
		"name_plural": "Macedonian denari"
	},
	"MMK": {
		"symbol": "MMK",
		"name": "Myanma Kyat",
		"symbol_native": "K",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "MMK",
		"name_plural": "Myanma kyats"
	},
	"MOP": {
		"symbol": "MOP$",
		"name": "Macanese Pataca",
		"symbol_native": "MOP$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "MOP",
		"name_plural": "Macanese patacas"
	},
	"MUR": {
		"symbol": "MURs",
		"name": "Mauritian Rupee",
		"symbol_native": "MURs",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "MUR",
		"name_plural": "Mauritian rupees"
	},
	"MXN": {
		"symbol": "MX$",
		"name": "Mexican Peso",
		"symbol_native": "$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "MXN",
		"name_plural": "Mexican pesos"
	},
	"MYR": {
		"symbol": "RM",
		"name": "Malaysian Ringgit",
		"symbol_native": "RM",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "MYR",
		"name_plural": "Malaysian ringgits"
	},
	"MZN": {
		"symbol": "MTn",
		"name": "Mozambican Metical",
		"symbol_native": "MTn",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "MZN",
		"name_plural": "Mozambican meticals"
	},
	"NAD": {
		"symbol": "N$",
		"name": "Namibian Dollar",
		"symbol_native": "N$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "NAD",
		"name_plural": "Namibian dollars"
	},
	"NGN": {
		"symbol": "₦",
		"name": "Nigerian Naira",
		"symbol_native": "₦",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "NGN",
		"name_plural": "Nigerian nairas"
	},
	"NIO": {
		"symbol": "C$",
		"name": "Nicaraguan Córdoba",
		"symbol_native": "C$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "NIO",
		"name_plural": "Nicaraguan córdobas"
	},
	"NOK": {
		"symbol": "Nkr",
		"name": "Norwegian Krone",
		"symbol_native": "kr",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "NOK",
		"name_plural": "Norwegian kroner"
	},
	"NPR": {
		"symbol": "NPRs",
		"name": "Nepalese Rupee",
		"symbol_native": "नेरू",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "NPR",
		"name_plural": "Nepalese rupees"
	},
	"NZD": {
		"symbol": "NZ$",
		"name": "New Zealand Dollar",
		"symbol_native": "$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "NZD",
		"name_plural": "New Zealand dollars"
	},
	"OMR": {
		"symbol": "OMR",
		"name": "Omani Rial",
		"symbol_native": "ر.ع.",
		"decimal_digits": 3,
		"rounding": 0,
		"code": "OMR",
		"name_plural": "Omani rials"
	},
	"PAB": {
		"symbol": "B/.",
		"name": "Panamanian Balboa",
		"symbol_native": "B/.",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "PAB",
		"name_plural": "Panamanian balboas"
	},
	"PEN": {
		"symbol": "S/.",
		"name": "Peruvian Nuevo Sol",
		"symbol_native": "S/.",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "PEN",
		"name_plural": "Peruvian nuevos soles"
	},
	"PHP": {
		"symbol": "₱",
		"name": "Philippine Peso",
		"symbol_native": "₱",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "PHP",
		"name_plural": "Philippine pesos"
	},
	"PKR": {
		"symbol": "PKRs",
		"name": "Pakistani Rupee",
		"symbol_native": "₨",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "PKR",
		"name_plural": "Pakistani rupees"
	},
	"PLN": {
		"symbol": "zł",
		"name": "Polish Zloty",
		"symbol_native": "zł",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "PLN",
		"name_plural": "Polish zlotys"
	},
	"PYG": {
		"symbol": "₲",
		"name": "Paraguayan Guarani",
		"symbol_native": "₲",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "PYG",
		"name_plural": "Paraguayan guaranis"
	},
	"QAR": {
		"symbol": "QR",
		"name": "Qatari Rial",
		"symbol_native": "ر.ق.",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "QAR",
		"name_plural": "Qatari rials"
	},
	"RON": {
		"symbol": "RON",
		"name": "Romanian Leu",
		"symbol_native": "RON",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "RON",
		"name_plural": "Romanian lei"
	},
	"RSD": {
		"symbol": "din.",
		"name": "Serbian Dinar",
		"symbol_native": "дин.",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "RSD",
		"name_plural": "Serbian dinars"
	},
	"RUB": {
		"symbol": "RUB",
		"name": "Russian Ruble",
		"symbol_native": "₽.",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "RUB",
		"name_plural": "Russian rubles"
	},
	"RWF": {
		"symbol": "RWF",
		"name": "Rwandan Franc",
		"symbol_native": "FR",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "RWF",
		"name_plural": "Rwandan francs"
	},
	"SAR": {
		"symbol": "SR",
		"name": "Saudi Riyal",
		"symbol_native": "ر.س.",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "SAR",
		"name_plural": "Saudi riyals"
	},
	"SDG": {
		"symbol": "SDG",
		"name": "Sudanese Pound",
		"symbol_native": "SDG",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "SDG",
		"name_plural": "Sudanese pounds"
	},
	"SEK": {
		"symbol": "Skr",
		"name": "Swedish Krona",
		"symbol_native": "kr",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "SEK",
		"name_plural": "Swedish kronor"
	},
	"SGD": {
		"symbol": "S$",
		"name": "Singapore Dollar",
		"symbol_native": "$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "SGD",
		"name_plural": "Singapore dollars"
	},
	"SOS": {
		"symbol": "Ssh",
		"name": "Somali Shilling",
		"symbol_native": "Ssh",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "SOS",
		"name_plural": "Somali shillings"
	},
	"SYP": {
		"symbol": "SY£",
		"name": "Syrian Pound",
		"symbol_native": "ل.س.",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "SYP",
		"name_plural": "Syrian pounds"
	},
	"THB": {
		"symbol": "฿",
		"name": "Thai Baht",
		"symbol_native": "฿",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "THB",
		"name_plural": "Thai baht"
	},
	"TND": {
		"symbol": "DT",
		"name": "Tunisian Dinar",
		"symbol_native": "د.ت.",
		"decimal_digits": 3,
		"rounding": 0,
		"code": "TND",
		"name_plural": "Tunisian dinars"
	},
	"TOP": {
		"symbol": "T$",
		"name": "Tongan Paʻanga",
		"symbol_native": "T$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "TOP",
		"name_plural": "Tongan paʻanga"
	},
	"TRY": {
		"symbol": "TL",
		"name": "Turkish Lira",
		"symbol_native": "TL",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "TRY",
		"name_plural": "Turkish Lira"
	},
	"TTD": {
		"symbol": "TT$",
		"name": "Trinidad and Tobago Dollar",
		"symbol_native": "$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "TTD",
		"name_plural": "Trinidad and Tobago dollars"
	},
	"TWD": {
		"symbol": "NT$",
		"name": "New Taiwan Dollar",
		"symbol_native": "NT$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "TWD",
		"name_plural": "New Taiwan dollars"
	},
	"TZS": {
		"symbol": "TSh",
		"name": "Tanzanian Shilling",
		"symbol_native": "TSh",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "TZS",
		"name_plural": "Tanzanian shillings"
	},
	"UAH": {
		"symbol": "₴",
		"name": "Ukrainian Hryvnia",
		"symbol_native": "₴",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "UAH",
		"name_plural": "Ukrainian hryvnias"
	},
	"UGX": {
		"symbol": "USh",
		"name": "Ugandan Shilling",
		"symbol_native": "USh",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "UGX",
		"name_plural": "Ugandan shillings"
	},
	"UYU": {
		"symbol": "$U",
		"name": "Uruguayan Peso",
		"symbol_native": "$",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "UYU",
		"name_plural": "Uruguayan pesos"
	},
	"UZS": {
		"symbol": "UZS",
		"name": "Uzbekistan Som",
		"symbol_native": "UZS",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "UZS",
		"name_plural": "Uzbekistan som"
	},
	"VEF": {
		"symbol": "Bs.F.",
		"name": "Venezuelan Bolívar",
		"symbol_native": "Bs.F.",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "VEF",
		"name_plural": "Venezuelan bolívars"
	},
	"VND": {
		"symbol": "₫",
		"name": "Vietnamese Dong",
		"symbol_native": "₫",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "VND",
		"name_plural": "Vietnamese dong"
	},
	"XAF": {
		"symbol": "FCFA",
		"name": "CFA Franc BEAC",
		"symbol_native": "FCFA",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "XAF",
		"name_plural": "CFA francs BEAC"
	},
	"XOF": {
		"symbol": "CFA",
		"name": "CFA Franc BCEAO",
		"symbol_native": "CFA",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "XOF",
		"name_plural": "CFA francs BCEAO"
	},
	"YER": {
		"symbol": "YR",
		"name": "Yemeni Rial",
		"symbol_native": "ر.ي.",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "YER",
		"name_plural": "Yemeni rials"
	},
	"ZAR": {
		"symbol": "R",
		"name": "South African Rand",
		"symbol_native": "R",
		"decimal_digits": 2,
		"rounding": 0,
		"code": "ZAR",
		"name_plural": "South African rand"
	},
	"ZMK": {
		"symbol": "ZK",
		"name": "Zambian Kwacha",
		"symbol_native": "ZK",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "ZMK",
		"name_plural": "Zambian kwachas"
	},
	"ZWL": {
		"symbol": "ZWL$",
		"name": "Zimbabwean Dollar",
		"symbol_native": "ZWL$",
		"decimal_digits": 0,
		"rounding": 0,
		"code": "ZWL",
		"name_plural": "Zimbabwean Dollar"
	}
}