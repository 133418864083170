import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { useFormik } from 'formik';
import CreateProfile from './CreateProfile';
import AppContext from '../../../appContext';
import AccountDetails from './AccountDetails';
import { useNavigate } from 'react-router-dom';
import CreateProfilePreview from './CreateProfilePreview';
import { preventBack } from '../../../utils/helperFunctions';
import { AccountDetailsValidation, CreateProfileValidation } from '../../../components/validation/Validation';
import withUnsavedChangesPrompt from '../../../components/unsaved-prompt-wrapper/useUnsavedChangesPrompt';

const Index = ({ setUnsavedChanges }) => {

  const appContext = useContext(AppContext);
  const { state } = appContext;
  const { loggedInUser } = state;

  const [skillD, setSkillD] = useState('');
  const [industriesD, setIndustriesD] = useState('');
  const [timezoneD, setTimezoneD] = useState('');
  const [languagesD, setLanguagesD] = useState('');
  const [countryCodeD, setCountryCodeD] = useState({ label: 'Australia (+61)', value: '+61', iso: 'AU' });
  const [isVisibletoOthersD, setisVisibletoOthersD] = useState({ label: 'Yes', value: true });
  const [cvFieldError, setCvFieldError] = useState(false);
  const [portfolioVideoFieldError, setPortfolioVideoFieldError] = useState(false);
  const [portfolioClass, setPortfolioClass] = useState('text-muted mb-5');

  const handleInputChange = () => {
    // Update your form state or any other relevant state
    setUnsavedChanges(true);
  };



  const navigate = useNavigate();

  const [step, setStep] = useState(1);

  const formik1 = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      profilePic: '',
      skills: [{ value: '', label: '' }],
      language: [{ value: '', label: '' }],
      // postCode: "",
      location: '',
      countryCode: [{ value: '', label: '' }],
      phoneNo: '',
      birthDate: new Date(),
      cv: '',
      timezone: [{ value: '', label: '' }],
      // cvLink: [{ label: "", value: "" }],
      introVideo: '',
      portfolioVideo: '',
      otherLinks: [{ label: '', value: '' }],
      instagram: '',
      facebook: '',
      twitter: '',
      linkedin: '',
      tiktok: '',
      websiteUrl: '',
      websiteUrls: [{ url: '' }],
      industryType: [{ value: '', label: '' }],
      clientsWorkedFor: '',
      isVisibletoOthers: [{ value: '', label: '' }]
    },
    validationSchema: CreateProfileValidation,

    onSubmit: (values) => {
      if (cvFieldError) {
        return;
      }
      if (values.websiteUrls[0].url == '' && values.introVideo == '' && values.cv == '') {
        setPortfolioClass('text-danger mb-5');
      } else if (values?.countryCode == '') {
        formik1.setFieldError('phoneNo', 'Country code can\'t be empty');
      }
      else {
        setUnsavedChanges(false);
        setPortfolioClass('text-muted mb-5');
        setStep(2);
      }
    }
  });

  const formik2 = useFormik({
    initialValues: {
      experience: '',
      workedForYourself: '',
      minDayRate: '',
      currency: 'USD',
      isTermsAccepted: false,
    },
    validationSchema: AccountDetailsValidation,
    onSubmit: (values) => {
      setStep(3);
    }
  });

  const checkMasherProfile = () => {
    if (loggedInUser?.data) {
      navigate('/');
    }
  };


  useEffect(() => {
    checkMasherProfile();
    preventBack();
  }, [loggedInUser]);

  return (
    <>
      {step === 1 &&
        <CreateProfile
          portfolioVideoFieldError={portfolioVideoFieldError}
          setPortfolioVideoFieldError={setPortfolioVideoFieldError}
          formik1={formik1}
          skillD={skillD}
          industriesD={industriesD}
          cvFieldError={cvFieldError}
          setCvFieldError={setCvFieldError}
          setSkillD={setSkillD}
          setIndustriesD={setIndustriesD}
          languagesD={languagesD}
          setLanguagesD={setLanguagesD}
          countryCodeD={countryCodeD}
          setCountryCodeD={setCountryCodeD}
          portfolioClass={portfolioClass}
          setPortfolioClass={setPortfolioClass}
          timezoneD={timezoneD}
          setTimezoneD={setTimezoneD}
          isVisibletoOthersD={isVisibletoOthersD}
          setisVisibletoOthersD={setisVisibletoOthersD}
          handleInputChange={handleInputChange}
        />
      }
      {step === 2 && <AccountDetails formik2={formik2} setStep={setStep} />}
      {step === 3 && <CreateProfilePreview setStep={setStep} formik1={formik1} skillD={skillD} setSkillD={setSkillD} industriesD={industriesD} setIndustriesD={setIndustriesD} languagesD={languagesD} setLanguagesD={setLanguagesD} countryCodeD={countryCodeD} timezoneD={timezoneD} formik2={formik2} isVisibletoOthersD={isVisibletoOthersD} />}
    </>
  );
};

export default withUnsavedChangesPrompt(Index);