import React from 'react';
import { useContext } from 'react';
import AppContext from '../../appContext';
import { Loader2 } from '../loader/loader';
import { API_BASE_URL } from '../../utils/baseurl';
import { Link, useNavigate } from 'react-router-dom';
import EditProfile from '../../Assets/img/covers/profilePicDemo.png';
import ProfilePicDemo from '../../Assets/img/covers/profilePicDemo.png';
import { assignBucketUrl } from '../../utils/helperFunctions';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
    gtmId: 'GTM-KTNLX4FF'
};
TagManager.initialize(tagManagerArgs);
ReactGA.initialize('G-VL3PFPXMSE');
export default function Header() {

  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { state, logout } = appContext;
  const { loggedInUser, isLoggedIn } = state;

  const handleLogout = () => {
    logout(navigate);
  };

  return (
    <>
      <nav
        className="navbar navbar-light position-lg-sticky top-lg-0 d-none d-lg-block overlap-10 flex-none border-bottom px-0 py-8"
        style={{ background: '#eaca43' }}
        id="topbar">
        <div className="container-fluid">
          <div></div>
          <div className="navbar-user d-none d-sm-block">
            <div className="hstack gap-3 ms-4">
              <div className="dropdown">
                <a
                  href=""
                  className="nav-link px-3 text-base text-muted text-opacity-70 text-opacity-100-hover"
                  id="dropdown-notifications"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                </a>
                <div
                  className="dropdown-menu dropdown-menu-end px-2"
                  aria-labelledby="dropdown-notifications"
                >
                  <div className="dropdown-item d-flex align-items-center">
                    <h6 className="dropdown-header p-0 m-0 font-semibold">
                                            Notifications
                    </h6>
                    <a href="#" className="text-sm font-semibold ms-auto">
                                            Clear all
                    </a>
                  </div>
                  <div className="dropdown-item py-3 d-flex">
                    <div>
                      <div className="avatar bg-tertiary text-white rounded-circle">
                                                RF
                      </div>
                    </div>
                    <div className="flex-fill ms-3">
                      <div className="text-sm lg-snug w-64 text-wrap">
                        <a
                          href="#"
                          className="font-semibold text-heading text-primary-hover"
                        >
                                                    Robert
                        </a>
                                                sent a message to
                        <a
                          href="#"
                          className="font-semibold text-heading text-primary-hover"
                        >
                                                    Webpixels
                        </a>
                      </div>
                      <span className="text-muted text-xs">30 mins ago</span>
                    </div>
                  </div>
                  <div className="dropdown-item py-3 d-flex">
                    <div>
                      <img
                        alt="..."
                        src="/img/people/img-1.jpg"
                        className="avatar rounded-circle"
                      />
                    </div>
                    <div className="flex-fill ms-3">
                      <div className="text-sm lg-snug w-64 text-wrap">
                        <a
                          href="#"
                          className="font-semibold text-heading text-primary-hover"
                        >
                                                    Robert
                        </a>
                                                sent a message to
                        <a
                          href="#"
                          className="font-semibold text-heading text-primary-hover"
                        >
                                                    Webpixels
                        </a>
                      </div>
                      <span className="text-muted text-xs">30 mins ago</span>
                    </div>
                  </div>
                  <div className="dropdown-item py-3 d-flex">
                    <div>
                      <img
                        alt="..."
                        src="/img/people/img-2.jpg"
                        className="avatar rounded-circle"
                      />
                    </div>
                    <div className="flex-fill ms-3">
                      <div className="text-sm lg-snug w-64 text-wrap">
                        <a
                          href="#"
                          className="font-semibold text-heading text-primary-hover"
                        >
                                                    Robert
                        </a>
                                                sent a message to
                        <a
                          href="#"
                          className="font-semibold text-heading text-primary-hover"
                        >
                                                    Webpixels
                        </a>
                      </div>
                      <span className="text-muted text-xs">30 mins ago</span>
                    </div>
                  </div>
                  <div className="dropdown-item py-3 d-flex">
                    <div>
                      <div className="avatar bg-success text-white rounded-circle">
                                                KW
                      </div>
                    </div>
                    <div className="flex-fill ms-3">
                      <div className="text-sm lg-snug w-64 text-wrap">
                        <a
                          href="#"
                          className="font-semibold text-heading text-primary-hover"
                        >
                                                    Robert
                        </a>
                                                sent a message to
                        <a
                          href="#"
                          className="font-semibold text-heading text-primary-hover"
                        >
                                                    Webpixels
                        </a>
                      </div>
                      <span className="text-muted text-xs">30 mins ago</span>
                    </div>
                  </div>
                  <div className="dropdown-item py-3 d-flex">
                    <div>
                      <img
                        alt="..."
                        src="/img/people/img-4.jpg"
                        className="avatar rounded-circle"
                      />
                    </div>
                    <div className="flex-fill ms-3">
                      <div className="text-sm lg-snug w-64 text-wrap">
                        <a
                          href="#"
                          className="font-semibold text-heading text-primary-hover"
                        >
                                                    Robert
                        </a>
                                                sent a message to
                        <a
                          href="#"
                          className="font-semibold text-heading text-primary-hover"
                        >
                                                    Webpixels
                        </a>
                      </div>
                      <span className="text-muted text-xs">30 mins ago</span>
                    </div>
                  </div>
                  <div className="dropdown-divider" />
                  <div className="dropdown-item py-2 text-center">
                    <a
                      href="#"
                      className="font-semibold text-muted text-primary-hover"
                    >
                                            View all
                    </a>
                  </div>
                </div>
              </div>
              {/* {
                                loggedInUser?.data?._id && loggedInUser?.data?.userId?.profilePic && loggedInUser?.data?.coverPic ? (<>{""}</>) : (<><p>Build out your profile to get more briefs! ➤ </p></>)
                            } */}
              <p>Build out your profile to get more briefs! ➤ </p>
              <div className="dropdown">
                {isLoggedIn && (<a
                  className="d-flex align-items-center"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <div className='avatar rounded-circle text-white' style={{
                    padding: '10px',
                    border: '15px solid white',
                    background: 'White',
                    borderRadius: '50%',
                    maxWidth: '100%'
                  }}>
                    {loggedInUser?.loading ? (<div className='text-center' style={{
                      height: '25px',
                      width: '25px',
                      marginTop: '2px',
                    }}><Loader2 /></div>) : (<div className="avatar rounded-circle text-white" style={{
                      'backgroundSize': 'cover',
                      'position': 'absolute',
                      'backgroundRepeat': 'no-repeat',
                      'cursor': 'pointer',
                      'bordeRadius': '50%',

                      'backgroundImage': Boolean(loggedInUser?.data?.userId?.profilePic) ? `url(${assignBucketUrl(loggedInUser?.data?.userId?.profilePic)})` : `url(${EditProfile})`,
                    }}>
                      {/* <img src={loggedInUser?.data?.userId?.profilePic ? `${API_BASE_URL}/${loggedInUser?.data?.userId?.profilePic}` : EditProfile} /> */}
                    </div>)}
                  </div>
                  <div className="d-none d-sm-block ms-3">
                    <span className="h6">{`${loggedInUser?.data?.userId?.firstName || 'Hey Masher...'} ${loggedInUser?.data?.userId?.lastName || ''}`}</span>
                  </div>
                  <div className="d-none d-md-block ms-md-2">
                    <i className="bi bi-chevron-down text-muted text-xs" />
                  </div>
                </a>)}
                <div className="dropdown-menu dropdown-menu-end">
                  <div className="dropdown-header">
                    <span className="badge badge-lg badge-dot text-heading font-semibold"><i className="bg-success"></i> {`${loggedInUser?.data?.userId?.firstName || ''} ${loggedInUser?.data?.userId?.lastName || ''}`}</span>
                  </div>
                  <div className="dropdown-divider" />
                  <Link to={`/mashers-profile/${loggedInUser?.data?._id || 'create'}`}>
                    <button className="dropdown-item">
                      <i className="bi bi-pencil me-3" />
                                            Profile
                    </button>
                  </Link>
                  <Link to="/my-mash-toolkit">
                    <button className="dropdown-item">
                      <i className="bi bi-gear me-3" />
                                            My Mash Toolkit
                    </button>
                  </Link>

                  <div className="dropdown-divider" />
                  <button className="dropdown-item cursor-pointer" onClick={handleLogout}>
                    <i className="bi bi-person me-3" />
                                        Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
