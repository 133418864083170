import React, { createContext, useContext,useEffect, useState } from 'react';
import AppContext from '../../appContext';
import { WeavyClient, WeavyProvider, Chat, MessengerContext, MessengerProvider, ConversationList, Conversation } from '@weavy/uikit-react';
import axios from 'axios';
import { Loader } from '../../components/common/loader';
import moment from 'moment';
import '@weavy/uikit-react/dist/css/weavy.css';
import { Link } from 'react-router-dom';
import { weavyTokenApi } from '../../apis';
const NotificationList = ({loggeduser}) => {
  let fname, lname,username,notiCount,weavyClient,briefdetails;
  const accessToken = process.env.REACT_APP_WEAVY_API_KEY;
  const tsKey = process.env.REACT_APP_TYPESENSE_API_KEY;
  const tsHost = process.env.REACT_APP_HOST_NAME;
  const weavyUrl = process.env.REACT_APP_WEAVY_URL;
  const [notifications, setNotifications] = useState([]);
  const [notificationsLoader, setNotiLoader] = useState(true);
  const [notificationsEmpty, setNotiEmpty] = useState(false);
  const [briefLink, setBriefLink] = useState([]);
  fname =  loggeduser?.user?.firstName;
  lname = loggeduser?.user?.lastName;
  username = fname+'-'+lname;
  username = username.replace(/\s+/g, '');
  //console.log(username);
  const getWeavyToken = async (userfname) =>{
    try {
      const values = {firstname: userfname};
      const res = await weavyTokenApi(values);
      if (res.status === 200 || 201) {
        console.log(res);
        return res.data;
      }
      if(userfname === undefined){
        
        console.log('user name undefined');
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getNotifications = async (userfname) =>{
    try {
      const usertoken = await getWeavyToken(userfname);
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: weavyUrl+'/api/notifications?order_by=id+desc&unread=true',
        headers: { 
          'Authorization': 'Bearer '+usertoken,
          'Content-Type': 'application/json',
        }
      };
      axios.request(config)
        .then(response => {
          notiCount=response.data.count;
          if(notiCount == 0){
            setNotiEmpty(true);
            setNotiLoader(false);
          }
          for(let i=0;i<notiCount;i++){
            // console.log('response from axios request',response.data.data[i].args[1]);
            let tsconfig = {
              method: 'get',
              maxBodyLength: Infinity,
              url: 'https://'+tsHost+'/collections/briefsv2/documents/search?q='+response?.data?.data[i]?.args[1]+'&query_by=name',
              headers: { 
                'X-TYPESENSE-API-KEY': tsKey
              }
            };
          
            axios.request(tsconfig)
              .then((response) => {
                briefdetails  = response?.data?.hits[0]?.document._id;
                // console.log('Brief details'+response?.data?.data[i]?.args[1]+'brief id'+briefdetails);
                setBriefLink(current => [...current, briefdetails]);
              })
              .catch((error) => {
                console.log(error);
              });
          }
          setNotifications(response.data.data);
          setNotiLoader(false);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getNotifications(username);
  },[username]);
  return(
    <>
      {notificationsLoader &&
        <div className='card-header'>
          <div className='d-flex align-items-center'>
            <div className='text-center align-items-center p-4 pt-12'>
              <Loader />
            </div>
          </div>
        </div>
      }
      {notificationsEmpty &&
          <div className='card-header'>
              No notifications to display
          </div>
      }
      <div className='card-body py-0 position-relative scrollable-y' style={{ maxHeight: '50vh' }}>
        <div className='list-group list-group-flush'>
          {notifications && notifications.map((data,i) => (
            <div key={i} className='list-group-item px-0 position-relative hstack flex-wrap'>
              <Link to={`/brief/brief-view/${briefLink[i]}`} className='d-block stretched-link'>
                <div className='flex-1'>
                  <div className='d-flex align-items-center d-block'>
                    <div className='text-sm text-muted me-auto' style={{ whiteSpace: 'break-spaces' }}>
                      <div dangerouslySetInnerHTML={{ __html: `<p><strong>${data?.args[0]}</strong> sent a message in <strong>${data?.args[1]}:</strong>&quot;${data.args[2]}&quot;`}} />
                    </div>
                  </div>
                  <div className='d-flex align-items-center mt-1'>
                    <span className='text-muted text-xs'>{moment(data.created_at).fromNow()}</span>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default NotificationList;