import React, { useState } from "react";

const ToggleSwitch = ({togglefunction,checked,setChecked}) => {
  const toggleChecked = () => {
    setChecked((prevChecked) => !prevChecked);
    togglefunction(!checked);
  };

  return (
    <div className="relative inline-block w-10 align-middle select-none">
      <input
        type="checkbox"
        id="toggle"
        name="toggle"
        className="sr-only"
        checked={checked}
        onChange={toggleChecked}
      />
      <label
        htmlFor="toggle"
        className={`toggle-label block overflow-hidden h-5 rounded-full  cursor-pointer ${checked ? 'bg-[#00A46F]' : 'bg-[#dadada]'}`}
        style={{ width: "2.5rem" }}
      >
        <span className="sr-only">Toggle</span>
        <span
          className={`block w-5 h-5 rounded-full bg-white shadow-md transform duration-300 ease-in-out ${checked ? 'translate-x-full' : ''}`}
        />
      </label>
    </div>
  );
};

export default ToggleSwitch;
