import React, { useEffect, useState } from 'react';
import SorryPopup from './SorryPopup';
import AllProjects from './AllProjects';
import { getAllBrief } from '../../apis';
import { useNavigate } from 'react-router-dom';
import AdminLayout from '../../components/admin-layout';
import { logoutOnJwtExpire } from '../../utils/helperFunctions';

export default function Index() {
  const [step, setStep] = useState(1);
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('ASC');
  const [searchFilter, setSearchFilter] = useState({ page: '', limit: '', fieldName: '', orderBy: ''});
  const navigate = useNavigate();
  const [allBriefs, setAllBriefs] = useState({ data: null, loading: false });
  const fetchAllBriefs = async (limit, page, fieldName, orderBy) => {

    try {
      setAllBriefs((v) => ({ ...v, loading: true }));
      const allBriefs = await getAllBrief(limit, page, fieldName, orderBy);
      if ((allBriefs.status === 200)) {
        setAllBriefs((v) => ({ data: allBriefs.data, loading: false }));
      }
    } catch (error) {
      setAllBriefs((v) => ({ data: null, loading: false }));
      if (error?.response?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }

  };

  const handleSortingChange = (accessor) => {
     
    if(accessor){ 
      const currentOrder = order === 'ASC'? 'DESC' : 'ASC';
      setOrder(currentOrder);
      setSortField(accessor);
    }
  };

    
  useEffect(()=> { 
    if(order && sortField) {
      const sortOrder =  order === 'ASC' ? 1 : -1;
      setSearchFilter({ ...searchFilter, fieldName: sortField, orderBy: sortOrder });
    }
  },[order, sortField]);
     
  useEffect(() => {
    const { limit, page, fieldName, orderBy } = searchFilter;
    fetchAllBriefs(limit, page, fieldName, orderBy);
  }, [searchFilter]);
  
  return (
    <AdminLayout>
      <div className="container-fluid">
        <div className="row mx-0">
          <h1 className="h2 ls-tight mt-10"><span style={{ color: '#E6CB47' }}></span>Global Mash Ups</h1>
          <p className="text-muted">Get inspired by what’s going on globally. Jealousy unintended.</p>
        </div>
        <article>
          <div className="border-bottom pt-6">
          </div>
        </article>
        {step === 1 && <AllProjects allBriefs={allBriefs} handleSortingChange={handleSortingChange}/>}
        <SorryPopup />
      </div>
    </AdminLayout>
  );
}
