import React from 'react';
import DashboardHeader from '../Header';
import DashboardSidenav from '../Sidebar';
import HeaderSecond from '../Header/HeaderSecond';
import ClickUpHelpButton from '../ClickUpHelpButton';
import Weavy from '../../Pages/Weavy';

const AdminLayout = ({ children, briefData, invitationId, briefDetails, delLength, masherQuotedObj, sidenav }) => {

  const urlPathLocation = window.location.pathname;

  return (
    <div className="flex flex-col lg:flex-row lg:h-full">
      { !sidenav && <DashboardSidenav /> }
      <div className="flex-lg-1 h-screen overflow-y-auto">
        {/* {
          (urlPathLocation.startsWith('/brief/brief-view/')) && <HeaderSecond masherQuotedObj={masherQuotedObj} delLength={delLength} briefData={briefData} invitationId={invitationId} briefDetails={briefDetails} />
        } */}
        <div className={'content'}>{children}</div>
      </div>
      {/* <ClickUpHelpButton url="https://forms.clickup.com/9003215177/f/8ca4aa9-1502/K3614QV7ME2SAXDFVE" open={false} /> */}
    </div>
  );
};
export default AdminLayout;
