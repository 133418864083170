import { useState } from 'react';

const Weavy = () => {
  const [expanded, IsExpanded] = useState(false);
  return <>
    {expanded 
      ? 
      <div className="fixed bottom-24 right-8 h-[768px] w-96 rounded-lg bg-white border-[#EAECF0] border-2 p-2">
        <p className='text-center font-bold text-2xl py-2'>MASHER CHAT</p>
        <div className='w-full h-16 bg-[#EAECF0] rounded-lg flex justify-between px-2'>
          <div className='flex items-center'>
            <img src="https://i.pravatar.cc/150?img=1" className='z-0 h-12 w-12 rounded-full' />
            <img src="https://i.pravatar.cc/150?img=2" className='z-0 h-12 w-12 rounded-full -ml-8' />
            <img src="https://i.pravatar.cc/150?img=3" className='z-0 h-12 w-12 rounded-full -ml-8' />
          </div>
          <div className='py-2'>
            <p className='font-bold text-black'>Nike Brief</p>
            <p className='text-sm'>This is the last chat message</p>
          </div>
          <div className='flex items-center justify-center'>
            <svg width="16" height="16" viewBox='0 0 24 24' xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg>
          </div>
        </div>
        <div className='w-full h-16 bg-[#EAECF0] rounded-lg flex justify-between px-2 mt-2'>
          <div className='flex items-center'>
            <img src="https://i.pravatar.cc/150?img=1" className='z-0 h-12 w-12 rounded-full' />
          </div>
          <div className='py-2'>
            <p className='font-bold text-black'>Micheal</p>
            <p className='text-sm'>This is the last chat message</p>
          </div>
          <div className='flex items-center justify-center'>
            <svg width="16" height="16" viewBox='0 0 24 24' xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg>
          </div>
        </div>
        <div className='w-full h-16 bg-[#EAECF0] rounded-lg flex justify-between px-2 mt-2'>
          <div className='flex items-center'>
            <img src="https://i.pravatar.cc/150?img=1" className='z-0 h-12 w-12 rounded-full' />
          </div>
          <div className='py-2'>
            <p className='font-bold text-black'>Sam</p>
            <p className='text-sm'>This is the last chat message</p>
          </div>
          <div className='flex items-center justify-center'>
            <svg width="16" height="16" viewBox='0 0 24 24' xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg>
          </div>
        </div>
        <div className='w-full h-16 bg-[#EAECF0] rounded-lg flex justify-between px-2 mt-2'>
          <div className='flex items-center'>
            <img src="https://i.pravatar.cc/150?img=1" className='z-0 h-12 w-12 rounded-full' />
          </div>
          <div className='py-2'>
            <p className='font-bold text-black'>Joy</p>
            <p className='text-sm'>This is the last chat message</p>
          </div>
          <div className='flex items-center justify-center'>
            <svg width="16" height="16" viewBox='0 0 24 24' xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg>
          </div>
        </div>
      </div>
      : 
      <div className="fixed bottom-24 right-8 flex flex-col gap-4">
        <div className='relative flex justify-center items-center'>
          <div className='absolute right-0 top-0 bg-red-600 h-5 w-5 z-10 text-white flex items-center justify-center rounded-full'>1</div>
          <img src="https://i.pravatar.cc/300" className='z-0 h-16 w-16 rounded-full' />
        </div>
      </div>
    }
    <button className="fixed bottom-4 right-8 h-16 w-16 rounded-full bg-black text-white" onClick={() => IsExpanded(!expanded)}>{expanded ? 'X' : 'Chat'}</button>
  </>;
};

export default Weavy;