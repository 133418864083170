import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import ApplyPopup from '../../components/Header/ApplyPopup';
import Flatpickr from 'react-flatpickr';
import AppContext from '../../appContext';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import React, { useContext, useEffect, useRef } from 'react';
import { InputErrorMessage } from '../../components/errorMessages';
import EditProfile from '../../Assets/img/covers/profilePicDemo.png';
import ProfilePicDemo from '../../Assets/img/covers/profilePicDemo.png';
import { EditProfileValidation } from '../../components/validation/Validation';
import { logoutOnJwtExpire, assignBucketUrl } from '../../utils/helperFunctions';
import { EditProfileLoader, ImgLoader, Loader } from '../../components/loader/loader';
import { fileUploadApi, getOtherSkillsApi, getBrandsDataApi, updateMasherProfile, getAllClientsApi, updateUserApi, getLocationInputApi, getTimeZoneLocationApi, getCountryCode, fetchClientApi, fetchIndustryApi } from '../../apis';
import { debounce } from 'lodash';
import { countryCodeOptions, countryNameCode } from '../../utils/countryCode';
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumber } from 'react-phone-number-input';
import Autocomplete from 'react-google-autocomplete';
import { GOOGLE_API } from '../../utils/const';
import LANGUAGES from '../../Assets/docs/languages.json';

export default function Profile({ formData, loading }) {

  const [skillS, setSkillS] = useState({ data: null, loading: false });
  const [brands, setBrands] = useState({ data: null, loading: false });
  const [timezoneArrayD, setTimezoneArrayD] = useState({ data: null, loading: false });
  const [countryCodeArr, setCountryCodeArr] = useState({ data: null });
  const [loader, setLoader] = useState(false);
  const [profile, setProfile] = useState('');
  const [profilePic, setProfilelPic] = useState('');
  const [websiteUrlsState, setWebsiteUrlsState] = useState([{ url: '' }]);
  const appContext = useContext(AppContext);
  const { fetchMasherProfile } = appContext;
  const [select, setSelect] = useState({ industryType: '', clients: '' });
  const [selected, setSelected] = useState({ industryType: { value: '', label: '' }, clientsWorkedFor: { value: '', label: '' } });
  const [countryCodeD, setCountryCodeD] = useState({ label: 'Australia (+61)', value: '+61', iso: 'AU' });

  const navigate = useNavigate();

  const handleLocationChange = async (search) => {
    try {
      const res = await getLocationInputApi(search);

      if (res.status === 200) {
        return res?.data?.length ? res.data.map((e) => ({ label: e.formatted_address, value: e.formatted_address, latitude: e.geometry.location.lat, longitude: e.geometry.location.lng })) : null;
      }
    } catch (error) {
      return null;
    }
  };
  const fetchLocationArray = useCallback(
    debounce((inputText, callback) => {
      if (callback) {
        handleLocationChange(inputText).then((options) => callback(options));
      }
    }, 700),
    []
  );

  const getAllTimeZones = async (country, latitude, longitude) => {
    const res = await getTimeZoneLocationApi(country, latitude, longitude);
    if (res.status === 201) {
      setTimezoneArrayD({ data: res?.data });
    }
  };
  const fetchAllCountryCode = async () => {
    const res = await getCountryCode();
    if (res.status === 200) {
      setCountryCodeArr({ data: res?.data });
    }
  };
  const timezoneOptions = timezoneArrayD?.data?.listedTimeZonesWithTime?.map((el, i) => {
    const container = {};
    container['value'] = el.name;
    container['label'] = el.value;
    return container;
  });

  const phoneNoCode = countryCodeArr?.data?.countries?.map(el => {
    let container = {};
    container['label'] = `${el?.name} (${el?.code})`;
    container['value'] = el?.code;
    return container;
  });



  const visibletoOthers = [{ value: true, label: 'Yes' }, { value: false, label: 'No' }];

  const otherSkillsData = async () => {
    try {
      const res = await getOtherSkillsApi();
      if (res.status === 200) {
        setSkillS({ data: res.data });
      }
    } catch (error) {
      toast.error(error.response.data.err.message);
      if (error?.response?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };
  const brandsData = async () => {
    try {
      const res = await getBrandsDataApi();
      if (res.status === 200) {
        setBrands({ data: res.data });
      }
    } catch (error) {
      toast.error(error.response.data.err.message);
      if (error?.response?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };


  const fetchSelectRegion = async () => {
    const industryRes = await fetchIndustryApi();
    const clientsRes = await getAllClientsApi();
    setSelect({ industry: industryRes.data, clients: clientsRes.data?.allClients });
  };

  const indOptions =
    select?.industry &&
    select?.industry?.map((el, i) => {
      const container = {};
      container['value'] = el.id;
      container['label'] = el?.name || '--';
      return container;
    });

  const typOptions =
    select?.clients &&
    select?.clients?.map((el, i) => {
      const container = {};
      container['value'] = el._id;
      container['label'] = el?.brandName || '--';
      return container;
    });

  const options = skillS?.data?.map((el, i) => {
    const container = {};
    container['value'] = el.id;
    container['label'] = el.name;

    return container;
  });
  const brandOptions = 
  select?.clients &&
  select?.clients?.map((el, i) => {
    const container = {};
    container['value'] = el._id;
    container['label'] = el?.brandName || '--';
    container['logo'] = el.logo;
    return container;
  });
  const handleChangeBirth = (e) => {
    formik.setFieldValue('birthDate', new Date(e).toISOString());
  };
  const languagesOptions = LANGUAGES;
  //  [
  //   { value: '1', label: 'Arabic' },
  //   { value: '2', label: 'Bahasa' },
  //   { value: '3', label: 'Chinese (simplified)' },
  //   { value: '4', label: 'Chinese (traditional)' },
  //   { value: '5', label: 'Danish' },
  //   { value: '6', label: 'Dutch (Netherlands)' },
  //   { value: '7', label: 'English' },
  //   { value: '8', label: 'Finnish' },
  //   { value: '9', label: 'French' },
  //   { value: '10', label: 'German' },
  //   { value: '11', label: 'Greek' },
  //   { value: '12', label: 'Hindi' },
  //   { value: '13', label: 'Japanese' },
  //   { value: '14', label: 'Korean' },
  //   { value: '15', label: 'Norwegian' },
  //   { value: '16', label: 'Polish' },
  //   { value: '17', label: 'Portuguese' },
  //   { value: '18', label: 'Russian' },
  //   { value: '19', label: 'Spanish' },
  //   { value: '20', label: 'Swedish' },
  //   { value: '21', label: 'Thai' },
  //   { value: '22', label: 'Vietnamese' },
  // ];

  const formik = useFormik({
    initialValues: {
      image: '',
      firstName: '',
      lastName: '',
      email: '',
      catchLine: '',
      cv: '',
      birthDate: '',
      introVideo: '',
      portfolioVideo: '',
      designation: '',
      description: '',
      jobTitle: '',
      skills: '',
      location: '',
      language: '',
      timezone: '',
      city: '',
      region: '',
      postCode: '',
      countryCode: '',
      phoneNo: '',
      otherLinks: '',
      brand: '',
      lovedBrand: '',
      linkedin: '',
      instagram: '',
      facebook: '',
      twitter: '',
      tiktok: '',
      websiteUrl: '',
      websiteUrls: [{ url: '' }],
      industryType: '',
      clientsWorkedFor: '',
      isVisibletoOthers: ''
    },
    validationSchema: EditProfileValidation,
    onSubmit: async (values) => {
      const {
        firstName,
        lastName,
        language,
        postCode,
        countryCode,
        phoneNo,
        cv,
        otherLinks,
        skills,
        description,
        designation,
        city,
        region,
        jobTitle,
        birthDate,
        introVideo,
        portfolioVideo,
        catchLine,
        timezone,
        instagram,
        facebook,
        twitter,
        linkedin,
        tiktok,
        websiteUrls,
        location,
        industryType,
        clientsWorkedFor,
        isVisibletoOthers
      } = formik.values;
      try {
        setLoader(true);
        const res1 = await updateMasherProfile({
          language,
          location,
          postCode,
          brand: values?.brand,
          lovedBrand: values.lovedBrand,
          countryCode: countryCode?.value,
          phoneNo,
          cv,
          otherLinks,
          skills,
          description,
          designation,
          city,
          region,
          timezone,
          jobTitle,
          birthDate,
          introVideo,
          portfolioVideo,
          catchLine,
          instagram,
          facebook,
          twitter,
          linkedin,
          tiktok,
          websiteUrls,
          id: formData?._id,
          industryType,
          clientsWorkedFor,
          isVisibletoOthers: isVisibletoOthers?.value
        });

        const payload2 = { firstName, lastName };
        if (profilePic) {
          payload2.profilePic = profilePic;
        }
        const res2 = await updateUserApi(payload2);

        if (res1.status === 200 && res2.status === 200) {
          setLoader(false);
          toast.success(res1.data.message, { id: 'succcess' });
        }
        fetchMasherProfile();
        // }
      } catch (error) {
        setLoader(false);
        toast.error(error.response.data.message, { id: '007' });
        if (error?.response?.data?.status === 401) {
          logoutOnJwtExpire();
          navigate('/sign-in');
        }
      }
    },
  });

  // const [dummy, setDummy] = useState({ label: formik.values.location || "", value: formik.values.location || "" })

  const handleChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
    e.preventDefault();
    formik.handleChange(e);
  };
  const handleAddWebsiteUrls = (e) => {
    setWebsiteUrlsState({ url: e.target.value });
    formik.setFieldValue(e.target.name, websiteUrlsState);
  };
  const handleChangeInstagram = (e) => {
    if (e.target.value[0] == '@') {
      formik.setFieldValue(e.target.name, e.target.value);
      e.preventDefault();
      formik.handleChange(e);
    } else {
      toast.error('Please include an @ at the start', { id: '011' });
      formik.setFieldError('instagram', 'Please include an @ at the start');
      e.preventDefault();
    }
  };
  const handleCvUpload = async (event) => {
    let loadingToast;
    try {
      let introVideo = event.target.files[0];
      const formdata = new FormData();
      formdata.append('file', introVideo);

      if (!introVideo) {
        return;
      }
      loadingToast = toast.loading('Uploading...');
      const type = introVideo.name;
      const videoMp4 = /pdf/g;
      const videoMov = /docx/g;

      let validIVideoType = false;

      let fistD = videoMp4.test(type);
      let secondD = videoMov.test(type);
      if (fistD) {
        validIVideoType = true;
      } else if (secondD) {
        validIVideoType = true;
      }
      if (!validIVideoType) {
        toast.dismiss(loadingToast);
        toast.error('Please upload a valid file type');
        formik.setFieldError('cv', 'Please upload a .pdf, .doc or .docx');
      } else {
        const resFileUpload = await fileUploadApi(formdata);
        if (resFileUpload.status === 200) {
          toast.dismiss(loadingToast);
          formik.setFieldValue('cv', resFileUpload?.data?.filePath);
          toast.success(resFileUpload.data.message, { id: 'succcess' });
        }
      }
    } catch (error) {
      toast.dismiss(loadingToast);
      toast.error(error.response.data.message, { id: '002' });
      if (error?.response?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };
  const handleIntroVideoUpload = async (e) => {
    let loadingToast;

    try {
      let introVideo = e.target.files[0];

      const formdata = new FormData();
      formdata.append('file', introVideo);
      if (!introVideo) {
        return;
      }
      loadingToast = toast.loading('Uploading...');
      const type = introVideo.name;

      const videoMp4 = /mp4/g;
      const videoMov = /mov/g;
      const video3gp = /3gp/g;

      let validIVideoType = false;

      let fistD = videoMp4.test(type);
      let secondD = videoMov.test(type);
      let thirdD = video3gp.test(type);
      if (fistD) {
        validIVideoType = true;
      } else if (secondD) {
        validIVideoType = true;
      } else if (thirdD) {
        validIVideoType = true;
      }

      if (!validIVideoType) {
        toast.dismiss(loadingToast);
        toast.error('Please upload a valid video type');
        formik.setFieldError('introVideo', 'Upload a valid video type : mp4/3gp/mov');
        return;
      }
      if (introVideo.size > 104857600) {
        toast.dismiss(loadingToast);
        toast.error('Please upload file less than 100 mb');
        return;
      }

      const resVideoUpload = await fileUploadApi(formdata);
      if (resVideoUpload.status === 200) {
        toast.dismiss(loadingToast);
        formik.setFieldValue('introVideo', resVideoUpload.data.filePath);
        toast.success(resVideoUpload.data.message, { id: 'succcess' });
      }
    } catch (err) {
      toast.dismiss(loadingToast);
      toast.error(err.response.data.message, { id: '003' });
      if (err?.response?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };
  const handleProfilePicUpload = async (e) => {
    let loadingToast;
    try {
      let file = e.target.files[0];
      if (!file) {
        return;
      }
      loadingToast = toast.loading('Uploading...');
      const type = file.type.split('/')[1];
      const imageType = ['jpeg', 'jpg', 'png'];
      const validImageType = imageType.includes(type);
      if (!validImageType) {
        toast.dismiss(loadingToast);
        toast.error('Please upload a valid image type');
        formik.setErrors('image', 'upload a valid image : jpeg, png, jpg');
        return;
      } else {
        if (file.size > 10000000) {
          toast.dismiss(loadingToast);
          toast.error('Please upload a file less than 10 mb');
          setProfile('');
          return;
        } else {
          let fr = new FileReader();
          fr.readAsDataURL(file);
          fr.onload = function () {
            setProfile(fr.result);
          };
          formik.setErrors({});
        }
        formik.setErrors({});
      }

      const formdata = new FormData();
      formdata.append('file', file);
      const response = await fileUploadApi(formdata);
      if (response.status === 200) {
        toast.dismiss(loadingToast);
        setProfilelPic(response.data.filePath);
        formik.setFieldValue('profilePic', response.data.filePath);
        toast.success(response.data.message, { id: 'succcess' });

      }
    } catch (err) {
      toast.dismiss(loadingToast);

      toast.error(err.response.data.message, { id: '001' });
      if (err?.response?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };

  const handleAddFields = (formik) => {
    const websiteUrls = [...formik.values.websiteUrls, { url: '' }];
    formik.setFieldValue('websiteUrls', websiteUrls);
  };

  const handleRemoveFields = (formik, i) => {
    const webId = formik.values.websiteUrls[i];
    const websiteUrls = formik.values.websiteUrls.filter((el) => el !== webId);
    formik.setFieldValue('websiteUrls', websiteUrls);
  };
  const getCountryExist = (location) => {
    let data = countryCodeOptions.find(item => item.label.toLowerCase() == location.trim().toLowerCase());
    return data;
    // let data = null;
    // if (location?.trim() === "USA") {
    //   let rgex = new RegExp(`\\b${"United States"}\\b`, 'gi');
    //   data = countryCodeOptions?.filter(item => item?.label.match(rgex));
    // } else {
    //   let rgex = new RegExp(`\\b${location?.trim()}\\b`, 'gi');
    //   data = countryCodeOptions?.filter(item => item?.label.match(rgex) || item?.iso.match(rgex));
    // }
    // return data?.[0]
  };
  const handleLocationSet = (e) => {
    formik.setFieldValue('location', e.formatted_address);
    // setDummy({ value: e.formatted_address, label: e.formatted_address })
    getAllTimeZones(e.formatted_address, e.geometry.location.lat(), e.geometry.location.lng());
  };

  useEffect(() => {
    if (formData?._id) {
      formik.setValues({
        image: formData?.userId?.profilePic || '',
        firstName: formData?.userId?.firstName || '',
        lastName: formData?.userId?.lastName || '',
        email: formData?.userId?.email || '',
        catchLine: formData?.catchLine || '',
        birthDate: formData?.birthDate || '',
        designation: formData?.designation || '',
        description: formData?.description || '',
        jobTitle: formData?.jobTitle || '',
        skills: formData?.skills || '',
        introVideo: formData?.introVideo || '',
        portfolioVideo: formData?.portfolioVideo || '',
        language: formData?.language || '',
        cv: formData?.cv || '',
        city: formData?.city || '',
        region: formData?.region || '',
        postCode: formData?.postCode || '',
        countryCode: formData?.countryCode || '',
        phoneNo: formData?.phoneNo || '',
        otherLinks: formData?.otherLinks || '',
        brand: formData?.brand || '',
        lovedBrand: formData?.lovedBrand || '',
        instagram: formData?.instagram || '',
        facebook: formData?.facebook || '',
        twitter: formData?.twitter || '',
        linkedin: formData?.linkedin || '',
        tiktok: formData?.tiktok || '',
        websiteUrl: formData?.websiteUrl || '',
        websiteUrls: formData?.websiteUrls || '',
        location: formData?.location || '',
        isVisibletoOthers: { value: !Object.keys(formData).includes('isVisibletoOthers') ? true : formData.isVisibletoOthers ? true : false, label: !Object.keys(formData).includes('isVisibletoOthers') ? 'Yes' : formData.isVisibletoOthers ? 'Yes' : 'No' } || '',
        timezone: formData?.timezone || '',
        industryType: formData?.industryType || '',
        clientsWorkedFor: formData?.clientsWorkedFor || ''
      });
    }
    // setDummy({ value: formData?.location, label: formData?.location })
  }, [formData]);

  useEffect(() => {
    otherSkillsData();
    brandsData();
    fetchAllCountryCode();
    fetchSelectRegion();
  }, []);

  const inputRef = useRef(null);
  const handleClick = () => {
    inputRef.current.click();
  };
  useEffect(() => {
    if (formik.values.location === '') {
      console.log('null');
    } else {
      let location = null;
      if (formik.values.location.includes(',') && formik.values.location.split(',').length <= 3) {
        let rec = formik.values.location?.split(',');
        if (rec?.[rec.length - 1]?.trim()?.split(' ')) {
          let loc = rec?.[rec.length - 1]?.trim();
          location = loc.match(/\d/) ? loc.split(' ')?.[0] : loc;
        } else {
          location = formik.values.location?.split(',')[1];
        }
      } else {
        location = formik.values.location?.split(' ')?.length > 2 ? formik.values.location.split(' ')?.[formik.values.location?.split(' ')?.length - 1] : formik.values.location;
      }
      const obj = getCountryExist(location);
      const c_code = {};
      c_code['label'] = obj?.label;
      c_code['value'] = obj?.value;
      c_code['iso'] = obj?.iso;
      if (c_code !== null) {
        let _countrCode = (formik.values.countryCode && formik.values.countryCode.value ? formik.values.countryCode.value : formik.values.countryCode);
        if (formik.values.countryCode && _countrCode != c_code['value']) {
          formik.setFieldValue('phoneNo', '');
          formik.setFieldValue('timezone', '');
        }
        formik.setFieldValue('countryCode', c_code);
        setCountryCodeD(c_code);
      }
    }
  }, [formik.values.location]);
  return (
    <>
      {/* Container */}
      <div className='row avtar-outer align-items-center mb-10 position-relative'>
        <input
          type='file'
          id='profile'
          // value={formik.values.image}
          name='image'
          onChange={handleProfilePicUpload}
          disabled={loader}
          hidden={true}
          className='form-control'
        // inputProps={{ accept: "image/*" }}
        />

        {loading ? (
          <div className='text-center mt-5 ms-5'>
            <ImgLoader />
          </div>
        ) : (
          <div className='avtar-text'>
            <div className='seting-avtar col-md-6'>
              <div className='row align-items-center'>
                <div className='profileImg col-sm-2'>
                  <label
                    className='bg-repeat-0 bg-position-center bg-size-cover'
                    style={{
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundColor: 'white',
                      top: '-20%',
                      width: '70px',
                      height: '70px',
                      border: '1px solid rgb(25, 19, 19, 0.15)',
                      cursor: 'pointer',
                      borderRadius: '50%',
                      boxShadow: 'rgb(0 0 0 / 25%) 0px 5px 12px 2px',
                      backgroundImage: `${profile
                        ? `url(${encodeURI(profile)})`
                        : Boolean(formData?.userId?.profilePic)
                          ? `url(${assignBucketUrl(formData?.userId?.profilePic)})`
                          : `url(${EditProfile})`
                      }`,
                    }}></label>
                </div>
                <div className='col-sm-10 profileImgText'>
                  <label>Your avatar</label>
                  <p>PNG or JPG no bigger than 1000px wide and tall.</p>
                  <div className='profileImgUpload'>
                    <label htmlFor='profile'>
                      <div className='btn btn-sm btn_primary_black mt-2' onClick={handleClick}>
                        Upload
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {loading ? (
        <div className='text-center'>
          <Loader />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit} className='mb-10'>
          <div className='row'>
            <div className='col-md-6 mb-5'>
              <label className='form-label'>First Name</label>
              <input
                type='text'
                name='firstName'
                value={formik.values.firstName}
                onChange={handleChange}
                disabled={loader}
                className={Boolean(formik.touched?.firstName && formik.errors?.firstName) ? 'form-control border-danger' : 'form-control'}
                placeholder='First Name'
              />
              <InputErrorMessage error={formik.touched.firstName && formik.errors.firstName} marginBottom={-5} />
            </div>
            <div className='col-md-6 mb-5'>
              <label className='form-label'>Last Name</label>
              <input
                type='text'
                name='lastName'
                onChange={handleChange}
                value={formik.values.lastName}
                className={Boolean(formik.touched?.lastName && formik.errors?.lastName) ? 'form-control border-danger' : 'form-control'}
                disabled={loader}
                placeholder='Last Name'
              />
              <InputErrorMessage error={formik.touched.lastName && formik.errors.lastName} marginBottom={-5} />
            </div>
            <div className='col-md-12 mb-5'>
              <label className='form-label'>Email address</label>
              <input
                type='text'
                name='email'
                onChange={handleChange}
                value={formik.values.email}
                className={Boolean(formik.touched?.email && formik.errors?.email) ? 'form-control border-danger' : 'form-control'}
                disabled={true}
              />
              <InputErrorMessage error={formik.touched.email && formik.errors.email} marginBottom={-5} />
            </div>
            <div className='col-md-12 mb-5'>
              <label className='form-label'>Top Skills</label>
              <Select
                isMulti
                name='skills'
                closeMenuOnSelect={false}
                options={options}
                placeHolder='Your top skills'
                value={formik.values.skills}
                className={Boolean(formik.touched?.skills && formik.errors?.skills) ? 'form-control border-danger p-0' : 'form-control p-0'}
                onChange={(e) => {
                  formik.setFieldValue('skills', e);
                }}
              />
              <InputErrorMessage error={formik.touched.skills && formik.errors.skills} marginBottom={-5} />
            </div>
            <div className='col-md-12 mb-5'>
              <label className='form-label'>Language</label>
              <CreatableSelect
                isMulti
                name='language'
                // isDisabled={true}
                closeMenuOnSelect={false}
                options={languagesOptions}
                className={Boolean(formik.touched?.language && formik.errors?.language) ? 'form-control border-danger p-0' : 'form-control p-0'}
                onChange={(e) => {
                  formik.setFieldValue('language', e);
                }}
                placeHolder='Languages you know'
                value={formik.values.language}
              />
              <InputErrorMessage error={formik.touched.language && formik.errors.language} marginBottom={-5} />
            </div>
            <div className='col-md-6 mb-5'>
              <label className='form-label'>Industry</label>
              <Select
                options={indOptions}
                isMulti
                closeMenuOnSelect={false}
                name='industryType'
                value={formik.values.industryType}
                onChange={(e) => {
                  formik.setFieldValue('industryType', e);
                }}
                className={Boolean(formik.touched?.industryType && formik.errors?.industryType) ? 'form-control border-danger p-0' : 'form-control p-0'}
                classNamePrefix='select'
              />
              <InputErrorMessage error={formik.touched.industryType && formik.errors.industryType} marginBottom={-5} />
            </div>
            <div className='col-md-6 mb-5'>
              <label className='form-label'>Clients worked for</label>
              <CreatableSelect
                isMulti
                options={typOptions}
                closeMenuOnSelect={false}
                className="form-control p-0"
                name="clientsWorkedFor"
                placeHolder="Clients Worked For"
                value={formik.values.clientsWorkedFor?.length > 0
                  ? formik.values.clientsWorkedFor?.map((el) => {
                    return el;
                  })
                  : ['']}
                onChange={(e) => {
                  formik.setFieldValue('clientsWorkedFor', e);
                }}
              />
              <InputErrorMessage error={formik.touched.clientsWorkedFor && formik.errors.clientsWorkedFor} marginBottom={-5} />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>Full Address</label>
              <span className="text-danger"> *</span>
              <Autocomplete
                fetchDetails={true} // you need this to fetch the details object onPress
                apiKey={GOOGLE_API}
                placeholder={''}
                name='location'
                className={Boolean(formik.touched?.location && formik.errors?.location) ? 'form-control border-danger' : 'form-control'}
                defaultValue={formik.values.location}
                onPlaceSelected={(s) => handleLocationSet(s)}
                options={{ types: ['geocode', 'establishment'] }} />
              {/* <AsyncSelect
                cacheOptions
                loadOptions={fetchLocationArray}
                onChange={(e) => { handleLocationSet(e) }}
                defaultOptions
                name='location'
                className={Boolean(formik.touched?.timezone && formik.errors?.timezone) ? "form-control border-danger p-0" : "form-control p-0"}
                value={dummy}
                onFocus={() => { setDummy({ value: "", label: "" }) }}
              /> */}
              <InputErrorMessage error={formik.touched.location && formik.errors.location} marginBottom={-5} />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Time Zone</label>
              <span className="text-danger"> *</span>
              <Select
                className={Boolean(formik.touched?.timezone && formik.errors?.timezone) ? 'form-control border-danger p-0' : 'form-control p-0'}
                name="timezone"
                options={timezoneOptions}
                placeHolder="Your timezone"
                onChange={(e) => formik.setFieldValue('timezone', e)}
                value={formik.values.timezone}
                noOptionsMessage={({ inputValue }) => !inputValue ? 'Please Select Full Address' : 'No results found'}
              />
              <InputErrorMessage error={formik.touched.timezone && formik.errors.timezone} marginBottom={-5} />
            </div>
            <div className="col-md-6 mb-5">
              <label className="form-label">Phone No.</label>
              <span className="text-danger"> *</span>
              <div className="input-group position-relative">
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry={countryCodeD?.iso}
                  international={true}
                  value={formik?.values?.phoneNo}
                  // rules={{ required: true }}
                  onChange={(e) => {
                    if (e) {
                      setCountryCodeD({ label: countryCodeOptions.filter(item => item.value == e.trim())?.[0]?.iso, value: e });
                    }
                    formik.setFieldValue('phoneNo', e || '');
                  }}
                />

              </div>
              <InputErrorMessage error={formik.touched.phoneNo && formik.errors.phoneNo} marginBottom={-5} />
            </div>
            <div className="col-md-6 mb-5">
              <label className="form-label">Do you want to be seen by other mashers?</label>
              <div className="position-relative">
                <Select
                  name="isVisibletoOthers"
                  className={Boolean(formik.touched.isVisibletoOthers && formik.errors.isVisibletoOthers ? 'form-control border-danger p-0' : 'form-control p-0')}
                  options={visibletoOthers}
                  onChange={(e) => formik.setFieldValue('isVisibletoOthers', e)}
                  value={formik.values.isVisibletoOthers}
                  isClearable={false}
                />
              </div>
              <InputErrorMessage error={formik.touched.isVisibletoOthers && formik.errors.isVisibletoOthers} marginBottom={-5} />
            </div>
            <div className='col-md-6 mb-5'>
              <label className='form-label'>Birthday <small><em>(for birthday celebration purpose only)</em></small></label>
              <Flatpickr
                className='form-control cursor-pointer'
                style={{ backgroundColor: '#fff' }}
                options={{ dateFormat: 'j M' }}
                // data-enable-time
                name='birthDate'
                // enableTime={false}
                value={formik.values.birthDate}
                onChange={(event) => {
                  handleChangeBirth(event);
                }}
              />
              <InputErrorMessage error={formik.touched.postCode && formik.errors.postCode} marginBottom={-5} />
            </div>
            <div className='col-md-6 mb-5 ' style={{ position: 'relative' }}>
              <label className='form-label' style={{ display: 'block' }}>
                CV
              </label>
              {formik?.values?.cv ? (
                <a className='blackOnHover' href={`${formik?.values?.cv}`} rel='noreferrer' target='_blank'>
                  <div
                    style={{
                      width: '80px',
                      height: '80px',
                      border: '1px solid rgb(25, 19, 19, 0.25)',
                      borderRadius: '50%',
                      boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                      marginRight: '10px',
                    }}
                    className='icon icon-shape text-xl bg-black text-white'>
                    <i className='bi bi-file-earmark-fill'></i>
                  </div>
                </a>
              ) : (
                <img
                  src={ProfilePicDemo}
                  alt="profile-pic"
                  style={{
                    width: '80px',
                    height: '80px',
                    border: '1px solid rgb(25, 19, 19, 0.25)',
                    borderRadius: '50%',
                    boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                    marginRight: '10px',
                  }}
                />
              )}
              <div className='profileImgUpload d-inline-block  '>
                <label htmlFor='cv'>
                  <div
                    className='changeFileBtn'
                    onClick={() => {
                      inputRef?.current?.click();
                    }}>
                    Choose File
                  </div>
                </label>
              </div>


              {formik.values?.cv !== '' ? (
                <div
                  className='removeFileBtn d-inline-block  '
                  onClick={() => {
                    formik.setFieldValue('cv', '');
                  }}>
                  Remove
                </div>
              ) : (
                <div className=' d-inline-block'>No CV Found</div>
              )}
              <input
                type='file'
                name='cv'
                onChange={handleCvUpload}
                placeholder=''
                id='cv'
                className={
                  Boolean(formik.touched?.cv) && Boolean(formik.errors?.cv)
                    ? 'btn btn-sm btn_primary_black edit-pen2 d-none fileInput'
                    : '  btn btn-sm btn_primary_black edit-pen2 d-none fileInput'
                }
              />
              <InputErrorMessage error={formik.touched.cv && formik.errors.cv} marginBottom={-5} />
            </div>

            <div className='col-md-6 mb-5 ' style={{ position: 'relative' }}>
              <label className='form-label' style={{ display: 'block' }}>
                Introductory Video
              </label>

              {formik?.values?.introVideo ? (
                <a className='blackOnHover' href={`${formik?.values?.introVideo}`} rel='noreferrer' target='_blank'>
                  <div
                    style={{
                      width: '80px',
                      height: '80px',
                      border: '1px solid rgb(25, 19, 19, 0.25)',
                      borderRadius: '50%',
                      boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                      marginRight: '10px',
                    }}
                    className='icon icon-shape text-xl bg-black text-white'>
                    <i className='bi bi-camera-video-fill'></i>
                  </div>
                </a>
              ) : (
                <img
                  src={ProfilePicDemo}
                  style={{
                    width: '80px',
                    height: '80px',
                    border: '1px solid rgb(25, 19, 19, 0.25)',
                    borderRadius: '50%',
                    boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                    marginRight: '10px',
                  }}
                />
              )}
              <div className='profileImgUpload d-inline-block  '>
                <label htmlFor='introVideo'>
                  <div
                    className='changeFileBtn'
                    onClick={() => {
                      inputRef?.current?.click();
                    }}>
                    Choose File
                  </div>
                </label>
              </div>


              {formik.values?.introVideo !== '' ? (
                <div
                  className='removeFileBtn d-inline-block  '
                  onClick={() => {
                    formik.setFieldValue('introVideo', '');
                  }}>
                  Remove
                </div>
              ) : (
                <div className=' d-inline-block'>No Intro Video Found</div>
              )}
              <input
                type='file'
                name='introVideo'
                onChange={handleIntroVideoUpload}
                placeholder=''
                id='introVideo'
                className={
                  Boolean(formik.touched?.introVideo) && Boolean(formik.errors?.introVideo)
                    ? 'btn btn-sm btn_primary_black edit-pen2 d-none fileInput'
                    : '  btn btn-sm btn_primary_black edit-pen2 d-none fileInput'
                }
              />
              <InputErrorMessage error={formik.touched.introVideo && formik.errors.introVideo} marginBottom={-5} />
            </div>
            <div className='col-md-12 mb-5'>
              <label className='form-label'>Brands I have worked with</label>
              <CreatableSelect
                isMulti
                name='brand'
                options={brandOptions}
                placeHolder='Brands'
                value={formik.values.brand}
                className={Boolean(formik.touched?.brand && formik.errors?.brand) ? 'form-control border-danger p-0' : 'form-control p-0'}
                onChange={(e) => {
                  formik.setFieldValue('brand', e);
                }}
              />
              <InputErrorMessage error={formik.touched.brand && formik.errors.brand} marginBottom={-5} />
            </div>
            <div className='col-md-12 mb-5'>
              <label className='form-label'>Brands I Love</label>
              <CreatableSelect
                isMulti
                name='lovedBrand'
                options={brandOptions}
                placeHolder='Brands I Love'
                value={formik.values.lovedBrand}
                className={Boolean(formik.touched?.lovedBrand && formik.errors?.lovedBrand) ? 'form-control border-danger p-0' : 'form-control p-0'}
                onChange={(e) => {
                  formik.setFieldValue('lovedBrand', e);
                }}
              />
              <InputErrorMessage error={formik.touched.lovedBrand && formik.errors.lovedBrand} marginBottom={-5} />
            </div>
            <div className='col-md-6 mb-5'>
              <label className='form-label'>Linked In</label>
              <input
                type='text'
                className={Boolean(formik.touched?.linkedin && formik.errors?.linkedin) ? 'form-control border-danger' : 'form-control'}
                name='linkedin'
                value={formik.values.linkedin}
                onChange={handleChange}
                placeholder='LinkedIn'
              />
              <InputErrorMessage error={formik.touched.linkedin && formik.errors.linkedin} marginBottom={-5} />
            </div>
            <div className='col-md-6 mb-5'>
              <label className='form-label'>Facebook</label>
              <input
                type='text'
                className={Boolean(formik.touched?.facebook && formik.errors?.facebook) ? 'form-control border-danger' : 'form-control'}
                name='facebook'
                value={formik.values.facebook}
                onChange={handleChange}
                placeholder='Facebook'
              />
              <InputErrorMessage error={formik.touched.facebook && formik.errors.facebook} marginBottom={-5} />
            </div>
            <div className='col-md-6 mb-5'>
              <label className='form-label'>Twitter</label>
              <input
                type='text'
                className={Boolean(formik.touched?.twitter && formik.errors?.twitter) ? 'form-control border-danger' : 'form-control'}
                name='twitter'
                value={formik.values.twitter}
                onChange={handleChange}
                placeholder='Twitter'
              />
              <InputErrorMessage error={formik.touched.twitter && formik.errors.twitter} marginBottom={-5} />
            </div>
            <div className='col-md-6 mb-5'>
              <label className='form-label'>Instagram</label>
              <input
                type='text'
                className={Boolean(formik.touched?.instagram && formik.errors?.instagram) ? 'form-control border-danger' : 'form-control'}
                name='instagram'
                value={formik.values.instagram}
                onChange={handleChangeInstagram}
                placeholder='Instagram'
              />
              <InputErrorMessage error={formik.touched.instagram && formik.errors.instagram} marginBottom={-5} />
            </div>
            <div className='col-md-12 mb-5'>
              <label className='form-label'>TikTok</label>
              <input
                type='text'
                className={Boolean(formik.touched?.tiktok && formik.errors?.tiktok) ? 'form-control border-danger' : 'form-control'}
                name='tiktok'
                value={formik.values.tiktok}
                onChange={handleChange}
                placeholder='TikTok'
              />
              <InputErrorMessage error={formik.touched.tiktok && formik.errors.tiktok} marginBottom={-5} />
            </div>
            <div className='col-md-12 mb-5'>
              <label className='form-label'>Website Url</label>
              {
                formik.values.websiteUrls.length > 0 ?
                  formik.values.websiteUrls?.map((el, i, initArr) => {
                    return (
                      <div key={`website-urls-${i}`}>
                        <div className='row' style={{ marginBottom: '10px' }}>
                          <div className='col-12'>
                            <input
                              type='text'
                              className={'form-control'}
                              placeholder="Website URL"
                              name={`websiteUrls.${[i]}.url`}
                              value={formik.values.websiteUrls?.[i]?.url}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <InputErrorMessage error={formik.errors.websiteUrls?.[i]?.url && formik.errors.websiteUrls?.[i]?.url} marginBottom={5} />
                        {
                          Boolean(formik.values.websiteUrls?.length - 1 === i) ? (
                            formik.values.websiteUrls?.length < 5 ?
                              <div className='mb-2 col-2 p-0 btn-area-details d-inline mt-5 mx-1 ' style={{ marginLeft: '0px' }}>
                                {
                                  formik.values.websiteUrls?.[0]?.url === ''
                                    ?
                                    (
                                      <button
                                        disabled
                                        className='btn btn-sm btn-neutral border-base py-2 buton-width-del mb-5 mx-1'
                                      >
                                        <i className='bi bi-plus-lg'></i> Add More
                                      </button>
                                    )
                                    :
                                    (
                                      <span
                                        className='btn btn-sm btn-neutral border-base py-2 buton-width-del mb-5 mx-1'
                                        onClick={() => handleAddFields(formik)}
                                      >
                                        <i className='bi bi-plus-lg'></i> Add More
                                      </span>
                                    )
                                }
                              </div> : ''
                          ) : null
                        }
                        {
                          Boolean(initArr?.length > 1) && (
                            <div className='mb-2 col-2 p-0 btn-area-details d-inline mt-5 mx-1'>
                              <span
                                className='btn btn-sm btn-neutral-rmv btn-neutral border-base py-2 buton-width-del mb-5 text-danger  mx-1 '
                                onClick={() => handleRemoveFields(formik, i)}
                              >
                                <i className='bi bi-dash-lg text-danger'></i> Remove
                              </span>
                            </div>
                          )
                        }
                      </div>
                    );
                  })
                  :
                  ''
              }
              {
                formik.values.websiteUrls?.length === 0
                  ?
                  <input
                    type='text'
                    className={Boolean(formik.touched?.websiteUrls && formik.errors?.websiteUrls) ? 'form-control border-danger' : 'form-control'}
                    name='websiteUrls'
                    value={websiteUrlsState?.url}
                    onChange={(e) => handleAddWebsiteUrls(e)}
                    placeholder='Website Url'
                  />
                  :
                  ''
              }
            </div>
            <div className='text-right-custom'>
              {
                formik.errors?.websiteUrls ?
                  <button className='btn btn-sm btn_primary_black d-inline-block' disabled>
                    Save Changes
                  </button>
                  :
                  <button type='submit' className='btn btn-sm btn_primary_black d-inline-block'>
                    {!loader ? (
                      'Save Changes'
                    ) : (
                      <div className='text-xs w-full'>
                        <EditProfileLoader />
                      </div>
                    )}
                  </button>
              }
            </div>
          </div>
        </form>
      )}
      <ApplyPopup />
    </>
  );
}
