import { Dialog } from '@headlessui/react';
import MasherChat from '../../components/MasherChat';

const ChatModal = ({loggeduser, briefowner, isOpen, onClose}) => {
  return  <Dialog  className="relative z-50" open={isOpen} onClose={onClose}>
    <div className="fixed top-16 inset-0 flex w-screen items-center justify-center p-4 bg-white flex-col">
      <button onClick={onClose} className='w-full h-12 bg-white flex items-center justify-end'>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6L6 18M6 6L18 18" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
      <MasherChat  loggeduser={loggeduser} briefowner={briefowner} />
    </div>
  </Dialog>;
};

export default ChatModal;