import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import GmailPopup from './GmailPopup';
import ReferCreativepopup from '../ReferCreativepopup';
import AdminLayout from '../../components/admin-layout';

import './MasherProfile.css';

import { useFormik } from 'formik';
import { AccountEditValidation } from '../../components/validation/Validation';
import { InputErrorMessage } from '../../components/errorMessages';
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumber } from 'react-phone-number-input';
import { countryCodeOptions, countryNameCode } from '../../utils/countryCode';
import Flatpickr from 'react-flatpickr';
import { otherMasherProfileApi, updateMasherProfile, createProfileApi,DeleteMasherApi,updateMasheruserProfile } from '../../apis';
import { Loader, EditProfileLoader } from '../../components/loader/loader';
import toast from 'react-hot-toast';
import { useContext } from 'react';
import AppContext from '../../appContext';
import { TrashFill } from 'react-bootstrap-icons';
import ToggleSwitch from '../../components/button/Togglebtn';
export default function Index() {

  const [skills, setSkills] = useState([]);
  const [masherData, setMasherData] = useState({ data: null, loading: false });
  const [loader, setLoader] = useState(false);
  const [privateAccess,setprivateAccess]=useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const loading = masherData?.loading;
  const appContext = useContext(AppContext);
  const { state, fetchMasherProfile } = appContext;
  const { loggedInUser } = state;
  const fetchOtherMasherProfile = async () => {
    setMasherData({ loading: true });
    try {
      const res = await otherMasherProfileApi(params?.masherId);
      if (res.status === 200) {
        // toast.success(, { id : "002" })
        setMasherData({ data: res?.data, loading: false });
      }
    } catch (error) {
      setMasherData({ loading: false });
      if (error?.response?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };

  useEffect(() => {
    if(params?.masherId!=='create'){
      fetchMasherProfile();
      fetchOtherMasherProfile();
    }
  }, []);
  useEffect(() => {
    setprivateAccess(masherData?.data?.masherProfile?.isVisibletoOthers)
  }, [masherData]);
  const[deleteloader,setdeleteloader]=useState(false);
  const handleDeleteAccount = async() => {
    setdeleteloader(true);
    try {
      const res = await DeleteMasherApi({email:formik.values.email,firstName:formik.values.firstName});
      if (res.status === 200) {
        setdeleteloader(false);
        const subject = 'Account deletion required';
        const body = 'Hi, I am reaching out to request the deletion of my profile and portfolio data on Mash. Please consider this email as a formal request for the removal of my account from your platform. I appreciate your prompt attention to this matter.';
      
        // Compose the mailto URL with subject and body
        const mailtoUrl = `mailto:community@thisismash.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      
        // Open the default email client
        window.location.href = mailtoUrl;
      }
    } catch (error) {
     setdeleteloader(false)
    }
   
  };
  
  const user_name = (loggedInUser?.user?.firstName || '') + ' ' + (loggedInUser?.user?.lastName || '');
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      user_id:masherData?.data?.masherProfile?.userId?._id || '',
      userrole:loggedInUser?.user?.role || '',
      firstName:loggedInUser?.user?.firstName || '',
      name: user_name || '',
      email: loggedInUser?.user?.email || '',
      phoneNo: (masherData?.data?.masherProfile?.countryCode || '+61') + (masherData?.data?.masherProfile?.phoneNo || ''),
      city: masherData?.data?.masherProfile?.city || '',
      country: masherData?.data?.masherProfile?.country || '',
      birthDate: masherData?.data?.masherProfile?.birthDate || '',
      experience:masherData?.data?.masherProfile?.workDetails?.experience || '',
      workedForYourself:masherData?.data?.masherProfile?.workDetails?.workedForYourself || '',
      minDayRate: masherData?.data?.masherProfile?.workDetails?.minDayRate || '',
      currency: masherData?.data?.masherProfile?.workDetails?.currency || 'USD',
      countryISO:masherData?.data?.masherProfile?.workDetails?.countryISO || '',
      

    },
    validationSchema: AccountEditValidation,
    onSubmit: async (values) => {
      const { user_id,name, email, phoneNo, city, country, birthDate, experience,workedForYourself,minDayRate, currency,countryISO } = values;
      const selectedCountry = countryCodeOptions.find(item => item.value === phoneNo.trim());
 
      const phoneNumber = parsePhoneNumber(phoneNo.trim());
      const location = (country ? country : '') + (city ? ', ' + city : '');
      try {
        setLoader(true);
        if(params?.masherId==='create'){
          try {
            setLoader(true);
            const res = await createProfileApi({
              'workDetails': {
                'experience': experience || '',
                'workedForYourself': workedForYourself || '',
                'minDayRate': minDayRate || '',
                'currency': currency || '',
                'countryISO':countryISO || ''
              },
              'location':location ,
              'country': country || '',
              'city': city || '',
              'countryCode':'+'+phoneNumber.countryCallingCode,
              'phoneNo':phoneNumber.nationalNumber,
              'birthDate': birthDate || '',



            });
            if (res.status === 200 || 201) {
              setLoader(false);
              toast.success(res.data.message, { id: 'succcess' });
              setTimeout(() => {
                navigate('/');
              }, 2000);

            }
          } catch (error) {
            setLoader(false);
            toast.error(error.response.data.message, { id: '001' });
          }

        }
        else{
          try {
            setLoader(true);
            const updateuser = await updateMasheruserProfile({
              'id': user_id || '',
              'email':email,
            });
            const res = await updateMasherProfile({
              'id': params?.masherId || '',
              'workDetails': {
                'experience': experience || '',
                'workedForYourself': workedForYourself || '',
                'minDayRate': minDayRate || '',
                'currency': currency || '',
                'countryISO':countryISO || '',
                
              },
              'location':location ,
              'country': country || '',
              'city': city || '',
              'countryCode':'+'+phoneNumber.countryCallingCode,
              'phoneNo':phoneNumber.nationalNumber,
              'birthDate': birthDate || '',

            });
            if (res.status === 200 || 201) {
              setLoader(false);
              toast.success(res.data.message, { id: 'succcess' });
                 // fetchOtherMasherProfile();
                 window.location.reload();
            }
          } catch (error) {
            setLoader(false);
            toast.error(error, { id: '001' });
          }
        }
      } catch (error) {
        setLoader(false);
        toast.error(error.response.data.message, { id: '001' });
      }
    }
  });
  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };
 
  const handleChangeBirth = (e) => {
    formik.setFieldValue('birthDate', new Date(e).toISOString());
  };
  const onDrop = useCallback(acceptedFiles => {

  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.pdf', '.sfg']
    }, onDrop
  });
  const updatevisibality=async(state)=>{
    try {
      const res = await updateMasherProfile({
        'id': params?.masherId || '',
        'isVisibletoOthers': state,
      });
      if (res.status === 200 || 201) {
        toast.success(res.data.message, { id: 'succcess' });
      }
    } catch (error) {
      toast.error(error.response.data.message, { id: '001' });
    }
  }

  const submitForm = () => {
    if (Object.keys(formik.errors).length > 0) {
      // If there are errors, find the first one
      const firstErrorField = Object.keys(formik.errors)[0];
      // Scroll to the first error field
      const errorFieldElement = document.getElementById(firstErrorField);
      if (errorFieldElement) {
        errorFieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };
  return (
    <AdminLayout>
      <div className='d-flex flex-column h-lg-full masher-profile-container'>
        <header>
          <div className='masher-profile-page-header w-full flex flex-row justify-between items-center'>
            <p className='font-semibold text-[#101828] text-3xl'>Account</p>
            <p className='font-semibold text-[#101828] text-1xl'><ToggleSwitch togglefunction={updatevisibality} checked={privateAccess} setChecked={setprivateAccess} /> Visible to Mashers</p>
          </div>
          <div className='masher-profile-navbar gap-4'>
            <Link className='masher-profile-navbar-selected' to={`/account/${params?.masherId || 'create'}`}>
              <div>
                Account
              </div>
            </Link>
            <Link to={`/mashers-profile/${loggedInUser?.data?._id || 'create'}`}>
              <div>
                Profile
              </div>
            </Link>
            <Link to={`/portfolio/${loggedInUser?.data?._id || 'create'}`}>
              <div>
                Portfolio
              </div>
            </Link>
          </div>
        </header>
        {loading ? (
          <div className='text-center mt-10'>
            <Loader />
          </div>
        ) : (
          <div>
            <div>
              <form className='masher-profile-form' onSubmit={formik.handleSubmit}>
                <div className='w-full hidden md:flex justify-between items-start md:items-center pb-6 mb-6 border-b border-[#EAECF0]'>
                  <div>
                  </div>
                  {/* <div className='flex gap-4 mt-2 md:mt-0'>
                    <button className='text-lg font-semibold text-[#00A46F] w-full py-2 px-4' type="button">Cancel</button>
                    <button className='text-lg font-semibold text-white bg-[#00A46F] w-full py-2 px-4' type='submit' >
                      {!loader ? ('Save') : (<div className="text-xs"><EditProfileLoader /></div>)}
                    </button>
                  </div> */}
                </div>

                <div>
                  <label>
                    Name*
                  </label>
                  <div>
                    <div className='input-group'>
                      <input type='text'
                        onKeyDown={(e) => ['+', '-'].includes(e.key) && e.preventDefault()}
                        className={Boolean(formik.touched?.name && formik.errors?.name) ? 'form-control border-danger' : 'form-control'}
                        id='name'
                        value={formik.values.name}
                        onChange={handleChange}
                        disabled={true}
                      />
                    </div>
                    <InputErrorMessage error={formik.touched.name && formik.errors.name} />
                  </div>
                </div>


                <div>
                  <label>
                    Email*
                  </label>
                  <div>
                    <div className='input-group'>
                      <input type='email'
                        onKeyDown={(e) => ['+', '-'].includes(e.key) && e.preventDefault()}
                        className={Boolean(formik.touched?.email && formik.errors?.email) ? 'form-control border-danger' : 'form-control'}
                        id='email'
                        // disabled={(formik.values.userrole!=='' && formik.values.userrole=='user') ? false : true}
                        value={formik.values.email}
                        onChange={handleChange} />
                    </div>
                    <InputErrorMessage error={formik.touched.email && formik.errors.email} />
                  </div>
                </div>
                <div>
                  <label>
                    Mobile*
                  </label>
                  <div>
                    <div className='input-group'>
                      {/* <input type='email' className='form-control' /> */}
                    
                      <PhoneInput
                        placeholder="Enter phone number"
                        defaultCountry={'AU'}
                        international
                        value={formik?.values?.phoneNo}
                        rules={{ required: true }}
                        onChange={(e) => {
                          formik.setFieldValue('phoneNo', e || '');
                        }}
                      />
                    </div>
                    <InputErrorMessage error={formik.touched.phoneNo && formik.errors.phoneNo} />
                  </div>
                </div>
                <div>
                  <label>
                    City*
                  </label>
                  <div>
                    <div className='input-group'>
                      <input type='text'
                        onKeyDown={(e) => ['+', '-'].includes(e.key) && e.preventDefault()}
                        className={Boolean(formik.touched?.city && formik.errors?.city) ? 'form-control border-danger' : 'form-control'}
                        id='city'
                        value={formik.values.city}
                        onChange={handleChange} />
                    </div>
                    <InputErrorMessage error={formik.touched.city && formik.errors.city} />
                  </div>
                </div>
                <div>
                  <label>
                    Country*
                  </label>
                  <div>
                    <div className='input-group'>
                      {/* <select className="form-select" name="region">
                    <option selected>Select</option>
                  </select> */}
                      <select
                        className={Boolean(formik.touched?.country && formik.errors?.country) ? 'form-select border-danger' : 'form-select'}
                        id='country'
                        value={formik.values.country}
                        onChange={handleChange}
                      >
                        <option value=''>Select Country</option>
                        {Object.entries(countryNameCode).map(([countryName, countryCode]) => (
                          <option key={countryCode} value={countryName}>
                            {countryName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <InputErrorMessage error={formik.touched.country && formik.errors.country} />
                  </div>
                </div>
                <div>
                  <label>
                    Birth Date*
                  </label>
                  <div>
                    <div className='input-group'>


                      {/* <input type='text' className='form-control' /> */}
                      <Flatpickr
                        className={Boolean(formik.touched?.birthDate && formik.errors?.birthDate) ? 'form-control cursor-pointer flatpickr-month border-danger' : 'form-control cursor-pointer flatpickr-month'}
                        // className='form-control cursor-pointer flatpickr-month'
                        options={{ dateFormat: 'd M' }}
                        name="birthDate"
                        value={formik.values.birthDate}
                        onChange={(event) => {
                          handleChangeBirth(event);
                        }}
                      />

                    </div>
                    <InputErrorMessage error={formik.touched.birthDate && formik.errors.birthDate} />
                  </div>
                </div>
                <div>
                  <label>
                    Minimum Day Rate*
                  </label>
                  <div>
                    <div className='input-group'>
                      {/* <input type='text' className='form-control' /> */}
                      <select className="currency-selector-new" name="currency" id="currency" onChange={handleChange} 
                        value={formik.values.currency}>
                        <option data-symbol="$" value={'USD'} data-placeholder="0.00" selected>USD</option>
                        <option data-symbol="$" value={'AUD'} data-placeholder="0.00">AUD</option>
                        <option data-symbol="£" value={'GBP'} data-placeholder="0.00">GBP</option>
                        <option data-symbol="¥" value={'EUR'} data-placeholder="0">EUR</option>
                        <option data-symbol="$" value={'SGD'} data-placeholder="0.00">SGD</option>
                      </select>
                      <input
                        type="number"
                        onKeyDown={(e) => ['+', '-'].includes(e.key) && e.preventDefault()}
                        className={Boolean(formik.touched?.minDayRate && formik.errors?.minDayRate) ? 'form-control border-danger' : 'form-control'}
                        id="minDayRate"
                        name="minDayRate"
                        value={formik.values.minDayRate}
                        onChange={handleChange}
                        placeholder="Enter your minimum day rate"
                      />


                    </div>
                    <InputErrorMessage error={formik.touched.minDayRate && formik.errors.minDayRate} />
                  </div>
                </div>
                <div className='w-full border-b border-[#EAECF0]'></div>
                
                 
                <div className='w-full flex justify-start items-start md:items-center mt-0'>
              
                 
                  <div className='flex gap-1 account-del' onClick={() => handleDeleteAccount()}>
                   
                   <div className='delete-acc-icon'>
                    {
                      !deleteloader && <TrashFill color='#FF3838' fontSize={15} />
                    }
                      
                    </div>
                    {!deleteloader ? ('Delete My Account') : (<div className="text-xs ml-10"><EditProfileLoader /></div>)}
                  
                   
                  </div>
                </div>
                
              
                <div className='w-full flex justify-end items-end md:items-center mt-0'>
                  <div className='flex gap-4'>
                    <button className='text-lg font-semibold text-[#00A46F] w-full py-2 px-4' type="button">Cancel</button>
                    <button className='text-lg font-semibold text-white bg-[#00A46F] w-full py-2 px-4' type="submit" onClick={submitForm}>
                      {!loader ? ('Save') : (<div className="text-xs"><EditProfileLoader /></div>)}

                    </button>

                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <GmailPopup />
      <ReferCreativepopup />
    </AdminLayout>
  );
}
