import React from 'react';

export default function AskingModal() {
  return (
    <>
      <div
        className="modal fade" 
        id="modalAsking"
        tabIndex={-1}
        aria-labelledby="modalAsking"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content shadow-3">
            <div className="modal-header">
              <div className="d-flex full-width justify-content-center">
                <h5 className="mb-1">
                  Why We Are Asking For Each Of These Things?
                </h5>
              </div>
              <div className="ms-auto">
                {/* <div className="form-check form-switch me-n2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckChecked"
                          defaultChecked=""
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckChecked"
                        />
                      </div> */}
              </div>
            </div>
            <div className="modal-body">
              {/* Text */}
              <div className="d-flex align-items-center mb-5">
                <p className="text-center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercLorem ipsum dolor
                  sit amet, consectetur adipiscing elit, s
                </p>
              </div>
              {/* Form group */}
            </div>
            <div className="modal-footer justify-content-center">
              <button type="button" className="btn btn-sm btn-primary">
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
