import React, { useState } from 'react';
import toast from 'react-hot-toast';
import Avatar from '../../../components/Avatar/Avatar';
import useOpenController from '../../../Hooks/useOpenController';
import ExpandableButton from '../components/ExpandableButton';
import editIcon from '../../../Icons/editIconSimple.svg';
import trashIcon from '../../../Icons/trachIcon.svg';
import plusIconBlack from '../../../Icons/plusIconBlack.svg';
import { addRoleApi, assignMasherToRoleApi, removeMasherFromRoleApi } from '../../../apis/index';
import { Loader, EditProfileLoader } from '../../../components/common/loader';
import ProfilePreview from '../../../components/ProfileView';


function RoleCard({deliverable, briefId, refreshBrief,loggedInUser}) {
  // const { deliverableName } = deliverable;
  const[currentid,setcurrentid]=useState('');

  const { isOpen, toggle } = useOpenController(false);
  const [opened, setOpened] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addRole, setAddRole] = useState(false);
  const [removeMasher, setRemoveMasher] = useState(false);
  const [removeMasherLoading, setRemoveMasherLoading] = useState(false);
  const [currentMasher, setCurrentMasher] = useState();
  const [currentRole, setCurrentRole] = useState();
  
  const { deliverableId, role} = deliverable;

  const handleAddRole = async () => {
    // setLoading(true);
    setAddRole(true);
    // const res = await assignMasherToRoleApi({ id: briefId, deliverableId});
    
    // if (res?.data?.roleId) {
    //   setLoading(false);
    //   // refreshBrief();
    // }
  };

  return (
    deliverable ? deliverable.role?.map((role, j) => {
      return (
        <>
          <div className='role-card w-[550px] md:w-full' key={`Role-Card-${role.roleId}`}>
            <div className="role-card-header">
              <div className='role-card-left-detail' style={{display: 'flex', alignItems: 'center'}}>
                <ExpandableButton isOpen={opened.findIndex((o) => o === deliverable.role[j].roleId) > -1} toggle={() => {
                  toggle();
                  setOpened((prev) => {
                    let index = opened.findIndex((o) => o === deliverable.role[j].roleId);
                    if (index < 0) {
                      prev.push(deliverable.role[j].roleId);
                    } else {
                      prev.splice(index, 1);
                    }

                    return prev;
                  });
                }} backgroundColor='#FFF'/>
                <div>
                  <h5 style={{marginBottom: '5px'}}>{ deliverable.deliverableName }</h5>
                  <p>{deliverable.role ? deliverable.role[j]?.roleName : ''}</p>
                </div>
              </div>
              <div className='role-card-masher-avatars'>
                {
                  deliverable.role ?
                    deliverable.role[j]?.Masher?.map((masher, index) => {
                      return (
                        <div key={index} className='role-card-avatar-list'>
                          <div className='role-card-avatar-container' style={{zIndex:index, right: `${index * 10}px`}}>
                            <Avatar name={masher.masherName} url={masher.masherAvatarUrl} id={masher.masherId} borderColor='white'/>
                          </div>
                        </div>
                      );
                    }) :
                    <></>
                }
              </div>
            </div>

            {opened.findIndex((o) => o === deliverable.role[j].roleId) > -1 && 
            <div className='role-card-body w-fit'>
              <div className='role-card-body-block w-fit'>
                {
                  deliverable.role ?
                    deliverable.role[j]?.Masher?.map((masher, i) => {
                      return (
                        <div key={i} className='role-card-masher-info w-fit'>
                          <div  className='role-card-masher-empty-column'></div>
                          <div className='role-card-masher-details-column'>
                            <button 
                              data-bs-toggle="modal"
                              data-bs-target="#profilePreview"
                              id="profilePreview_open"
                              onClick={()=>{setcurrentid(masher.masherId);}}
                              className='search-card-masher-name'
                            >
                              <div className='role-card-masher-details'>
                                <Avatar name={masher.masherName} url={masher.masherAvatarUrl} id={masher.masherId}/>
                                <div>
                                  <span className='form-label' style={{minWidth: 'fit-content', marginLeft: '10px'}}>{masher.masherName}</span>
                                </div>
                              </div>
                            </button>
                          </div>
                        </div>
                      );
                    })
                    : <></>
                }
              </div>
            </div>
            }      
          </div>
          <ProfilePreview id={currentid} loggedInUser={loggedInUser} />
        </>
      );
    }) : []    
  );
}

export default RoleCard;