import React, { useContext, useEffect } from 'react';
import AdminLayout from '../../components/admin-layout';
import Profile from './Profile';
import FormSetting from './FormSetting';
import AppContext from '../../appContext';

export default function Index() {

  const appContext = useContext(AppContext);
  const { state, fetchMasherProfile } = appContext;
  const { loggedInUser } = state;

  useEffect(() => {
    fetchMasherProfile();
  }, []);

  return (
    <AdminLayout>
      <div className="container">
        <div className="dashboard-head brief-head">
          <h1 className="h2 ls-tight">Settings</h1>
        </div>
        <article>
          <div className="border-bottom pt-6">
            <ul className="nav nav-tabs overflow-x border-0" id="nav-tab" role="tablist">
              <li className="nav-item">
                <a href="#" className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">
                  Profile
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">
                  Work Details
                </a>
              </li>
            </ul>
          </div>
          <main className="py-6">
            {/* Offcanvas */}

            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <Profile formData={loggedInUser?.data} loading={loggedInUser?.loading} />
              </div>
              <div
                className="tab-pane fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <FormSetting formData={loggedInUser?.data} fetchMasherProfile={fetchMasherProfile} />
              </div>
            </div>
          </main>
        </article>
      </div>
      <div
        className="modal fade"
        id="modalExport"
        tabIndex={-1}
        aria-labelledby="modalExport"
        aria-hidden="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content shadow-3">
            <div className="modal-header">
              <div className="icon icon-shape rounded-3 bg-soft-primary text-primary text-lg me-4">
                <i className="bi bi-globe" />
              </div>
              <div>
                <h5 className="mb-1">Invite New Mashers</h5>
                <small className="d-block text-xs text-muted">
                  Publish and share link with anyone
                </small>
              </div>
              <div className="ms-auto">
                {/* <div className="form-check form-switch me-n2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckChecked"
                          defaultChecked=""
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckChecked"
                        />
                      </div> */}
              </div>
            </div>
            <div className="modal-body">
              {/* Text */}
              <div className="d-flex align-items-center mb-5">
                <div className="form-group">
                  <label className="mb-2">
                    Enter Email Id/URL Link to New Mashers to invite to the
                    Portal
                  </label>
                  <div className="row align-items-center">
                    <div className="col-9">
                      <input
                        type="text"
                        placeholder="Enter your Email id"
                        className="form-control"
                      />
                    </div>
                    <div className="col-3 text-end">
                      <button className="btn btn-sm btn-primary button-font-size py-3">
                        Add More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* Form group */}
              <div>
                <div className="input-group input-group-inline">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="username"
                    defaultValue="https://webpixels.io/your-amazing-link"
                  />
                  <span className="input-group-text">
                    <i className="bi bi-clipboard" />
                  </span>
                </div>
                <span className="mt-2 valid-feedback">Looks good!</span>
              </div>
            </div>
            <div className="modal-footer">
              <div className="me-auto">
                <a href="#" className="text-sm font-semibold">
                  <i className="bi bi-clipboard me-2" />
                  Copy link
                </a>
              </div>
              <button
                type="button"
                className="btn btn-sm btn-neutral"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-sm btn-success">
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}
