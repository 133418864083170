import React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from 'react-bootstrap/Tooltip';
import AskingModal from '../Popup/AskingModal';
import TermDoc from '../../../Assets/docs/Terms.pdf';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { logoutOnJwtExpire } from '../../../utils/helperFunctions';
import LogoImage from '../../../Assets/img/logos/mash_logo.png';
import { InputErrorMessage } from '../../../components/errorMessages';

export default function AccountDetails({ formik2, setStep }) {

  const handleChange = (e) => {
    formik2.setFieldValue(e.target.name, e.target.value);
    e.preventDefault();
    formik2.handleChange(e);
  };
  const handleSelectCurrency = (e) => {
    formik2.setFieldValue(e.target.name, e.target.value);
    e.preventDefault();
    formik2.handleChange(e);
  };

  return (
    <>
      <div className="min-h-screen bg-surface-secondary d-flex flex-column justify-content-center">
        <div className="row mx-0">
          <div className="col-lg-3 p-xl-10 position-fixed start-0 top-0 h-screen overflow-y-hidden bg_yellow_color d-none d-lg-flex flex-column">
            <img src={LogoImage} width="150" />
            <div className='mt-10'>
              <h1 className='ls-tight font-bolder display-5 text-dark mb-5'>Our office is only 148 billion square metres</h1>
              <p className="text-dark h4 mb-2">A place for you to connect, collaborate and grow in our community. </p>
              <small className="font-semibold" style={{ 'color': 'rgb(95,95,95)' }}>Already signed up? </small>
              <Link to="/sign-in" className="text-sm font-semibold si-hover-2" onClick={logoutOnJwtExpire}>
                <p
                  className=""
                >
                  Login here.
                </p>
              </Link>
              <div className="position-lg-absolute bottom-0 end-0 my-8 mx-12 text-center text-lg-end">
              </div>
            </div>
          </div>
          <div className="col-12 col-md-9 col-lg-9 offset-lg-3 border-left-lg min-h-screen d-flex flex-column position-relative">
            <div className="py-lg-16 px-lg-20">
              <div className="row">
                <h1 className="ls-tight font-bolder h2 mb-5">
                  Just A Few things to set up your account
                </h1>
                <form onSubmit={formik2.handleSubmit}>
                  <div className="col-lg-10 col-md-9 PY-4 col-xl-6 mx-auto ms-xl-0">
                    <div className="mb-5" >
                      <label className="form-label" htmlFor="experience">
                        How many years of experience do you have in this sector?
                      </label>
                      <span className="text-danger"> *</span>
                      <div className="input-group">
                        <span className="input-group-text">Years</span>
                        <input
                          type="number"
                          onKeyDown={(e) =>['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()} 
                          className={Boolean(formik2.touched?.experience && formik2.errors?.experience) ? 'form-control border-danger' : 'form-control'}
                          id="experience"
                          name="experience"
                          value={formik2.values.experience}
                          onChange={handleChange}
                        />
                      </div>
                      <InputErrorMessage error={formik2.touched.experience && formik2.errors.experience} marginBottom={-5} />
                    </div>
                    <div className="mb-5">
                      <label className="form-label" htmlFor="workedForYourself">
                        How many years have you been working for yourself?
                      </label>
                      <span className="text-danger"> *</span>
                      <div className="input-group">
                        <span className="input-group-text">Years</span>
                        <input
                          type="number"
                          onKeyDown={(e) =>['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()} 
                          className={Boolean(formik2.touched?.workedForYourself && formik2.errors?.workedForYourself) ? 'form-control border-danger' : 'form-control'}
                          id="workedForYourself"
                          name="workedForYourself"
                          value={formik2.values.workedForYourself}
                          onChange={handleChange}
                        />
                      </div>
                      <InputErrorMessage error={formik2.touched.workedForYourself && formik2.errors.workedForYourself} marginBottom={-5} />
                    </div>
                    <div className="mb-5">
                      <label className="form-label" htmlFor="email">
                        What is your minimum day rate ?
                        <OverlayTrigger
                          delay={{ hide: 450, show: 300 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              Don’t worry, we won’t hold you to this. It’s just a guide for us and won’t show on your public profile.
                            </Tooltip>)}
                          placement="top">
                          <i className="bi bi-info-circle ms-1" />
                        </OverlayTrigger>
                      </label>
                      <span className="text-danger"> *</span>
                      <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                        <div className="input-group-addon currency-addon">
                          <select className="currency-selector" name="currency" onChange={handleSelectCurrency}>
                            <option data-symbol="$" value={'USD'} data-placeholder="0.00" selected>USD</option>
                            <option data-symbol="$" value={'AUD'} data-placeholder="0.00">AUD</option>
                            <option data-symbol="£" value={'GBP'} data-placeholder="0.00">GBP</option>
                            <option data-symbol="¥" value={'EUR'} data-placeholder="0">EUR</option>
                            <option data-symbol="$" value={'SGD'} data-placeholder="0.00">SGD</option>
                          </select>
                        </div>
                        <input
                          type="number"
                          onKeyDown={(e) =>['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()} 
                          className={Boolean(formik2.touched?.minDayRate && formik2.errors?.minDayRate) ? 'form-control border-danger' : 'form-control'}
                          id="minDayRate"
                          name="minDayRate"
                          value={formik2.values.minDayRate}
                          onChange={handleChange}
                          placeholder="Enter your minimum day rate"
                        />
                      </div>
                      <InputErrorMessage error={formik2.touched.minDayRate && formik2.errors.minDayRate} marginBottom={-5} />
                    </div>
                    <div className="mb-5" >
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="isTermsAccepted"
                          onChange={e => formik2.setFieldValue('isTermsAccepted', e.target.checked ? true : false)}
                          value={formik2.values.isTermsAccepted}
                          id="check_example"
                        />
                        <label className="form-label" htmlFor="experience">
                          <p className="text-black">I agree to the <a href={TermDoc} target="_blank" className="terms-services">Terms of Service.</a></p>
                        </label>
                      </div>
                      <InputErrorMessage error={formik2.touched.isTermsAccepted && formik2.errors.isTermsAccepted} marginBottom={-5} />
                    </div>
                    <div className="btn-back-cntnr">
                      <button onClick={() => { setStep(1); }} className="btn btn-primary me-4 btn_primary_black">
                        Back
                      </button>
                      <button className="btn btn-primary btn_primary_black" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AskingModal />
    </>
  );
}
