import { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { applyToBriefNew, applyToBriefV2 } from '../../apis';
import { ApplyBriefValidation } from '../../components/validation/Validation';
import toast from 'react-hot-toast';
import { EditProfileLoader } from '../../components/loader/loader';
import { currencysymbols } from '../../utils/countryCode';

const ApplyToBriefModal = ({ brief = {}, masher = {}, isOpen = false, setIsOpen = () => { }, onUpdate = () => { }, currency, quoteData }) => {
  const [updating, setUpdating] = useState(false);
  const [quotes, setQuotes] = useState({});
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  console.log('brief', brief);
  console.log('masher', masher);
  const params = useParams();

  const formik = useFormik({
    initialValues: {
      deliverables: [],
      role_id: [],
      masherID: [],
      deliverableId: [],
      role: quoteData || null
    },
    onSubmit: async (values) => {
      const areAllValuesFilled = values.role && values.role.every((item) => !!item.value);
      if (!areAllValuesFilled) {
        // Not all deliverables are filled
        toast.error('Please fill in all the fields before submitting', { id: '002' });
        return;
      }
      else {
        try {
          setLoader(true);
          for (let i = 0; i < values.role.length; i++) {
            const roleId = values.role[i].roleId;
            const quotes = values.role[i].value;
            const masherID = masher.masherProfile?._id;
            const deliverableId = values.role[i].deliverableId;
            const res = await applyToBriefNew({
              "briefId": params?.briefId,
              "masherId": masherID,
              "roleId": roleId,
              "deliverableId": deliverableId,

              "data": {
                "status": "accepted",
                "masherQuote": quotes,
              }
            });

            if (res.status === 200 || 201) {
              // toast.success(res.data.message, { id: 'success' });
            }
          }
          navigate('/');
          setLoader(false);

        } catch (error) {
          setLoader(false);
          toast.error(error.response?.data?.message || 'An error occurred', { id: '001' });
        }

      }

    },
  });
  function updateQuoteData(deliverableId, roleId, value, currentRoleData) {
    return currentRoleData.map((deliverable) => {
      if (deliverable.deliverableId === deliverableId && deliverable.roleId === roleId) {
        // Update the value for the matching roleId
        return { ...deliverable, value: value };
      }
      // If the deliverable does not match, return it unchanged
      return deliverable;
    });
  }
  const handleChange = (e) => {
    formik.handleChange(e);
  };
  useEffect(() => {
    formik.setFieldValue('role', quoteData || null);
  }, [quoteData]);
  var currencyurl = `https://www.xe.com/currencyconverter/convert/?Amount=1&From=USD&To=${currency || 'USD'}`;
  return (
    <>    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50 border"
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-md rounded-xl bg-white p-8 max-h-[calc(100vh-112px)] mt-16 overflow-y-auto">
            <div className='flex flex-col'>
              {/* <span className='text-[#101828] font-bold text-lg'>Role Title</span>
              <div className='text-[#667085] text-sm mt-2'>{masher.user?.firstName} enter a quote as the {brief?.data?.name} for each of the deliverables below</div>
              <div className='mt-6'>
                <p className='text-[#344054] font-semibold'>Deliverable 1</p>
                <input type='number' className='w-full border rounded-xl px-3 py-2 border-[#D0D5DD] mt-2 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]' />
                <p className='text-[#667085] text-sm mt-2'>Suggested Fee: $11,000</p>
              </div> */}
              <span className='text-[#101828] font-bold text-lg'>Apply for Brief</span>
              <div className="text-[#667085] text-sm mt-2">
                          Enter a quote for each of the deliverables below in{" "}
                          {currencysymbols[currency]?.code || "USD"}
                        </div>
              {
                brief?.data?.deliverables
                  .reduce((collected, deliverable) => {
                    const hasPendingMasher = deliverable?.role?.some((currentRole) =>
                      currentRole.Masher?.some(
                        (m) =>

                          m.masherId === masher.masherProfile?._id && m.status === "pending"
                      )
                    );

                    if (hasPendingMasher) {
                      collected.push(deliverable);
                    }
                    return collected;
                  }, [])
                  .map((deliverable, i) => {
                    const index = i + 1;

                    return (
                      <div key={i} className="w-full mt-2">
                        <p className="font-extrabold text-[#344054] mt-1 mb-1">
                          {deliverable.deliverableName || "Deliverable " + index}
                        </p>
                        {deliverable?.role
                          .filter((role) =>
                            role.Masher?.some(
                              (m) => m.masherId === masher.masherProfile?._id && m.status === "pending"
                            )
                          )
                          .map((role, j) => {
                            return (
                              <div key={j}>
                                <p className="font-medium text-[#344054]">{role.roleName}</p>
                                <div className="px-4 py-2 border border-[#D0D5DD] text-[#667085] rounded-lg w-full mt-2 flex">
                                  {currencysymbols[currency]?.code || "USD"}
                                  <input
                                    type="number"
                                    min="0.01"
                                    step="0.01"
                                    className="apply-btn"
                                    defaultValue=""
                                    name={formik.values.role[role.roleId]}
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      const updatedQuoteData = updateQuoteData(deliverable.deliverableId, role.roleId, newValue, formik.values.role);
                                      formik.setValues({ ...formik.values, role: updatedQuoteData });
                                    }}
                                  />
                                </div>
                                {role.suggestedFeeAllocation !== '' && (
                                  <p className="text-[#667085] text-sm mt-2">
                                    Suggested Fee: {currencysymbols[currency]?.code || 'USD'}{" "}
                                    {role.suggestedFeeAllocation}
                                  </p>
                                )}
                              </div>

                            )
                          })}
                      </div>
                    );
                  })
              }

              <div className='w-full mt-4'>
                <p className='text-[#667085] text-sm mt-2'><a href={currencyurl} target='_blank' rel='noreferrer'>Currency Converter</a></p>
              </div>
              <div className='w-full flex justify-between mt-2 gap-4'>
                <button className='text-lg font-semibold text-[#344054] w-full py-2 border-[#344054] border' onClick={() => setIsOpen(false)}>Cancel</button>
                <button type='submit' className='text-lg text-white bg-[#00A46F] w-full py-2'>
                  {!loader ? ('Submit') : (<div className="text-xs"><EditProfileLoader /></div>)}
                </button>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </form>
    </Dialog>
      {isOpen && <div className='z-40 fixed top-0 bottom-0 left-0 bg-[rgba(52,64,84,0.7)] h-screen w-screen backdrop-blur'></div>}
    </>

  );
};
export default ApplyToBriefModal;