import React, { useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import OtpInput from 'react-otp-input';
import AppContext from '../../../appContext';
import { useLocation, useNavigate } from 'react-router-dom';
import Cover from '../../../Assets/img/covers/loginBack.jpg';
import LogoImage from '../../../Assets/img/logos/mash_logo.png';
import {
  getMahserProfileApi,
  registerMasherApi,
  verifyOtpApi,
} from '../../../apis';
import { EditProfileLoader } from '../../../components/loader/loader';
import { logoutOnJwtExpire, preventBack } from '../../../utils/helperFunctions';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
  gtmId: 'GTM-KTNLX4FF'
};
TagManager.initialize(tagManagerArgs);
ReactGA.initialize('G-VL3PFPXMSE');
export default function Index() {
  const appContext = useContext(AppContext);
  const { setIsLoggedIn, setLoggedInUser, state } = appContext;
  const { loggedInUser } = state;
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [otp, setOTP] = useState('');
  const [repeatTimer, setRepeatTimer] = useState('');
  const [show, setShow] = useState(true);
  const [loader, setLoader] = useState(false);
  const [logInEmail, setLogInEmail] = useState('');
  const [keepLoggedIn, setKeepLoggedIn] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const checkEmailPresent = () => {
    if (location?.state?.email == null || location?.state?.keepLoggedIn == null) {
      navigate('/sign-in');
    } else {
      setLogInEmail(location.state.email);
      setKeepLoggedIn(location.state.keepLoggedIn);
    }
  };

  const resendOtp = async (values) => {
    try {
      values = { email: logInEmail };
      const res = await registerMasherApi(values);
      if (res.status === 200) {
        // navigate('/create-profile');
        toast.success(res.data.message, { id: 'resendSuccess' });
        setRepeatTimer(values);
      }
    } catch (error) {
      const message = error.response.data.message || error.response.statusText;
      toast.error(message, { id: 'error' });
      if (error?.response?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };

  const fetchMasherProfile = async () => {
    setLoggedInUser({ ...loggedInUser, loading: true });
    try {
      const res = await getMahserProfileApi();
      if (res.status === 200) {
        // if (Boolean(res?.data?.masherProfile) === false) {
        //   navigate('/create-profile', { replace: true });
        // }
        setIsLoggedIn(true);
        setLoggedInUser({
          data: res.data?.masherProfile,
          user: res?.data?.user,
          referredBy: res?.data?.referedBy,
          loading: false,
        });
      }
    } catch (error) {
      setLoggedInUser({ data: null, loading: false });
      if (error?.response?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };

  const onVerifyOtp = async (values) => {
    values.preventDefault();
    try {
      if (otp.length < 6) {
        toast.error('Otp is not of 6 digits');
        setIsWrongOtp(true);
        setErrorMsg('Please enter 6 digits OTP');
      } else {
        setLoader(true);
        values.OTP = {
          otp: otp,
          email: logInEmail,
          keepLoggedIn: keepLoggedIn,
          referId: location?.state?.referId,
        };
        const res2 = await verifyOtpApi(values.OTP);
        const data = res2.data;
        if (res2.status === 200) {
          localStorage.setItem('token', data.token);
          fetchMasherProfile();
          toast.success(data.message);
        } else {
          setIsWrongOtp(true);
          setErrorMsg('Wrong OTP');
        }
      }
    } catch (error) {
      setLoader(false);
      toast.error(error.resposne.data.message, { id: 'error' });
      if (error?.res2?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };

  const handleOtpInput = (otp) => {
    setIsWrongOtp(false);
    setErrorMsg('');
    setOTP(otp);
  };

  const Ref = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState('00:00');

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : '0' + minutes) +
        ':' +
        (seconds > 9 ? seconds : '0' + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer('00:59');

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + 59);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
    setInterval(() => {
      // startTimer()
      setShow(false);
    }, 60000);

    setTimeout(() => {
      setShow(true);
    }, 0);
  }, [, repeatTimer]);

  useEffect(() => {
    checkEmailPresent();
    preventBack();
  }, []);

  return (
    <>
      <div className="min-h-screen bg-surface-secondary flex flex-col justify-center items-center sign-in-up !h-screen w-full bg-[url('../public/login.jpg')] bg-cover">
        <div className="col-lg-3 p-6 overflow-y-auto bg-white sm:h-fit flex flex-col justify-center md:justify-start rounded-xl">
          <img src={LogoImage} width="150" />
          <div className="mt-10">
            <h1 className="ls-tight font-bolder display-5 text-dark mb-5">
                Welcome Back{' '}
            </h1>
            <p className="text-dark h4">
                We couldn't be happier to have you.{' '}
            </p>
            <div className="col-lg-12 px-2 mt-8">
              <form onSubmit={onVerifyOtp} className="sign-in-up">
                <div className="mb-5">
                  <label className="form-label" htmlFor="email">
                      Email address
                  </label>
                  <input
                    type="email"
                    className="form-control transparent-input-border"
                    id="email"
                    placeholder="Your email address"
                    value={logInEmail}
                    disabled={true}
                  />
                </div>
                <div className="mb-5">
                  <div className="otpBox">
                    <OtpInput
                      inputStyle={{
                        marginLeft: '0',
                        width: '2.375rem',
                        height: '2.375rem',
                        borderRadius: '0.313rem',
                        textAlign: 'center',
                        border: '1px solid rgba(0,0,0,0.3)',
                      }}
                      className={'me-2 text-center fs-3  w-50 otpInputt'}
                      errorStyle={{ border: 'solid 1px red' }}
                      hasErrored={isWrongOtp}
                      isInputNum
                      value={otp}
                      onChange={(otp) => handleOtpInput(otp)}
                      numInputs={6}
                      separator={<span> </span>}
                    />
                  </div>
                  <div className="resend-again">
                    {show ? (
                      <>
                        <div className="resendBtnhide d-inline ">
                            Resend again
                        </div>
                        <span> ({timer})</span>
                      </>
                    ) : (
                      <>
                        <div
                          className="resendBtnShow d-inline cursor-pointer"
                          onClick={() => resendOtp()}
                        >
                          <a className="si-hover-2 text-black">
                              Resend Again
                          </a>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <button
                    className="btn btn_primary_black w-full"
                    type="submit"
                  >
                    {!loader ? (
                      'Submit'
                    ) : (
                      <div className="text-xs">
                        <EditProfileLoader />
                      </div>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
