import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Question, X } from 'react-bootstrap-icons';
// import questionMarkIcon from '../../Icons/questionMarkIcon.svg';

const StyledButton = styled.button`
  position: absolute;
  bottom: 5%;
  left: 3%;
  border: 1px solid transparent;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  background: #e6cb47 !important;
  z-index: 9999;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: 0.2s all;
  &:hover {
    transform: translateY(-5px);
  }
`;

const Modal = styled.div`
  position: fixed;
  left: 8%;
  bottom: 0%;
  width: 370px;
  height: calc(100% - 120px);
  max-height: 590px;
  box-sizing: border-box;
  z-index: 9999;
  display: ${props => props.open ? "block" : "none"};
`;

const StyledIframe = styled.iframe`
  width: inherit;
  height: inherit;
`;

const ClickUpHelpButton = ({
  url,
  open,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const classList = [
    'sc-launcher',
    (isOpen ? 'opened' : ''),
  ];
/**
 * <button className='floatBtn' onClick={() => window.open('https://forms.clickup.com/9003215177/f/8ca4aa9-1502/K3614QV7ME2SAXDFVE', '_blank')}>
        <i className='bi bi-question' style={{ fontSize: '1.75rem', fontWeight: 900 }}></i>
      </button>
 */
  return (
    <div>
      <button 
        className='floatBtn' 
        onClick={() => {
          setIsOpen(!isOpen);
          if (isOpen) {
            setRefreshKey(refreshKey + 1);
          }
        }}>
        { 
          isOpen ?
          <X color="white" fontSize={'1.75rem'} fontWeight={900}></X> :
          <i className='bi bi-question' style={{ fontSize: '1.75rem', fontWeight: 900 }}></i>
        }
      </button>
      <Modal open={isOpen}>
        <StyledIframe key={refreshKey} src={url} id="clickup-report-form"></StyledIframe>
      </Modal>
    </div>
  );
};

export default ClickUpHelpButton;
