
const Twitter = () => {
    return (
      <div className="App">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_946_29724)">
            <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="black" stroke="white" stroke-miterlimit="10"/>
            <path d="M3.84 4.2063L8.61988 10.5973L3.80988 15.7936H4.8925L9.10375 11.2441L12.5062 15.7936H16.1902L11.1412 9.0431L15.6184 4.2063H14.5358L10.6576 8.39615L7.52396 4.2063H3.84ZM5.43205 5.00367H7.12444L14.5979 14.9962H12.9055L5.43205 5.00367Z" fill="white"/>
          </g>
          <defs>
            <clipPath id="clip0_946_29724">
              <rect width="20" height="20" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>
    );
  }
  export default Twitter;