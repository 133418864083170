import React from 'react';
import AdminLayout from '../../components/admin-layout';
import Cover from '../../Assets/img/covers/toolkit.png';
import MyMashToolkit from '../../Assets/docs/MyMashToolkit.pdf';
import SideCover from '../../Assets/img/covers/side_toolkit.png';

const MyMashToolkitPage = () => {
  return (
    <AdminLayout>
      <div className='container-fluid p-lg-0'>
        <div className='row mx-0 mb-5'>
          <div className='cover p-0'>
            <img src={Cover} />
          </div>
        </div>
        <div className='container'>
          <div className='row mx-0'>
            <div className='col-sm-9'>
              <h2 className='mb-5'>MyMash Toolkit</h2>
              <p className='text'><b>There's freelancing. Then there's Mashing.</b>
                <br />
                <br />
                                At Mash, we do things a little differently. Sometimes, that requires a bit of unlearning.
                                The toolkit below is designed to teach you everything you need to know. Whether this is your first time
                                freelancing or you're a veteran of the game, learn how to do it the Mash way. </p>
              {/* <button className='btn btn-sm btn_primary_black mt-4'>Download</button> */}
              <a className='btn btn-sm btn-dark mt-4 mb-4' href={MyMashToolkit} download target='_blank'>
                                Download
              </a>
            </div>
            <div className='col-sm-3'>
              <div className='side_toolkit'>
                <img src={SideCover} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default MyMashToolkitPage;