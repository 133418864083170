import React, { useContext, useEffect, useRef, useState } from 'react';
import Moment from 'react-moment';
import { useFormik } from 'formik';
import { io } from 'socket.io-client';
import { fileUploadApi } from '../../apis';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../utils/baseurl';
import { assignBucketUrl, logoutOnJwtExpire } from '../../utils/helperFunctions';
import ProfilePicDemo from '../../Assets/img/covers/profilePicDemo.png';
import { ImgLoader, Loader, Loader2 } from '../../components/loader/loader';
import { toast } from 'react-hot-toast';
import AppContext from '../../appContext';

export default function ChatSection({ briefData, briefId, adminId, loading }) {
 
  const [chat, setChat] = useState([]);
  const [chatLoader, setChatLoader] = useState(false);
  const [attaches, setAttaches] = useState('');
  const [attaches2, setAttaches2] = useState({ data: null, loading: false });

  const appContext = useContext(AppContext);
  const { state } = appContext;
  const { loggedInUser } = state;

  const inputRef = useRef(null);

  const [socket, setSocket] = useState(io(API_BASE_URL, { transports: ['websocket'], query: { convoId: '' } }));

  const navigate = useNavigate();

  const convoId = Boolean(briefId && loggedInUser?.data?.userId?._id) && briefId + '_' + loggedInUser?.data?.userId?._id;
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const fileCheck = (file) => {
    const fileExt = file?.split('.').at(-1);
    if (fileExt.toLowerCase() === 'png' || fileExt.toLowerCase() === 'jpg' || fileExt.toLowerCase() === 'jpeg') {
      return (
        <a href={file} target='_blank'>
          <img
            src={assignBucketUrl(file)}
            alt='placeholder'
            onError={(event) => {
              event.target.src = ProfilePicDemo;
              event.onerror = null;
            }}
          />
        </a>
      );
    } else {
      return (
        <a href={file} target='_blank'>
          <div className='icon icon-shape text-xl bg-black text-white'>
            <i className='bi bi-file-earmark-fill'></i>
          </div>
        </a>
      );
    }
  };
 
  const formik = useFormik({
    initialValues: {
      message: '',
      senderId: '',
      recieverId: '',
      convoId: '',
      isRead: false,
      attachment: {},
    },
    validationSchema: '',
    onSubmit: async (values) => {

      values.message = values?.message?.trim();

      if (!values?.message) {
        if (attaches2?.data) {
          socket.emit('send message', { ...values, clientName: briefData?.clientId?.clientName, briefName: briefData?.briefName });
          formik.setFieldValue('attachment', '');
        }
      } else if (values?.message) {
        socket.emit('send message', { ...values, clientName: briefData?.clientId?.clientName, briefName: briefData?.briefName });
        formik.setFieldValue('message', '');
      }
      setAttaches2({ data: null });
    },
  });
 
  const handleAttachment2 = async (e) => {
    const toastAttachment = toast.loading('Uploading...');
    try {
      let file = e.target.files[0];
      if (!file) {
        toastAttachment.dismiss();

        return;
      }
      const type = file.type.split('/')[1];
      const videoType = ['jpeg', 'png', 'img', 'jpg'];

      const validIVideoType = videoType.includes(type);
      if (!validIVideoType) {
        toastAttachment.dismiss();
        toast.error('Please upload a valid attachment type', { id: '100' });
        return;
      }
      if (file.size > 100000001) {
        toastAttachment.dismiss();
        formik.setFieldError('image', 'Image size is more than 1 MB');
        toast.error('Please upload an image less than 10 mb');
        setAttaches('');
        return;
      } else {
        let fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = function () {
          setAttaches(fr.result);
        };
        formik.setErrors({});
      }
      formik.setErrors({});

      const formdata = new FormData();
      formdata.append('file', file);
      setAttaches2({ loading: true });
      const response = await fileUploadApi(formdata);
      if (response.status === 200) {
        toastAttachment.dismiss();
        setAttaches2({ data: response.data.filePath, loading: false });
      }
      formik.setFieldValue('attachment', { data: response.data.filePath });
      // toast.success(response.data.message, { id: "succcess" })
    } catch (err) {
      toastAttachment.dismiss();

      if (err?.response?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };

  const handleAttachment = async (e) => {
    try {
      let file = e.target.files[0];
      let validFile = file.type.split('/').at(-1).toLowerCase();

      if (validFile?.match(/.(jpg|jpeg|png|mp4|mov|mkv|webm|pdf|doc|docx|xls)$/)) {
        // toast.error("Only PDF format allowed");
        formik.setFieldError('attachment', 'Only PDF format allowed');
        setAttaches('');
        toast('Only PDF format allowed', { icon: '⚠️' });
        return;
      }
      if (file.size > 10000001) {
        // toast.error("File size is more than 10 MB");
        toast('File size is more than 10 MB', { icon: '⚠️' });

        formik.setFieldError('attachment', 'image size is more than 1 MB');
        setAttaches('');
        return;
      } else {
        let fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = function () {
          setAttaches(fr.result);
        };
      }

      const formdata = new FormData();
      formdata.append('file', file);
      setAttaches2({ loading: true });

      const response = await fileUploadApi(formdata);
      if (response.status === 200) {
        setAttaches2({ data: response.data.filePath, loading: false });
      }
      formik.setFieldValue('attachment', { data: response.data.filePath });
    } catch (error) {
      toast.error(error.response.data.message, { id: 'error' });
    }
  };

  const handleChange = (e) => {
    formik.handleChange(e);
  };

  useEffect(() => {
    socket.on('message', (message) => {
      setChat(chat[0]?.msg ? [message] : [...chat, message]);
    });
    // return () => socket.disconnect()
  }, [chat]);

  useEffect(() => {
    socket.on('previous message', (message) => {
      setChat(message);
    });
  }, [socket]);

  useEffect(() => {
    scrollToBottom();
  }, [formik]);

  useEffect(() => {
    window.socket = socket;
  }, [socket]);

  useEffect(() => {
    formik.setFieldValue('senderId', loggedInUser?.data?.userId?._id);
    if (typeof briefData?.createdBy === 'string') {
      formik.setFieldValue('recieverId', briefData?.createdBy);
    } else {
      formik.setFieldValue('recieverId', briefData?.createdBy?._id);
    }
    formik.setFieldValue('convoId', convoId);
    if (Boolean(convoId)) {
      setSocket(
        io(API_BASE_URL, {
          transports: ['websocket'],
          query: { convoId: convoId },
        })
      );
    }
    setChatLoader(true);
  }, [briefData, loggedInUser, loading]);
 
  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <div className='chat-ui'>
      <div className='container px-0'>
        <header className='d-flex btn_primary_black p-3'>
          <span href=''>
            {loading ? (
              <div className='text-center w-full'>
                <Loader2
                  style={{
                    height: '15px',
                    width: '15px',
                    color: 'white',
                  }}
                />
              </div>
            ) : (
              <div className='bg-secondary rounded-5'>
                <img
                  src={assignBucketUrl(adminId?.profilePic)}
                  onError={event => {
                    event.target.src = ProfilePicDemo;
                    event.onerror = null;
                  }}
                  alt=''
                  className='avatar avatar-sm rounded-circle briefIndi w-10 h-10'
                />
              </div>
            )}
          </span>
          <div className='ps-3'>
            <h2 className='d-block text-secondary'>{adminId?.firstName ? adminId?.firstName : ''}</h2>
          </div>
        </header>

        <div className='chat-container'>
          <div className='chat-container-inner'>
            {

            }
            {chat?.length >= 1 && !chat[0]?.msg
              ? chat?.map((el, i) => {
                return loggedInUser?.data?.userId?._id === el.senderId?._id ? (
                  <React.Fragment key={`chat-${i}`}>
                    <div className='chat-wrapper senderWrapper' ref={messagesEndRef}>
                      <div className='time-date text-end mb-1 me-2'>
                        <Moment format='MMMM Do YYYY, h:mm:ss a' date={el?.createdAt} />
                      </div>
                      <div className='chat-box right-user me-2'>
                        <div className='card'>
                          <div className='card-body'>
                            <label className='font-semibold username mb-2'>{loggedInUser?.data?.userId?.firstName}</label>
                            {Boolean(el?.attachment?.data) && (
                              <p>
                                {fileCheck(`${el?.attachment?.data}`)}
                              </p>
                            )}
                            {Boolean(el?.message) && (
                              <p className='user_message' style={{ whiteSpace: 'pre-line' }}>
                                {el?.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={i}>
                    <div className='chat-wrapper recieverWrapper ' ref={messagesEndRef}>
                      <div className='time-date text-start mb-1 ms-2'>
                        <Moment format='MMMM Do YYYY, h:mm:ss a' date={el?.createdAt} />
                      </div>
                      <div className='chat-box left-user ms-2'>
                        <div className='card'>
                          <div className='card-body'>
                            <label className='font-semibold username'>{adminId?.firstName}</label>
                            {/* <p className='user_message'>{el?.message}</p> */}
                            {Boolean(el?.attachment?.data) && (
                              <p>
                                {fileCheck(`${el?.attachment?.data}`)}
                              </p>
                            )}
                            {Boolean(el?.message) && (
                              <p className='user_message' style={{ whiteSpace: 'pre-line' }}>
                                {el?.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })
              : chat?.map((el, i) => {
                return (
                  <React.Fragment key={`chat-2-${i}`}>
                    <div className='text-center mt-8'>{el?.msg}</div>
                  </React.Fragment>
                );
              })}
            {chat.length <= 0 && (
              <div className='text-center mt-8'>
                <Loader />
              </div>
            )}

          </div>
          <div>
            {attaches2?.loading ? (
              <>
                <div className='text-end mb-20 me-10'>
                  <ImgLoader />
                </div>
              </>
            ) : attaches2?.data !== null ? (
              <div className='position-absolute chat-preview'>
                <p className='text-sm'>Preview</p>
                {/* <img
                  src={assignBucketUrl(attaches2?.data)}
                  alt=''
                  onError={event => {
                    event.target.src = ProfilePicDemo
                    event.onerror = null
                  }}
                /> */}
                {
                  fileCheck(attaches2?.data)
                }
              </div>
            ) : (
              <div className='position-absolute chat-preview d-none'></div>
            )}
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className='textarea-wrapper-bottom row align-items-center mt-10'>
              <div className='col-md-8 col-8 me-5'>
                <input name='message' value={formik.values.message} onChange={handleChange} placeholder='Type here ...' className='chatInput' />
              </div>
              <div className='col-md-3 col-3 text-end d-flex justify-content-end align-items-center'>
                <div className='chat-attachment cursor-pointer me-2'>
                  <div className=''>
                    <i className='bi bi-paperclip' onClick={handleClick}></i>
                  </div>
                  <input
                    id='chat_attachment'
                    // className="w-70"
                    hidden={true}
                    type='file'
                    name='attachment'
                    onChange={handleAttachment}
                    ref={inputRef}
                  />
                </div>
                <button type='submit' className='btn btn_primary_black btn_send mt-0' style={{ maxHeight: '40px' }}>
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </div >
    </div >
  );
}
