import { useState } from 'react';

import { Dialog } from '@headlessui/react';

const DeclineModal = ({ isOpen, onAccept, onDecline }) => {
  const [reason, setReason] = useState('')
  return     <><Dialog
    open={isOpen}
    onClose={() => setIsOpen(false)}
    className="relative z-50 border"
  >
    <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
      <Dialog.Panel className="w-full max-w-md rounded-xl bg-white p-8">
        <div className='flex flex-col'>
          <span className='text-[#101828] font-bold text-lg'>Are you sure you want to decline? <br /><span className='text-sm font-normal'>You will not be able to view this brief once declined.</span> </span>
          <span className='font-bold text-black text-sm mt-4'>Reason for rejection:</span>
          <textarea onChange={(e) => setReason(e.target.value)} className="border border-black px-4 py-2"></textarea>
          <div className='w-full flex justify-between mt-10 gap-4'>
            <button type='submit' className='text-lg text-white bg-[#FF3838] w-full py-2' onClick={() => onAccept(reason)}>Yes
            </button>
            <button className='text-lg font-semibold text-[#344054] w-full py-2 border-[#344054] border' onClick={onDecline}>No</button>
          </div>
        </div>
      </Dialog.Panel>
    </div>
  </Dialog>
  {isOpen && <div className='z-40 fixed top-0 bottom-0 left-0 bg-[rgba(52,64,84,0.7)] h-screen w-screen backdrop-blur'></div>}
  </>;
};
export default DeclineModal;