import React, { useContext, useEffect, useState, useRef } from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import SkipPopUp from '../../components/Header/SkipPopUp';
import AppContext from '../../appContext';
import Tooltip from 'react-simple-tooltip';
import { Link, json, useNavigate } from 'react-router-dom';
import ReferCreativepopup from '../ReferCreativepopup';
import AdminLayout from '../../components/admin-layout';
import SorryPopup from '../MashGlobalProject/SorryPopup';
import ThumbsUp from '../../Assets/img/covers/thumbs-up.png';
import Victory from '../../Assets/img/covers/victory-hand.png';
import PointingHand from '../../Assets/img/covers/hand-pointing.png';
import ProfilePicDemo from '../../Assets/img/covers/profilePicDemo.png';
import NoMashplayerimg from '../../Assets/img/media/no_mashplayer.png';
import { EditProfileLoader, ImgLoader, ImgLoader2, Loader } from '../../components/loader/loader';
import { getAllBriefOfMasher, getAllCounts, getAllMasherProjectsApi, meetMashersInYourArea, getmasherdashboardApi, otherMasherProfileApi, UpdateLastLoginApi } from '../../apis';
import { assignBucketUrl, formatDiffTimeFromNowHHMMfor48hours, getTimeRemaining, logoutOnJwtExpire, redirectToReferralPortal } from '../../utils/helperFunctions';
import RequiredFieldPopup from '../../components/Header/RequiredFieldPopup';
import ProfilePreview from '../../components/Header/ProfilePreview';
import NotificationList from './NotificationList';
// import MasherStatus from "../MashGlobalProject/MasherStatus";

export default function Index() {

  const appContext = useContext(AppContext);
  const { authcall } = appContext;
  const { state, fetchMasherProfile } = appContext;
  const { loggedInUser } = state;

  const [meetMasher, setMeetMasher] = useState({ data: null, loading: false });
  const [allCounts, setAllCounts] = useState({ data: null, loading: false });
  const [allBriefInvite, setAllBriefInvite] = useState({ data: null, loading: false });
  const [allMasherProject, setAllMasherProject] = useState({ data: null, loading: false });
  const [meetMasherDashboard, setMeetMasherDashboard] = useState({ data: null, loading: false });
  const invitedBriefs = allBriefInvite?.data;
  const masherData = loggedInUser;
  const navigate = useNavigate();

  const getAllMasherProject = async (search, startDate, dueDate, limit, page, isProject) => {
    setAllMasherProject({ loading: true });
    try {
      const res = await getAllMasherProjectsApi(search, startDate, dueDate, limit, page, isProject);
      if (res.status === 200) {
        setAllMasherProject({ data: res?.data?.allProjects, loading: false });
      }
    } catch (error) {
      setAllMasherProject({ loading: false });
      if (error?.response?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };
  const allMasherBrief = async (search, startDate, dueDate, limit, page, _id) => {
    setAllBriefInvite({ loading: true });
    try {
      const res = await getAllBriefOfMasher(search, startDate, dueDate, limit, page);
      if (res.status === 200) {
        setAllBriefInvite({ data: res?.data?.allBriefInvitations, loading: false });
      }
    } catch (error) {
      setAllBriefInvite({ loading: false });
      if (error?.response?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };
  const meetMasherArea = async () => {
    setMeetMasher({ loading: true });
    try {
      const res = await meetMashersInYourArea();
      if (res.status === 200) {
        setMeetMasher({ data: res?.data?.nearByMashers, loading: false });
      }
    } catch (error) {
      setMeetMasher({ loading: false });
    }
  };
  const allCountsApi = async () => {
    setAllCounts({ loading: true });
    try {
      const res = await getAllCounts();
      if (res.status === 200) {
        setAllCounts({ data: res?.data });
      }
    } catch (error) {

    }
  };


  const mashersDashboard = async () => {
    setMeetMasherDashboard({ loading: true });
    try {
      const masherid = loggedInUser?.data?._id;
      if (masherid) {
        const res = await getmasherdashboardApi(masherid || '');
        if (res.status === 200) {
          setMeetMasherDashboard({ data: res?.data, loading: false });
        }
      }

    } catch (error) {
      setMeetMasherDashboard({ loading: false });
    }
  };
  const [Requiredpopup, setRequiredpopup] = useState(false);
  const [Fieldvalue, setFieldvalue] = useState([]);
  const [createprofile, setcreateprofile] = useState(false);
  const FieldCheck = async (Dataval) => {
    const Fieldempty = [];
    if (!Dataval?.masherProfile?.roles.length > 0) {
      Fieldempty.push({ name: 'Role Set' });
    }
    if (!Dataval?.masherProfile?.biography) {
      Fieldempty.push({ name: 'Biography' });
    }
    if (!Dataval?.masherProfile?.skills.length > 0) {
      Fieldempty.push({ name: 'Skills Set' });
    }
    if (!Dataval?.masherProfile?.workDetails?.experience) {
      Fieldempty.push({ name: 'Years Of Experience' });
    }
    if (!Dataval?.masherProfile?.language.length > 0) {
      Fieldempty.push({ name: 'Languages' });
    }

    if (Fieldempty.length > 0) {
      setRequiredpopup(true);
    } else {

      setRequiredpopup(false);
    }

    setFieldvalue(Fieldempty);
  };

  const [masherDatabyid, setMasherDatabyid] = useState({ data: null, loading: false });
  const fetchOtherMasherProfile = async () => {

    if (loggedInUser?.data?._id) {
      try {
        const res = await otherMasherProfileApi(loggedInUser?.data?._id);
        if (res.status === 200) {
          // toast.success(, { id : "002" })
          setMasherDatabyid({ data: res?.data, loading: false });

          FieldCheck(res?.data);
        }
        else {

        }
      } catch (error) {
      }
    }


  };

const updatelastlogin=async()=>{
  if (loggedInUser?.user?._id) {
    try {
      const res = await UpdateLastLoginApi({userId:loggedInUser?.user?._id});
      if (res.status === 200) {
      }
      else {

      }
    } catch (error) {
      // alert(error)
    }
  }
}
  useEffect(() => {
    if (Requiredpopup) {
      const closeButton = document.getElementById('RequiredField_open');

      if (closeButton) {

        closeButton.click();
        if (loggedInUser?.data) {
          setcreateprofile(false);
        }
        else {
          setcreateprofile(true);
        }
      }
    }
  }, [Requiredpopup]);
  const hasUpdatedLastLogin = useRef(false);
  useEffect(() => {
    if (!hasUpdatedLastLogin.current && loggedInUser?.user) {
      updatelastlogin();
      hasUpdatedLastLogin.current = true;
    }
  }, [loggedInUser?.user]);
  useEffect(() => {
    fetchMasherProfile();
    mashersDashboard();
    // allMasherBrief();
    // getAllMasherProject();
    // meetMasherArea();

    // allCountsApi();
  }, []);



  useEffect(() => {

    // alert(JSON.stringify(masherData))
    if (loggedInUser?.data == null && loggedInUser?.user !== null) {
      setFieldvalue(prevState => [
        { name: 'Role Set' },
        { name: 'Biography' },
        { name: 'Skills Set' },
        { name: 'Years Of Experience' },
        { name: 'Languages' }
      ]);
      setRequiredpopup(true);
      const closeButton = document.getElementById('RequiredField_open');

      if (closeButton) {
        closeButton.click();
      }
      if (loggedInUser?.data === null && loggedInUser?.user !== null) {
        setcreateprofile(false);
      } else {
        setcreateprofile(true);
      }
    }
    else {
      mashersDashboard();
      fetchOtherMasherProfile();
    }



  }, [masherData.loading]);



  // Function to calculate the difference in days between two dates
  const calculateDaysDifference = (date1, date2) => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    return diffDays;
  };

  // Function to get the expiration message and return the time alone
  const Datetimeconverter = (timestamp, propName = 'Message') => {
    if (!timestamp || isNaN(timestamp.getTime())) {
      return { [propName]: null, time: '' };
    }

    const today = new Date();
    const daysDifference = calculateDaysDifference(today, timestamp);

    let message;

    if (daysDifference === 0) {
      message = 'Today';
    } else if (daysDifference === 1) {
      message = 'Tomorrow';
    } else {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const formattedDate = timestamp.toLocaleDateString(undefined, options);
      message = `${formattedDate}`;
    }

    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    const time = timestamp.toLocaleTimeString(undefined, timeOptions);

    return { [propName]: message };
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  };
  function getInitials(fullName) {
    const names = fullName.split(' ');

    if (names.length === 1) {
      // Only first name
      return names[0].slice(0, 2).toUpperCase();
    } else {
      // First name and last name
      const firstNameInitial = names[0].charAt(0).toUpperCase();
      const lastNameInitial = names[names.length - 1].charAt(0).toUpperCase();
      return `${firstNameInitial}${lastNameInitial}`;
    }
  }
  const filteredMeetMasher = meetMasher?.data?.filter((e) => { return e?._id !== loggedInUser?.data?._id; });
  const pendingBriefs = meetMasherDashboard?.data?.Briefs
    .map(item => {
      const filteredDeliverables = item.deliverables.reduce((collected, deliverable) => {
        if (
          deliverable &&
          deliverable.role &&
          deliverable.role.some((currentRole) =>
            currentRole.Masher &&
            currentRole.Masher.find(
              (m) => m.masherId === loggedInUser?.data?._id && m.status === 'pending'
            )
          )
        ) {
          collected.push(deliverable);
        }
        return collected;
      }, []);

      // Add the filtered deliverables to the item
      return { ...item, filteredDeliverables };
    })
    .filter(item => item.filteredDeliverables.length > 0);

  // Now pendingBriefs contains an array of items that meet the specified criteria
  const pendingCount = pendingBriefs?.length || 0;
  return (
    <AdminLayout>
      {/* {masherData?.data?.status === "pending" ? <MasherStatus /> : <span></span>} */}
      {
        masherData.loading ? (
          <div className='text-center w-full mt-10'>
            <Loader />
          </div>
        ) : (

          <div className="container-fluid pt-5 md:px-12 pb-6 bg-[#FCFCFD]">
            <div className='flex flex-col md:flex-row justify-center md:justify-between w-full  mt-0 mb-8 gap-6'>
              <div className='flex gap-6 items-center w-full'>
                <button
                  className='none'
                  data-bs-toggle="modal"
                  data-bs-target="#RequiredField"
                  id="RequiredField_open"
                >Chat Notifications</button>


                <button style={{ width: '160px', height: '160px' }} className='rounded-circle'
                  data-bs-toggle="modal"
                  data-bs-target="#profilePreview"
                  id="profilePreview_open"
                >
                  {
                    loggedInUser?.user?.profilePic ?

                      <label
                        className='col-sm-4 bg-repeat-0 bg-position-center bg-size-cover'
                        htmlFor='profile'
                        style={{
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundColor: 'white',
                          width: '160px',
                          height: '160px',
                          border: '4px solid rgb(255, 255, 255, 1)',
                          cursor: 'pointer',
                          borderRadius: '50%',
                          boxShadow: '0px 1.156px 2.312px 0px rgba(16, 24, 40, 0.06), 0px 1.156px 3.468px 0px rgba(16, 24, 40, 0.10)',
                          backgroundImage: `${`url(${assignBucketUrl(loggedInUser?.user?.profilePic)})`}`,
                        }}></label>
                      :
                      <div className='no-profile-placeholder'>
                        {loggedInUser?.data?.userId?.firstName ? getInitials(loggedInUser?.user?.firstName) : ''}
                      </div>

                  }
                </button>
                <div className='h-fit'>
                  <p className='text-4xl font font-semibold text-black'>Hello {loggedInUser?.user?.firstName ? (!loggedInUser?.user?.firstName ? <ImgLoader /> : loggedInUser?.user?.firstName) : 'Anonymous'}</p>
                  <Link to={`/masher-view/${loggedInUser?.data?._id}`}>
                    <div className='flex mt-4 gap-2 items-center'>
                      <span className='text-[#00A46F] font-semibold'>Portfolio</span>
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.1665 10.5001H15.8332M15.8332 10.5001L9.99984 4.66675M15.8332 10.5001L9.99984 16.3334" stroke="#00A46F" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
              <div className='flex items-center md:gap-2 w-full justify-center md:justify-end '>
                <a
                  className='border border-[#D0D5DD] bg-white text-[#344054] px-3 py-2'
                  href="https://ats.thisismash.com/jobs/apply/masher-referral-4"
                  target='_blank'
                >Refer a Masher</a>
                <Link to={`/mashers-profile/${loggedInUser?.data?._id || 'create'}`}>
                  <button className='border border-[#00A46F] bg-[#00A46F] px-3 py-2 text-white'>Update Profile</button>
                </Link>
              </div>
            </div>

            {
              (meetMasherDashboard?.data?.Briefs.length > 0) &&

                <div className='flex flex-col'>
                  <p className='text-black text-2xl font-bold'>My briefs</p>
                  {pendingCount > 0 && <p className='text-black text-lg font-normal'>You have {pendingCount} {pendingCount > 1 ? 'briefs' : 'brief'} waiting for a response</p>}
                  <div className='flex  gap-4 my-4 py-4 w-full overflow-x-auto md:flex-wrap'>
                    {meetMasherDashboard?.data?.Briefs.sort((a, b) => {
                      // Function to determine the rank of a status
                      const getStatusRank = (item) => {
                        const hasPending = item.deliverables?.some(deliverable =>
                          deliverable.role?.some(role =>
                            role.Masher?.some(masher =>
                              masher.status === 'pending' && masher.masherId === loggedInUser?.data?._id
                            )
                          )
                        );

                        const hasAccepted = item.deliverables?.some(deliverable =>
                          deliverable.role?.some(role =>
                            role.Masher?.some(masher =>
                              masher.status === 'accepted' && masher.masherId === loggedInUser?.data?._id
                            )
                          )
                        );

                        if (hasPending) return 1;
                        if (hasAccepted) return 2;
                        return 3;
                      };

                      // Compare the ranks
                      return getStatusRank(a) - getStatusRank(b);
                    })
                      .map((item, index) => {
                        // Determine overall status based on Masher roles
                        const hasPending = item.deliverables?.some(deliverable =>
                          deliverable.role?.some(role =>
                            role.Masher?.some(masher =>
                              masher.status === 'pending' && masher.masherId === loggedInUser?.data?._id
                            )
                          )
                        );

                        const hasAccepted = item.deliverables?.some(deliverable =>
                          deliverable.role?.some(role =>
                            role.Masher?.some(masher =>
                              masher.status === 'accepted' && masher.masherId === loggedInUser?.data?._id
                            )
                          )
                        );

                        const hasDeclined = item.deliverables?.some(deliverable =>
                          deliverable.role?.some(role =>
                            role.Masher?.some(masher =>
                              masher.status === 'declined' && masher.masherId === loggedInUser?.data?._id
                            )
                          )
                        );

                        let overallStatus;
                        if (hasPending) {
                          overallStatus = 'pending';
                        } else if (hasAccepted) {
                          overallStatus = 'accepted';
                        } else {
                          overallStatus = 'declined';
                        }

                        const expiryTimestamp = new Date(item.sendBriefLog?.[0]?.expirytimestamp);
                        const createdTimestamp = new Date(item.sendBriefLog?.[0]?.timestamp);
                        const { expirymessage } = Datetimeconverter(expiryTimestamp, 'expirymessage');
                        const { submittedmessage } = Datetimeconverter(createdTimestamp, 'submittedmessage');

                        return (
                          <div key={`Work-${index}`} className={`w-[calc(33%-8px)] min-w-[298px] rounded-lg border-t-8 py-6 px-6 ${overallStatus === 'pending' ? 'border-[#FF3838]' : overallStatus === 'accepted' ? 'border-[#039855]' : 'border-[#FF3838]'} shadow-[0px_2px_4px_-2px_rgba(16,24,40,0.06),_0px_4px_8px_-2px_rgba(16,24,40,0.10)]`}>
                            {(overallStatus || expirymessage) &&
                              <div className='flex w-full justify-between'>
                                {overallStatus && <span className={`text-white ${overallStatus === 'pending' ? 'bg-[#FF3838]' : overallStatus === 'accepted' ? 'bg-[#039855]' : 'bg-[#FF3838]'} px-2 py-1 rounded-2xl text-xs`}>{overallStatus === 'accepted' ? 'Applied' : 'Pending'}</span>}
                                {expirymessage && <span className='text-xs text-[#FF3838]'>Expiry on {expirymessage}</span>}
                              </div>
                            }
                            <p className='mt-4 text-black text-xs'>{item.name}</p>
                            <p className='mt-4  text-xl font-semibold text-[#344054]'>{item.companyName}</p>
                            <Link to={`/brief/brief-view/${item._id}`}>
                              <div className='flex mt-6 gap-2 items-center'>
                                <span className='text-[#00A46F] font-semibold'>View Brief</span>
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M4.1665 10.5001H15.8332M15.8332 10.5001L9.99984 4.66675M15.8332 10.5001L9.99984 16.3334" stroke="#00A46F" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              </div>
                            </Link>
                            {submittedmessage && <p className='mt-8 text-xs text-[#344054]'>Submitted {submittedmessage}</p>}
                          </div>
                        );
                      })
                    }



                    {/* {meetMasherDashboard?.data?.Briefs.sort((a, b) => {
                    const getStatusValue = (item) => {
                      const overallStatus = item.deliverables?.every(deliverable =>
                        deliverable.role?.every(role =>
                          role.Masher?.every(masher => masher.status === 'accepted')
                        )
                      )
                        ? 'accepted'
                        : item.deliverables?.some(deliverable =>
                          deliverable.role?.some(role =>
                            role.Masher?.some(masher => masher.status === 'declined')
                          )
                        )
                          ? 'declined'
                          : 'pending';

                      return overallStatus === 'pending' ? 1 : overallStatus === 'accepted' ? 2 : 3;
                    };

                    return getStatusValue(a) - getStatusValue(b);
                  }).map((item, index) => {
                    // Determine overall status based on Masher roles
                    const overallStatus = item.deliverables?.every(deliverable =>
                      deliverable.role?.every(role =>
                        role.Masher?.every(masher => masher.status === 'accepted')
                      )
                    )
                      ? 'accepted'
                      : item.deliverables?.some(deliverable =>
                        deliverable.role?.some(role =>
                          role.Masher?.some(masher => masher.status === 'declined')
                        )
                      )
                        ? 'declined'
                        : 'pending';
                    const expiryTimestamp = new Date(item.sendBriefLog?.[0]?.expirytimestamp);
                    const createdTimestamp = new Date(item.sendBriefLog?.[0]?.timestamp);
                    const { expirymessage } = Datetimeconverter(expiryTimestamp, 'expirymessage');
                    const { submittedmessage } = Datetimeconverter(createdTimestamp, 'submittedmessage');

                    return (
                      overallStatus !== 'declined' && (
                        <div key={`Work-${index}`} className={`w-[calc(33%-8px)] min-w-[298px] rounded-lg border-t-8 py-6 px-6 ${overallStatus === 'pending' ? 'border-[#FF3838]' : overallStatus === 'accepted' ? 'border-[#039855]' : 'border-[#FF3838]'} shadow-[0px_2px_4px_-2px_rgba(16,24,40,0.06),_0px_4px_8px_-2px_rgba(16,24,40,0.10)]`}>
                          {(overallStatus || expirymessage) &&
                            <div className='flex w-full justify-between'>
                              {overallStatus && <span className={`text-white ${overallStatus === 'pending' ? 'bg-[#FF3838]' : overallStatus === 'accepted' ? 'bg-[#039855]' : 'bg-[#FF3838]'} px-2 py-1 rounded-2xl text-xs`}>{overallStatus === 'accepted' ? 'Applied' : 'Pending'}</span>}
                              {expirymessage && <span className='text-xs text-[#FF3838]'>Expiry on {expirymessage}</span>}
                            </div>
                          }
                          <p className='mt-4 text-black text-xs'>{item.name}</p>
                          <p className='mt-4  text-xl font-semibold text-[#344054]'>{item.companyName}</p>
                          <Link to={`/brief/brief-view/${item._id}`}>
                            <div className='flex mt-6 gap-2 items-center'>
                              <span className='text-[#00A46F] font-semibold'>View Brief</span>
                              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.1665 10.5001H15.8332M15.8332 10.5001L9.99984 4.66675M15.8332 10.5001L9.99984 16.3334" stroke="#00A46F" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </div>
                          </Link>
                          {submittedmessage && <p className='mt-8 text-xs text-[#344054]'>Submitted {submittedmessage}</p>}

                        </div>
                      )
                    );
                  })} */}
                    {/* {meetMasherDashboard?.data?.Briefs.filter((item) => {
              const overallStatus = item.deliverables?.every(deliverable =>
                deliverable.role?.every(role =>
                  role.Masher?.every(masher => masher.status === 'accepted')
                )
              )
                ? 'accepted'
                : item.deliverables?.some(deliverable =>
                  deliverable.role?.some(role =>
                    role.Masher?.some(masher => masher.status === 'declined')
                  )
                )
                  ? 'declined'
                  : 'pending';
              return overallStatus !== 'declined';
            }).map((item, index) => {
              // Determine overall status based on Masher roles
              const overallStatus = item.deliverables?.every(deliverable =>
                deliverable.role?.every(role =>
                  role.Masher?.every(masher => masher.status === 'accepted')
                )
              )
                ? 'accepted'
                : item.deliverables?.some(deliverable =>
                  deliverable.role?.some(role =>
                    role.Masher?.some(masher => masher.status === 'declined')
                  )
                )
                  ? 'declined'
                  : 'pending';
              const expiryTimestamp = new Date(item.sendBriefLog?.[0]?.expirytimestamp);
              const createdTimestamp = new Date(item.sendBriefLog?.[0]?.timestamp);
              const { expirymessage} = Datetimeconverter(expiryTimestamp, 'expirymessage');
              const { submittedmessage} = Datetimeconverter(createdTimestamp, 'submittedmessage');

              return (
              
                <div key={`Work-${index}`} className={`w-[calc(33%-8px)] min-w-[298px] rounded-lg border-t-8 py-6 px-6 ${ overallStatus === 'pending' ? 'border-[#344054]' : overallStatus === 'accepted' ? 'border-[#039855]' : 'border-[#FF3838]' } shadow-[0px_2px_4px_-2px_rgba(16,24,40,0.06),_0px_4px_8px_-2px_rgba(16,24,40,0.10)]`}>
                  {(overallStatus || expirymessage) &&
                  <div className='flex w-full justify-between'>
                    {overallStatus && <span className={`text-white ${overallStatus === 'pending' ? 'bg-[#344054]' : overallStatus === 'accepted' ? 'bg-[#039855]' : 'bg-[#FF3838]'} px-2 py-1 rounded-2xl text-xs`}>{overallStatus}</span>}
                    {expirymessage && <span className='text-xs text-[#FF3838]'>Expiry on {expirymessage}</span>}
                  </div>
                  }
                  <p className='mt-4 text-black text-xs'>{item.name}</p>
                  <p className='mt-4  text-xl font-semibold text-[#344054]'>{item.companyName}</p>
                  <Link to={`/brief/brief-view/${item._id}`}>
                    <div className='flex mt-6 gap-2 items-center'>
                      <span className='text-[#00A46F] font-semibold'>View Brief</span>
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.1665 10.5001H15.8332M15.8332 10.5001L9.99984 4.66675M15.8332 10.5001L9.99984 16.3334" stroke="#00A46F" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </div>
                  </Link>
                  {submittedmessage && <p className='mt-8 text-xs text-[#344054]'>Submitted {submittedmessage}</p>}

                </div>
              );
            })} */}
                  </div>
                </div>
            }
            <div className="row g-6 my-6">
              <div className="col-sm-9 col-lg-9">
                <div className="card shadow">
                  <div className="card-header border-bottom bg-[#1D2939]">
                    <p className="mb-0 text-white">My Mash Projects</p>
                  </div>

                  <div className="table-responsive" style={{ maxHeight: 424, minHeight: 383, padding: 0 }}>
                  
                    {meetMasherDashboard?.data?.Projects?.length > 0 ?
                      <table className="table table-hover table-nowrap">
                        <thead className="text-white bg-black">
                          <tr>
                            <th scope="col">Project Name</th>
                            <th scope="col">Company</th>
                            <th scope="col">Expected Start Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Project Owner</th>
                            <th scope="col">Masher Team</th>
                            <th scope="col">Link</th>
                          </tr>
                        </thead>
                        <tbody>
                          {meetMasherDashboard?.data?.Projects.map((item, index) => {
                            const owner_name = item?.owner?.[0]?.firstName + ' ' + item?.owner?.[0]?.lastName;
                            const owner_img = item?.owner?.[0]?.profilePic;
                            const rolesDetails = item?.deliverables?.[0]?.role || [];
                            const rolesWithoutMashManager = Array.from(
                              new Set(
                                rolesDetails.flatMap((role) => {
                                  const mashers = role.Masher || [];
                                  return mashers.map((masher) => masher.masherId);
                                })
                              )
                            );
                            return (
                              <tr key={`Work-${index}`}>
                                <td className='text-left'>
                                  <span className='text-[#667085]'>{item.name || '-'}</span>
                                </td>
                                <td className='text-left'>
                                  <span className='text-[#667085]'>{item.companyName || '-'}</span>
                                </td>
                                <td className='text-left'>
                                  <span className='text-[#667085]'>{formatDate(item.projectExpectedStartDate) || '-'}</span>
                                </td>
                                <td className='text-left'>
                                  <span className='text-[#027A48] bg-[#ECFDF3] rounded-xl px-2 py-1'>{item.status}</span>
                                </td>
                                <td className='text-left'>
                                  {/*  */}
                                  {
                                    owner_name != '' ?
                                      <div className='flex items-center gap-4'>
                                        {
                                          owner_img ? <img src={owner_img} className='w-10 h-10 rounded-full' /> :
                                            <div class="avatar-container">
                                              <div class="avatar-no-url"><span>{getInitials(owner_name)}</span>
                                              </div>
                                            </div>
                                        }
                                        <span className='text-[#101828]'>{owner_name || ''}</span>
                                      </div>
                                      :
                                      '-'

                                  }

                                </td>
                                <td className='text-left'>
                                  <div className='flex '>
                                    {
                                      Array.from(
                                        new Set(
                                          rolesDetails.flatMap((role) => {
                                            const mashers = role.Masher || [];
                                            return mashers.map((masher) => masher.masherId);
                                          })
                                        )
                                      ).slice(0, 5).map((masherId, index) => {
                                        const role = rolesDetails.find((r) => (r.Masher || []).some((m) => m.masherId === masherId));
                                        const masher = (role.Masher || []).find((m) => m.masherId === masherId);
                                        return masher.profilePic ? (
                                          <img
                                            key={index}
                                            src={masher.profilePic}
                                            className={index === 0 ? 'w-10 h-10 rounded-full bg-[#fff]' : 'w-10 h-10 rounded-full -ml-3  border-2 border-white bg-[#fff]'}
                                            alt={`Profile ${index + 1}`}
                                          />
                                        ) : (
                                          <div className={index === 0 ? 'avatar-container' : 'avatar-container -ml-3 '} key={index}>
                                            <div className="avatar-no-url"><span>{getInitials(masher.masherName)}</span></div>
                                          </div>
                                        );
                                      })
                                    }


                                    {rolesWithoutMashManager.length > 5 && (
                                      <div className="w-10 h-10 rounded-full bg-[#00A46F] -ml-3 z-30 flex items-center justify-center font-bold text-white border-2 border-white">+
                                        {rolesWithoutMashManager.length - 5}</div>
                                    )}
                                  </div>
                                </td>
                                <td className='text-left'>

                                  <Link to={`/project/project-view/${item._id}`}>
                                    <span className='text-[#00A46F] text-lg'>View</span>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      :
                      <div className='pl-container'>

                        <img src={NoMashplayerimg} alt="..." />

                        <p className='high-pl'>Your Project List is emptier than the vacuum of space</p>
                        <p className='low-pl'>Update your profile and get some projects!</p>
                        <Link to={`/mashers-profile/${loggedInUser?.data?._id || 'create'}`} className='text-lg font-semibold text-white bg-[#00A46F]  p-3 mt-3'>
                                Complete Profile
                        </Link>
                      </div>
                    }
                      
                    {allMasherProject?.loading ? <div className="text-center mt-8"><Loader /></div> : ''}
                  </div>

                </div>
              </div>
              <div className="col-sm-3 col-lg-3">
                <div className="card shadow">
                  <div className="card-header border-bottom bg-[#1D2939]">
                    <p className="mb-0 text-white">Chat Notifications</p>
                  </div>
                  <NotificationList loggeduser={loggedInUser} />
                </div>
              </div>
            </div>
            {/* {meetMasherDashboard?.data?.MasherFeeds.length > 0 && 
            <div className='flex flex-col items-center md:items-start gap-4 mt-4 w-full'>
              <span className="text-2xl text-black font-bold flex items-center gap-2 w-full">Mash Ups</span>

              <div className='w-[calc(100vw-32px)] md:w-full flex flex-col md:flex-row gap-8 flex-wrap justify-between'>
         
                {meetMasherDashboard?.data?.MasherFeeds.slice(0, 2).map((item, index) => {
                  return(
                    <div key={`Work-${index}`} className='w-full md:w-[calc(50%-16px)] flex flex-col md:flex-row md:gap-8 min-w-[312px] min-h-[268px] max-w-1/2 bg-[#FED53F]  relative text-white px-8 py-6 rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]'>
                      {
                        item.imageLink ? 
                          <img  src={item.imageLink} className='w-full md:w-[211px] border-black border-2' />
                          :
                          <img src={loggedInUser?.data?.userId?.profilePic} className='w-full md:w-[211px] border-black border-2' />
               
                      }
                      <div className='flex flex-col gap-2 mt-4'>
                        <p className='font-[Smooch] text-4xl'>{item.heading || ''}</p> 
                        <div dangerouslySetInnerHTML={{ __html: item.message || '' }} />
                        {
                          item.cta &&  
                <div className='w-full flex justify-end'>
                  <button className='bg-[#00A46F] px-4 py-2 text-white'>{item.cta}</button>
                </div>
                        }
                      </div>
              
                    </div>
                  );
                })}
                
              </div>
            </div>
            } */}
          </div>
        )}
      <RequiredFieldPopup data={Fieldvalue} FieldCheck={FieldCheck} masherID={loggedInUser?.data?._id} createprofile={createprofile} />
      <ProfilePreview masherID={loggedInUser?.data?._id} loggedInUser={loggedInUser} />
      <SkipPopUp />
      <ReferCreativepopup />
      <SorryPopup />

    </AdminLayout>
  );
}


/*       
        <div className="row g-6 mb-6">
          <div className="col-md-8 custom-table">
            <div className="card shadow rounded-4 " style={{ maxHeight: 450, minHeight: 450 }}>
              <div className="card-header">
                <h5 className="mb-0">My Briefs</h5>

                <div><p className="text-muted">See anything you love? We’d love to know.</p></div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover table-nowrap">
                  <thead className="text_gray_light ">
                    <tr>
                      <th scope="col">BRAND</th>
                      <th scope="col">BRIEF</th>
                      <th scope="col">CREATED DATE</th>
                      <th scope="col">REMAINING TIME</th>
                      <th scope="col">STATUS</th>
                      <th></th>
                    </tr>
                  </thead>
                  {allBriefInvite?.loading ? ('') :
                    <tbody>
                      {invitedBriefs?.length > 0 ? invitedBriefs?.map((el, i) => {
                        let colorForTimer = getTimeRemaining(el?.validFrom, el?.validTill);
                        return (
                          <React.Fragment key={`inv-${i}`}>
                            <tr>
                              <td >
                                <div onClick={() => { navigate(`/brief/brief-view/${el?._id}`, { state: { masherData: masherData } }); }}>
                                  <img
                                    alt="..."
                                    src={assignBucketUrl(el?.briefId?.logo)}
                                    className="avatar avatar-sm rounded-circle me-2 cursor-pointer"
                                    onError={event => {
                                      event.target.src = ProfilePicDemo;
                                      event.onerror = null;
                                    }}
                                  />
                                  <Link to="" className="text-heading font-semibold me-2">
                                    {el?.briefId?.clientName || 'N/A'}
                                  </Link>
                                </div>
                              </td>
                              <td>
                                {el?.briefId?.briefName || 'N/A'}
                              </td>
                              <td>
                                <span className="badge badge-lg badge-dot">
                                  <Moment
                                    format="DD-MM-YYYY"
                                    date={el?.briefId?.createdAt || 'N/A'}
                                  />
                                </span>
                              </td>
                              <td>
                                <span className="badge badge-lg badge-dot">
                                  {
                                    (el?.status === 'skipped' || el?.status === 'applied') ?
                                      ('Time Ended') :
                                      (
                                        el?.validTill > 1
                                      ) ?
                                        <div className={colorForTimer === -1 ? 'text-danger' : 'text-success'}>{formatDiffTimeFromNowHHMMfor48hours(el?.validFrom, el?.validTill)}</div>
                                        :
                                        ('Time Ended')
                                  }
                                </span>
                              </td>
                              <td>
                                {
                                  el?.status === 'applied' || el?.status === 'approved' ? (
                                    <>
                                      <span className={'badge rounded-pill bg-soft-success text-success'}>
                                        {el?.status === 'applied' ? 'Applied' : 'Active'}
                                      </span>
                                    </>) : (
                                    el?.status === 'pending' ? (
                                      <>
                                        <span className="badge rounded-pill bg-soft-warning text-warning">
                                          {'Pending' || ''}
                                        </span>
                                      </>) : (
                                      <>
                                        <span className="badge rounded-pill bg-soft-danger text-danger">
                                          {'Skipped' || ''}
                                        </span>
                                      </>
                                    )
                                  )
                                }
                              </td>
                              <td className="sticky-div">
                                <button onClick={() => { navigate(`/brief/brief-view/${el?._id}`, { state: { masherData: masherData } }); }} className="btn btn-sm btn-neutral">
                                  View
                                </button>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      }) : ''}
                    </tbody>}
                </table>
                {allBriefInvite?.loading ? <div className="text-center mt-10"><Loader /></div> : (!allBriefInvite?.data?.length > 0 ? <div className="text-center mt-10">No data found</div> : '')}
              </div>
</div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card shadow rounded-4" style={{ minHeight: 450, maxHeight: 450 }}>
              <div className="card-header d-flex align-items-center">
                <p className="d-block mb-0"><h5>Meet Mashers in your area</h5>
                  <p className="text-muted meet-masher-text">Your next best friend is only a hello away.</p>
                </p>
              </div>
              <div
                className="card-body position-relative scrollable-y"
                style={{
                  paddingTop: '0'
                }}
              >
                {
                  filteredMeetMasher?.length > 0 ? filteredMeetMasher?.map((el, i) => {
                    return (
                      <React.Fragment key={`fil-${i}`}>
                        <div className="list-group list-group-flush">
                          <div className="list-group-item px-0 position-relative hstack flex-wrap">
                            <div className="me-4">
                              <div className="avatar rounded-circle">
                                <img alt="..." style={{ 'height': '100%' }}
                                  src={assignBucketUrl(el?.userId?.profilePic)}
                                  onError={event => {
                                    event.target.src = ProfilePicDemo;
                                    event.onerror = null;
                                  }}
                                />
                              </div>
                            </div>
                            <div className="flex-fill">
                              <Link to={el?._id ? `/masher-view/${el?._id}` : '/no-masher-found'} className="d-block h6 font-semibold mb-1">
                                {(el?.userId?.firstName && el?.userId?.lastName) ? el?.userId?.firstName + ' ' + el?.userId?.lastName : el?.userId?.email}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  }) : ('')
                }
                {
                  meetMasher?.loading ? (<><div className="text-center mt-8"><Loader /></div></>) : ('')
                }
                {
                  filteredMeetMasher?.length === 0 ? (<><div className="text-center mt-10">No Mashers found in your area</div></>) : ('')
                }
              </div>
            </div>
          </div>
        </div>



        {
                      allMasherProject?.data?.length > 0 &&
                        [...allMasherProject?.data].reverse()?.map((el, i) => {
                          return (
                            <React.Fragment key={`msh-${i}`}>
                              <tr>
                                <td>
                                  <div onClick={() => { navigate(`/masher-project-view/${el?._id}`, { state: { masherData: masherData } }); }}>
                                    <img
                                      alt="..."
                                      src={assignBucketUrl(el?.briefId?.logo)}
                                      className="avatar avatar-sm rounded-circle me-2 cursor-pointer"
                                      onError={event => {
                                        event.target.src = ProfilePicDemo;
                                        event.onerror = null;
                                      }}
                                    />
                                    <Link to="" className="text-heading text-primary-hover font-semibold">
                                      <a
                                      >
                                        {el?.briefId?.clientName.length > 20 ? el?.briefId?.clientName.slice(0, 10 - 1) + '...' : el?.briefId?.clientName}
                                      </a>
                                    </Link>
                                  </div>
                                </td>
                                <td>{el?.briefId?.briefName.length > 30 ? el?.briefId?.briefName.slice(0, 10 - 1) + '...' : el?.briefId?.briefName || 'N/A'}</td>
                                <td>
                                  <Moment
                                    format="DD-MM-YYYY"
                                    date={el?.briefId?.createdAt}
                                  />
                                </td>
                                <td>{
                                  el?.status === 'active' ? (
                                    <>
                                      <span className={'badge rounded-pill bg-soft-success text-success'}>
                                        {el?.status}
                                      </span>
                                    </>) : ('')
                                }</td>
                                <td>{(el?.briefId?.deliverableDetails?.[0]?.masherRole ? (Array.isArray(el?.briefId?.deliverableDetails?.[0]?.masherRole) ? (el?.briefId?.deliverableDetails?.[0]?.masherRole[0].label) : (el?.briefId?.deliverableDetails?.[0]?.masherRole.label)) : ('--'))}</td>

                                <td>
                                  <div className='avatar-group'>
                                    {!el?.team?.length <= 0
                                      ? el?.team?.map((el, i) => {
                                        let masherName = el?.masherName?.label;
                                        let masherFirstLetter = el?.masherName?.label?.charAt(0);
                                        let masherId = el?.masherName?.value;
                                        return (
                                          <Tooltip key={`tool-${i}`} content={masherName} fadeDuration={10} fadeEasing='linear'>
                                            <Link
                                              to={`/masher-view/${masherId}`}
                                              className=' projectTeam avatar rounded-circle text-white border border-1 border-solid border-card z-index-1'>
                                              {masherFirstLetter}
                                            </Link>
                                          </Tooltip>
                                        );

                                      })
                                      : '--'}
                                  </div>
                                </td>
                                <td className="sticky-div">
                                  <button onClick={() => { navigate(`/masher-project-view/${el?._id}`, { state: { masherData: masherData } }); }} className="btn btn-sm btn-neutral">
                                    View
                                  </button>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })
                    }
          */