import { POST,postApi,DeleteApi, postApiWithoutToken, getApi, fileUpload, patchApi, getAuthApi, getDetailsById, getWithoutDataApi, GET, fileUploadwithprogress } from '../apis/api-interfaces';
//fikle upload API for brief...
export const fileUploadApiBrief = (payload,onUploadProgress,file) => {
  return fileUploadwithprogress('/api/v1/common/uploads/briefattach', payload,onUploadProgress,file);
};
//fikle upload API for projects...
export const ProjectfileUploadApi = (payload,onUploadProgress,file) => {
  return fileUploadwithprogress('/api/v1/common/uploads/projectattach', payload,onUploadProgress,file);
};
export const createBriefAttachApi = (payload) => POST('/api/v2/admin/brief/attachment/add', payload);
export const createProjectAttachApi = (payload) => POST('/api/v2/admin/project/attachment/add', payload);

//delete account api...........
export const DeleteMasherApi = (payload) => POST('/api/v2/user/masherProfile/masherDelete', payload);

//send-otp api... for sign in...
export const signInOtpApi = (payload) => {
  return postApiWithoutToken('/api/v1/common/auth/sendOtp', payload);
};

//verify OTP api...
export const verifyOtpApi = (payload) => {
  return postApiWithoutToken('/api/v1/common/auth/verifyOtp', payload);
};

//register masher api with otp...
export const registerMasherApi = (payload) => {
  return postApiWithoutToken('/api/v1/common/auth/register', payload);
};

//other skills json...
export const getOtherSkillsApi = (id) => {
  return getApi('/json/skills/skills.json', id);
};

export const getRegionNameApi = (id) => {
  return getApi('/json/region/region.json', id);
};

// export const getTimeZonesApi = (id) => {
//   return getApi("/json/timezone/timezone.json")
// }
export const getTimeZonesApi = (search = '') => {
  return getApi(`/json/timezone/timezone.json?search=${search}`);
};

export const getTimeZoneLocationApi = (search = '', lat = '', long = '' ) => {
  return getApi(`/api/v1/user/masherProfile/timeZones?country=${search}&latitude=${lat}&longitude=${long}`);
};

export const getAllArchivedApi = () => getApi('/api/v1/admin/brief/archived');

export const getIndustryNameApi = (id) => {
  return getApi('/json/industry/industry.json', id);
};
export const fetchIndustryApi = () => getApi('/api/v1/helper/industry-list');
export const fetchClientApi = () => getApi('/json/brand/brand.json');

export const getAllClientsApi = (
  search = '', 
  limit, 
  page = 1,
  industry = '',
  type='',
  region='',
  fieldName='',
  orderBy='',
) => {
  return getApi(`/api/v1/admin/client/allClients?search=${search}&limit=${limit}&page=${page}&industry=${industry}&type=${type}&region=${region}&fieldName=${fieldName}&orderBy=${orderBy}`);
};

export const getCategoryNameApi = (id) => {
  return getApi('/json/categories/categories.json', id);
};

export const getCountryCode = (id) => {
  return getApi('/json/countryCode/countryCode.json', id);
};
// brands api json 
export const getBrandsDataApi = (id) => {
  return getApi('/json/brand/brand.json', id);
};
//fikle upload API...
export const fileUploadApi = (payload,onUploadProgress,file) => {
  return fileUploadwithprogress('/api/v1/common/uploads/file', payload,onUploadProgress,file);
};
//fikle upload with progress API...
export const fileUploadwithprogressApi = (payload,onUploadProgress,file) => {
  return fileUploadwithprogress('/api/v1/common/uploads/masherworkattach', payload,onUploadProgress,file);
};


//profile upload API...
export const MasherProfileUploadApi = (payload,onUploadProgress,file) => {
  return fileUploadwithprogress('/api/v1/common/uploads/masherprofile', payload,onUploadProgress,file);
};
//masherworkattach API...
export const masherworkattach = (payload) => {
  return fileUpload('/api/v1/common/uploads/masherworkattach', payload);
};
//mashMyworkAddApi API...
export const mashMyworkAddApi = (payload) => {
  return postApi('/api/v2/user/masherProfile/mywork/add', payload);
};
//mashMyworkupdateApi API...
export const mashMyworkUpdateApi = (payload) => {
  return patchApi('/api/v2/user/masherProfile/mywork/update', payload);
};
//mashMyworkRemoveApi API...
export const mashMyworkRemoveApi = (masherId,myworkId) => {
  return DeleteApi(`/api/v2/user/masherProfile/mywork/remove?masherId=${masherId}&myworkId=${myworkId}`);
};
export const weavyTokenApi = (payload) => {
  return POST('/api/v2/common/weavy/getToken', payload);
};

export const weavyAddProjectApi = (payload) => {
  return POST('/api/v2/common/weavy/addProject', payload);
};
//create-profile
export const createProfileApi = (payload) => {
  return postApi('/api/v1/user/masherProfile/create', payload);
};

export const updateUserApi = (payload) => {
  return patchApi('/api/v1/user/update', payload);
};

//masher-profile-api...
export const getMahserProfileApi = () => {
  return getAuthApi('/api/v1/user/masherProfile/byAuth');
};

//masher profile update api...
export const updateMasherProfile = (payload) => {
  return patchApi('/api/v1/user/masherProfile/update', payload);
};
//masher user update api...
export const updateMasheruserProfile = (payload) => {
  return patchApi('/api/v1/user/updateById', payload);
};

//getting all particular masher's brief...
export const getAllBriefOfMasher = (search = '', startDate = '', dueDate = '', limit = '', page = '') => {
  return getWithoutDataApi(`/api/v1/common/briefInvitation/all?search=${search}&startDate=${startDate}&dueDate=${dueDate}&limit=${limit}&page=${page}`);
};

//updating brief status by accepting brief...
export const updateBriefStatus = (payload) => {
  return postApi('/api/v1/common/briefInvitation/update', payload);
};

//get brief details by _id...
export const getBriefDetailsById = (id) => {
  return getDetailsById(`/api/v1/common/briefInvitation/detailsById?id=${id}`);
};

//get brief details by _id in v2 ...
export const getBriefDetailsByIdV2 = (id) => {
  return getDetailsById(`/api/v2/admin/brief/byId?id=${id}&type=masher`);
};
//get Project details by _id in v2 ...
export const getProjectDetailsByIdV2 = (id) => {
  return getDetailsById(`/api/v2/admin/project/byId?id=${id}&type=masher`);
};
//global brief details by _id...
export const getGlobalBriefDetailsById = (id) => {
  return getDetailsById(`/api/v1/admin/brief/byId?id=${id}`);
};

//global project details by _id...
export const getGlobalProjectDetailsById = (id) => {
  return getDetailsById(`/api/v1/admin/project/byId?id=${id}`);
};


export const getAllBriefsApi = (
  briefName = '',
  startDate = '',
  dueDate = '',
  limit = '',
  page = '',
  isProject = '',
  brandName = '',
  createdBy = '',
  minBudget = '',
  maxBudget = ''
) =>
  getApi(`/api/v1/admin/brief/allBriefs?briefName=${briefName}
&startDate=${startDate}
&dueDate=${dueDate}
&limit=${limit}
&page=${page}
&isProject=${isProject}
&brandName=${brandName}
&createdBy=${createdBy}
&minBudget=${minBudget}
&maxBudget=${maxBudget}`);



//Masher Projects...
export const getAllMasherProjectApi = (search = '', startDate = '', dueDate = '', limit = '', page = '', isProject = true) => {
  return getWithoutDataApi(`/api/v1/admin/brief/allBriefs?search=${search}&startDate=${startDate}&dueDate=${dueDate}&limit=${limit}&page=${page}&isProject=${isProject}`);
};

//get global projects API(s)...
export const getAllMasherProjectsApi = (search = '', startDate = '', dueDate = '', limit = '', page = '') => {
  return getWithoutDataApi(`/api/v1/user/project/allProjects?search=${search}&startDate=${startDate}&dueDate=${dueDate}&limit=${limit}&page=${page}`);
};

//get recent activity...
export const getRecentActivityApi = () => {
  return getWithoutDataApi('/api/v1/common/recentActivity/masher');
};

export const referCreativeApi = (payload) => {
  return postApi('/api/v1/common/refer/referMasher', payload);
};

export const getLocationInputApi = (search) => {
  return getWithoutDataApi(`/api/v1/common/google/google?input=${search}`);
};

export const getRefereredMashers = () => {
  return getWithoutDataApi('/api/v1/common/refer/byAuth');
};


//masher prfile by ID...
export const otherMasherProfileApi = (id) => {
  return getWithoutDataApi(`/api/v1/user/masherProfile/byId?id=${id}`);
};
//update lastlogin ID...
export const UpdateLastLoginApi = (payload) => {
  return patchApi('/api/v1/user/updateLastLogin', payload);
};

export const otherMasherProfileApiNew = (id) => {
  // return getWithoutDataApi(`/api/v1/user/masherProfile/byId?id=${id}`);
  return getWithoutDataApi(`/api/v1/user/masherProfile/getById?id=${id}`);
};

//meet mashers in your area
export const meetMashersInYourArea = () => {
  return getWithoutDataApi('/api/v1/user/masherProfile/nearBy');
};

export const getAllBrief = (limit = '', page = '', fieldName='', orderBy='') => {
  return getWithoutDataApi(`/api/v1/admin/brief/briefGlobal?limit=${limit}&page=${page}&fieldName=${fieldName}&orderBy=${orderBy}`);
};

export const getMashReferred = () => {
  return getWithoutDataApi('/api/v1/common/refer/byAuth');
};

export const getAllCounts = () => {
  return getWithoutDataApi('/api/v1/user/dashboard/counts');
};

// export const referSaveApi = (payload) => {
//   return postApi(`/api/v1/common/refer/referSave`, payload)
// }

export const getReferDetailsApi = () => {
  return getApi('/api/v1/common/refer/email');
};

export const applyToBriefV2 = (payload) => {
  return postApi('/api/v2/common/briefInvitation/apply', payload);
};

//Apply brief Api..............
export const applyToBriefNew = (payload) => {
  return patchApi('/api/v2/admin/brief/mashupteam/update', payload);
};
//Decline brief Api..............
export const DeclineBriefNew = (payload) => {
  return postApi('/api/v2/admin/brief/mashupteam/remove', payload);
};

//mashersDashboard Api.....................

export const getmasherdashboardApi = (id) => {
  return getApi(`/api/v1/user/dashboard/masherdashboard?masherId=${id}`);
};

export const getMasherByIdApi = (id) => {
  return GET(`/api/v1/user/masherProfile/byId?id=${id}`);
};


//get all roles ......................
export const getAllRolls = (limit = '', page = '', type='roles') => {
  return getWithoutDataApi(`/api/v2/admin/profile/getAll?limit=${limit}&page=${page}&type=${type}`);
};
//get all Skills ............
export const getAllSkills = (limit = '', page = '') => {
  return getWithoutDataApi(`/api/v1/admin/profile/allSkills?limit=${limit}&page=${page}`);
};
//get all Skills ............
export const getRollsbySkills = (skills='') => {
  return getWithoutDataApi(`/api/v2/admin/profile/getRole?skill=${skills}`);
};
