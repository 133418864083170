import React, { useEffect, useState, useCallback } from 'react';
import { WeavyClient, WeavyProvider, Chat, MessengerProvider, ConversationList } from '@weavy/uikit-react';
import {useParams} from 'react-router-dom';
import axios from 'axios';

import '@weavy/uikit-react/dist/css/weavy.css';
import './chat.css';
import { weavyTokenApi } from '../../apis';

const accessToken = process.env.REACT_APP_WEAVY_API_KEY;
const tsKey = process.env.REACT_APP_TYPESENSE_API_KEY;
const tsHost = process.env.REACT_APP_HOST_NAME;
const weavyUrl = process.env.REACT_APP_WEAVY_URL;

const MasherChat = ({ loggeduser, briefOwnerId }) => {
  const [weavyClient, setWeavyClient] = useState(null);
  const [chatUID, setChatUID] = useState();
    
  const params = useParams();
  const roomId = params.briefId;
  const uid = loggeduser?.data?._id;
  //Room for briefs P2P
  const chatId = `${uid}_${briefOwnerId}_${roomId}`;
  const username = loggeduser?.user?._id;
    


  const getWeavyToken = useCallback(async (username) =>{
    try {
      //alert("Inside getWeavyToken",userfname);
      const values = {firstname: username};
      const res = await weavyTokenApi(values);
      if (res.status === 200 || 201) {
        // alert(res);
        return res.data;
      }
    } catch (err) {
      //alert(err);
    }
  }, []);
    
  const setToken = async (username) => {
    if (username) {
      const usertoken = await getWeavyToken(username);
      let client = new WeavyClient({ 
        url: weavyUrl, 
        tokenFactory: async () => usertoken
      });
      await weavySetup(chatId);
      setWeavyClient(client);
    }
        
  };
    
  const handleMessage = (message) => {
    console.log(message);
  };
  useEffect(() => {
    if (username) {
      setToken(username); 
    }
  }, [username]);
    
  const weavySetup = async (chatId) => {
    const userurl = weavyUrl+'/api/users/'+username;
    const headers = {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    };
    const data = {
      uid: username,
      name: `${loggeduser?.user?.firstName} ${loggeduser?.user?.lastName}`,
      directory: `${chatId}_directory`
    };
      
    await axios.put(userurl, data, { headers })
      .then(response => {
        // console.log('Success:', response.data.name);
        const chaturl = weavyUrl+'/api/apps/init';
  
        const headers = {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        };
        const chatdata = {
          app: {
            uid: chatId,
            name: 'Brief Owner',
            type: 'Chat',
          },
          user: {
            uid: username
          },
        };
  
        axios.post(chaturl, chatdata, { headers })
          .then(response => {
            setChatUID(response.data.uid);
            /*const tokenurl = weavyUrl+'/api/users/'+userfname+'/tokens';
                const headers = {
                  'Authorization': `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
                };
                axios.post(tokenurl, null, { headers })
                  .then(response => {
                    // console.log('Success:', response.data.access_token);
                    usertoken = response.data.access_token;
                  })
                  .catch(error => {
                    console.error('Error:', error.response.data);
                  });*/
          })
          .catch(error => {
            console.error('Error:', error);
          });
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
    
  //   let config = {
  //     method: 'get',
  //     maxBodyLength: Infinity,
  //     url: weavyUrl+'/api/notifications?order_by=id+desc&unread=true&count_only=true',
  //     headers: { 
  //       'Authorization': 'Bearer '+usertoken,
  //       'Content-Type': 'application/json',
  //     }
  //   };
  
  //   axios.request(config)
  //     .then(response => {
  //       notiCount=response.data.count;
  //       // console.log('response from axios request',response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error:', error);
  //     });
  return <><div className="App chat-main-window">
    <div className='bg-[#334054] w-full py-4 px-6 font-bold text-white text-right'>Brief Owner Chat</div>
    {chatUID && <WeavyProvider client={weavyClient}>
      <MessengerProvider>
        <Chat features={{ cloudFiles: false, attachments: false, mentions: true, receipts: true, typing: true }} uid={chatUID}/>
      </MessengerProvider>
    </WeavyProvider>}
  
  </div></>;
};

export default MasherChat;