import React,{useState}from 'react';
import {ExclamationTriangle} from 'react-bootstrap-icons';
import { EditProfileLoader } from '../loader/loader';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { assignBucketUrl, logoutOnJwtExpire } from '../../utils/helperFunctions';
import { Pen, PenFill, Download, ArrowUp, ArrowDown } from 'react-bootstrap-icons';
import expandView from '../../Icons/expandView.svg';
import minimiseView from '../../Icons/minimiseView.svg';
export default function ProfilePreview({masherID,loggedInUser}) {
  var current_masher=false;
  if(loggedInUser?.data?._id===masherID){
    current_masher=true;
  }
  else{
    current_masher=false;
  }
  const url = `/masher-view/${masherID}/viewonly/masher/type=${current_masher ? 'current':'other'}`;
  const [fullscreenView, setFullscreenView] = useState(false);

  const handleViewSize = () => {
    setFullscreenView(!fullscreenView);
  }
  return (
    <>
       <form>
        <div
          className="modal"
          id="profilePreview"
          tabIndex={-1}
          aria-labelledby="profilePreview"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" style={{maxWidth: `${fullscreenView ? '90vw' : '500px'}` }}>
            <div className="modal-content shadow-3">
              <div className="modal-body w-full">
                <div style={{display: 'flex', alignItems: 'flex-start'}}>
                  <div onClick={handleViewSize}><img src={!fullscreenView ? expandView : minimiseView}/></div>
                  <div className="ms-auto btn-close cursor-pointer mb-5" data-bs-dismiss="modal" aria-label="Close"></div>
                </div>
                <iframe
                  title="Embedded Content"
                  className='w-full h-full-vh'
                  style={{height:'80vh'}}
                  src={url}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
