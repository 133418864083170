import React, { useEffect, useState,useContext } from 'react';
import Moment from 'react-moment';
import { otherMasherProfileApi,otherMasherProfileApiNew } from '../../apis';
import AdminLayout from '../../components/admin-layout';
import { useNavigate, useParams, Link } from 'react-router-dom';
import CoverPic from '../../Assets/img/covers/img-profile.jpg';
import SideCover from '../../Assets/img/covers/side_toolkit.png';
import EditProfile from '../../Assets/img/covers/profilePicDemo.png';
import { assignBucketUrl, logoutOnJwtExpire } from '../../utils/helperFunctions';
import { EditProfileLoader, ImgLoader, Loader } from '../../components/loader/loader';
import UpdateWorkModal from './UpdateWorkModal';
import AddWorkModal from './AddWorkModal';
import WorkModal from './WorkModal';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { DeletePopup } from './DeletePopup';
import ProfilePreview from '../../components/Header/ProfilePreview';
import {PersonCircle} from 'react-bootstrap-icons';
import AppContext from '../../appContext';
import { mashMyworkAddApi, mashMyworkRemoveApi, getmasherdashboardApi } from '../../apis';
import { Pen, PenFill, Download, ArrowUp, ArrowDown } from 'react-bootstrap-icons';
const WorkSample = [
  {
    image: 'https://picsum.photos/300/200',
    company: 'Appleby\'s',
    role: 'Graphic Designer',
    description: 'Branding and Menu Design',
    link: 'https://google.com'
  },
  {
    image: 'https://picsum.photos/300/200',
    company: 'Appleby\'s',
    role: 'Graphic Designer',
    description: 'Branding and Menu Design',
  },
  {
    image: 'https://picsum.photos/300/200'
  }
];

export default function Index() {
  const [masherData, setMasherData] = useState({ data: null, loading: false });
  const [updateMode, setUpdateMode] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [updateDetails, setUpdateDetails] = useState({});
  const [DeleteDetails, setDeleteDetails] = useState({});
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isWorkOpen, setIsWorkOpen] = useState(false);
  const [meetMasherDashboard, setMeetMasherDashboard] = useState({ data: null, loading: false });
  const[private_state,setprivate_state]=useState(false);
  const [workDetails, setWorkDetails] = useState({
    details: {},
    index: 0
  });
  // let countryForTime = masherData?.data?.masherProfile?.location?.split(",")?.at(-1);

  const navigate = useNavigate();

  const loading = masherData?.loading;

  const params = useParams();
  const {'*' : rest } = params;
  
  const appContext = useContext(AppContext);
  const {authcall}=appContext;
  const { state, fetchMasherProfile } = appContext;
  const { loggedInUser } = state;
 
  const fetchOtherMasherProfile = async () => {
    setMasherData({ loading: true });
    try {
      const res = await otherMasherProfileApiNew(params?.masherId);
      if (res.status === 200) {
        // toast.success(, { id : "002" })
        setMasherData({ data: res?.data, loading: false });
        var ismasher=rest.includes('masher');
        var iscurrentmasher=rest.includes('current');
          if(res.data?.masherProfile?.isVisibletoOthers==false && rest && ismasher){
            if(iscurrentmasher){
              setprivate_state(false);
            }
            else{
              setprivate_state(true);
            }
            
          }
          else{
            setprivate_state(false);
          }
        
        if(res.data?.masherProfile?.Mywork?.[0]){
          setUpdateMode(false);
        }
        else{
          setUpdateMode(true);
        }
      }
    } catch (error) {
      setMasherData({ loading: false });
      if (error?.response?.data?.status === 401) {
        
        // logoutOnJwtExpire();
        // navigate('/sign-in');
      }
    }
  };
  const mashersDashboard = async () => {
    setMeetMasherDashboard({ loading: true });
    try {
      const res = await getmasherdashboardApi();
      if (res.status === 200) {
        setMeetMasherDashboard({ data: res?.data, loading: false });
      }
    } catch (error) {
      setMeetMasherDashboard({ loading: false });
    }
  };
  useEffect(() => {
    fetchOtherMasherProfile();
    mashersDashboard();
  }, []);
 

  const closeModal = (id) => {
    const closeButton = document.getElementById(id);

    if (closeButton) {
      closeButton.click();
    }
  };

  // Add MyWrok.............
  const [loader_1, setLoader_1] = useState(false);
  const formik_1 = useFormik({
    initialValues: {
      company: '',
      role: '',
      description: '',
      link: '',
      files: '',


    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {

      const { company, role, description, link, files } = values;

      try {
        setLoader_1(true);
        const res = await mashMyworkAddApi({
          'masherId': params?.masherId || '',
          'filePath': files || '',
          'company': company || '',
          'role': role || '',
          'description': description || '',
          'link': link || ''
        });
        if (res.status === 200 || 201) {
          setLoader_1(false);
          toast.success(res.data.message, { id: 'succcess' });
          fetchOtherMasherProfile();
          closeModal('modalworkCloseButton');
          resetForm();
          setSubmitting(false);
        }
      } catch (error) {
        setLoader_1(false);
        toast.error(error.response.data.message, { id: '001' });
      }
    }

  });





  const getFileNameFromURL = (url) => {
    try {
      const urlObject = new URL(url);
      const pathname = urlObject.pathname;
      const pathComponents = pathname.split('/');
      return pathComponents[pathComponents.length - 1];
    } catch (error) {
      console.error('Error parsing URL:', error);
      return ''; // Return a default value or handle the error as per your requirement
    }
  };
  const [showFullText, setShowFullText] = useState(false);
  const toggleText = () => {
    setShowFullText(!showFullText);
  };
  return (
    <>
    {
      (!private_state) ? (

    <AdminLayout sidenav={!rest ? "":"no"}>
      <div className='d-flex flex-column flex-lg-row h-lg-full'>
        {/* Sidebar */}
        {/* Content */}
        <div className='flex-lg-1 h-screen overflow-x-hidden'>
          {/* Navbar */}
          {loading ? (
            <div className='text-center mt-10'>
              <Loader />
            </div>
          ) : (
            <>
              <header className='bg my-8 px-4 md:px-12 md:pb-32'>
                <div className='position-relative'>
                  <div className='flex flex-col md:flex-row md:gap-8 justify-start items-center w-full'>
                    <div className='w-full flex flex-col md:flex-row md:gap-4'>
                      <div className='w-full md:w-2/3 flex flex-col md:flex-row md:gap-8 items-center md:items-start'>
                        <div className='mt-4 md:mt-0'>
                          <div style={{ width: '280px', height: '280px' }} className='rounded-circle'>
                            {loading ? (
                              <div
                                className='text-xs text-center'
                                style={{
                                  backgroundSize: 'cover',
                                  backgroundRepeat: 'no-repeat',
                                  position: 'absolute',
                                  backgroundColor: 'white',
                                  top: '0px',
                                  width: '280px',
                                  height: '280px',
                                  border: '5px solid rgb(255, 255, 255, 1)',
                                  cursor: 'pointer',
                                  borderRadius: '50%',
                                  boxShadow: '0px 1.156px 2.312px 0px rgba(16, 24, 40, 0.06), 0px 1.156px 3.468px 0px rgba(16, 24, 40, 0.10)',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <EditProfileLoader />
                              </div>
                            ) : (
                              <label
                                className='col-sm-4 bg-repeat-0 bg-position-center bg-size-cover'
                                htmlFor='profile'
                                // data-bs-toggle="modal"
                                // data-bs-target="#profilePreview"
                                // id="profilePreview_open"
                                style={{
                                  backgroundSize: 'cover',
                                  backgroundRepeat: 'no-repeat',
                                  position: 'absolute',
                                  backgroundColor: 'white',
                                  top: '0px',
                                  width: '280px',
                                  height: '280px',
                                  border: '5px solid rgb(255, 255, 255, 1)',
                                  cursor: 'pointer',
                                  borderRadius: '50%',
                                  boxShadow: '0px 1.156px 2.312px 0px rgba(16, 24, 40, 0.06), 0px 1.156px 3.468px 0px rgba(16, 24, 40, 0.10)',
                                  backgroundImage: `${Boolean(masherData?.data?.masherProfile?.userId?.profilePic)
                                    ? `url(${assignBucketUrl(masherData?.data?.masherProfile?.userId?.profilePic)})`
                                    : `url(${EditProfile})`
                                    }`,
                                }}></label>
                            )}
                          </div>
                        </div>


                        <div>
                          <p className='text-4xl text-black font-bold break-all'>
                            {masherData?.data?.masherProfile?.userId?.firstName} {masherData?.data?.masherProfile?.userId?.lastName}
                          </p>
                          <div className='flex gap-4 flex-wrap mt-4'>
                            {/* Render the skills inside the span with slashes */}
                            <span className='text-2xl text-black font-bold flex items-center'>
                              {/* {masherData?.data?.masherProfile?.roles?.slice(0, 3)?.map((el, i) => {
                                return i === 0 ? el.label : ` / ${el.label}`; // Add a slash before all elements except the first one
                              })} */}
                               {masherData?.data?.masherProfile?.roles
                                        ?.reduce((acc, el, i, arr) => {
                                          if (!acc.includes(el.label)) {
                                            acc.push(el.label);
                                          }
                                          return acc;
                                        }, [])
                                        .join(' / ')
                                      }
                            </span>
                          </div>
                          {/* <div className="text-2xl text-black font-bold flex items-center gap-2 mt-4">Designer / Copywriter / Art Director</div> */}
                          <div className='flex gap-4 mt-4 items-start'>
                            {/* <span className="text-lg text-black font-bold flex items-center gap-2" >Skills</span> */}
                            <div className='flex gap-4 flex-wrap'>{masherData?.data?.masherProfile?.skills?.slice(0, 3)?.map((el, i) => {
                              return (
                                <React.Fragment key={`skills-${i}`}>
                                  <span className='bg-[#344054] text-[#EEE9E0] px-3 py-1 flex items center rounded-2xl break-word'>
                                    {el?.label}
                                  </span>
                                </React.Fragment>
                              );
                            })}</div>

                          </div>
                          <div className='flex gap-4 mt-8 items-start'>
                            <span className="text-lg text-black font-bold flex items-center gap-2 w-24">Location</span>
                            <span className="text-md font-normal text-black">{masherData?.data?.masherProfile?.location ? masherData?.data?.masherProfile?.location : 'N/A'}</span>
                          </div>
                          <div className='flex gap-4 mt-4 items-start'>
                            <span className="text-lg text-black font-bold flex items-center gap-2 w-24">Languages</span>
                            <span className="text-md font-normal text-black">{
                              masherData?.data?.masherProfile?.language?.map((a) => a.label).join(', ') || '-'}</span>
                          </div>
                         
                          <div className='flex gap-4 mt-4 items-start '>
                            <span className="text-lg text-black font-bold flex items-center gap-2 w-24">Website</span>
                            <span className="text-lg font-normal text-black">
                                {masherData?.data?.masherProfile?.websiteUrls?.[0]?.url ? (
                                  <a
                                    className="underline break-all"
                                    target="_blank"
                                    href={
                                      masherData?.data?.masherProfile?.websiteUrls?.[0]?.url.startsWith('http')
                                        ? masherData?.data?.masherProfile?.websiteUrls?.[0]?.url
                                        : `http://${masherData?.data?.masherProfile?.websiteUrls?.[0]?.url}`
                                    }
                                    rel="noreferrer"
                                  >
                                    {masherData?.data?.masherProfile?.websiteUrls?.[0]?.url}
                                  </a>
                                ) : (
                                  '-'
                                )}
                            </span>
                          </div>
                          <div className='flex gap-4 mt-4 items-start'>
                            <span className="text-lg text-black font-bold flex items-center gap-2 w-24">CV</span>
                            {masherData?.data?.masherProfile?.cv ? <><a className="underline cv-pdf break-all" href={masherData?.data?.masherProfile?.cv} rel='noreferrer' target="_blank">{getFileNameFromURL(masherData?.data?.masherProfile?.cv)}</a>
                              <div className='download-pdf'><Download color='#00A46F' /></div>
                            </> : '-'}

                          </div>
                        </div>

                      </div>
                      <div className='flex flex-col gap-4 mt-4 items-start w-full md:w-1/3 md:mt-0'>
                        {
                          !rest &&  <div className='w-full justify-end hidden md:flex'>
                                    <Link className="text-md text-[#00A46F] font-bold flex items-center gap-2" to={`/mashers-profile/${params?.masherId || 'create'}`}>Edit your details <PenFill /> </Link>
                                    </div>
                        }
                       

                        <span className="text-lg text-black font-bold flex items-center gap-2 mt-4">I've worked for</span>
                        <span className="text-md font-normal text-black">{masherData?.data?.masherProfile?.clientsWorkedFor?.[0]?.label || '-'}</span>
                        <span className="text-lg text-black font-bold flex items-center gap-2 mt-4">Brands I love</span>
                        <span className="text-md font-normal text-black">{masherData?.data?.masherProfile?.brand?.[0]?.label || '-'}</span>
                      </div>

                    </div>

                  </div>
                  <div className='flex flex-col md:flex-row gap-4 mt-4 items-start w-full md:mt-4'>
                    <div className='w-3/3'>
                      <span className="text-2xl text-black font-bold flex items-center gap-2">Bio</span>

                      {masherData?.data?.masherProfile?.biography ?
                        <p className="text-md font-normal text-black whitespace-pre-wrap">
                          {masherData?.data?.masherProfile?.biography}
                          {/* 
                          {showFullText ? masherData?.data?.masherProfile?.biography : `${masherData?.data?.masherProfile?.biography.slice(0, 150)}...`}
                          <button onClick={toggleText}>
                            {showFullText ?
                              <div className='read-con'> Read Less <ArrowUp />  </div> : <div className='read-con'> Read More <ArrowDown />  </div>}
                          </button>
                            */ }
                        </p> : '-'}
                    </div>
                  </div>
                  <div className='flex flex-col gap-4 mt-4 items-start w-full'>
                    
                    
                    
                      <div className='flex w-full justify-between'>
                      <span className="text-2xl text-black font-bold flex items-center gap-2">My Work</span>
                      {
                      !rest &&
                      <button className="text-md text-[#00A46F] font-bold md:flex items-center gap-2 " onClick={() => setUpdateMode(!updateMode)}>{updateMode ? 'Save Portfolio' : 'Update Portfolio'}</button>
                      }
                      </div>
                    <div className='overflow-x-auto w-[calc(100vw-16px)] md:w-full flex gap-4 flex-nowrap md:flex-wrap'>
                      {masherData?.data?.masherProfile?.Mywork.map((item, index) => {
                        return (<div key={`Work-${index}`} className='min-w-[312px] md:w-[calc(33%-8px)] min-h-[268px] md:h-[353px] relative bg-center bg-no-repeat bg-cover' 
                        data-bs-toggle="modal"
                        data-bs-target="#workmodalwork"
                        onClick={() => {
                          // !updateMode && setIsWorkOpen(true), 
                          setWorkDetails({ details: item, index }); }} style={{ backgroundImage: item.filePath ? `url(${item.filePath})` : '' }}>
                          {updateMode && <div className='absolute top-0 left-0 w-full bg-white text-black px-4 py-4'>
                            <div className='flex justify-between items-center gap-2 text-sm'>
                              <div>
                                <button className="text-md text-[#00A46F] font-bold"
                                  data-bs-toggle="modal"
                                  data-bs-target="#updatemodalwork"
                                  onClick={() => {
                                    setUpdateDetails(item);

                                  }}
                                >Edit Details</button>
                              </div>
                              <div>
                                <i className="bi bi-trash text-2xl text-red-600"
                                  data-bs-toggle="modal"
                                  data-bs-target="#Deletemodalwork"
                                  onClick={() => {
                                    setDeleteDetails(item);

                                  }}
                                // onClick={() => { DeleteMywork(item._id) }}
                                />
                              </div>
                            </div>
                          </div>}

                          {(item.company || item.role || item.description || item.link) && <div className={'absolute bottom-0 left-0 w-full bg-white text-black px-4 py-2'}>
                            <div className='flex justify-between items-center gap-2 text-sm'>
                              <div>
                                {item.company && <p className='font-semibold'>Company: <span className='font-normal'>{item.company}</span></p>}
                                {item.company && <p className='font-semibold'>My Role: <span className='font-normal'>{item.role}</span></p>}
                                {item.company && <p className='font-semibold'>Description: <div className='font-normal descr'>{item.description}</div></p>}
                              </div>
                              <div>
                                {item.link && (
                                  <a
                                    onClick={(e) => e.stopPropagation()}
                                    className='font-bold underline text-[#00A46F]'
                                    target='_blank'
                                    href={
                                      item.link.startsWith('http') ? item.link : `http://${item.link}`
                                    }
                                    rel='noreferrer'
                                  >
                                    Link
                                  </a>
                                )}

                              </div>
                            </div>


                          </div>}
                        </div>);
                      })}

                      {(updateMode && !rest) && <button className='min-w-[312px] md:w-[calc(33%-8px)] min-h-[268px] hover:opacity-40 md:h-[353px] border-4 flex items-center justify-center border-[#00A46F] relative'
                        // onClick={()=> setIsAddOpen(true)}
                        data-bs-toggle="modal"
                        data-bs-target="#Addmodalwork"
                      >
                        <i className="bi bi-plus text-7xl text-[#00A46F]" />
                      </button>}
                        {
                          rest && (!masherData?.data?.masherProfile?.Mywork || masherData?.data?.masherProfile?.Mywork.length === 0)  && (
                            <span>N/A</span>
                          )
                        }
                    </div>

                  </div>
                  
                {/* {
                  !rest &&
                
                  <div className='flex flex-col items-center md:items-start gap-4 mt-4 w-full'>
                    <span className="text-2xl text-black font-bold flex items-center gap-2 w-full">Mash Ups</span>
     
                    <div className='w-[calc(100vw-32px)] md:w-full flex flex-col md:flex-row gap-8 flex-wrap justify-between'>
                      {meetMasherDashboard?.data?.MasherFeeds.map((item, index) => {
                        return (

                          <div key={`Work-${index}`} className='w-full md:w-[calc(50%-16px)] flex flex-col md:flex-row md:gap-8 min-w-[312px] min-h-[268px] max-w-1/2 bg-[#FED53F] relative text-white px-8 py-6 rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]'>
                            {
                              item.imageLink ?
                                <img src={item.imageLink} className='w-full md:w-[211px] border-black border-2' />
                                :
                                <img src={masherData?.data?.masherProfile?.userId?.profilePic} className='w-full md:w-[211px] border-black border-2' />

                            }
                            <div className='flex flex-col gap-2 mt-4'>
                              <p className='font-[Smooch] text-4xl'>{item.heading || ''}</p>
                              <div dangerouslySetInnerHTML={{ __html: item.message || '' }} />
                              {
                                item.cta &&
                                <div className='w-full flex justify-end'>
                                  <button className='bg-[#00A46F] px-4 py-2 text-white'>{item.cta}</button>
                                </div>
                              }
                            </div>

                          </div>
                        );
                      })}
                     
                    </div>
                  </div>
               } */}
                </div>
              </header>       
            </>
          )}
           <ProfilePreview masherID={params?.masherId}  />
          <UpdateWorkModal details={updateDetails} isOpen={isUpdateOpen} setIsOpen={setIsUpdateOpen} masherId={params?.masherId} fetchOtherMasherProfile={fetchOtherMasherProfile} />
          <AddWorkModal loader={loader_1} isOpen={isAddOpen} setIsOpen={setIsAddOpen} masherId={params?.masherId} fetchOtherMasherProfile={fetchOtherMasherProfile} formik={formik_1} />
          <WorkModal isOpen={isWorkOpen} setIsOpen={setIsWorkOpen} setDetails={setWorkDetails} details={workDetails.details} index={workDetails.index} items={masherData?.data?.masherProfile?.Mywork} />
          <DeletePopup details={DeleteDetails} fetchOtherMasherProfile={fetchOtherMasherProfile} masherId={params?.masherId} />
          {/* Main */}
        </div>
      </div>
    </AdminLayout>
    ):
    (
      <div className="custom-height w-screen flex flex-col justify-center items-center gap-2">
         <div>
         <PersonCircle size={90}/>
         </div>
          <div>
          <h1>Private Profile</h1>
          </div>
        
     </div>
    )
   }
   </>
  );
}