import React from 'react';
import { Loader } from '../../components/loader/loader';
import { assignBucketUrl } from '../../utils/helperFunctions';
import ProfilePicDemo from '../../Assets/img/covers/profilePicDemo.png';


const AllProjects = ({ allBriefs, handleSortingChange }) => {
 
  const getStatus = (el) => {
    if (Boolean(el?.isArchived)) {
      return <span className={'badge rounded-pill bg-soft-dark text-dark'}>
                Archived
      </span>;
    } else if (Boolean(el?.isProject)) {
      return <span className={'badge rounded-pill bg-soft-warning text-warning'}>
                Converted
      </span>;
    } else if (Boolean(el?.isActive)) {
      return <span className={'badge rounded-pill bg-soft-success text-success'}>
                Live
      </span>;
    } else {
      return <span className={'badge rounded-pill bg-soft-success text-success'}>
                InActive
      </span>;
    }

  };

  return (
    <>
      {/* All Global Projects from here........  */}
            
      <div className="card">
        <div className="vstack gap-4">
          <div className="card rounded-4" style={{ maxHeight: 600, minHeight: 600 }}>
            <div className="table-responsive">
              <table className="table table-hover table-nowrap">
                <thead className='text_gray_light'>
                  <tr>

                    <th scope='col' data-sort='tables-name' style={{ cursor: 'pointer' }} onClick={() => handleSortingChange('brandName')}>
                                            BRAND
                    </th>
                    <th scope='col' style={{ cursor: 'pointer' }} onClick={() => handleSortingChange('industryName')} data-sort='tables-name'>INDUSTRY</th>
                    <th scope='col' style={{ cursor: 'pointer' }} onClick={() => handleSortingChange('regionName')} data-sort='tables-name'>REGION</th>
                    <th scope='col' style={{ cursor: 'pointer' }} onClick={() => handleSortingChange('categoryName')} data-sort='tables-name'>BRIEF CATEGORY</th>
                    <th scope='col' style={{ cursor: 'pointer' }} onClick={() => handleSortingChange('status')} data-sort='tables-name'>STATUS</th>
                  </tr>
                </thead>
                {allBriefs?.loading ? ('') : (
                  <tbody>
                    {
                      Boolean(allBriefs?.data?.briefs?.length) ? allBriefs?.data.briefs?.map((el, i) => {
                                            
                        return (
                          <React.Fragment key={`all-p-${i}`}>
                            <tr>
                              <td>
                                <img
                                  alt="..."
                                  src={assignBucketUrl(el?.clientId?.logo)}
                                  className="avatar avatar-sm rounded-circle me-2"
                                  onError={event => {
                                    event.target.src = ProfilePicDemo;
                                    event.onerror = null;
                                  }}
                                />
                                <a>
                                  {el?.clientId?.brandName}
                                </a>
                                                               

                              </td>
                              <td>{el?.industry?.name}</td>
                              <td>
                                {el?.region?.name}
                              </td>
                              <td>
                                {el?.category?.name}
                              </td>
                              <td>
                                {getStatus(el)}
                              </td>
                            </tr>
                          </React.Fragment>);
                      }) : ('')
                    }
                  </tbody>)}
              </table>
              {allBriefs?.loading ? <div className="text-center mt-20 mb-10"><Loader /></div> : ''}
              {!Boolean(allBriefs?.data?.briefs?.length) ? (<><div className='text-center mt-10'>No project found</div></>) : ('')}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllProjects;