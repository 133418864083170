import React from 'react';
import CloseIcon from '../../Assets/img/covers/black-close.png';

export default function SorryPopup() {
  return (
    <>
      {/* Modal */}
      <div
        className="modal"
        id="modalExport"
        tabIndex={-1}
        aria-labelledby="modalExport"
        aria-hidden="false" 
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content shadow-3">
            <div className="modal-body">
              <div className="close-div text-end"><button
                type="button"
                className=""
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <img src={CloseIcon}/>
              </button>
              </div>
              {/* Text */}
              <div className="d-flex align-items-center mb-5">
                <div className="form-group">
                  <h1 className="ls-tight font-bolder mt-6 text-center mb-10">
                    Sorry
                  </h1>
                  <p className="text-center mb-4">
                    You skipped this brief so you can't able to see this
                  </p>
                  <p className="text-center">
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit, sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercLorem ipsum dolor sit amet, consectetur
                    adipiscing elit, s
                  </p>
                </div>
              </div>
              {/* Form group */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
