import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import AppContext from '../../../appContext';
import { signInOtpApi } from '../../../apis';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../../utils/baseurl';
// import { SignInWithGoogle } from '../../../utils/firebase'
import ImgTwo from '../../../Assets/img/social/google.png';
import ImgOne from '../../../Assets/img/social/linkdin.png';
import Cover from '../../../Assets/img/covers/loginBack.jpg';
import LogoImage from '../../../Assets/img/logos/mash_logo.png';
import { logoutOnJwtExpire } from '../../../utils/helperFunctions';
import { InputErrorMessage } from '../../../components/errorMessages';
import { EditProfileLoader } from '../../../components/loader/loader';
import { SignInValidation } from '../../../components/validation/Validation';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
  gtmId: 'GTM-KTNLX4FF'
};
TagManager.initialize(tagManagerArgs);
ReactGA.initialize('G-VL3PFPXMSE');
export default function Index() {

  const appContext = useContext(AppContext);
  const { setIsLoggedIn } = appContext;

  const [userEmail, setUserEmail] = useState('');
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      keepLoggedIn: false,
    },
    validationSchema: SignInValidation,
    onSubmit: async (values) => {
      const { email } = values;
      try {
        setLoader(true);
        const res = await signInOtpApi({ email });
        if (res.status === 200) {
          // formik.resetForm()
          setUserEmail(formik.values.email);
          setLoader(false);
          navigate('/otp-sign-in', { state: { email: formik.values.email, keepLoggedIn: formik.values.keepLoggedIn } });

        }
      } catch (error) {
        setLoader(false);
        toast.error(error.response.data.message, { id: '001' });
        if (error?.response?.data?.status === 401) {
          logoutOnJwtExpire();
          navigate('/sign-in');
        }
      }
    }
  });

  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };

  ////remove google and linkdln signup for temporary(11 july 2023) as per client request
  // const googleLogin = async () => {

  //   const token = await SignInWithGoogle();
  //   if (token?.token?.flag == "login") {
  //     setIsLoggedIn(true)
  //     navigate("/")
  //   } else {
  //     navigate("/create-profile")
  //   }

  // }

  return (
    <>
      <div className="min-h-screen bg-surface-secondary flex flex-col justify-center items-center sign-in-up !h-screen w-full bg-[url('../public/login.jpg')] bg-cover">
        <div className='col-lg-3 p-6 overflow-y-auto bg-white sm:h-fit flex flex-col justify-center md:justify-start rounded-xl'>
          <img src={LogoImage} width="150" />
          <div className='mt-5'>
            <h1 className='ls-tight font-bolder display-5 text-dark mb-5'>Welcome Back </h1>
            <p className="text-dark h4">We couldn't be happier to have you. </p>
            <div className="col-lg-12 px-0 mt-5">
              <form onSubmit={formik.handleSubmit} className="sign-in-up">
                <h2 className='font-bolder h-4 text-dark mb-8'>Login</h2>
                <div className="mb-3">
                  <label className="form-label" htmlFor="email">
                      Email address
                  </label>
                  <input
                    type="text"
                    name="email"
                    className={Boolean(formik.touched.email) && Boolean(formik.errors.email) ? 'form-control border-danger transparent-input-border' : 'form-control transparent-input-border'}
                    id="email"
                    placeholder="Your email address"
                    value={formik.values.email}
                    onChange={handleChange}
                  />
                </div>
                <InputErrorMessage error={formik.touched.email && formik.errors.email} />
                <div className="mb-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="keepLoggedIn"
                      onChange={e => formik.setFieldValue('keepLoggedIn', e.target.checked ? true : false)}
                      value={formik.values.keepLoggedIn}
                      id="check_example"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="check_example"
                    >
                        Keep me logged in
                    </label>
                  </div>
                </div>
                <div>
                  <button className="btn btn_primary_black w-full" type="submit">
                    {!loader ? ('Send Verification Code') : (<div className="text-xs" ><EditProfileLoader /></div>)}
                  </button>
                </div>
              </form>
              {/* <div className="py-3 text-center">
                  <span className="text-xs text-uppercase font-semibold">
                    or
                  </span>
                </div> 
                 <div className="hstack gap-4 justify-content-center">
                  <a href={API_BASE_URL + "/api/v1/common/linkedin/"} className="btn btn-sm btn-square btn-neutral">
                    <span className="icon icon-sm">
                      <img alt="..." src={ImgOne} width="20" />
                    </span>
                  </a>
                  <button className="btn btn-sm btn-square btn-neutral" onClick={googleLogin} >
                    <span className="icon icon-sm">
                      <img alt="..." src={ImgTwo} width="20" />
                    </span>

                  </button>
                </div> */}
              <div className="text-center text-lg-start d-flex justify-content-between mt-5">
                <div className="mx-0 text-center text-lg-start">
                  <a href="https://thisismash.com/contact-us/" className="text-dark text-sm font-semibold si-hover-2" target="_blank">Not a Masher? Get in touch to find out more.</a>
                </div>
                {/* <a className="text-dark text-sm font-semibold si-hover-2" href="" target="_blank">Not a Masher? Get in touch to find out more.</a> */}
                {/* <div className="mx-1 mt-1 text-center text-lg-end" style={{ lineHeight: 0.6 }}><small>Don't have an account? </small>
                    <Link className="text-dark text-sm font-semibold si-hover-2" to="/sign-up">Sign up</Link>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
