import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../appContext';
import { preventBack } from '../../utils/helperFunctions';

export default function Index() {

  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { logout, fetchMasherProfile } = appContext;

  const handleRedirect = () => {
    navigate('/');
  };

  // const tokenClear = () => {
  //   window.localStorage.clear();
  // }

  useEffect(() => {
    preventBack();
    const timerId = setTimeout(async () => {
      await fetchMasherProfile();
      // navigate("/")
    }, 10000);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <>
      <div className="thankyou_wrapper pt-5">
        <div className="container">
          <div className="row justify-content-center align-content-center">
            <div className="col-xl-6 mt-20">
              <div className="card shadow-3">
                <div className="card-body bg_yellow_color">
                  <div className="d-flex align-items-center justify-content-center mb-5">
                    <div className="px-5">
                      <div className="my-5">
                        {' '}
                        <span className="display-5">📨</span>
                        <h1 className="fs-1 ls-tight font-bolder my-4">Account Setup: Complete!</h1>
                        <p>You've unlocked the door to a world of awesomeness. </p>
                        <br />
                        <p>There's great things ahead, and we can't wait to be a part of it with you.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="position-lg-absolute bottom-0 end-0 my-8 mx-12 text-center text-lg-end">
                  <a className="text-warning text-sm font-semibold" href="/" onClick={() => { handleRedirect(); }} >
                    <a className="si-hover-2">Finish</a>
                  </a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}