import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import AdminLayout from '../../components/admin-layout';
import teamImg from '../../Assets/img/people/img-3.jpg';
import ProfilePicDemo from '../../Assets/img/covers/profilePicDemo.png';
import UserImage from '../../Assets/img/icons/users.png';
import ChatSection from '../Chat/ChatSection';
import { getBriefDetailsById, getGlobalBriefDetailsById } from '../../apis';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { API_BASE_URL } from '../../utils/baseurl';
import { Loader } from '../../components/loader/loader';
import { assignBucketUrl, logoutOnJwtExpire } from '../../utils/helperFunctions';


export default function GlobalBrieffView() {

  const [briefData, setBriefData] = useState({ data: null, loading: false });

  const navigate = useNavigate();
  const location = useLocation();
  const masherData = location.state?.masherData;

  const params = useParams();

  const briefDetails = async () => {
    setBriefData({ loading: true });
    try {
      const res = await getGlobalBriefDetailsById(params.briefId);
      if (res.status === 200) {
        setBriefData({ data: res?.data?.brief, loading: false });
      }
    } catch (error) {
      setBriefData({ loading: false });
      toast.error(error.response.data.message, { id: '001' });
      if (error?.response?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };

  useEffect(() => {
    briefDetails();
  }, []);
 
  const globalBriefData = briefData?.data;

  return (
    <AdminLayout>
      <div className="">
        <div className="d-xl-flex">
          {briefData?.loading ? (<div className="text-center w-full mt-10"><Loader /></div>) : (<div className="min-w-0 flex-xl-fill">
            <header>
              <div className="container-fluid">
                <div className="pt-6">

                  <div className="row align-items-center">
                    <div className="col-sm-8 col">
                      <div className="d-flex align-items-center mb-3">
                        <div>
                          <img alt="..."
                            src={assignBucketUrl(globalBriefData?.logo)}
                            className='avatar avatar-sm rounded-circle me-2 briefIndi'
                            style={{ border: '1px solid rgba(0,0,0,0.5)' }}
                            onError={event => {
                              event.target.src = ProfilePicDemo;
                              event.onerror = null;
                            }} />
                        </div>
                        <div className="ps-2">
                          {globalBriefData?.clientName}
                        </div>
                      </div>
                      <h1 className="h2 ls-tight">{globalBriefData?.briefName}</h1>
                    </div>
                  </div>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0 mt-4">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                                                View
                      </li>
                    </ol>
                  </nav>

                  {/* <ul className="nav nav-tabs overflow-x border-0">
                    <li className="nav-item">
                      <a href="#" className="nav-link active">
                        View all
                      </a>
                    </li>
                  </ul> */}

                </div>
              </div>
            </header>
            <main className="py-6">
              <div className="container-fluid view-detail">
                <div className="vstack gap-6">
                  <div className="d-none mb-2">
                    <h4 className="mb-3">Tags</h4>

                    <div className="d-flex gap-2 flex-wrap">
                      <a
                        href="#"
                        className="bg-white bg-opacity-20 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs"
                      >
                                                Media
                      </a>
                      <a
                        href="#"
                        className="bg-white bg-opacity-20 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs"
                      >
                                                MNC
                      </a>
                      <a
                        href="#"
                        className="bg-white bg-opacity-20 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs"
                      >
                                                Brand
                      </a>
                      <a
                        href="#"
                        className="bg-white bg-opacity-20 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs"
                      >
                                                Region
                      </a>
                      {/* <a
                        href="#"
                        className="bg-white bg-opacity-20 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs"
                      >
                        Multiplanetary
                      </a> */}
                    </div>
                  </div>
                  <div className="row mb-2 d-none">
                    <div className="col-sm-6 col-lg-4">
                      <div className="card shadow-none">
                        <div className="card-body">
                          <div className="">
                            <h4 className="mb-3">Budget</h4>

                            <p className="text-sm">
                                                            $$$$$$$$$$
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-2 shadow-none">
                    <div className="card-body">
                      <div>
                        <h4 className="mb-3">In Brief</h4>
                        <p className="text-sm">
                          {globalBriefData?.inBrief || 'N/A'}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-2 shadow-none">
                    <div className="card-body">
                      <h4 className="mb-2">Deliverable Details</h4>
                      {
                        briefData ? (globalBriefData?.deliverableDetails?.map((el, i) => {
                          return (
                            <React.Fragment key={`del-d-${i}`}>
                              <div className="row">
                                <div className="col-md-6 mb-5">
                                  {/* <label className="h4">Deliverable Details</label> */}
                                  {/* <input type="text" className="form-control" placeholder="Lorem Ipsum" /> */}
                                  <p className="mb-0 fs-13">{'Name : ' + el?.name || 'N/A'}</p>
                                </div>
                                <div className="col-md-6 mb-5">
                                  <div className="cal-div">
                                    <div className="col-md-6">
                                      <div>
                                        <label className="h4">Start date</label>
                                        <p className="mb-0 fs-13">{el?.startDate}</p>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div>
                                        <label className="h4">Due date</label>
                                        <p className="mb-0 fs-13">{el?.dueDate}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-5">
                                  <div className="col-md-6 pr-15">
                                    {/* <input
                                type="text"
                                className="form-control"
                                placeholder="Lead By (MASHer Role)"
                              /> */}
                                    <p className="mb-0 fs-13">{'Delivery Format : ' + el?.delieveryFormat || 'N/A'}</p>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-5">
                                  <div className="col-md-6 pr-15">
                                    {/* <input
                                type="text"
                                className="form-control"
                                placeholder="Lead By (MASHer Role)"
                              /> */}
                                    <p className="mb-0 fs-13">{'Lead Masher : ' + (el?.masherRole ? (Array.isArray(el.masherRole) ? (el.masherRole[0].label):(el.masherRole.label)):('N/A') )}</p>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-5">
                                  {/* <textarea
                              className="form-control"
                              placeholder="Description"
                            ></textarea> */}
                                  <p className="mb-0 fs-13">{'Description : ' + el?.description || 'N/A'}</p>
                                </div>
                              </div>
                            </React.Fragment>);
                        })
                        ) : 'N/A'
                      }
                    </div>
                  </div>

                  <div className="card mb-2 shadow-none">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12 mb-5">
                          <label className="h4">Background</label>
                          <p className="mb-0 fs-13">{globalBriefData?.background || 'N/A'}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-2 shadow-none">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12 mb-5">
                          <label className="h4">Project Ambition</label>
                          <p className="mb-0 fs-13">{globalBriefData?.projectAmbition || 'N/A'}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-2 shadow-none">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12 mb-5">
                          <label className="h4">Brand</label>
                          <p className="mb-0 fs-13">{globalBriefData?.brand || 'N/A'}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-2 shadow-none">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12 mb-5">
                          <label className="h4">Audience</label>
                          <p className="mb-0 fs-13">{globalBriefData?.audience || 'N/A'}</p>
                        </div>
                        <div className="col-md-12 mb-5">
                          <label className="h4">KPIs</label>
                          <p className="mb-0 fs-13">{globalBriefData?.kpis || 'N/A'}</p>
                        </div>
                        <div className="col-md-12 mb-5 create_brief_links">
                          <label className="h4">
                                                        Client Website / Social / Press Links
                          </label>
                          {/* <input
                            type="text"
                            className="form-control "
                            placeholder=""
                          />
                          <button className="btn btn-xs btn_primary_black btn-width create_brief_add_btn">Add More</button> */}
                          {
                            globalBriefData?.websiteLink?.map((el, i) => {
                              return (
                                <React.Fragment key={`web-l-${i}`}>
                                  <p>{el?.name}{' : '}<a href={`${el?.link}`} target="_blank">{el?.link || 'N/A'}</a></p>
                                </React.Fragment>
                              );
                            })
                          }
                        </div>
                        <div className="col-md-12 mb-5">
                          <label className="h4">Attachment</label>
                          {/* <input
                            type="file"
                            className="form-control"
                            placeholder="Upload"
                          /> */}
                          <br />
                          <a className="mb-0 fs-13" href={`${API_BASE_URL}/${globalBriefData?.attachement}`} target="_blank">{globalBriefData?.attachement || 'N/A'}</a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card d-none">
                    <div className="card-body">
                      <h4 className="mb-3">About Brief</h4>
                      <p className="text-sm">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                                sed do eiusmod tempor incididunt ut labore et dolore
                                                magna aliqua. Ut enim ad minim veniam, quis nostrud
                                                exercitation ullamco laboris nisi ut aliquip ex ea
                                                commodo consequat. Duis dolore eu fugiat nulla pariatur.
                      </p>
                    </div>
                  </div>
                  <div className="card d-none">
                    <div className="card-body">
                      <h4 className="mb-3">Project Ambition</h4>
                      <p className="text-sm">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                                sed do eiusmod tempor incididunt ut labore et dolore
                                                magna aliqua. Ut enim ad minim veniam, quis nostrud
                                                exercitation ullamco laboris nisi ut aliquip ex ea
                                                commodo consequat. Duis dolore eu fugiat nulla pariatur.
                      </p>
                    </div>
                  </div>
                  <div className="card shadow-none d-none">
                    <div className="card-body">
                      <h4 className="mb-3">Team</h4>
                      <div className="row g-6 mt-n3">
                        <div className="col-xxl-3 col-md-4 col-sm-6">
                          <div className="position-relative d-flex align-items-center">
                            <div className="me-4">
                              <div className="avatar rounded-circle border-2 border-dashed">
                                <i className="bi bi-plus-lg" />
                              </div>
                            </div>
                            <div className="flex-fill">
                              <a
                                href="#"
                                className="d-block h6 text-muted text-primary-hover font-semibold stretched-link"
                              >
                                                                Add member
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-3 col-md-4 col-sm-6">
                          <div className="d-flex align-items-center">
                            <div className="me-3">
                              <div className="avatar rounded-circle">
                                <img alt="..." src={teamImg} />
                              </div>
                            </div>
                            <div className="flex-fill text-limit">
                              <a href="#" className="d-block h6 font-semibold">
                                                                Sarah
                              </a>

                              <span className="d-block text-xs text-muted">
                                                                Author
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-3 col-md-4 col-sm-6">
                          <div className="d-flex align-items-center">
                            <div className="me-3">
                              <div className="avatar rounded-circle">
                                <img alt="..." src={teamImg} />
                              </div>
                            </div>
                            <div className="flex-fill text-limit">
                              <a href="#" className="d-block h6 font-semibold">
                                                                Darlene Robe
                              </a>

                              <span className="d-block text-xs text-muted">
                                                                UI/UX Designer
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-3 col-md-4 col-sm-6">
                          <div className="d-flex align-items-center">
                            <div className="me-3">
                              <div className="avatar rounded-circle">
                                <img alt="..." src={teamImg} />
                              </div>
                            </div>
                            <div className="flex-fill text-limit">
                              <a href="#" className="d-block h6 font-semibold">
                                                                Theresa Webb
                              </a>

                              <span className="d-block text-xs text-muted">
                                                                Web Developer
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-3 col-md-4 col-sm-6">
                          <div className="d-flex align-items-center">
                            <div className="me-3">
                              <div className="avatar rounded-circle">
                                <img alt="..." src={teamImg} />
                              </div>
                            </div>
                            <div className="flex-fill text-limit">
                              <a href="#" className="d-block h6 font-semibold">
                                                                Kristin Watson
                              </a>

                              <span className="d-block text-xs text-muted">
                                                                Web Developer
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-3 col-md-4 col-sm-6">
                          <div className="d-flex align-items-center">
                            <div className="me-3">
                              <div className="avatar rounded-circle">
                                <img alt="..." src={teamImg} />
                              </div>
                            </div>
                            <div className="flex-fill text-limit">
                              <a href="#" className="d-block h6 font-semibold">
                                                                Cody Fisher
                              </a>

                              <span className="d-block text-xs text-muted">
                                                                Web Developer
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-3 col-md-4 col-sm-6">
                          <div className="d-flex align-items-center">
                            <div className="me-3">
                              <div className="avatar rounded-circle">
                                <img alt="..." src={teamImg} />
                              </div>
                            </div>
                            <div className="flex-fill text-limit">
                              <a href="#" className="d-block h6 font-semibold">
                                                                Robert Fox
                              </a>

                              <span className="d-block text-xs text-muted">
                                                                Web Developer
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-3 col-md-4 col-sm-6">
                          <div className="d-flex align-items-center">
                            <div className="me-3">
                              <div className="avatar rounded-circle">
                                <img alt="..." src={teamImg} />
                              </div>
                            </div>
                            <div className="flex-fill text-limit">
                              <a href="#" className="d-block h6 font-semibold">
                                                                Darlene Robertson
                              </a>

                              <span className="d-block text-xs text-muted">
                                                                Web Developer
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card shadow-none">
                    <div className="card-body">
                      <h4 className="mb-4">Files</h4>
                      <div className="row g-3">
                        <div className="col-xxl-4 col-lg-6">
                          <div className="position-relative border border-dashed rounded">
                            <div className="p-3 d-flex align-items-center">
                              <div className="me-4">
                                <div className="icon icon-shape text-xl bg-black text-white">
                                  <i className="bi bi-card-image" />
                                </div>
                              </div>
                              <div className="flex-fill">
                                <a
                                  href="#"
                                  className="d-block h6 text-sm font-semibold mb-1"
                                >
                                                                    Images
                                </a>
                                <span className="d-block text-xs">
                                                                    2560 files
                                </span>
                              </div>
                              <div className="ms-4 pe-2 text-end">
                                <div className="dropdown">
                                  <a
                                    className="text-muted"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="bi bi-three-dots-vertical" />
                                  </a>
                                  <div className="dropdown-menu">
                                    <a href="#!" className="dropdown-item">
                                                                            Action
                                    </a>
                                    <a href="#!" className="dropdown-item">
                                                                            Another action
                                    </a>
                                    <a href="#!" className="dropdown-item">
                                                                            Something else here
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-4 col-lg-6">
                          <div className="position-relative border border-dashed rounded">
                            <div className="p-3 d-flex align-items-center">
                              <div className="me-4">
                                <div className="icon icon-shape text-xl bg-black text-white">
                                  <i className="bi bi-file-earmark-font" />
                                </div>
                              </div>
                              <div className="flex-fill">
                                <a
                                  href="#"
                                  className="d-block h6 text-sm font-semibold mb-1"
                                >
                                                                    Documents
                                </a>
                                <span className="d-block text-xs">
                                                                    9800 files
                                </span>
                              </div>
                              <div className="ms-4 pe-2 text-end">
                                <div className="dropdown">
                                  <a
                                    className="text-muted"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="bi bi-three-dots-vertical" />
                                  </a>
                                  <div className="dropdown-menu">
                                    <a href="#!" className="dropdown-item">
                                                                            Action
                                    </a>
                                    <a href="#!" className="dropdown-item">
                                                                            Another action
                                    </a>
                                    <a href="#!" className="dropdown-item">
                                                                            Something else here
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-4 col-lg-6">
                          <div className="position-relative border border-dashed rounded">
                            <div className="p-3 d-flex align-items-center">
                              <div className="me-4">
                                <div className="icon icon-shape text-xl bg-black text-white">
                                  <i className="bi bi-file-earmark-excel" />
                                </div>
                              </div>
                              <div className="flex-fill">
                                <a
                                  href="#"
                                  className="d-block h6 text-sm font-semibold mb-1"
                                >
                                                                    Sheets
                                </a>
                                <span className="d-block text-xs">
                                                                    120 files
                                </span>
                              </div>
                              <div className="ms-4 pe-2 text-end">
                                <div className="dropdown">
                                  <a
                                    className="text-muted"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="bi bi-three-dots-vertical" />
                                  </a>
                                  <div className="dropdown-menu">
                                    <a href="#!" className="dropdown-item">
                                                                            Action
                                    </a>
                                    <a href="#!" className="dropdown-item">
                                                                            Another action
                                    </a>
                                    <a href="#!" className="dropdown-item">
                                                                            Something else here
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-4 col-lg-6">
                          <div className="position-relative border border-dashed rounded">
                            <div className="p-3 d-flex align-items-center">
                              <div className="me-4">
                                <div className="icon icon-shape text-xl bg-black text-white">
                                  <i className="bi bi-camera-video" />
                                </div>
                              </div>
                              <div className="flex-fill">
                                <a
                                  href="#"
                                  className="d-block h6 text-sm font-semibold mb-1"
                                >
                                                                    Videos
                                </a>
                                <span className="d-block text-xs">
                                                                    400 files
                                </span>
                              </div>
                              <div className="ms-4 pe-2 text-end">
                                <div className="dropdown">
                                  <a
                                    className="text-muted"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="bi bi-three-dots-vertical" />
                                  </a>
                                  <div className="dropdown-menu">
                                    <a href="#!" className="dropdown-item">
                                                                            Action
                                    </a>
                                    <a href="#!" className="dropdown-item">
                                                                            Another action
                                    </a>
                                    <a href="#!" className="dropdown-item">
                                                                            Something else here
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-4 col-lg-6">
                          <div className="position-relative border border-dashed rounded">
                            <div className="p-3 d-flex align-items-center">
                              <div className="me-4">
                                <div className="icon icon-shape text-xl bg-black text-white">
                                  <i className="bi bi-file-arrow-down" />
                                </div>
                              </div>
                              <div className="flex-fill">
                                <a
                                  href="#"
                                  className="d-block h6 text-sm font-semibold mb-1"
                                >
                                                                    Downloads
                                </a>
                                <span className="d-block text-xs">
                                                                    400 files
                                </span>
                              </div>
                              <div className="ms-4 pe-2 text-end">
                                <div className="dropdown">
                                  <a
                                    className="text-muted"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="bi bi-three-dots-vertical" />
                                  </a>
                                  <div className="dropdown-menu">
                                    <a href="#!" className="dropdown-item">
                                                                            Action
                                    </a>
                                    <a href="#!" className="dropdown-item">
                                                                            Another action
                                    </a>
                                    <a href="#!" className="dropdown-item">
                                                                            Something else here
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>)}
          <div className="flex-xl-none w-xl-96 border-start-xl bg-surface-primary h-xl-calc position-sticky top-xl-18">
            <aside className="p-4 h-full overflow-y-xl-auto">
              <div className="vstack gap-6">
                <div className="brief_chat_wrapper">

                  <ChatSection />

                  <div className="row d-none">
                    <div className="col-12">
                      <div>
                        <div
                          style={{
                            height: 400,
                            border: '1px solid rgb(206, 204, 204)',
                            flexDirection: 'column'
                          }}
                          className="d-flex 
                          "
                        >
                          <header className="d-flex  bg-primary p-3">
                            <a href="">
                              <img
                                src={UserImage}
                                alt=""
                                className="user_image"
                              />
                            </a>
                            <div className="ps-3">
                              <small
                                className="d-block text-xs text-secondary
                                        "
                              >
                                                                Admin
                              </small>
                              <h4 className="text-white ">Script Writer</h4>
                            </div>
                          </header>
                          <div className="d-flex mt-auto">
                            <input
                              type="text"
                              className="form-control rounded-0 text-sm overflow-auto"
                              placeholder="In this section, one to two sentences describe the brief. "
                              aria-label="First name"
                            />
                            <button
                              type="button"
                              className="btn btn btn-primary text-center px-5 ms-2 rounded-0"
                            >
                                                            Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-none">
                  <h4 className="text-muted font-semibold mb-3">In Brief</h4>

                  <p className="text-sm mb-5">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua.
                  </p>
                </div>
                <div className="d-none">
                  {/* Title */}
                  <h6 className="text-muted font-semibold mb-5">
                                        Deliverables details
                  </h6>
                  {/* List group */}
                  <div className="list-group list-group-flush list-group-borderless">
                    <div className="list-group-item px-2 py-0">
                      <div className="border-start">
                        <div className="d-flex ms-n2 pb-6">
                          <div className="flex-none me-3">
                            <div className="icon icon-shape text-sm w-4 h-4 bg-success rounded-circle" />
                          </div>
                          <div>
                            <a
                              href="#!"
                              className="h6 font-semibold lh-none text-heading text-primary-hover d-inline-block mb-1"
                            >
                                                            Sarah
                            </a>
                            <div>
                              <small className="text-xs text-muted">Name</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-group-item px-2 py-0">
                      <div className="border-start">
                        <div className="d-flex ms-n2 pb-6">
                          <div className="flex-none me-3">
                            <div className="icon icon-shape text-sm w-4 h-4 bg-danger rounded-circle" />
                          </div>
                          <div>
                            <a
                              href="#!"
                              className="h6 font-semibold lh-none text-heading text-primary-hover d-inline-block mb-1"
                            >
                                                            LEAD MASHER
                            </a>
                            <div>
                              <small className="text-xs text-muted">05</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-group-item px-2 py-0">
                      <div className="border-start">
                        <div className="d-flex ms-n2 pb-6">
                          <div className="flex-none me-3">
                            <div className="icon icon-shape text-sm w-4 h-4 bg-success rounded-circle" />
                          </div>
                          <div>
                            <a
                              href="#!"
                              className="h6 font-semibold lh-none text-heading text-primary-hover d-inline-block mb-1"
                            >
                                                            EXPECTED START DATE
                            </a>
                            <div>
                              <small className="text-xs text-muted">
                                                                03-06-2022
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-group-item px-2 py-0">
                      <div className="border-start">
                        <div className="ms-n2 pb-6 pad-left-2">
                          <div className="mb-4">
                            <h4 className="mb-3">DISCRIPTION</h4>
                            <p className="text-sm">
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit, sed do eiusmod tempor enim ad minim veniam,
                                                            quis nostrud exercitation
                            </p>
                          </div>
                          <div className="mb-4">
                            <h4 className="mb-3">DETAILED TIMELINE</h4>
                            <p className="text-sm">
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit, sed do eiusmod tempor enim ad minim veniam,
                                                            quis nostrud exercitation
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}
