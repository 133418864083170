import React, { useContext, useEffect, useCallback } from 'react';
import { useState } from 'react';
const QuillDeltaToHtmlConverter =
	require('quill-delta-to-html').QuillDeltaToHtmlConverter;
import moment from 'moment';
import { Dialog } from '@headlessui/react';
import toast from 'react-hot-toast';
import useCollapse from 'react-collapsed';
import ChatSection from '../Chat/ChatSection';
import AdminLayout from '../../components/admin-layout';
import { assignBucketUrl } from '../../utils/helperFunctions';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ProfilePicDemo from '../../Assets/img/covers/profilePicDemo.png';
import AppContext from '../../appContext';
import Avatar from '../../components/Avatar/Avatar';
import MasherChat from '../../components/MasherChatBriefs';
import {
  getBriefDetailsByIdV2,
  getMahserProfileApi,
  DeclineBriefNew,
  fileUploadApiBrief,
  createBriefAttachApi,
} from '../../apis';
import Emoji from '../../components/emojis/Emojis';
import ApplyToBriefModal from '../Mashers/ApplyToBriefModal';
import '../briefs.css';
import { EditProfileLoader } from '../../components/loader/loader';
import ChatModal from './ChatModal';
import DeclineModal from './DeclineModal';
import { useFormik } from 'formik';
import { useDropzone } from 'react-dropzone';
import {
  FileUploadComponent,
  FilePreviewComponent,
} from '../../components/loader/loader';

export default function BriefView() {
  const [BriefDetails, setBriefDetails] = useState({
    data: null,
    loading: false,
  });
  const [masherObj, setMasherObj] = useState('');
  const [chatOpen, setChatOpen] = useState(false);
  const [declineOpen, setDeclineOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState('');

  const [applyToBriefModalOpen, setApplyToBriefModalOpen] = useState(false);
  const [declineModalOpen, setDeclineModalOpen] = useState(false);

  const appContext = useContext(AppContext);
  const { state } = appContext;
  const { loggedInUser } = state;

  const invitationId = BriefDetails?.data?._id;
  console.log(BriefDetails)
  const params = useParams();
  const navigate = useNavigate();
  const briefDetails = async () => {
    setBriefDetails({ loading: true });

    try {
      const res = await getBriefDetailsByIdV2(params.briefId);
      if (res.status === 200) {
        setBriefDetails({ data: res?.data?.brief, loading: false });
      }
    } catch (error) {
      console.log('error', error);
      // setBriefData({ loading: false });
      // toast.error(error.response.data.message, { id: '001' });
    }
  };

  const currentUserApi = async () => {
    try {
      const res = await getMahserProfileApi();
      setCurrentUser(res?.data);
    } catch (e) {
      console.log('currentUserApi', e);
    }
  };

  useEffect(() => {
    briefDetails();
    currentUserApi();
  }, []);
  const formik = useFormik({
    initialValues: {
      attachments: '',
    },
  });

  useEffect(() => {
    //alert(JSON.stringify(BriefDetails?.data));
    formik.setValues({
      attachments: BriefDetails?.data?.attachments || null,
    });
  }, [BriefDetails]);
  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      // Process each accepted file
      acceptedFiles.forEach((file, index) => {
        fileupload(file, index, acceptedFiles.length);
      });
    }
  }, []);

  const [uploadPercentage, setUploadPercentage] = useState([]);
  const [filename, setfileName] = useState([]);
  const [filesize, setfilesize] = useState([]);
  const [fileiserror, setfileiserror] = useState([]);
  const [isfile, setisfile] = useState(false);
  function formatFileSize(sizeInBytes) {
    const sizeInKB = sizeInBytes / 1024;
    const sizeInMB = sizeInKB / 1024;

    if (sizeInMB >= 1) {
      return `${sizeInMB.toFixed(2)} MB`;
    } else if (sizeInKB >= 1) {
      return `${sizeInKB.toFixed(2)} KB`;
    } else {
      return `${sizeInBytes} bytes`;
    }
  }
  const onUploadProgress = (progressEvent, file, index) => {
    setUploadPercentage((prevUploadPercentage) => {
      const updatedProgress = [...prevUploadPercentage];
      updatedProgress[index] = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      return updatedProgress;
    });

    setfileName((prevFileName) => {
      const updatedFileName = [...prevFileName];
      updatedFileName[index] = file.name;
      return updatedFileName;
    });

    setfilesize((prevFileSize) => {
      const updatedFileSize = [...prevFileSize];
      updatedFileSize[index] = formatFileSize(file.size);
      return updatedFileSize;
    });
  };

  const fileupload = async (file, index, totalFiles) => {
    try {
      setisfile(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('briefId', params.briefId);

      // Make the file upload request
      const uploadResponse = await fileUploadApiBrief(
        formData,
        (progressEvent) => onUploadProgress(progressEvent, file, index)
      );

      if (uploadResponse.status === 200 || uploadResponse.status === 201) {
        // If the file upload was successful, update the filename and filesize
        setfileName((prevFileName) => {
          const updatedFileName = [...prevFileName];
          updatedFileName[index] = file.name;
          return updatedFileName;
        });

        setfilesize((prevFileSize) => {
          const updatedFileSize = [...prevFileSize];
          updatedFileSize[index] = formatFileSize(file.size);
          return updatedFileSize;
        });

        // Create a brief attachment
        const createBriefAttachResponse = await createBriefAttachApi({
          briefId: params.briefId || '',
          fileName: file.name,
          filePath: uploadResponse.data.filePath || '',
          fileSize: formatFileSize(file.size),
        });

        if (
          createBriefAttachResponse.status === 200 ||
					createBriefAttachResponse.status === 201
        ) {
          setfileiserror((prevFileName) => {
            const updatedstatus = [...prevFileName];
            updatedstatus[index] = false;
            return updatedstatus;
          });
          // alert(JSON.stringify(createBriefAttachResponse?.data?.Attachments?.[0]?.attachments,null,2))
          formik.setValues({
            attachments:
							createBriefAttachResponse?.data?.Attachments?.[0]?.attachments,
          });
        } else {
          setfileiserror((prevFileName) => {
            const updatedstatus = [...prevFileName];
            updatedstatus[index] = true;
            return updatedstatus;
          });
        }
      } else {
        // Handle error in file upload
        setfileiserror((prevFileName) => {
          const updatedstatus = [...prevFileName];
          updatedstatus[index] = true;
          return updatedstatus;
        });
      }
    } catch (error) {
      // Handle any other errors
      console.error('An error occurred:', error);
      setfileiserror((prevFileName) => {
        const updatedstatus = [...prevFileName];
        updatedstatus[index] = true;
        return updatedstatus;
      });
    } finally {
      // This block will always be executed
      if (index === totalFiles - 1) {
        setisfile(false);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });
  // Function to calculate the difference in days between two dates
  const calculateDaysDifference = (date1, date2) => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    return diffDays;
  };

  // Function to get the expiration message and return the message along with reordered date and time
  const Datetimeconverter = (timestamp, propName = 'Message') => {
    if (!timestamp || isNaN(timestamp.getTime())) {
      return { [propName]: null, time: '' };
    }

    const today = new Date();
    const daysDifference = calculateDaysDifference(today, timestamp);

    let message, reorderedDate;

    if (daysDifference === 0) {
      message = 'Today';
    } else if (daysDifference === 1) {
      message = 'Tomorrow';
    } else {
      const day = timestamp.getDate();
      const month = timestamp.toLocaleString('default', { month: 'long' });
      const year = timestamp.getFullYear();
      reorderedDate = `${day} ${month} ${year}`;
      message = reorderedDate;
    }

    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    const time = timestamp.toLocaleTimeString(undefined, timeOptions);

    return { [propName]: message, reorderedDate, time };
  };
  const briefExpectedStartDate = new Date(
    BriefDetails?.data?.briefExpectedStartDate
  );
  const { briefExpectedStartDate_m } = Datetimeconverter(
    briefExpectedStartDate,
    'briefExpectedStartDate_m'
  );

  function getFlagEmoji(countryCode) {
    if (countryCode) {
      const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map((char) => 127397 + char.charCodeAt());
      return String.fromCodePoint(...codePoints);
    }
    return '';
  }
  function getInitials(fullName) {
    // Use a regular expression to match emojis
    const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;

    // Remove emojis from the full name
    const nameWithoutEmojis = fullName.replace(emojiRegex, '');

    // Split the modified name
    const names = nameWithoutEmojis.split(' ');

    if (names.length === 1) {
      // Only first name
      return names[0].slice(0, 2).toUpperCase();
    } else {
      // First name and last name
      const firstNameInitial = names[0].charAt(0).toUpperCase();
      const lastNameInitial = names[names.length - 1].charAt(0).toUpperCase();
      return `${firstNameInitial}${lastNameInitial}`;
    }
  }

  const owner_name =
		(BriefDetails?.data?.owner?.[0]?.firstName || '-') +
		' ' +
		BriefDetails?.data?.owner?.[0]?.lastName;
  const ownername_alt =
		(BriefDetails?.data?.owner?.[0]?.firstName || '-') +
		'-' +
		BriefDetails?.data?.owner?.[0]?.lastName;
  const getApply = () => {
    const deliverables = BriefDetails?.data?.deliverables;

    if (!deliverables) {
      // Handle the case where deliverables is undefined or null
      return false;
    }

    // const filteredDeliverables = deliverables.reduce(
    //   (collected, deliverable) => {
    //     if (
    //       deliverable &&
		// 			deliverable?.role &&
		// 			deliverable?.role.some(
		// 			  (currentRole) =>
		// 			    currentRole.Masher &&
		// 					currentRole.Masher.find(
		// 					  (m) =>
		// 					    m.masherId === currentUser?.masherProfile?._id &&
		// 							m.status === 'pending'
		// 					)
		// 			)
    //     ) {
    //       collected.push(deliverable);
    //     }
    //     return collected;
    //   },
    //   []
    // );
    const filteredDeliverables = deliverables.reduce((collected, deliverable) => {
      if (
        deliverable &&
        Array.isArray(deliverable.role) && // Check if role is an array
        deliverable.role.length > 0 && // Check if role array is not empty
        deliverable.role.some((currentRole) =>
          currentRole.Masher &&
          currentRole.Masher.find(
            (m) =>
              m.masherId === currentUser?.masherProfile?._id &&
              m.status === "pending"
          )
        )
      ) {
        collected.push(deliverable);
      }
      return collected;
    }, []);
    
    const isFilteredDeliverablesNotEmpty = filteredDeliverables.length > 0;
    return isFilteredDeliverablesNotEmpty;
  };
  const [loader, setLoader] = useState(false);
  const decline = async (reason) => {
    const deliverables = BriefDetails?.data?.deliverables;

    if (!deliverables) {
      // Handle the case where deliverables is undefined or null
      return false;
    }

    // const filteredDeliverables = deliverables.reduce(
    //   (collected, deliverable) => {
    //     if (
    //       deliverable &&
    // 			deliverable.role &&
    // 			deliverable.role.some(
    // 			  (currentRole) =>
    // 			    currentRole.Masher &&
    // 					currentRole.Masher.find(
    // 					  (m) =>
    // 					    m.masherId === currentUser?.masherProfile?._id &&
    // 							m.status === 'pending'
    // 					)
    // 			)
    //     ) {
    //       collected.push(deliverable);
    //     }
    //     return collected;
    //   },
    //   []
    // );
    const pendingMashers = [];

    deliverables.forEach((deliverable) => {
      deliverable.role?.forEach((role) => {
        role.Masher?.forEach((masher) => {
          if (masher.status === 'pending' && masher.masherId === currentUser?.masherProfile?._id) {
            pendingMashers.push({
              deliverableId: deliverable.deliverableId,
              roleId: role.roleId,
              masherId: masher.masherId,
            });
          }
        });
      });
    });
    const isFilteredDeliverablesNotEmpty = pendingMashers.length > 0;
    if (isFilteredDeliverablesNotEmpty) {
      setLoader(true);
      const requests = pendingMashers.map(async (pendingMasher) => {
        const { deliverableId, roleId } = pendingMasher;
        await processDeliverableAndRole(deliverableId, roleId, reason);
      });
  
      try {
      // Wait for all requests to complete
        await Promise.all(requests);
  
        // All requests completed successfully, navigate
        navigate('/');
      } catch (error) {
        toast.error(error.response?.data?.message || 'An error occurred', { id: '001' });
      } finally {
        setLoader(false);
      }
    }
  };

  // Custom function to process deliverableId and roleId
  const processDeliverableAndRole = async (deliverableId, roleId, reason) => {
    const deliverable_id = deliverableId;
    const briefId = params.briefId;
    const masherId = currentUser?.masherProfile?._id;
    try {
      const res = await DeclineBriefNew({
        briefId: briefId || '',
        masherId: masherId || '',
        roleId: roleId || '',
        deliverableId: deliverable_id || '',
        masherNotes: reason || ''
      });

      if (res.status === 200 || 201) {
        // toast.success(res.data.message, { id: 'success' });
      }
    } catch (error) {
      setLoader(false);
      toast.error(error.response?.data?.message || 'An error occurred', {
        id: '001',
      });
      return;
    }
  };

  const quoteData = BriefDetails?.data?.deliverables
    .reduce((collected, deliverable) => {
      const hasPendingMasher = deliverable?.role?.some((currentRole) =>
        currentRole.Masher?.some(
          (m) =>
            m.masherId === currentUser.masherProfile?._id &&
						m.status === 'pending'
        )
      );

      if (hasPendingMasher) {
        collected.push(deliverable);
      }
      return collected;
    }, [])
    .flatMap((deliverable, i) =>
      deliverable?.role?.filter((role) =>
          role.Masher?.some(
            (m) =>
              m.masherId === currentUser.masherProfile?._id &&
							m.status === 'pending'
          )
        )
        .map((role, j) => ({
          deliverableId: deliverable.deliverableId,
          roleId: role.roleId,
          value: '',
          masherId: currentUser.masherProfile?._id,
        }))
    );

  // Now `quoteData` holds the desired data structure as an array of objects

  return (
    <AdminLayout>
      <div className="w-full h-fit flex flex-wrap px-5 md:px-16 py-8 bg-white gap-4 justify-start md:justify-between">
        <div className="w-full md:w-fit text-[#101828] text-4xl font-bold flex items-center gap-4">
				  {BriefDetails?.data?.name}
        </div>
        <div className="flex gap-2 justify-between md:justify-start w-full md:w-fit md:flex-row-reverse">
          <div className="flex gap-2">
            {getApply() && (
              <button
                style={primaryButtonStyle}
                onClick={() => {
                  setApplyToBriefModalOpen(true);
                }}
              >
								Apply
              </button>
            )}

            <div className="flex md:hidden">
              <button
                onClick={() => setChatOpen(true)}
                style={unfilledButtonStyle}
                className="border border-[#D0D5DD] flex"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5 9.58336C17.5029 10.6832 17.2459 11.7683 16.75 12.75C16.162 13.9265 15.2581 14.916 14.1395 15.6078C13.021 16.2995 11.7319 16.6662 10.4167 16.6667C9.31678 16.6696 8.23176 16.4126 7.25 15.9167L2.5 17.5L4.08333 12.75C3.58744 11.7683 3.33047 10.6832 3.33333 9.58336C3.33384 8.26815 3.70051 6.97907 4.39227 5.86048C5.08402 4.7419 6.07355 3.838 7.25 3.25002C8.23176 2.75413 9.31678 2.49716 10.4167 2.50002H10.8333C12.5703 2.59585 14.2109 3.32899 15.4409 4.55907C16.671 5.78915 17.4042 7.42973 17.5 9.16669V9.58336Z"
                    stroke="#344054"
                    stroke-width="1.67"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>{' '}
								Chat
              </button>
            </div>
          </div>

          {getApply() && (
            <button
              style={unfilledButtonStyle}
              className="text-[#00A46F]"
              onClick={() => setDeclineOpen(true)}
            >
              {!loader ? (
                'Decline'
              ) : (
                <div className="text-xs">
                  <EditProfileLoader />
                </div>
              )}
            </button>
          )}
        </div>
        <ApplyToBriefModal
          quoteData={quoteData || []}
          brief={BriefDetails}
          currency={BriefDetails?.data?.companyCurrency || ''}
          masher={currentUser}
          isOpen={applyToBriefModalOpen}
          setIsOpen={setApplyToBriefModalOpen}
        />
      </div>
      <div className="w-full flex flex-col px-4 md:px-16 py-8 sm:max-w-[calc(100%-450px)]">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2">
            <div>
              <p className="text-[#101828] font-bold text-lg">Company</p>
              <p className="text-[#1D2939] mt-2">
                {BriefDetails?.data?.companyName || '-'}
              </p>
            </div>
            <div className="w-full flex justify-start flex-wrap mt-8">
              <div className="w-full md:w-1/2">
                <p className="text-[#101828] font-bold text-lg">Country</p>
                <div className="flex gap-2 mt-2">
                  {BriefDetails?.data?.companyCountry?.[0]?.countryName ? (
                    <>
                      {/* <img src='' className="w-5 h-5 rounded-full" /> */}
                      {BriefDetails?.data?.companyCountry?.[0] && (
                        <>
                          <Emoji
                            className="brief-flag"
                            codePoint={getFlagEmoji(
                              BriefDetails?.data?.companyCountry?.[0]
                                ?.countryISO || '-'
                            )}
                          />
                          <p className="text-[#1D2939]">
                            {BriefDetails?.data?.companyCountry?.[0]
                              ?.countryName || '-'}
                          </p>
                        </>
                      )}
                    </>
                  ) : (
                    <p className="text-[#1D2939]">-</p>
                  )}
                </div>
              </div>
              <div className="w-full md:w-1/2 mt-8 md:mt-0">
                <p className="text-[#101828] font-bold text-lg">Currency</p>
                <div className="flex gap-2 mt-2">
                  {/* <img src='' className="w-5 h-5 rounded-full" /> */}
                  {BriefDetails?.data?.companyCurrency ? (
                    <>
                      {/* <img src='' className="w-5 h-5 rounded-full" /> */}
                      <p className="text-[#1D2939]">
                        {BriefDetails?.data?.companyCurrency || '-'}
                      </p>
                    </>
                  ) : (
                    <p className="text-[#1D2939]">-</p>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full md:hidden block mt-8">
              <p className="text-[#101828] font-bold text-lg">
								Expected Start Date
              </p>
              <div className="flex gap-2 mt-2 items-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.6667 2.66675H3.33333C2.59695 2.66675 2 3.2637 2 4.00008V13.3334C2 14.0698 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0698 14 13.3334V4.00008C14 3.2637 13.403 2.66675 12.6667 2.66675Z"
                    stroke="black"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.6666 1.33325V3.99992"
                    stroke="black"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.33337 1.33325V3.99992"
                    stroke="black"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2 6.66675H14"
                    stroke="black"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p className="text-[#1D2939]">{briefExpectedStartDate_m}</p>
              </div>
            </div>
            <div className="w-full mt-8">
              <p className="text-[#101828] font-bold text-lg">Industry</p>
              <div className="flex justify-start flex-wrap gap-4">
                {BriefDetails?.data?.industry && (
                  <div className="flex justify-start flex-wrap gap-4">
                    {BriefDetails?.data?.industry.length > 0 ? (
                      BriefDetails?.data?.industry.map((item, index) => (
                        <>
                          {item.industryName && (
                            <div
                              key={index}
                              className="w-fit text-[#EEE9E0] bg-[#344054] rounded-2xl flex items-center justify-center px-3 py-1"
                            >
                              {item.industryName}
                            </div>
                          )}
                        </>
                      ))
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <div className="w-full mt-8 md:mt-0">
              <p className="text-[#101828] font-bold text-lg">Brief Owner</p>
              <div className="flex gap-2 mt-2 items-center">
                {/* {
                  BriefDetails?.data?.companyId?.logo ? (
                    <img src={BriefDetails?.data?.companyId?.logo} className="w-10 h-10 rounded-full items-cen" />
                  ) :
                    (
                      <div className="w-10 h-10 rounded-full items-cen brief-avatar">{getInitials(owner_name || '')}</div>
                    )
                } */}
                <div className="w-10 h-10 rounded-full items-cen brief-avatar">
                  {getInitials(owner_name || '')}
                </div>
                <p className="text-[#1D2939]">{owner_name || '-'}</p>
              </div>
            </div>
            <div className="w-full hidden md:block mt-3">
              <p className="text-[#101828] font-bold text-lg">
								Expected Start Date
              </p>
              <div className="flex gap-2 mt-2 items-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.6667 2.66675H3.33333C2.59695 2.66675 2 3.2637 2 4.00008V13.3334C2 14.0698 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0698 14 13.3334V4.00008C14 3.2637 13.403 2.66675 12.6667 2.66675Z"
                    stroke="black"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.6666 1.33325V3.99992"
                    stroke="black"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.33337 1.33325V3.99992"
                    stroke="black"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2 6.66675H14"
                    stroke="black"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p className="text-[#1D2939]">{briefExpectedStartDate_m}</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="font-bold text-2xl text-[#101828] mt-12">
						How did this brief come about?
          </p>
          <p className="text-[#101828] mt-2">
            {BriefDetails?.data?.details?.howDidThisBriefComeAbout ? (
              <div
                className="converted-html-field"
                dangerouslySetInnerHTML={{
                  __html: new QuillDeltaToHtmlConverter(
                    JSON.parse(
                      BriefDetails?.data?.details?.howDidThisBriefComeAbout
                    ),
                    {}
                  ).convert(),
                }}
              ></div>
            ) : (
              'NA'
            )}
          </p>
          <p className="font-bold text-2xl text-[#101828] mt-8">
						What's the brief? Briefly.
          </p>
          <p className="text-[#101828] mt-2">
            {BriefDetails?.data?.details?.whatsTheBrief ? (
              <div
                className="converted-html-field"
                dangerouslySetInnerHTML={{
                  __html: new QuillDeltaToHtmlConverter(
                    JSON.parse(BriefDetails?.data?.details?.whatsTheBrief),
                    {}
                  ).convert(),
                }}
              ></div>
            ) : (
              'NA'
            )}
          </p>
          <p className="font-bold text-2xl text-[#101828] mt-8">
						What challenges do we need to overcome?
          </p>
          <p className="text-[#101828] mt-2">
            {BriefDetails?.data?.details?.whatChallengesDoWeNeedToOvercome ? (
              <div
                className="converted-html-field"
                dangerouslySetInnerHTML={{
                  __html: new QuillDeltaToHtmlConverter(
                    JSON.parse(
                      BriefDetails?.data?.details
                        ?.whatChallengesDoWeNeedToOvercome
                    ),
                    {}
                  ).convert(),
                }}
              ></div>
            ) : (
              'NA'
            )}
          </p>
          <p className="font-bold text-2xl text-[#101828] mt-8">
						What are we trying to achieve?
          </p>
          <p className="text-[#101828] mt-2">
            {BriefDetails?.data?.details?.whatAreWeTryingToAchieve ? (
              <div
                className="converted-html-field"
                dangerouslySetInnerHTML={{
                  __html: new QuillDeltaToHtmlConverter(
                    JSON.parse(
                      BriefDetails?.data?.details?.whatAreWeTryingToAchieve
                    ),
                    {}
                  ).convert(),
                }}
              ></div>
            ) : (
              'NA'
            )}
          </p>
          <p className="font-bold text-2xl text-[#101828] mt-8">
						Who are we trying to reach?
          </p>
          <div className="brief-tags">
            <div className="brief-tag-content">
              <h4>Subculture:</h4>
              <div className="brief-tag-list">
                {BriefDetails?.data?.subculture?.map((culture, index) => {
                  return (
                    <div className="brief-badge" key={index}>
                      <p style={{ height: '20px' }}>
                        {culture?.subcultureName}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="brief-tag-content">
              <h4>Audience Country:</h4>
              <div className="brief-tag-list">
                {BriefDetails?.data?.audienceCountry?.map((country, index) => {
                  return (
                    <div className="brief-badge" key={index}>
                      <p style={{ height: '20px' }}>{country?.countryName}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <p className="text-[#101828] mt-2">
            {BriefDetails?.data?.details?.whoAreWeTryingToReach ? (
              <div
                className="converted-html-field"
                dangerouslySetInnerHTML={{
                  __html: new QuillDeltaToHtmlConverter(
                    JSON.parse(
                      BriefDetails?.data?.details?.whoAreWeTryingToReach
                    ),
                    {}
                  ).convert(),
                }}
              ></div>
            ) : (
              'NA'
            )}
          </p>
          {/* <div className="flex flex-wrap gap-2 mt-4">
            <p className="font-bold text-xl text-[#101828] ">Tag Content</p>
            <div className="w-fit text-[#EEE9E0] bg-[#344054] rounded-2xl flex items-center justify-center px-3 py-1">
              Tag Two
            </div>
            <div className="w-fit text-[#EEE9E0] bg-[#344054] rounded-2xl flex items-center justify-center px-3 py-1">
              Tag Three
            </div>
          </div> */}
          <p className="font-bold text-2xl text-[#101828] mt-12">
						What’s the special sauce of this brand or product?
          </p>
          <p className="text-[#101828] mt-2">
            {BriefDetails?.data?.details
              ?.whatsTheSpecialSauceOfThisBrandOrProduct ? (
                <div
                  className="converted-html-field"
                  dangerouslySetInnerHTML={{
                    __html: new QuillDeltaToHtmlConverter(
                      JSON.parse(
                        BriefDetails?.data?.details
                          ?.whatsTheSpecialSauceOfThisBrandOrProduct
                      ),
                      {}
                    ).convert(),
                  }}
                ></div>
              ) : (
                'NA'
              )}
          </p>
          <p className="text-[28px] font-bold text-black mt-12">Deliverables</p>
          {BriefDetails?.data?.deliverables?.length > 0 ? (
            BriefDetails?.data?.deliverables.map((deliverable, index) => (
              <div key={index}>
                <p className="font-bold text-2xl text-[#101828] mt-8">
                  {deliverable?.deliverableName}
                </p>
                <p className="font-semibold text-sm text-[#101828] mt-2">
									Role
                  {deliverable?.role?.map((role) => role.roleName).length > 1 &&
										's'}
									: {deliverable?.role?.map((role) => role.roleName).join(',')}
                </p>
                {/* {deliverable?.deliverableDescription && (
                  <ul className="text-[#101828] mt-2 list-inside">
                    <li>
                      {deliverable.deliverableDescription ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: new QuillDeltaToHtmlConverter(
                              JSON.parse(deliverable.deliverableDescription),
                              {}
                            ).convert(),
                          }}
                        ></div>
                      ) : (
                        'NA'
                      )}
                    </li>
                  </ul>
                )} */}
                {deliverable?.deliverableDescription && (
                  <ul className="text-[#101828] mt-2 list-inside">
                    <li>
                      {deliverable.deliverableDescription.trim() ? (
                        <>
                          {(() => {
                            try {
                              const trimmedDescription = deliverable.deliverableDescription.trim();
                              const parsedDescription = JSON.parse(trimmedDescription);
                              return (
                                <div className="converted-html-field"
                                  dangerouslySetInnerHTML={{
                                    __html: new QuillDeltaToHtmlConverter(parsedDescription, {}).convert(),
                                  }}
                                ></div>
                              );
                            } catch (error) {
                              console.error('Error parsing JSON:', error);
                              return '';
                            }
                          })()}
                        </>
                      ) : (
                        'NA'
                      )}
                    </li>
                  </ul>
                )}

              </div>
            ))
          ) : (
            <p>NA</p>
          )}

          <p className="text-[28px] font-bold text-black mt-12">
						Supporting Documents
          </p>
          {/* <div style={{width: '97%'}}>
             
             {!isfile && (
               <div className='w-full bg-white rounded-xl flex justify-center items-center h-96' {...getRootProps()}>
                 <input {...getInputProps()} />
                 {isDragActive ? (
                   <div className='flex flex-col items-center text-center'>
                     <div className='flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]'><i className="bi bi-cloud-arrow-up text-2xl text-[#475467]" /></div>
                     <p className='font-semibold text-[#00A46F] mt-6'>Drop here</p>
                   </div>
                 ) : (
                   <div className='flex flex-col items-center text-center'>
                     <div className='flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]'><i className="bi bi-cloud-arrow-up text-2xl text-[#475467]" /></div>
                     <p className='font-semibold text-[#00A46F] mt-6'>Click to upload <span className='text-[#667085] font-normal hidden md:block'>order drag and drop</span></p>
                   </div>
                 )}
               </div>
             )}
             {(uploadPercentage?.length > 0 && isfile) && (
               <div>
                 {uploadPercentage.map((progress, index) => (
                   <div key={index}>
                     <FileUploadComponent name={filename[index]} filesize={filesize[index]} percentage={progress} isfileerror={fileiserror[index]} />
                   </div>
                 ))}
               </div>
             )}
           
         
       
       {
         formik.values.attachments && (
           <div>
             {formik.values.attachments?.length > 0 &&
               formik.values.attachments.map((attachment, index) => (
                 <div key={index} className='w-full rounded-xl'>
                   <div>
                     <FilePreviewComponent
                       name={attachment.fileName}
                       filesize={attachment.fileSize}
                       url={attachment.filePath}
                     />
                   </div>
                 </div>
               ))}
           </div>
         )
       }

      



     </div> */}
          {(BriefDetails?.data?.attachments ?? []).length === 0 ? (
            <p>NA</p>
          ) : (
            BriefDetails?.data?.attachments?.map((attachment) => (
              <div key={attachment.id} className="w-full">
                <FilePreviewComponent
                  name={attachment.fileName}
                  filesize={attachment.fileSize}
                  url={attachment.filePath}
                />
              </div>
            ))
          )}

          {/* <button className="flex w-full max-w-3xl px-4 py-4 mt-4 justify-between bg-white rounded-lg border border-[#EAECF0] hover:border-[#00A46F]">
            <div className="flex gap-2">
              <div className="bg-[#F2F4F7] border-4 border-[#F9FAFB] rounded-full flex justify-center items-center h-8 w-8">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.66675 1.33331H4.00008C3.64646 1.33331 3.30732 1.47379 3.05727 1.72384C2.80722 1.97389 2.66675 2.31302 2.66675 2.66665V13.3333C2.66675 13.6869 2.80722 14.0261 3.05727 14.2761C3.30732 14.5262 3.64646 14.6666 4.00008 14.6666H12.0001C12.3537 14.6666 12.6928 14.5262 12.9429 14.2761C13.1929 14.0261 13.3334 13.6869 13.3334 13.3333V5.99998M8.66675 1.33331L13.3334 5.99998M8.66675 1.33331V5.99998H13.3334" stroke="#475467" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <div>
                <p className="text-[#344054]">Design.pdf</p>
                <p className="w-fit">200 KB</p>
              </div>
            </div>
            <div>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.66675 6.66669L8.00008 10L11.3334 6.66669" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8 10V2" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </button>
          <button className="flex w-full max-w-3xl px-4 py-4 mt-4 justify-between bg-white rounded-lg border border-[#EAECF0] hover:border-[#00A46F]">
            <div className="flex gap-2">
              <div className="bg-[#F2F4F7] border-4 border-[#F9FAFB] rounded-full flex justify-center items-center h-8 w-8">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_2785_8690)">
                    <path d="M4.66659 1.33331V14.6666M11.3333 1.33331V14.6666M1.33325 7.99998H14.6666M1.33325 4.66665H4.66659M1.33325 11.3333H4.66659M11.3333 11.3333H14.6666M11.3333 4.66665H14.6666M2.78659 1.33331H13.2133C14.0159 1.33331 14.6666 1.98399 14.6666 2.78665V13.2133C14.6666 14.016 14.0159 14.6666 13.2133 14.6666H2.78659C1.98393 14.6666 1.33325 14.016 1.33325 13.2133V2.78665C1.33325 1.98399 1.98393 1.33331 2.78659 1.33331Z" stroke="#475467" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                  </g>
                  <defs>
                    <clipPath id="clip0_2785_8690">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div>
                <p className="text-[#344054]">TV Commercial.mp4</p>
                <p className="w-fit">16 MB</p>
              </div>
            </div>
            <div>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.66675 6.66669L8.00008 10L11.3334 6.66669" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8 10V2" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </button> */}
          {getApply() && (
            <button
              className="text-white text-xl px-4 py-4 bg-[#00A46F] mt-12"
              onClick={() => {
                setApplyToBriefModalOpen(true);
              }}
            >
							Apply
            </button>
          )}
        </div>
      </div>
      <div className='brief-chat-window rounded-md overflow-hidden'>
        {
          currentUser !== '' && loggedInUser && BriefDetails?
            <MasherChat loggeduser={loggedInUser} projectName={BriefDetails?.data?.name} mashers={[{id: BriefDetails?.data?.owner[0].userId, name: `${owner_name}`, avatar: <Avatar key={BriefDetails?.data?.owner[0].userId} name={owner_name} id={BriefDetails?.data?.owner[0].userId} borderColor='white'/>}, ...BriefDetails?.data?.deliverables?.flatMap(d => 
              d.role?.flatMap(r => 
                r.Masher?.map(m => ({ id: m.masherId, name: m.masherName.trim(), avatar: <Avatar key={m.masherId} name={`${m.firstName} ${m.lastName}`} id={m?.masherId} borderColor='white'/> }))
              )
            ).reduce((unique, masher) => {
              if (!unique.find(u => u?.id === masher?.id)) {
                unique.push(masher);
              }
              return unique;
            }, [])]} briefOwnerId={BriefDetails?.data?.owner ? BriefDetails?.data?.owner[0].userId : undefined} /> :
            <></>
        }
      </div>
      <ChatModal loggeduser={loggedInUser} briefowner={ownername_alt} isOpen={chatOpen} onClose={() => setChatOpen(false)} />
      <DeclineModal
        isOpen={declineOpen}
        onDecline={() => setDeclineOpen(false)}
        onAccept={decline}
      />
    </AdminLayout>
  );
}

const pageHeaderStyle = {
  width: '100%',
  backgroundColor: '#fff',
  height: '130px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '0 64px',
  alignItems: 'center',
};

const pageHeaderTextStyle = {
  color: '#101828',
  fontFamily: 'Bricolage Grotesque',
  fontSize: '36px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '110%',
};

const pageHeaderRightStyle = {
  fontFamily: '\'Bricolage Grotesque\', sans-serif',
  fontWeight: '700',
  display: 'inline-flex',
};

const pageHeaderLeftStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

const primaryButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  height: '48px',
  padding: '10px 18px',
  fontWeight: 600,
  backgroundColor: '#00A46F',
  color: 'white',
};

const unfilledButtonStyle = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  height: '48px',
  padding: '10px 18px',
  fontWeight: 600,
  backgroundColor: 'transparent',
  color: '#00A46F !important',
};
