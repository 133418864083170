import React from 'react';
import { useState } from 'react';
import Select from 'react-select';
import toast from 'react-hot-toast';
import Flatpickr from 'react-flatpickr';
import { Link, useNavigate } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import { API_BASE_URL } from '../../../utils/baseurl';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import LogoImage from '../../../Assets/img/logos/mash_logo.png';
import { createProfileApi, updateUserApi } from '../../../apis';
import { logoutOnJwtExpire } from '../../../utils/helperFunctions';
import { EditProfileLoader } from '../../../components/loader/loader';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';

const CreateProfilePreview = ({ formik1, skillD, industriesD, languagesD, countryCodeD, isVisibletoOthersD, timezoneD, setStep, formik2 }) => {

  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  //final submit function...
  const profileSubmit = async () => {
    const { firstName, lastName, language, location, timezone, phoneNo, countryCode, cv, cvLink, otherLinks, skills, introVideo, portfolioVideo, birthDate, instagram, linkedin, facebook, tiktok, twitter, websiteUrls, industryType, clientsWorkedFor, isVisibletoOthers } = formik1.values;
    const { experience, workedForYourself, minDayRate, currency, isTermsAccepted } = formik2.values;

    try {
      setLoader(true);
      const res1 = await createProfileApi({ language, location, timezone, phoneNo, cv, countryCode: countryCode?.value, cvLink, otherLinks, skills, introVideo, portfolioVideo, birthDate, workDetails: { experience, workedForYourself, minDayRate, currency }, instagram, linkedin, facebook, twitter, tiktok, websiteUrls, industryType, clientsWorkedFor, isVisibletoOthers: isVisibletoOthers?.value });
      const res2 = await updateUserApi({ firstName, lastName, isTermsAccepted });
      if ((res1.status === 200 || 201) && (res2.status === 200 || 201)) {
        toast.success(res1.data.message, { id: 'succcess' });
        navigate('/thank-you');
      }
    }
    catch (error) {
      setLoader(false);
      toast.error(error.response.data.message, { id: 'failed' });
      if (error?.res1?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };

  const handleEditClick = () => {
    formik2.setFieldValue('isTermsAccepted', false);
  };

  return (
    <>
      <div className="min-h-screen bg-surface-secondary d-flex flex-column justify-content-center">
        <div className="row mx-0">
          <div className="col-lg-3 p-xl-10 position-fixed start-0 top-0 h-screen overflow-y-hidden bg_yellow_color d-none d-lg-flex flex-column">
            <img src={LogoImage} width="150" />
            <div className='mt-10'>
              <h1 className='ls-tight font-bolder display-5 text-dark mb-5'>Our office is only 148 billion square metres</h1>
              <p className="text-dark h4 mb-2">A place for you to connect, collaborate and grow in our community. </p>
              <small className="font-semibold" style={{ 'color': 'rgb(95,95,95)' }}>Already signed up? </small>
              <Link to="/sign-in" className="text-sm font-semibold si-hover-2" onClick={logoutOnJwtExpire}>
                <p
                  className=""
                >
                  Login here.
                </p>
              </Link>
              <div className="position-lg-absolute bottom-0 end-0 my-8 mx-12 text-center text-lg-end">
              </div>
            </div>
          </div>
          <div className="col-12 col-md-9 col-lg-9 offset-lg-3 border-left-lg min-h-screen d-flex flex-column position-relative">
            <div className="py-lg-16 px-lg-20">
              <div className="d-flex mb-10 align-items-center justify-content-between">
                <div className="heading-new">
                  <h1 className="h4 ls-tight">Profile Preview</h1>
                </div>
                <div className="col-auto">
                  <div className="hstack gap-2 justify-content-end z-index-2">
                    <a href="#modalExport" data-bs-toggle="modal">
                      <button
                        type="button"
                        className="btn btn-sm btn-neutral border-base d-md-block"
                        onClick={() => { setStep(1); handleEditClick(); }}
                      >
                        <span>Edit</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-8">
                  <label className="form-label">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstName"
                    value={formik1.values.firstName}
                    disabled={true}
                    placeholder="First Name"
                  />
                </div>
                <div className="col-md-6 mb-8">
                  <label className="form-label">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lastName"
                    value={formik1.values.lastName}
                    disabled={true}
                    placeholder="Last Name"
                  />
                </div>
                <div className="col-md-12 mb-8">
                  <label className="form-label">Top Skills</label>
                  <CreatableSelect
                    isMulti
                    className="form-control custom-disabled-style p-0"
                    name="skills"
                    isDisabled={true}
                    placeHolder="Your top skills"
                    value={skillD}
                  />
                </div>
                <div className="col-md-6 mb-8">
                  <label className="form-label">Industry</label>
                  <CreatableSelect
                    isMulti
                    className="form-control custom-disabled-style p-0"
                    name="industryType"
                    isDisabled={true}
                    placeholder="Industry"
                    value={industriesD}
                  />
                </div>
                <div className="col-md-6 mb-8">
                  <label className="form-label">Clients worked for</label>

                  <CreatableSelect
                    isMulti
                    className="form-control custom-disabled-style p-0"
                    name="clientsWorkedFor"
                    isDisabled={true}
                    placeHolder="Clients Worked For"
                    value={formik1.values.clientsWorkedFor?.length > 0
                      ? formik1.values.clientsWorkedFor?.map((el) => {
                        return el;
                      })
                      : ['']}
                  />
                </div>
                <div className="col-md-6 mb-8">
                  <label className="form-label">Language</label>
                  <CreatableSelect
                    isMulti
                    className="form-control custom-disabled-style p-0"
                    name="language"
                    isDisabled={true}
                    // options={languagesOptions}
                    placeHolder="Languages you know"
                    value={languagesD}
                  />
                </div>
                <div className="col-md-6 mb-8">
                  <label className="form-label">Full Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="location"
                    value={formik1?.values?.location}
                    disabled={true}
                    placeholder="Full Address"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Time Zone</label>
                  <Select
                    className="form-control p-0 custom-disabled-style"
                    name="timezone"
                    placeHolder="Your timezone"
                    value={timezoneD}
                    isDisabled={true}
                  />
                </div>
                <div className="col-md-6 mb-5">
                  <label className="form-label">Birthday</label>
                  <Flatpickr
                    className='form-control'
                    options={{ dateFormat: 'j M' }}
                    disabled={true}
                    name="birthDate"
                    value={formik1.values.birthDate}
                  />
                </div>
                <div className="col-md-6 mb-8">
                  <label className="form-label">Phone No.</label>
                  <div className="input-group position-relative">
                    <PhoneInput
                      disabled={true}
                      placeholder="Enter phone number"
                      defaultCountry={countryCodeD?.iso}
                      international
                      value={formik1?.values?.phoneNo}
                      rules={{ required: true }}
                    />

                  </div>
                </div>
                <div className='col-md-12 mb-6'>
                  <label className="form-label">Do you want to be seen by other mashers?</label>
                  <div className="position-relative">
                    <CreatableSelect
                      name="isVisibletoOthers"
                      className="dg custom-disabled-style"
                      isDisabled={true}
                      value={isVisibletoOthersD}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <h4 className="mb-2">Your Portfolio</h4>
                  <div className="row">

                    <div className="col-md-12 mb-3">
                      <label className="form-label">Website URL</label>
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                          <Tooltip {...props}>
                            <strong>{'This will show on your public profile'}</strong>
                          </Tooltip>)}
                        placement="top">
                        <i className="bi bi-info-circle ms-1" />
                      </OverlayTrigger>
                      {formik1.values.websiteUrls?.map((el, i, initArr) => {
                        return (
                          <div key={i}>
                            <div className='row' style={{ marginBottom: '20px' }}>
                              <div className='col-12 '>
                                <input
                                  type='text'
                                  className={' form-control'}
                                  placeholder="Website URL"
                                  name={`websiteUrls.${[i]}.url`}
                                  value={formik1.values.websiteUrls?.[i]?.url}
                                  disabled={true}

                                />
                              </div>

                            </div>




                          </div>
                        );
                      })}

                    </div>

                    <div className="col-md-12 mb-3">
                      <label className="form-label">Uploaded CV<span className="ms-2 text-sm">{'(File types- .pdf, .doc or .docx)'}</span></label>
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                          <Tooltip {...props}>
                            <strong>{'This will show on your public profile'}</strong>
                          </Tooltip>)}
                        placement="top">
                        <i className="bi bi-info-circle ms-1" />
                      </OverlayTrigger>
                      <div className="">
                        {
                          formik1.values.cv ? (
                            <a className='blackOnHover' href={`${formik1?.values?.cv}`} target='_blank'>
                              <div
                                style={{
                                  width: '40px',
                                  height: '40px',
                                  border: '1px solid rgb(25, 19, 19, 0.25)',
                                  borderRadius: '50%',
                                  boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                                  marginRight: '10px',
                                }}
                                className='icon icon-shape text-xl bg-black text-white'>
                                <i className='bi bi-file-earmark-fill'></i>
                              </div>
                              <span className='si-hover-2'>Click to view</span>
                            </a>
                          ) : (
                            <>
                              <div
                                style={{
                                  width: '40px',
                                  height: '40px',
                                  border: '1px solid rgb(25, 19, 19, 0.25)',
                                  borderRadius: '50%',
                                  boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                                  marginRight: '10px',
                                }}
                                className='icon icon-shape text-xl bg-black text-white'>
                                <i className='bi bi-file-earmark-fill'></i>
                              </div>
                              <span className='text-muted'>No CV found</span>
                            </>
                          )
                        }
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label className="form-label">Uploaded Video<span className="ms-2 text-sm">{'(File types- .mp4, .3gp)'}</span></label>
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                          <Tooltip {...props}>
                            <strong>{'This will show on your public profile'}</strong>
                          </Tooltip>)}
                        placement="top">
                        <i className="bi bi-info-circle ms-1" />
                      </OverlayTrigger>
                      <div className="">
                        {
                          formik1.values.portfolioVideo ? (
                            <a className='blackOnHover' href={`${formik1?.values?.portfolioVideo}`} target='_blank'>
                              <div
                                style={{
                                  width: '40px',
                                  height: '40px',
                                  border: '1px solid rgb(25, 19, 19, 0.25)',
                                  borderRadius: '50%',
                                  boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                                  marginRight: '10px',
                                }}
                                className='icon icon-shape text-xl bg-black text-white'>
                                <i className='bi-camera-video-fill'></i>
                              </div>
                              <span className='si-hover-2'>Click to view</span>
                            </a>
                          ) : (
                            <>
                              <div
                                style={{
                                  width: '40px',
                                  height: '40px',
                                  border: '1px solid rgb(25, 19, 19, 0.25)',
                                  borderRadius: '50%',
                                  boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                                  marginRight: '10px',
                                }}
                                className='icon icon-shape text-xl bg-black text-white'>
                                <i className='bi bi-file-earmark-play-fill'></i>
                              </div>
                              <span className='text-muted'>No intro video found</span>
                            </>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <h4 className="mb-2 mt-2">Social Links
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        This will show on your public profile
                      </Tooltip>)}
                    placement="top">
                    <i className="bi bi-info-circle ms-2" />
                  </OverlayTrigger>
                </h4>
                <div className="col-md-6 mb-5">
                  <label className="form-label">Linked In</label>
                  <input
                    type="text"
                    className={Boolean(formik1.touched?.linkedin && formik1.errors?.linkedin) ? 'form-control border-danger' : 'form-control'}
                    name="linkedin"
                    value={formik1.values.linkedin}
                    placeholder="LinkedIn"
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-5">
                  <label className="form-label">Facebook</label>
                  <input
                    type="text"
                    className={Boolean(formik1.touched?.facebook && formik1.errors?.facebook) ? 'form-control border-danger' : 'form-control'}
                    name="facebook"
                    value={formik1.values.facebook}
                    placeholder="Facebook"
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-5">
                  <label className="form-label">Twitter</label>
                  <input
                    type="text"
                    className={Boolean(formik1.touched?.twitter && formik1.errors?.twitter) ? 'form-control border-danger' : 'form-control'}
                    name="twitter"
                    value={formik1.values.twitter}
                    placeholder="Twitter"
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-5">
                  <label className="form-label">Instagram</label>
                  <input
                    type="text"
                    className={Boolean(formik1.touched?.instagram && formik1.errors?.instagram) ? 'form-control border-danger' : 'form-control'}
                    name="instagram"
                    value={formik1.values.instagram}
                    placeholder="Instagram"
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-5">
                  <label className="form-label">TikTok</label>
                  <input
                    type="text"
                    className={Boolean(formik1.touched?.tiktok && formik1.errors?.tiktok) ? 'form-control border-danger' : 'form-control'}
                    name="tiktok"
                    value={formik1.values.tiktok}
                    placeholder="TikTok"
                    disabled
                  />
                </div>
                <div className="col-md-12 mb-8" >
                  <label className="form-label" htmlFor="email">
                    How Many Years Of Experience Do You Have In This Sector?

                  </label>
                  <input
                    type="text"
                    className={Boolean(formik2.touched?.experience && formik2.errors?.experience) ? 'form-control border-danger' : 'form-control'}
                    id="email"
                    name="experience"
                    value={formik2.values.experience}
                    disabled
                    placeholder="Enter your experience"
                  />
                </div>
                <div className="col-md-12 mb-8">
                  <label className="form-label" htmlFor="email">
                    How Long Have You Worked For Yourself?
                  </label>
                  <input
                    type="text"
                    className={Boolean(formik2.touched?.workedForYourself && formik2.errors?.workedForYourself) ? 'form-control border-danger' : 'form-control'}
                    id="email"
                    name="workedForYourself"
                    value={formik2.values.workedForYourself}
                    disabled
                    placeholder="How Long Have You Worked"
                  />
                </div>
                <div className="col-md-12 mb-8">
                  <label className="form-label" htmlFor="email">
                    What Is Your Minimum Day Rate ?
                  </label>
                  <input
                    type="text"
                    className={Boolean(formik2.touched?.minDayRate && formik2.errors?.minDayRate) ? 'form-control border-danger' : 'form-control'}
                    id="email"
                    name="minDayRate"
                    value={formik2.values.minDayRate}
                    disabled
                    placeholder="Enter your day $ rate"
                  />
                </div>
                <div>
                  <button type="submit" className="btn btn-sm btn_primary_black d-inline" onClick={profileSubmit}>
                    {!loader ? ('Submit') : (<div className="text-xs"><EditProfileLoader /></div>)}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateProfilePreview;