import React, { useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import useCollapse from 'react-collapsed';
import ChatSection from '../Chat/ChatSection';
import { API_BASE_URL } from '../../utils/baseurl';
import { Loader } from '../../components/loader/loader';
import AdminLayout from '../../components/admin-layout';
import { getGlobalProjectDetailsById } from '../../apis';
import ProfilePicDemo from '../../Assets/img/covers/profilePicDemo.png';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { assignBucketUrl, logoutOnJwtExpire } from '../../utils/helperFunctions';


export default function MasherProjectView() {

  const [projectData, setProjectData] = useState({ data: null, loading: false });
  const [format, setFormat] = useState({ video: 0, image: 0, doc: 0, sheet: 0 });
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const location = useLocation();

  const masherData = location.state.masherData;

  const navigate = useNavigate();

  const params = useParams();

  const fileCount = () => {
    if (!projectData) {
      setFormat({ video: 0, image: 0, doc: 0, sheet: 0 });
    } else {
      let extension = projectData?.data?.briefId?.attachement?.split('.')?.at(-1)?.toLowerCase();

      if (extension == 'jpg' || extension == 'jpeg' || extension == 'png') {
        setFormat({ image: 1 });
      } else if (extension == 'mp4' || extension == 'webm') {
        setFormat({ video: 1 });
      } else if (extension == 'doc' || extension == 'docx' || extension == 'pdf' || extension == 'ppt' || extension == 'pptx' || extension == 'txt' || extension == 'xls') {
        setFormat({ doc: 1 });
      } else if (extension == 'xls' || extension == 'xlsx') {
        setFormat({ sheet: 1 });
      }
    }
  };

  const projectDetails = async () => {
    setProjectData({ loading: true });
    try {
      const res = await getGlobalProjectDetailsById(params.briefId);
      if (res.status === 200) {
        setProjectData({ data: res?.data?.project, loading: false });
      }
    } catch (error) {
      setProjectData({ loading: false });
      toast.error(error.response.data.message, { id: '001' });
      if (error?.response?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };

  useEffect(() => {
    projectDetails();
  }, []);

  useEffect(() => {
    fileCount();
  }, [projectData?.data?.briefId]);

  const masherProjectData = projectData?.data;

  return (
    <AdminLayout>
      <div className="">
        <div className="d-xl-flex">
          {projectData?.loading ? (<div className="text-center w-full mt-10"><Loader /></div>) : (
            <div className="min-w-0 flex-xl-fill">
              <header>
                <div className="container-fluid">
                  <div className="pt-6">
                    <div className="row align-items-center">
                      <div className="col-sm-8 col">
                        <div className="d-flex align-items-center mb-3">
                          <div>
                            <img
                              alt="..."
                              src={assignBucketUrl(masherProjectData?.briefId?.logo)}
                              className='avatar avatar-sm rounded-circle me-2 briefIndi'
                              style={{ border: '1px solid rgba(0,0,0,0.5)' }}
                              onError={event => {
                                event.target.src = ProfilePicDemo;
                                event.onerror = null;
                              }} />
                          </div>
                          <div className="ps-2">
                            {masherProjectData?.briefId?.clientId?.brandName}
                          </div>
                        </div>
                        <h1 className="h2 ls-tight">{masherProjectData?.briefId?.briefName}</h1>
                      </div>
                    </div>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 mt-4">
                        <li className="breadcrumb-item text-muted">
                          <Link to="/" className="text-muted">Dashboard</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                                                    View
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </header>
              <main className="py-6">
                <div className="container-fluid view-detail">
                  <div className="vstack gap-6">
                    <div className="d-none mb-2">
                      <h4 className="mb-3">Tags</h4>

                      <div className="d-flex gap-2 flex-wrap">
                        <a
                          href="#"
                          className="bg-white bg-opacity-20 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs"
                        >
                                                    Media
                        </a>
                        <a
                          href="#"
                          className="bg-white bg-opacity-20 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs"
                        >
                                                    MNC
                        </a>
                        <a
                          href="#"
                          className="bg-white bg-opacity-20 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs"
                        >
                                                    Brand
                        </a>
                        <a
                          href="#"
                          className="bg-white bg-opacity-20 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs"
                        >
                                                    Region
                        </a>
                      </div>
                    </div>
                    <div className="row mb-2 d-none">
                      <div className="col-sm-6 col-lg-4">
                        <div className="card shadow-none">
                          <div className="card-body">
                            <div className="">
                              <h4 className="mb-3">Budget</h4>

                              <p className="text-sm">
                                                                $$$$$$$$$$
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="card mb-2 rounded-4">
                                            <div className="card-body">
                                                <div>
                                                    <h4 className="mb-3">Budget</h4>
                                                    <p className="text-sm expandToggle">
                                                        {masherProjectData?.briefId?.budget || "N/A"}
                                                    </p>
                                                </div>
                                            </div>
                                        </div> */}
                    <div className="card mb-2 rounded-4">
                      <div className="card-body">
                        <div>
                          <h4 className="mb-3">The Brief</h4>
                          <p className="text-sm expandToggle">
                            {masherProjectData?.briefId?.inBrief || 'N/A'}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='card mb-2 deliTable rounded-4'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-6 mb-5'>
                            <label className='form-label'>Deliverable Details</label>
                          </div>

                          <div className='table-responsive'>
                            <table className='table table-hover table-nowrap'>
                              <thead>
                                <tr>
                                  <th>name</th>
                                  <th>start</th>
                                  <th>due</th>
                                  <th>lead masher</th>
                                  <th>format</th>
                                  <th>description</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  masherProjectData?.briefId?.deliverableDetails.length > 0 ? masherProjectData?.briefId?.deliverableDetails?.map((el, i) => {
                                    return (
                                      <React.Fragment key={`del-d-${i}`}>
                                        <tr>
                                          <td className='text-heading font-semibold'> {el.name}</td>
                                          <td> {el.startDate}</td>
                                          <td> {el.dueDate}</td>
                                          <td>{(el?.masherRole ? (Array.isArray(el.masherRole) ? (el.masherRole[0].label):(el.masherRole.label)):('--') )}</td>

                                          <td> {el.delieveryFormat}</td>
                                          <td>
                                            {/* <div className=''>
                                            <i
                                                data-tip={el.description}
                                                data-place='right'
                                                data-background-color='black'
                                                data-border={true}
                                                data-text-color={`#e6cb47`}
                                                data-border-color={`#e6cb47`}>
                                                {el.description.length > 50 ? el.description.slice(0, 20) + " ..." : el.description}
                                            </i> */}

                                            {el.description.length > 50 ? el.description.slice(0, 20) + ' ...' : el.description}
                                            <button className='expandBtn' {...getToggleProps()}>
                                              {isExpanded ? <i className='bi bi-chevron-up'></i> : <i className='bi bi-chevron-down'></i>}
                                            </button>
                                            <p className='expandToggle' {...getCollapseProps()}>
                                              {el.description}
                                            </p>

                                            {/* </div> */}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  }) : ('No deliverable details found')
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-2 rounded-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12 mb-5">
                            <label className="h4">Project Background</label>
                            <p className="mb-0 fs-13 expandToggle">{masherProjectData?.briefId?.background || 'N/A'}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-2 rounded-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12 mb-5">
                            <label className="h4">Project Goal</label>
                            <p className="mb-0 fs-13 expandToggle">{masherProjectData?.briefId?.projectAmbition || 'N/A'}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-2 rounded-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12 mb-5">
                            <label className="h4">Brand</label>
                            <p className="mb-0 fs-13 expandToggle">{masherProjectData?.briefId?.brand || 'N/A'}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-2 rounded-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12 mb-5">
                            <label className="h4">Product/Experience</label>
                            <p className="mb-0 fs-13 expandToggle">{masherProjectData?.briefId?.experience || 'N/A'}</p>
                          </div>
                          <div className="col-md-12 mb-5">
                            <label className="h4">Audience</label>
                            <p className="mb-0 fs-13 expandToggle">{masherProjectData?.briefId?.audience || 'N/A'}</p>
                          </div>
                          <div className="col-md-12 mb-5">
                            <label className="h4">KPIs</label>
                            <p className="mb-0 fs-13 expandToggle">{masherProjectData?.briefId?.kpis || 'N/A'}</p>
                          </div>
                          <div className="col-md-12 mb-5 create_brief_links">
                            <label className="h4 d-block">
                                                            Client Website / Social / Press Links
                            </label>
                            {
                              masherProjectData?.briefId?.websiteLink.length > 0 ? masherProjectData?.briefId?.websiteLink?.map((el, i) => {
                                return (
                                  <React.Fragment key={`web-l-${i}`}>
                                    <a href={`${el?.link}`} target="_blank" className="si-hover-2 text-xs">{el?.name || 'N/A'}</a>
                                  </React.Fragment>
                                );
                              }) : ('No website links found')
                            }
                          </div>
                          <div className="col-md-12 mb-5">
                            <label className="h4">Attachment</label>
                            <br />
                            <a className="mb-0 fs-13 text-muted" href={`${masherProjectData?.briefId?.attachement}`} target="_blank">{masherProjectData?.briefId?.attachement || 'No attachment found'}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="card rounded-4">
                                            <div className="card-body">
                                                <h4 className="mb-4">Files</h4>
                                                <div className="row g-3">
                                                    <div className="col-xxl-4 col-lg-6">
                                                        <div className="position-relative border border-dashed rounded">
                                                            <div className="p-3 d-flex align-items-center">
                                                                <div className="me-4">
                                                                    <div className="icon icon-shape text-xl bg-black text-white">
                                                                        <i className="bi bi-card-image" />
                                                                    </div>
                                                                </div>
                                                                <div className="flex-fill">
                                                                    <p
                                                                        className="d-block h6 text-sm font-semibold mb-1"
                                                                    >
                                                                        Images
                                                                    </p>
                                                                    <span className="d-block text-xs">
                                                                        {format.image || "0"}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-4 col-lg-6">
                                                        <div className="position-relative border border-dashed rounded">
                                                            <div className="p-3 d-flex align-items-center">
                                                                <div className="me-4">
                                                                    <div className="icon icon-shape text-xl bg-black text-white">
                                                                        <i className="bi bi-file-earmark-font" />
                                                                    </div>
                                                                </div>
                                                                <div className="flex-fill">
                                                                    <p
                                                                        className="d-block h6 text-sm font-semibold mb-1"
                                                                    >
                                                                        Documents
                                                                    </p>
                                                                    <span className="d-block text-xs">
                                                                        {format.doc || "0"}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-4 col-lg-6">
                                                        <div className="position-relative border border-dashed rounded">
                                                            <div className="p-3 d-flex align-items-center">
                                                                <div className="me-4">
                                                                    <div className="icon icon-shape text-xl bg-black text-white">
                                                                        <i className="bi bi-file-earmark-excel" />
                                                                    </div>
                                                                </div>
                                                                <div className="flex-fill">
                                                                    <p
                                                                        className="d-block h6 text-sm font-semibold mb-1"
                                                                    >
                                                                        Sheets
                                                                    </p>
                                                                    <span className="d-block text-xs">
                                                                        {format.sheet || "0"}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-4 col-lg-6">
                                                        <div className="position-relative border border-dashed rounded">
                                                            <div className="p-3 d-flex align-items-center">
                                                                <div className="me-4">
                                                                    <div className="icon icon-shape text-xl bg-black text-white">
                                                                        <i className="bi bi-camera-video" />
                                                                    </div>
                                                                </div>
                                                                <div className="flex-fill">
                                                                    <p
                                                                        className="d-block h6 text-sm font-semibold mb-1"
                                                                    >
                                                                        Videos
                                                                    </p>
                                                                    <span className="d-block text-xs">
                                                                        {format.video || "0"}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                  </div>
                </div>
              </main>
            </div>
          )}
          <div className="flex-xl-none w-xl-96 border-start-xl bg-surface-primary h-xl-calc position-sticky top-xl-18">
            <aside className="p-4 h-full overflow-y-xl-auto">
              <div className="vstack gap-6">
                <div className="brief_chat_wrapper">
                  {
                    console.log(projectData, '<<<<,')
                  }
                  <ChatSection adminId={projectData?.data?.briefId?.createdBy} briefData={projectData?.data?.briefId} loading={projectData?.loading} briefId={projectData?.data?.briefId?._id} masherId={masherData?.data?.userId?._id} />
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}