import React, { useEffect, useRef } from 'react';

// This HOC takes a wrapped component as an argument
const withUnsavedChangesPrompt = (WrappedComponent) => {
  // Create and return a new functional component
  return function WithUnsavedChangesPrompt(props) {
    const hasUnsavedChanges = useRef(false);

    // Detect unsaved changes when the component unmounts or when navigating away
    useEffect(() => {
      const handleBeforeUnload = (e) => {
        if (hasUnsavedChanges.current) {
          e.preventDefault();
          prompt('Data might lost');
          e.returnValue = ''; // For older browsers
        }
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, []);

    // Function to set the unsaved changes flag
    const setUnsavedChanges = (unsaved) => {
      hasUnsavedChanges.current = unsaved;
    };

    // Render the wrapped component with the setUnsavedChanges function
    return <WrappedComponent {...props} setUnsavedChanges={setUnsavedChanges} />;
  };
};

export default withUnsavedChangesPrompt;
