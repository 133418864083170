import React, {useEffect, useState} from 'react';
// import {dummyBriefData as briefData} from '../PricingTable/dummyBriefData';
import RoleCard from './RoleCard';
import './masherTeamUpTable.css';


export default function MasherTeamUpTable({ briefData, refreshBrief,loggedInUser }) {
  return (
    <><div className='w-fit md:w-full'>
      <div className='bg-[#1D2939] text-white p-5 rounded-t-lg text-lg font-semibold mt-5 w-full'>Mash-Up Team</div>
      <div className='mashup-table-header-row w-full'>
        <div  className='role-card-masher-empty-column'></div>
        <div className='role-card-masher-details-column !w-fit'>
          <div className='role-card-masher-details'>
            <div>
              <p>Name</p>
            </div>
          </div>
        </div>
      </div>
      <div className='mashup-table-card-container'>
        {
          briefData?.data?.deliverables ?
            briefData.data?.deliverables?.map((deliverable, i) => {
              return <RoleCard key={i} loggedInUser={loggedInUser} refreshBrief={refreshBrief} deliverable={deliverable} briefId={briefData._id} index={deliverable.deliverableId} />;
            })
            :
            <p>Please Add deliverables before adding roles.</p>
        }
      </div>
    </div>
    </>

    
  );

}