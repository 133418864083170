import React, { useEffect, useState } from 'react';
import './App.css';
import AppContext from './appContext';
import { useNavigate } from 'react-router-dom';
import MainRouter from './MainRouter/MainRouter';
import { logoutOnJwtExpire, setStorageITem } from './utils/helperFunctions';
import { getMahserProfileApi, getRecentActivityApi } from './apis';

function App() {
  const [recentActivity, setRecentActivity] = useState({
    data: null,
    loading: false,
  });
  const [isLoggedIn, setIsLoggedIn] = useState(
    Boolean(localStorage.getItem('token'))
  );
  const [loggedInUser, setLoggedInUser] = useState({
    data: null,
    user: null,
    referredBy: null,
    loading: false,
  });

  const navigate = useNavigate();

  window.onstorage = () => {
    const token = window.localStorage.getItem('token');
    if (!token) {
      localStorage.clear();
      navigate('/sign-in');
    }
  };

  const logout = (navigate) => {
    localStorage.removeItem('token');
    localStorage.removeItem('isUserNew');
    setIsLoggedIn(false);
    setLoggedInUser(null);
    navigate('/sign-in');
  };

  const recentActivityData = async () => {
    setRecentActivity({ loading: true });
    try {
      const res = await getRecentActivityApi();
      setRecentActivity({ data: res?.data?.recentActivity, loading: false });
    } catch (error) {
      setRecentActivity({ loading: false });
      if (error?.response?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };

  const fetchMasherProfile = async () => {
    setLoggedInUser({ ...loggedInUser, loading: true });
    try {
      const res = await getMahserProfileApi();
      if (res.status === 200) {
        // if (Boolean(res?.data?.masherProfile) === false) {
        //   navigate('/create-profile');
        // }
        setLoggedInUser({ data: res.data?.masherProfile, user: res?.data?.user, referredBy: res?.data?.referedBy, loading: false });
      }
    } catch (error) {
      setLoggedInUser({ data: null, loading: false });
      if (error?.response?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  useEffect(() => {
    clearCacheData();
  }, []);

  return (
    <AppContext.Provider
      value={{
        state: {
          isLoggedIn,
          loggedInUser,
          recentActivity,
        },
        setIsLoggedIn,
        setLoggedInUser,
        fetchMasherProfile,
        recentActivityData,
        logout,
      }}
    >
      <MainRouter />
    </AppContext.Provider>
  );
}

export default App;
