import React from 'react';
import chevronRight from '../../../Icons/chevronRight.svg';

export default function ExpandableButton({ isOpen, toggle, backgroundColor }) {
  return (
    <button 
      onClick={toggle}
      style={{
        backgroundColor: `${backgroundColor || '#F2F4F7'}`,
        height: '100%',
        width: '25px',
        padding: '5px',
        marginRight: '25px',

      }}
    >

      <img 
        src={chevronRight}
        style={{
          transform: `rotate(${isOpen ? 90 : 0}deg)`,
          transition: 'all 0.25s',
          height: '15px',
          color: 'black'
        }}
      />
    </button>
  );
};