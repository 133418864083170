import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import GmailPopup from './GmailPopup';
import ReferCreativepopup from '../ReferCreativepopup';
import AdminLayout from '../../components/admin-layout';

import './MasherProfile.css';
import { Linkedin } from '../../components/Logos';
import Instagram from '../../components/Logos/Instagram';
import Twitter from '../../components/Logos/Twitter';
import Tiktok from '../../components/Logos/Tiktok';
import Facebook from '../../components/Logos/Facebook';

import { useContext } from 'react';
import AppContext from '../../appContext';
import { Loader, EditProfileLoader } from '../../components/loader/loader';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import { otherMasherProfileApi, fileUploadApi, updateMasherProfile, masherworkattach, mashMyworkAddApi, fileUploadwithprogressApi, createProfileApi } from '../../apis';
import { InputErrorMessage } from '../../components/errorMessages';
import { PortfolioEditValidation, profilePicValidaiton } from '../../components/validation/Validation';
import { FileUploadComponent } from '../../components/loader/loader';
import { XCircleFill, FileEarmarkPdfFill } from 'react-bootstrap-icons';
import { DeletePopup } from './DeletePopup';
import UpdateWorkModal from './UpdateWorkModal';
import AddWorkModal from './AddWorkModal';
import WorkModal from './WorkModal';
import ToggleSwitch from '../../components/button/Togglebtn';
export default function Index() {

  const [masherData, setMasherData] = useState({ data: null, loading: false });
  const [loader, setLoader] = useState(false);
  const [privateAccess,setprivateAccess]=useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const loading = masherData?.loading;
  const appContext = useContext(AppContext);
  const { state, fetchMasherProfile } = appContext;
  const { loggedInUser } = state;

  const [skills, setSkills] = useState([]);

  const [updateMode, setUpdateMode] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [updateDetails, setUpdateDetails] = useState({});
  const [DeleteDetails, setDeleteDetails] = useState({});
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isWorkOpen, setIsWorkOpen] = useState(false);
  const [meetMasherDashboard, setMeetMasherDashboard] = useState({ data: null, loading: false });
  const [workDetails, setWorkDetails] = useState({
    details: {},
    index: 0
  });
  const closeModal = (id) => {
    const closeButton = document.getElementById(id);

    if (closeButton) {
      closeButton.click();
    }
  };

  // Add MyWrok.............
  const [loader_1, setLoader_1] = useState(false);
  const formik_1 = useFormik({
    initialValues: {
      company: '',
      role: '',
      description: '',
      link: '',
      files: '',


    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {

      const { company, role, description, link, files } = values;

      try {
        setLoader_1(true);
        const res = await mashMyworkAddApi({
          'masherId': params?.masherId || '',
          'filePath': files || '',
          'company': company || '',
          'role': role || '',
          'description': description || '',
          'link': link || ''
        });
        if (res.status === 200 || 201) {
          setLoader_1(false);
          toast.success(res.data.message, { id: 'succcess' });
          fetchOtherMasherProfile();
          closeModal('modalworkCloseButton');
          resetForm();
          setSubmitting(false);
        }
      } catch (error) {
        setLoader_1(false);
        toast.error(error.response.data.message, { id: '001' });
      }
    }

  });
  const fetchOtherMasherProfile = async () => {
    setMasherData({ loading: true });
    try {
      const res = await otherMasherProfileApi(params?.masherId);
      if (res.status === 200) {
        // toast.success(, { id : "002" })
        setMasherData({ data: res?.data, loading: false });
        if(res.data?.masherProfile?.Mywork?.[0]){
          setUpdateMode(false);
        }
        else{
          setUpdateMode(true);
        }
      }
    } catch (error) {
      setMasherData({ loading: false });
      if (error?.response?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };
  useEffect(() => {
    if(params?.masherId!=='create'){
      fetchMasherProfile();
      fetchOtherMasherProfile();
    }
  }, []);
  useEffect(() => {
    setprivateAccess(masherData?.data?.masherProfile?.isVisibletoOthers)
  }, [masherData]);
  const clearcv = async () => {
    const res = await updateMasherProfile({
      'id': params?.masherId || '',
      'cv': '',
    });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      clientsWorkedFor: masherData?.data?.masherProfile?.clientsWorkedFor?.[0]?.label || '',
      cv: masherData?.data?.masherProfile?.cv || '',
      company: '',
      role: '',
      description: '',
      link: '',
      files: '',
    },
    validationSchema: PortfolioEditValidation,
    onSubmit: async (values) => {
      const { clientsWorkedFor, cv, company, role, description, link, files } = values;
      try {
        setLoader(true);
        if (params?.masherId === 'create') {
          try {
            setLoader(true);
            const res = await createProfileApi({
              'cv': cv || '',
              'clientsWorkedFor': [{ 'label': clientsWorkedFor, 'value': '001' }]
            });
            if (res.status === 200 || res.status === 201) {
              setLoader(false);
              toast.success(res.data.message, { id: 'success' });
              setTimeout(() => {
                navigate('/');
              }, 2000);
            }
          } catch (error) {
            setLoader(false);
            toast.error(error.response.data.message, { id: '001' });
          }
        } else {
          try {
            const res = await updateMasherProfile({
              'id': params?.masherId || '',
              'cv': cv || '',
              'clientsWorkedFor': [{ 'label': clientsWorkedFor, 'value': '001' }],
            });
            if (res.status === 200 || res.status === 201) {
              if (files !== '' || company !== '' || role !== '' || description !== '' || link !== '') {
                try {
                  const res = await mashMyworkAddApi({
                    'masherId': params?.masherId || '',
                    'filePath': files || '',
                    'company': company || '',
                    'role': role || '',
                    'description': description || '',
                    'link': link || ''
                  });
                  if (res.status === 200 || res.status === 201) {
                    setLoader(false);
                    toast.success(res.data.message, { id: 'success' });
                    setTimeout(() => {
                      navigate(`/masher-view/${params?.masherId}`);
                    }, 2000);
                  }
                } catch (error) {
                  setLoader(false);
                  toast.error(error.response.data.message, { id: '001' });
                }
              } else {
                setLoader(false);
                toast.success(res.data.message, { id: 'success' });
                setTimeout(() => {
                  navigate(`/masher-view/${params?.masherId}`);
                }, 2000);
              }
            }
          } catch (error) {
            setLoader(false);
            toast.error(error.response.data.message, { id: '001' });
          }
        }
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    }
    
  });
  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };
  function formatFileSize(sizeInBytes) {
    const sizeInKB = sizeInBytes / 1024;
    const sizeInMB = sizeInKB / 1024;

    if (sizeInMB >= 1) {
      return `${sizeInMB.toFixed(2)} MB`;
    } else if (sizeInKB >= 1) {
      return `${sizeInKB.toFixed(2)} KB`;
    } else {
      return `${sizeInBytes} bytes`;
    }
  }
  // mywork dropzone ........

  const onDrop_mywork = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      try {
        // Create a FileReader to read the contents of the file
        const reader = new FileReader();

        // Define a callback for when the file is loaded
        reader.onload = (e) => {

          const img = new Image();
          img.src = e.target.result;
          // Check the image dimensions
          img.onload = () => {
            const width = img.width;
            const height = img.height;
            // Check if the dimensions meet your criteria (max. 800x400px)
            // Check if the dimensions meet your criteria (min. 800px width, no max. height)
            if (width >= 800) {
              fileupload(file);

            } else {
              // Display an error message if dimensions exceed the limit
              toast.error('File width must be at least 800 pixels', { id: '002' });
            }
          };

        };
        // Read the contents of the file as a data URL
        reader.readAsDataURL(file);
      } catch (error) {
        // Handle errors, e.g., if the FileReader fails
        toast.error('Error processing file dimensions', { id: '004' });
      }
    }
  }, [formik]);

  const [uploadPercentage_mywork, setUploadPercentage_mywork] = useState(0);
  const [filename_mywork, setfileName_mywork] = useState('');
  const [filesize_mywork, setfilesize_mywork] = useState('');

  const [isfile_mywork, setisfile_mywork] = useState(false);

  const onUploadProgress_mywork = (progressEvent, file_value) => {

    setfileName_mywork((prev) => {
      return file_value.name;
    });
    setfilesize_mywork((prev) => {
      return formatFileSize(file_value.size);
    });
    setUploadPercentage_mywork((prevPercentage) => {
      const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      return progress;
    });
  };
  const fileupload = async (file) => {
    setisfile_mywork(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('masherId', params?.masherId);
    fileUploadwithprogressApi(formData, onUploadProgress_mywork, file)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success(res.data.message, { id: 'success' });
          formik.setFieldValue('files', res.data.filePath);
          setisfile_mywork(false);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, { id: '001' });
        setisfile_mywork(false);
      });
  };

  const {
    getRootProps: getRootProps_mywork,
    getInputProps: getInputProps_mywork,
    isDragActive: isDragActive_mywork,
  } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif'],
    },
    onDrop: onDrop_mywork,
  });



  // cv dropzone ..........
  const [uploadPercentage_cv, setUploadPercentage_cv] = useState(0);
  const [filename_cv, setfileName_cv] = useState('');
  const [filesize_cv, setfilesize_cv] = useState('');

  const [isfile_cv, setisfile_cv] = useState(false);
  const onUploadProgress_cv = (progressEvent, file_value) => {
    setfileName_cv((prev) => {
      return file_value.name;
    });
    setfilesize_cv((prev) => {
      return formatFileSize(file_value.size);
    });
    setUploadPercentage_cv((prevPercentage) => {
      const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      return progress;
    });
  };

  const onDrop_cv = useCallback(async (acceptedFiles) => {

    if (acceptedFiles.length > 0) {
      let cv = acceptedFiles[0];
      const formdata = new FormData();
      formdata.append('file', cv);
      formdata.append('masherId', params?.masherId);
      const type = cv.name;

      const videoMp4 = /pdf/g;
      const videoMov = /docx/g;
      const video3gp = /pdf/g;

      let fistD = videoMp4.test(type);
      let secondD = videoMov.test(type);
      let thirdD = video3gp.test(type);

      if (fistD || secondD || thirdD) {

        setisfile_cv(true);
        fileUploadApi(formdata, onUploadProgress_cv, cv)
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              toast.success(res.data.message, { id: 'succcess' });
              formik.setFieldValue('cv', res?.data?.filePath);
              setisfile_cv(false);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message, { id: '001' });
            setisfile_mywork(false);
          });


      }
      else {
        toast.error('invalid file type', { id: '001' });
        setisfile_cv(false);
      }

    }

  }, [formik]);
  const {
    getRootProps: getRootProps,
    getInputProps: getInputProps,
    isDragActive: isDragActive,
  } = useDropzone({
    acceptedFiles: 'application/pdf,.doc,.docx,.rtf',
    onDrop: onDrop_cv,
  });

  const MAX_CHARACTERS = 1000;
  const isDisabled = true;

  const updatevisibality=async(state)=>{
    try {
      const res = await updateMasherProfile({
        'id': params?.masherId || '',
        'isVisibletoOthers': state,
      });
      if (res.status === 200 || 201) {
        toast.success(res.data.message, { id: 'succcess' });
      }
    } catch (error) {
      toast.error(error.response.data.message, { id: '001' });
    }
  }
  const submitForm = () => {
    if (Object.keys(formik.errors).length > 0) {
      // If there are errors, find the first one
      const firstErrorField = Object.keys(formik.errors)[0];
      // Scroll to the first error field
      const errorFieldElement = document.getElementById(firstErrorField);
      if (errorFieldElement) {
        errorFieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  return (
    <AdminLayout>
      <div className='d-flex flex-column h-lg-full masher-profile-container'>
        <header>
          <div className='masher-profile-page-header w-full flex flex-row justify-between items-center'>
            <p className='font-semibold text-[#101828] text-3xl'>Portfolio</p>
            <p className='font-semibold text-[#101828] text-1xl'><ToggleSwitch togglefunction={updatevisibality} checked={privateAccess} setChecked={setprivateAccess} /> Visible to Mashers</p>
          </div>
          <div className='masher-profile-navbar gap-4'>
            <Link to={`/account/${loggedInUser?.data?._id  || 'create'}`}>
              <div>
                Account
              </div>
            </Link>
            <Link className='' to={`/mashers-profile/${loggedInUser?.data?._id  || 'create'}`}>
              <div>
                Profile
              </div>
            </Link>
            <Link className='masher-profile-navbar-selected' to={`/portfolio/${loggedInUser?.data?._id  || 'create'}`}>
              <div>
                Portfolio
              </div>
            </Link>
          </div>
        </header>
        {loading ? (
          <div className='text-center mt-10'>
            <Loader />
          </div>
        ) : (
          <div>
            <div>
              <form className='masher-profile-form' onSubmit={formik.handleSubmit}>
                <div className='w-full hidden md:flex justify-between items-start md:items-center pb-6 mb-6 border-b border-[#EAECF0]'>
                  <div>
                  </div>
                  {/* <div className='flex gap-4 mt-2 md:mt-0'>
                  <button className='text-lg font-semibold text-[#00A46F] w-full py-2 px-4' type='button'>Cancel</button>
                  <button className='text-lg font-semibold text-white bg-[#00A46F] w-full py-2 px-4' type='submit'>
                  {!loader ? ('Save') : (<div className="text-xs"><EditProfileLoader /></div>)}
                  </button>
                </div> */}
                </div>
                <div>
                  <label>
                    I've Worked With
                  </label>
                  <div>

                    <div className='input-group shadow-none' onSubmit={formik.handleSubmit}>
                      <textarea type='text'
                        onKeyDown={(e) => ['+', '-'].includes(e.key) && e.preventDefault()}
                        className={Boolean(formik.touched?.clientsWorkedFor && formik.errors?.clientsWorkedFor) ? 'form-control border-danger' : 'form-control'}
                        rows="7"
                        placeholder='List the companies or clients you’ve worked with'
                        id='clientsWorkedFor'
                        value={formik.values.clientsWorkedFor}
                        onChange={handleChange}
                      />

                    </div>
                    <span className='mt-2'>{Math.max(0, MAX_CHARACTERS - formik.values.clientsWorkedFor.length)} characters left</span>
                    <InputErrorMessage error={formik.touched.clientsWorkedFor && formik.errors.clientsWorkedFor} />
                  </div>
                </div>
                <div>
                  <label>
                    CV*
                  </label>
                  <div>
                    {formik.values.cv && <div className='edit-cv' >
                      <FileEarmarkPdfFill size={22} />
                      <div className='file-name'> {formik.values.cv.split('/').pop()}</div>
                      <XCircleFill onClick={() => { formik.setFieldValue('cv', ''); clearcv(); }} />
                    </div>}

                    {
                      !formik.values.cv &&
                      <div className='flex gap-4 input-group'>

                        <div className='w-full rounded-lg border border-[#D0D5DD] p-8 flex items-center justify-center mt-0' {...getRootProps()}>

                          <>
                            <input {...getInputProps()} />
                            {isDragActive ? <div className='flex flex-col items-center text-center'>
                              <div className='flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]'><i className="bi bi-cloud-arrow-up text-2xl text-[#475467]" /></div>
                              <p className='font-semibold text-[#00A46F] mt-6'>Drop here</p>
                              <p>PDF, DOCX, RTF</p>
                            </div> : <div className='flex flex-col items-center text-center'>
                              <div className='flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]'><i className="bi bi-cloud-arrow-up text-2xl text-[#475467]" /></div>
                              <p className='font-semibold text-[#00A46F] mt-6'>Click to upload <span className='text-[#667085] font-normal hidden md:block'>order drag and drop</span></p>
                              <p>PDF, DOCX, RTF</p>
                            </div>}
                          </>

                        </div>

                        <InputErrorMessage error={formik.touched.cv && formik.errors.cv} />
                      </div>
                    }

                    {
                      isfile_cv && <FileUploadComponent name={filename_cv} filesize={filesize_cv} percentage={uploadPercentage_cv} />
                    }


                  </div>
                </div>
                <div className='flex flex-col gap-4 mt-10 mb-10 items-start w-full'>  
                    <div className='flex w-full justify-between'>
                    <span className="text-2xl text-black font-bold flex items-center gap-2">Upload My Work</span>
                    
                    <button className="text-md text-[#00A46F] font-bold md:flex items-center gap-2 " type='button' onClick={() => setUpdateMode(!updateMode)}>{updateMode ? 'Save Portfolio' : 'Update Portfolio'}</button>
                    </div>
                  
                 


                  <div className='overflow-x-auto w-[calc(100vw-16px)] md:w-full flex gap-4 flex-nowrap md:flex-wrap'>
                    {masherData?.data?.masherProfile?.Mywork.map((item, index) => {
                      return (<div key={`Work-${index}`} className='min-w-[312px] md:w-[calc(33%-8px)] min-h-[268px] md:h-[353px] relative bg-center bg-no-repeat bg-cover' onClick={() => { !updateMode && setIsWorkOpen(true), !updateMode && setWorkDetails({ details: item, index }); }} style={{ backgroundImage: item.filePath ? `url(${item.filePath})` : '' }}>
                        {updateMode && <div className='absolute top-0 left-0 w-full bg-white text-black px-4 py-4'>
                          <div className='flex justify-between items-center gap-2 text-sm'>
                            <div>
                              <button className="text-md text-[#00A46F] font-bold"
                                data-bs-toggle="modal"
                                data-bs-target="#updatemodalwork"
                                onClick={() => {
                                  setUpdateDetails(item);

                                }}
                                type='button'
                              >Edit Details</button>
                            </div>
                            <div>
                              <i className="bi bi-trash text-2xl text-red-600"
                                data-bs-toggle="modal"
                                data-bs-target="#Deletemodalwork"
                                onClick={() => {
                                  setDeleteDetails(item);

                                }}
                              // onClick={() => { DeleteMywork(item._id) }}
                              />
                            </div>
                          </div>
                        </div>}

                        {(item.company || item.role || item.description || item.link) && <div className={'absolute bottom-0 left-0 w-full bg-white text-black px-4 py-2'}>
                          <div className='flex justify-between items-center gap-2 text-sm'>
                            <div>
                              {item.company && <p className='font-semibold'>Company: <span className='font-normal'>{item.company}</span></p>}
                              {item.company && <p className='font-semibold'>My Role: <span className='font-normal'>{item.role}</span></p>}
                              {item.company && <p className='font-semibold'>Description: <div className='font-normal descr'>{item.description}</div></p>}
                            </div>
                            <div>
                              {item.link && <a onClick={(e) => e.stopPropagation()} className='font-bold underline text-[#00A46F]' target='_blank' rel='noreferrer' href={item.link}>Link</a>}
                            </div>
                          </div>


                        </div>}
                      </div>);
                    })}

                    {updateMode && <button className='min-w-[312px] md:w-[calc(33%-8px)] min-h-[268px] hover:opacity-40 md:h-[353px] border-4 flex items-center justify-center border-[#00A46F] relative'
                      // onClick={()=> setIsAddOpen(true)}
                      data-bs-toggle="modal"
                      data-bs-target="#Addmodalwork"
                      type='button'
                    >
                      <i className="bi bi-plus text-7xl text-[#00A46F]" />
                    </button>}
                  </div>

                </div>
                {/* <div>
                  <label>
                    Upload My Work
                  </label>
                  <div className='flex gap-4 input-group'>
                    {formik.values.files && <div className='edit-profile' >
                      <div className='file-name'>{formik.values.files.substring(formik.values.files.lastIndexOf('/') + 1)} </div>
                      <XCircleFill onClick={() => { formik.setFieldValue('files', ''); }} />
                    </div>}
                    <div className='w-full rounded-lg border border-[#D0D5DD] p-8 flex items-center justify-center mt-0' {...getRootProps_mywork()}>
                      {
                        formik.values.files && <img src={formik.values.files} />
                      }
                      {
                        !formik.values.files &&
                        <>
                          <input {...getInputProps_mywork()} />
                          {isDragActive_mywork ? <div className='flex flex-col items-center text-center'>
                            <div className='flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]'><i className="bi bi-cloud-arrow-up text-2xl text-[#475467]" /></div>
                            <p className='font-semibold text-[#00A46F] mt-6'>Drop here</p>
                            <p>PNG,JPG or GIF (max. 800x400px)</p>
                          </div> : <div className='flex flex-col items-center text-center'>
                            <div className='flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]'><i className="bi bi-cloud-arrow-up text-2xl text-[#475467]" /></div>
                            <p className='font-semibold text-[#00A46F] mt-6'>Click to upload <span className='text-[#667085] font-normal hidden md:block'>order drag and drop</span></p>
                            <p>PNG,JPG or GIF (max. 800x400px)</p>
                          </div>}
                        </>
                      }
                    </div>
                    {
                      isfile_mywork && <FileUploadComponent name={filename_mywork} filesize={filesize_mywork} percentage={uploadPercentage_mywork} />
                    }
                  </div>
                </div>
                <div>
                  <label>
                    Company
                  </label>
                  <div className='input-group'>
                    <input type='text' className='form-control'
                      name="company"
                      value={formik.values.company}
                      onChange={handleChange}
                      disabled={params?.masherId==='create'}
                    />
                  </div>
                </div>
                <div>
                  <label>
                    My Role
                  </label>
                  <div className='input-group'>
                    <input type='text' className='form-control'
                      name="role"
                      value={formik.values.role}
                      onChange={handleChange}
                      disabled={params?.masherId==='create'}
                    />
                  </div>
                </div>
                <div>
                  <label>
                    Description
                  </label>
                  <div className='input-group'>
                    <input type='text' className='form-control'
                      id="description"
                      name="description"
                      value={formik.values.description}
                      onChange={handleChange}
                      disabled={params?.masherId==='create'}
                    />
                  </div>
                </div>
                <div>
                  <label>
                    Link
                  </label>
                  <div className='input-group'>
                    <input type='text' className='form-control'
                      id="link"
                      name="link"
                      value={formik.values.link}
                      onChange={handleChange}
                      disabled={params?.masherId==='create'}
                    />
                  </div>
                </div> */}
                <div className='w-full border-b border-[#EAECF0]'></div>
                <div className='w-full flex justify-end items-end md:items-center mt-0'>
                  <div className='flex gap-4'>
                    <button className='text-lg font-semibold text-[#00A46F] w-full py-2 px-4' type='button'>Cancel</button>
                    <button onClick={submitForm} className='text-lg font-semibold text-white bg-[#00A46F] w-full py-2 px-4' type='submit'>
                      {!loader ? ('Save') : (<div className="text-xs"><EditProfileLoader /></div>)}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <UpdateWorkModal details={updateDetails} isOpen={isUpdateOpen} setIsOpen={setIsUpdateOpen} masherId={params?.masherId} fetchOtherMasherProfile={fetchOtherMasherProfile} />
          <AddWorkModal loader={loader_1} isOpen={isAddOpen} setIsOpen={setIsAddOpen} masherId={params?.masherId} fetchOtherMasherProfile={fetchOtherMasherProfile} formik={formik_1} />
          <WorkModal isOpen={isWorkOpen} setIsOpen={setIsWorkOpen} setDetails={setWorkDetails} details={workDetails.details} index={workDetails.index} items={masherData?.data?.masherProfile?.Mywork} />
          <DeletePopup details={DeleteDetails} fetchOtherMasherProfile={fetchOtherMasherProfile} masherId={params?.masherId} />
      <GmailPopup />
      <ReferCreativepopup />
    </AdminLayout>
  );
}