import React, { useState } from 'react';
import TermDoc from '../../Assets/docs/Terms.pdf';
import EXPAND_ICON from '../../Assets/img/icons/fullscreen.png';
import COLLAPSE_ICON from '../../Assets/img/icons/minimize.png';
import './index.scss';

const TermsModal = ({ onAccept, acceptedTerms }) => {
  const [accepted, setAccepted] = useState(acceptedTerms);
  const [maximised, setMaximised] = useState(false);

  const toggleMaximised = () => {
    setMaximised(!maximised);
  };
  const handleAccept = () => {
    onAccept();
  };

  return (
    <div className='termsConditions'>

      <div className="modal-overlay">
        <div className={maximised ? 'modal-content-maximised' : 'modal-content four'}>
          <div className='modal-header'>
            {maximised ? <h2>Terms of Use</h2> : <h4>Terms of Use</h4>}
            <img className={maximised ? 'collapse-icon' : 'expand-icon'}
              src={maximised ? COLLAPSE_ICON : EXPAND_ICON}
              alt={maximised ? 'collapse' : 'expand'}
              onClick={toggleMaximised} />
          </div>
          <div className={maximised ? 'modal-body-maximised' : 'modal-body'}>
            <div style={{ marginTop: '0px' }}>
              <p>Please carefully read these terms and conditions (“Terms of Use”) for access and use of the Mash Hub available at https://masher.thisismash.com (“Hub”) before you access or use the Hub, including via the Mash website.</p>

              <p style={{ marginTop: '10px' }}>Mash Holdings Australia Pty Ltd is a private limited company having its registered oﬃce at PO Box 24145, Melbourne VIC 3001 (hereinafter referred to as “We”, “Us”, “Mash” or “Our” which expression shall mean and include its aﬃliates, successors and permitted assigns). Your (“You” or “Your”) use of the Hub (deﬁned below) is subject to the terms and conditions set forth in these Terms of Use.</p>
              <p style={{ marginTop: '10px' }}>Your use of the Hub is governed by the following terms and conditions as applicable to the Hub including the applicable policies which are incorporated herein by way of reference or notified to You through the Hub.</p>
              <p style={{ marginTop: '10px' }}>In order to access and use the Hub, You agree to be subject to these Terms of Use. We reserve the right, at Our sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time without any prior written notice to You. It is Your responsibility to review these Terms of Use periodically for updates / changes, however We will notify You of any material updates or changes to the Terms of Use from time to time. Your continued use of the Hub following the posting of changes will mean that You accept and agree to the updates or changes.</p>
              <p style={{ marginTop: '10px' }}>By accessing, browsing or using the Hub, including via the Mash website, you indicate that you accept these Terms of Use and that you agree to abide by them as a legally binding agreement between you and us and this agreement takes effect on the date on which you access or use the Hub. If you do not agree with these Terms of Use, you must cease accessing or using the Hub immediately.</p>

              <p style={{ marginTop: '10px' }}>These Terms of Use shall be enforceable in each jurisdiction in which you access or use the Hub.</p>

              <h5>1. WHAT WE DO</h5>
              Mash is a team curation and project management Hub for creative services.

              <h5>2. ACCESS TO THE HUB</h5>
              <p>2.1. You cannot access the Hub without registering for an account. To use the features, You will need to register and create a proﬁle with accurate and complete information. Your proﬁle must be your own or of a person or, an entity You are entitled to represent and You shall not use or provide any information that is offensive or that violates any third party’s rights.</p>
              <p>2.2. Mash may at any time for any reason and without notice modify, suspend, withdraw or terminate operation of or access to the Hub including any functionalities. Mash may also upgrade the Hub for fixes, and to add support for new functions or services.</p>
              <p>2.3. You acknowledge that you have no rights in, or to, the Hub or technology used or supported by the Hub, other than the right to use the Hub in accordance with these Terms of Use.</p>
              <p>2.4. You understand that creating an account does not automatically provide you with any guarantee of work or engagement from Mash and Mash may engage you for work at its sole discretion.</p>
              <h5>3. LIMITATIONS OF USE</h5>
              <p>3.1. You must:
                (a) use the Hub in accordance with these Terms of Use;
                (b) not, and must not allow third parties on your behalf to, copy, transmit, upload, distribute, reproduce, republish, post, encode, translate, licence, alter, reverse engineer, adapt or modify the whole or any part of the Hub in any way whatsoever;
                (c) not use the Hub in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these Terms of Use, or hacking into or inserting malicious code, destruction of data, including viruses, or harmful data, into the Hub;
                (d) not use the Hub in any way that could damage, disable, overburden, impair or compromise the Hub; and
                (e) comply with any applicable law and regulation relating to accessing or using technology supported by the Hub.</p>
              <p>3.2. You shall not host, display, upload, modify, publish, transmit, update or share any information on the Hub that:</p>
              <p>3.2.1. Belongs to another person and to which You do not have any right;</p>
              <p>3.2.2. Is grossly harmful, harassing, defamatory, invasive of another’s privacy, hateful or racially objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever;</p>
              <p>3.2.3. Harms minors in any way;</p>
              <p>3.2.4. Infringes any patent trademark, copyright or other proprietary rights;</p>
              <p>3.2.5. Violates any law for the time being in force;</p>
              <p>3.2.6. Deceives or misleads the users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</p>
              <p>3.2.7. Impersonates another person;</p>
              <p>3.2.8. Causes incitement to the commission of any cognizable offence or prevents investigation of any offence.</p>
              <p>3.3. You understand that any content You ﬁnd on or through the Hub is the sole responsibility of the person who originated such content. You conﬁrm that You are not relying on Mash to, and that You understand that We do not, endorse, support, represent or guarantee the completeness, truthfulness, accuracy or reliability of any content or communications posted via the Hub or endorse any opinions expressed on the Hub.</p>
              <p>3.4. You understand that Your content may be republished and if You do not have the right to submit content for such use, it may subject You to liability, and that Mash will not be responsible or liable for any use of Your content by Mash in accordance with these Terms of Use.</p>
              <p>3.5. You must not:</p>
              <p>3.5.1. submit any content or material to the Hub that falsely expresses or implies that such content or material is sponsored or endorsed by Mash;</p>
              <p>3.5.2. sell access to the Hub or any part thereof other than through a mechanism expressly approved by Mash;</p>
              <p>3.5.3. modify, publish, store, transmit, distribute, display, perform or participate in the transfer or sale of, create derivative works on, or in any way exploiting, any of the copyrighted material contained on the Hub, in whole or in part, without Our prior written consent.</p>
              <p>3.6. You must use the chat features of the Hub responsibly.</p>
              <p>3.7. You shall not use the Hub to send unsolicited commercial messages (‘spam’) or unsolicited mass distribution of ﬁles.</p>
              <p>3.8. In order to enforce this provision, Mash may establish additional policies regarding the use of chat features, including, but not limited to, maximum retention times of messages, maximum number of messages sent per day, or a maximum size of a message.</p>
              <p>3.9. You agree not to use or launch any automated system, including without limitation, “robots,” “spiders,” “oﬄine readers,” etc., to collect or copy data from the Hub. You further agree that You shall access the Hub only via the website through a web browser or a mobile user interface. Notwithstanding the foregoing, Mash grants the operators of public search engines permission to use spiders to copy materials from the site for the sole purpose of creating publicly available searchable indices of the materials, but not caches or archives of such materials Mash reserves the right to revoke these exceptions either generally or in speciﬁc cases. You agree not to collect or harvest any personally identiﬁable information, including account names, from the Hub, nor to use the communication systems provided by the Hub for any commercial solicitation purposes.</p>

              <h5>4. REPRESENTATIONS AND WARRANTIES</h5>
              By accessing and using the Hub, You hereby represent and warrant:
              <p>4.1. That You have the right, authority, and capacity to enter into this Terms of Use on Your own behalf and on behalf on any entity for whom You are acting and to abide by all of the terms and conditions contained herein, and that if any aspect of Your participation violates provisions of the law to which You are subject, You will cease using the services and close your account;</p>
              <p>4.2. That You are at least 18 years old and competent to execute and perform into any agreement you enter into through Our Hub;</p>
              <p>4.3. That You shall not use a false name or email address owned or controlled by another person with the intent to impersonate that person or for any other reason;</p>
              <p>4.4. That You shall not use a user ID name that is subject to any rights of a person other than yourself without appropriate authorization;</p>
              <p>4.5. That You shall comply with the terms and conditions stipulated in this Terms of Use;</p>
              <p>4.6. That You shall be solely responsible for maintaining the conﬁdentiality of Your passwords;</p>
              <p>4.7. That all the representations made by You to Us including in accordance with these terms and conditions are true, correct and complete and that You will update your registration information with Mash as needed so that it remains true, correct and complete;</p>
              <p>4.8. That You will conduct yourself in a professional manner in all your interactions with any other user, Mash their members, employees and oﬃcers and shall not defame, harass, intimidate, threaten, disparage, libel or otherwise make false statement concerning any of the aforesaid persons in the course of your interactions with them or third parties;</p>
              <p>4.9. That Your usage of the Hub will not, in any manner, directly or indirectly, violate terms of any contractual arrangement binding on You. Further, You agree not to use the Hub in a manner that results in an event where You may experience a conﬂict of interest such as with an entity in which You are currently holding shares; You are a director or a key employee or You exercise signiﬁcant inﬂuence;</p>

              <h5>5. EMAILS, NEWSLETTERS AND NOTIFICATIONS</h5>
              <p>5.1. You agree to us sending to you communications via email (“Email Notifications”). You acknowledge and agree that receipt of Email Notifications is dependent on the operation of your email provider. We will not be responsible or liable for any costs or damages incurred by you not receiving Email Notifications or by the insufficient operation of your device, mobile network or mobile phone company.</p>
              <p>5.2. You may opt out of receiving Email Notifications at any time by contacting Us at <a target='_blank' href="mailto: sayhi@thisismash.com">sayhi@thisismash.com</a>.</p>
              <p>5.3. In respect of Email Notifications,  We will use Your name and latest e-mail address provided by You in this regard.</p>

              <h5>6. INTELLECTUAL PROPERTY</h5>
              <p>6.1. This Hub is operated by and is the sole property of Mash. Any and all material on this Hub, including images, illustrations, audio and video clips, trade names, proprietary information and knowledge, technology, databases, or rights with respect thereto necessary for Our business as is now being operated are protected by copyrights, trademarks, and other intellectual property rights that are owned by Us or by other parties that have licensed such material to Us, or such parties having ownership of content uploaded on the Hub. You agree that any and all material displayed on the Hub is solely for Your personal use and You shall not, whether directly or indirectly, copy, reproduce, republish, post, upload, transmit or distribute such material in any manner and through any media including by way of e-mail or other electronic means and You shall not assist any other person in doing so. Modiﬁcation of the said materials or use of the materials on any other Hub or networked computer environment or use of the materials for any purpose other than personal use is a violation of the said copyrights, trademarks and other intellectual property rights, and is expressly prohibited. Mash grants to You a temporary, non-exclusive, revocable, non-transferable limited license to use the Hub for your own use. All right, title, and interest in and to the Hub (excluding content uploaded speciﬁcally by a user) is and will remain the exclusive property of Mash.</p>
              <p>6.2. You agree that these Terms of Use, do not entitle You to any support, upgrades, updates, add-ons, patches, enhancements, or ﬁxes for the Hub (“Updates”). We may, however, occasionally provide automatic Updates to the Hub at its sole discretion (and without any advanced notiﬁcation to You). Any such Updates shall become part of the services and subject to these Terms of Use.</p>
              <p>6.3. From time to time, we test various aspects of our Hub, including our website, user interfaces, service levels, plans, promotions, features, availability of content, delivery, and pricing, and we reserve the right to include you in or exclude you from these tests without notice.</p>
              <p>6.4. If You are a copyright owner or an agent thereof and believe that any content on the Hub infringes upon Your rights, You may contact <a target='_blank' href="mailto: sayhi@thisismash.com">sayhi@thisismash.com</a> and provide details requested in regards to such infringement.</p>

              <h5>7. FEEDBACK</h5>
              <p>7.1. If you are having difficulties accessing the Hub or would like to lodge a complaint, please contact us at <a target='_blank' href="mailto: sayhi@thisismash.com">sayhi@thisismash.com</a>. We take your feedback seriously and will promptly investigate and respond to all genuine complaints.</p>
              <p>7.2. If you have any questions relating to these Terms of Use or the Hub please contact us via email at <a target='_blank' href="mailto: sayhi@thisismash.com">sayhi@thisismash.com</a>.</p>

              <h5>8. MODIFICATIONS</h5>
              We reserve the right at any time and from time to time, in Our sole discretion, to modify the content on the Hub or any part thereof, including the transmission of any related materials or documentation, with or without providing prior notice to You. We further reserve the right at any time and from time to time, at Our sole discretion, to alter, modify or terminate any content or features contained on the Hub without providing to You prior notice of such alteration, modiﬁcation or termination. You agree that Mash shall not be liable to You or to any third party claiming through You, for any modiﬁcation of the content or features provided on the Hub.

              <h5>9. SYSTEM LIMITATIONS AND FAILURES</h5>
              We use internally developed systems for providing You access to and facilitating Your use of the Hub. These systems may encounter technical or other limitations, and computer and communications hardware systems might experience interruptions. Further, We continually enhance and improve these systems in order to accommodate the level of use of the Hub. We may also add additional features and functionality to Hub that might result in the need to develop or license additional technologies. Increased utilization of the Hub or providing new features or functionality may cause unanticipated system disruptions, slower response times, degradation in levels of customer service, and delays in reporting accurate ﬁnancial information. You agree that We shall not be liable to You or to any third party claiming through You, for any such failures contemplated herein.

              <h5>10. PRIVACY</h5>
              <p>10.1. Your use of the Hub is governed by Our privacy policy, which can be located at https://thisismash.com/</p>
              <p>10.2. By using the Hub you agree, that we will collect data and related information about your use of the Hub. This includes activation of account, updating your details, opting into notifications and features and general engagement with Mash and the Hub. Technical information and preferences about your device, system, and Hub software may be collected. This information is collected by us for the purpose of operating the Hub and to optimise your user experience including to better understand your requirements and preferences for using the Hub and to facilitate the provision of updates. Mash protects, collects and manages your personal information in accordance with the Privacy Act 1988 (Cth). Mash may be required to disclose personal information by applicable law, under compulsion of law by a court or government agency.</p>
              <p>10.3. For information on how to gain access to your personal information, how we use your personal information, provision of contact details or other privacy matters, please see our link about to the Privacy Policy.</p>

              <h5>11. ADDITIONAL / SPECIAL TERMS AND CONDITIONS</h5>
              <p>Mash may notify you or request you to accept additional or special terms and conditions in relation to Your access of certain features or services on the Hub. You agree that You shall access or use such features and services only in accordance with such additional or special terms and conditions as if they were incorporated herein these Terms of Use.</p>

              <h5>12. LINKS TO / FROM OTHER HUBS AND THIRD PARTY TOOLS</h5>
              <p>12.1. The Hub may contain link/s to various other websites. These links are provided solely for Your convenience and beneﬁt. Wherever such link/s lead to websites which are not owned by or belong to Mash. We shall not be responsible for the content, products and services provided on such linked sites. We do not warrant or make any representations regarding the correctness or accuracy of the content on such websites. If You decide to access such linked websites, You do so at Your own risk. We do not in any way endorse the linked websites.</p>
              <p>12.2. Similarly, this Hub can be made accessible through a link created by other websites. Access to this Hub through such link/s shall not mean or be deemed to mean that the objectives, aims, purposes, ideas, concepts of such other websites or their aim or purpose in establishing such link/s to this Hub are necessarily the same or similar to the idea, concept, aim or purpose of Our Hub or that such links have been authorized by Us. We are not responsible for any representation/s of such other websites while affording such link and no liability can arise upon Mash consequent to such representation, its correctness or accuracy. In the event that any link/s afforded by any other website/s derogatory in nature to the objectives, aims, purposes, ideas and concepts of this Hub, is utilized to visit this Hub and such event is brought to the notice or is within the knowledge of Mash civil or criminal remedies as may be appropriate shall be invoked.</p>
              <p>12.3. Certain Members can connect their account on the Hub to other Hubs such as LinkedIn or any other third party websites. This feature requires you to accept and comply with the terms of service and privacy policy of such third party website. If you choose to connect, you will be able to take advantage of various social features included as part of the Hub speciﬁcally for the features available on such third party websites. In addition, Mash may personalize and otherwise enhance your experience based on your information obtained from or through such third party website. By connecting your Mash account to your LinkedIn account or any other third party accounts, you acknowledge and agree that you are consenting to the continuous release of information about you to others, including to LinkedIn or any other third party (in accordance with your privacy settings on such third party and these Terms of Use). If you do not want information about you to be shared in this manner, do not use the connect feature of LinkedIn or any other third party website. We disclaim all warranties in relation to and all liabilities arising from any use of your personal information by LinkedIn or any other third party website.</p>

              <h5>13. SECURITY</h5>
              <p>13.1. You are responsible for maintaining the confidentiality of the information you hold for your account, including your passwords, and for any and all activity that occurs under your account as a result of your failing to keep this information secure and confidential. You must immediately notify us of any unauthorized use of your account or passwords, or any other breach of security.</p>
              <p>13.2. You are prohibited from violating or attempting to violate the security of the Hub, including, without limitation:</p>
              <p>(a) accessing data not intended for You or logging into an account which You are not authorized to access</p>
              <p>(b) attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization</p>
              <p>(c) interfere with service to any user, host, or network.</p>
              <p>13.3. You shall not misuse this Hub by knowingly introducing viruses, trojans, worms, logic bombs or other materials that are malicious or technologically harmful (together “Viruses“). You must not attempt to gain unauthorized access to our Hub, the server on which the Hub is stored or any server, computer or database connected to this Hub. You must not attack this Hub via a denial-of-service attack. Further, You shall not interfere with or circumvent any security feature of the Hub or any feature that restricts or enforces limitations on use of or access to the Hub, such as probing or scanning the vulnerability of any system, network or breach.</p>
              <p>13.4. By breaching the provisions of this Paragraph, You may be liable to be prosecuted under the applicable laws of Australia, the laws of where you reside and the laws of where you committed the breach. We will report any such breach to the relevant law enforcement authorities and We will co-operate with such authorities by disclosing Your identity to them. In the event of such a breach, Your rights to use this Hub will cease immediately.</p>
              <p>13.5. We will not be liable for any loss or damage caused by a denial-of-service attack or Viruses that may infect your computer equipment, computer programs, data or other proprietary material due to Your use of this Hub or to Your downloading of any material posted on it, or on any Hub linked to it.</p>
              <p>13.6. You agree to immediately report to Us all incidents involving suspected or actual unauthorized access, disclosure, alteration, loss, damage, or destruction of data.</p>
              <p>13.7. You shall not interfere with or disrupt (or attempt to interfere with or disrupt) access and enjoyment of the Hub of other users or any host or network, including, without limitation, creating or transmitting unwanted electronic communications such as “spam” to other users, overloading, ﬂooding or mail-bombing the Hub, or scripting the creation of content in such a manner as to interfere with or create an undue burden on the Hub.</p>
              <p>13.8. You are prohibited from reverse engineering, decompiling, reverse assembling, modifying or attempting to discover or copy any software, source code or structure that the Hub utilize to generate web pages or any software or other products or processes accessible through the Hub.</p>
              <p>13.9. All actions performed by any person using Your account and passwords shall be deemed to have been committed by You and You shall be liable for the same. Mash reserves the right to suspend/terminate Your account at any time if it is found that You have been sharing the passwords with any unauthorized user.</p>
              <p>13.10. In order to reduce the risk of unauthorized access, all accounts maintained with Us through the Hub are locked after multiple incorrect login attempts. In the event of such lockout, You can email the administrator at <a target='_blank' href="mailto: sayhi@thisismash.com">sayhi@thisismash.com</a> and the account shall be unlocked upon receipt of Your email.</p>

              <h5>14. MONITORING</h5>
              <p>All electronic communications and content presented and/or passed to Us, including that presented and/or passed from remote access connections, may be monitored, examined, saved, read, transcribed, stored, or retransmitted in the course of daily operations by any duly authorized employee or agent of Mash in the exercise of their duties, or by law enforcement authorities who may be assisting Mash in investigating possible contravention / non-compliance with applicable law. Electronic communications and content may be examined by automated means. Further, Mash has the right to reject, at its sole discretion, from the Hub any electronic communications or content deemed not to be in compliance with the corporate policies and procedures of Mash.</p>

              <h5>15. TERMINATION</h5>
              <p>15.1. Mash may terminate your use of the Hub in the following circumstances:</p>
              <p>15.1.1. you no longer satisfy the requirements to use the Hub set out in these Terms of Use;</p>
              <p>15.1.2. the representations given by you under these Terms of Use are either not true, or are otherwise misleading, in any material respect by reference to current circumstances;</p>
              <p>15.1.3. you use the Hub other than in accordance with these Terms of Use;</p>
              <p>15.1.4. Mash considers it reasonably necessary to protect itself or you from incurring damage; or</p>
              <p>15.1.5. Mash reasonably believes that you pose a security, fraud or material business risk to it or other users of the Hub.</p>
              <p>15.2. Mash will act reasonably and in good faith in deciding whether to terminate your authorization to use the Hub. Mash will provide you with 5 days’ written notice (including electronically via email or through the Hub) before terminating your use of the Hub (unless the termination is made to address an immediate risk in which case you will be notified as soon as practicable afterwards).</p>
              <p>15.3. You may terminate your use of the Hub at any time by giving Mash written notice (including electronically via email or, if available, through the Hub).</p>
              <p>15.4. If your use of the Hub is terminated:</p>
              <p>15.4.1. all rights granted to you under these Terms of Use shall cease; and</p>
              <p>15.4.2. you must cease all activities authorised under these Terms of Use.</p>
              <p>15.5. Except as otherwise speciﬁcally provided herein, the termination of these Terms of Use for any reason whatsoever shall be without prejudice to any rights or obligations accrued to or in respect of the parties prior to the date of termination.</p>


              <h5>16. EXCLUSION OF LIABILITY</h5>
              <p>16.1. To the maximum extent permitted by law, all content and services on or available through the Hub are provided on an ‘as is’ and ‘as available’ basis and without warranties of any kind, expressed or implied except any which may be implied by statute and are incapable of exclusion, in respect of the Hub or its content.</p>
              <p>16.2. To the maximum extent permitted by law, Mash excludes, and does not make any representation, warranty or endorsement of any kind, whether express or implied, in relation to the content, accuracy, completeness, accessibility, suitability, safety, security, reliability, merchantability, fitness for purpose or any other aspect of:</p>
              <p>16.2.1. the Hub;</p>
              <p>16.2.2. any material and/or information in the Hub;</p>
              <p>16.2.3. the performance and availability of the Hub;</p>
              <p>16.2.4. the loss, damage, corruption or degradation of any data or other material as a result of the use of the Hub.</p>
              <p>16.3. To the maximum extent permitted by law, Mash excludes all liability for any loss or damage whatsoever which is suffered, (including but not limited to direct, indirect, incidental, special, consequential or exemplary damages, loss of profits or revenue, business interruption, loss, damage or corruption of data or failure to realise anticipated savings or benefits or business opportunities) as a result of:</p>
              <p>16.3.1. the use of, reliance on, or action taken as a result of, any information or material in the Hub for any decision or otherwise;</p>
              <p>16.3.2. accessing, displaying and using the Hub and/or participating in any online activities contained therein;</p>
              <p>16.3.3. any interruption, suspension or termination of the Hub in whole or in part for whatever reason including failure of or suspension of public or private telecommunications network;</p>
              <p>16.3.4. the unavailability, unsuitability and non-connectivity of third party sites;</p>
              <p>16.3.5. any loss, damage, corruption or degradation of any data or other material as a result of the use of the Hub and or viruses or other technologically harmful material that may infect your mobile device, tablet, laptop, computer or other device due to your use of the Hub.</p>
              <p>16.4. To the maximum extent permitted by law, you access the Hub at your own risk and Mash accepts no responsibility for any interference, loss, damage or disruption to your own mobile device, tablet, laptop, computer or other device or otherwise which arises in connection with your use of the Hub. You must take your own precautions to ensure that the process which you employ to obtain access to the Hub does not expose you to the risk of viruses, malicious computer code or other forms of interference which may damage your device or otherwise cause you or any person loss, damage or corruption.</p>

              <h5>17. RELEASE AND INDEMNITY</h5>
              <p>17.1. To the maximum extent permitted by law, you hereby waive, release, forgive, discharge and relinquish any and all claims that you may have against Mash, its related bodies corporate, directors, officers, employees, contractors, agents and representatives, in connection with, or arising out of, or incidental to, your access to or use of the Hub including but not limited to downloading any information and materials in or from the Hub.</p>
              <p>17.2. To the maximum extent permitted by law, you release and indemnify Mash and its directors, officers, employees, contractors, agents and representatives (“those indemnified”) from and against:</p>
              <p>17.2.1. any loss or damage or liability for any loss or damage incurred by you; and</p>
              <p>17.2.2. any loss, liability, claim, demand, damage or expense (including legal fees) made by any third party against those indemnified, howsoever caused (including through negligence) directly or indirectly arising out of or in connection with:</p>
              <p>• your use of the Hub or any linked website;</p>
              <p>• your use of or reliance on information contained in or accessed through the Hub; or</p>
              <p>• breach by you of the Terms of Use.</p>
              <p>17.3. The exclusion of liability applied to these Terms of Use as set out in this clause apply to claims whether in contract, tort (including, without limitation, negligence) or equity and even if Mash and/or a third party supplier has been notified of the possibility of such loss or damage.</p>
              <p>17.4. You agree that any legal liability on the part of Mash will be reduced by the extent, if any, to which you contributed to the loss.</p>
              <p>17.5. Your obligations under this Paragraph shall survive the termination of these Terms of Use</p>
              <p>17.6. We note that the Hub is not available for general public use and is not for consumers to access.</p>

              <h5>18. FOREIGN JURISDICTIONS</h5>
              18.1. Mash makes no representation that the content contained on the Hub is appropriate or to be used or accessed outside of Australia. You are expressly notiﬁed that, many states and foreign countries all have their own regulations that must be observed in relation to Your use or access of the Hub. You must make your own assessment regarding, and are responsible for compliance with, regulatory requirements as may be applicable to Your use and access of the Hub in such jurisdictions.

              <h5>19. GOVERNING LAW AND DISPUTE RESOLUTION</h5>
              <p>19.1. These Terms of Use shall be governed by and construed in accordance with the laws of Victoria and subject to the provisions of arbitration set out herein, the courts at Victoria shall have exclusive jurisdiction in relation to any Disputes (deﬁned below) arising out of or in connection with these Terms of Use subject to the provisions of this Paragraph.</p>
              <p>19.2. Any action, dispute or difference arising under or relating to this Terms of Use (Dispute) shall at the ﬁrst instance be resolved through good faith negotiations between the parties hereto, which negotiations shall begin promptly, within 15 (ﬁfteen) days after a party has delivered to the other party a written request for such consultation. If the parties are unable to resolve the Dispute in question within 15 (ﬁfteen) days of the commencement of negotiations, such Dispute shall be referred to and ﬁnally resolved by arbitration in accordance with The International Arbitration Act 1974 , as amended from time to time and rules prescribed thereunder. When any Dispute is under arbitration, except for the matters under dispute, Mash and You shall continue to exercise the remaining respective rights and fulﬁl the remaining respective obligations under this Terms of Use.</p>
              <p>19.3. The arbitration shall be conducted by a sole arbitrator jointly appointed by Mash and You. If parties fail to appoint an arbitrator within 30 days after service of the notice of arbitration, such arbitrator shall be appointed in accordance with provisions of The International Arbitration Act 1974 .The venue of arbitration shall be Victoria</p>
              <p>19.4. The language of the arbitration proceedings and of all written decisions and correspondence relating to the arbitration shall be English.</p>
              <p>19.5. You agree and acknowledge that the provisions of Paragraph 6 (Intellectual Property) and Paragraph 10 (Privacy) are of importance to Mash and monetary compensation may not constitute adequate relief and remedy to Mash for non-performance by You of your obligations thereunder. Accordingly, Mash shall be entitled to claim speciﬁc performance and seek mandatory and/or perpetual injunctions requiring You to perform your duties and obligations under such provisions.</p>

              <h5>20. SEVERABILITY
                If any term, provision, covenant or restriction of these Terms of Use is held by a court of competent jurisdiction to be invalid, void or unenforceable, the remainder of the terms, provisions, covenants and restrictions of these Terms of Use shall remain in full force and effect and shall in no way be affected, impaired or invalidated.

                21. NO WAIVER</h5>
              The rights and remedies available under this Terms of Use may be exercised as often as necessary and are cumulative and not exclusive of rights or remedies provided by law. It may be waived only in writing. Delay in exercising or non-exercise of any such right or remedy does not constitute a waiver of that right or remedy, or any other right or remedy.

              <h5>22. GENERAL</h5>
              <p>22.1. These Terms of Use comprise the full and complete agreement between You and Mash with respect to the use of the Hub and supersedes and cancels all prior communications, understandings and agreements between You and Mash, whether written or oral, expressed or implied with respect thereto.</p>
              <p>22.2. Mash may assign or otherwise transfer its rights and duties under these Terms of Use to any person without giving prior notice to you. You will receive notice following such an assignment as soon as reasonably practicable. You may not assign your rights and duties under these Terms of Use without Mash’s prior written consent.</p>
              <p>22.3. Any notice to be given in connection with these Terms of Use shall be delivered via email to Mash at <a target="_blank" href="mailto: ﬁnance@thisismash.com">ﬁnance@thisismash.com</a> and to You at the email address provided by you in Your proﬁle. You consent to the use of electronic communications in order to the electronic delivery of notices in relation to any matter under these Terms of Use.</p>
            </div>
            <div style={{ marginTop: '10px' }}>
              <div style={{ textAlign: 'center' }} className='disclaimer' >
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="isTermsAccepted"
                  onChange={() => setAccepted(!accepted)}
                  value={accepted}
                  id="check_example"
                />
                <label className="form-label" htmlFor="experience">
                  <p className="text-black">&nbsp;  I agree to the Terms of Service. <a href={TermDoc} target="_blank" className="terms-services"> download here</a></p>
                </label>
              </div>
              <div style={{ textAlign: 'center' }} >
                <button className='btn btn-sm btn_primary_black btn-width' disabled={!accepted} style={{ marginTop: '10px' }} onClick={handleAccept}>Accept</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;