import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { isEmpty } from 'lodash';

export const SignUpValidation = yup.object({
  email: yup.string().email('Invalid email').required('Email can\'t be empty'),
});

export const SignInValidation = yup.object({
  email: yup.string().email('Invalid email').required('Email can\'t be empty'),
});

// const URL = /^(?:(?:https?|http|www)\:\/\/)?(?:[a-zA-Z0-9\-]+\.)+(?:(?:[a-zA-Z]{2,4})|(?:[a-zA-Z0-9\-]+))(?:\:[0-9]+)?(?:[a-zA-Z0-9\-\._\?\,\'\/\\\+&%\$#\=~])*$/;
const URL = /^(?:(?:https?|http|www):\/\/)?(?:[a-zA-Z0-9\-]+\.)+(?:(?:[a-zA-Z]{2,4})|(?:[a-zA-Z0-9\-]+))(?:\:[0-9]+)?(?:[a-zA-Z0-9\-\._\?\,\'\/\\\+&%\$#\=~])*(?:\?[a-zA-Z0-9\-\._\?\,\'\/\\\+&%\$#\=~]+)?$/
//((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

// /((https?):\/\/)/;
function getPhoneRegExp(countryCode) {
  // Define regular expressions for phone number validation based on country codes
  switch (countryCode) {
  case '+1': // United States
    return /^[2-9]\d{9}$/;
  case '+91': // India
    return /^[6789]\d{9}$/;
  case '+44': // United Kindom
    return /^(?:(?:\\+|0{0,2})44\\s?\\d{10}|(?:(?:\\+|0{0,2})44\\s?\\d{3}\\s?\\d{4}\\s?\\d{3})|(?:(?:\\+|0{0,2})44\\s?\\d{2}\\s?\\d{4}\\s?\\d{4}))$/;
  case '+61': // Australia
    return /^\(?(\d{2})\)?[\s-]?\d{4}[\s-]?\d{4}$/;
  case '+33': // France
    return /^0[67]\\d{8}$/;
    // case '+49': //Germany
    //   return /\(?[ ()]?([- ()]?\d[- ()]?){10}\\d{10}$/;
  case '+7': //Russian
    return /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;
    // Add more cases for other country codes as needed
  case '+31':  //Netherland 
    return /^\\d{9}|\\d{10}$/;
  case '+66':  //Thailand 
    return /^\\d{8}$/;
  case '+65':  //Singapore
    return /[6|8|9]\d{7}|\+65[6|8|9]\d{7}|\+65\s[6|8|9]\d{7}$/;
  default:
    return /^\d{10}$/; // Default regex for any other country
  }
}

// Main Create Account Validation...
export const CreateProfileValidation = yup.object({
  firstName: yup.string().required('This field can\'t be empty').min(3),
  lastName: yup.string().required('This field can\'t be empty').min(3),
  skills: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(''),
        label: yup.string().required(''),
      })
    )
    .required('Skills can\'t be empty')
    .max(3, 'Skills can\'t be more than three'),

  language: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(''),
        label: yup.string().required(''),
      })
    )
    .required('Languages can\'t be empty'),
  location: yup.string().required('This field can\'t be empty'),
  timezone: yup.array()
    .of(
      yup.object().shape({
        value: yup.string().required(''),
        label: yup.string().required(''),
      })
    ).nullable(),
  // timezone: yup.string().required("This field can't be empty").oneOf(["", ""]),

  countryCode: yup.object().shape({
    value: yup.string(),
    label: yup.string(),
  }),
  isVisibletoOthers: yup.object().shape({
    value: yup.string().required(''),
    label: yup.string().required(''),
  }),
  birthDate: yup.string().required('This field can\'t be empty'),
  introVideo: yup.mixed(),
  portfolioVideo: yup.mixed(),
  // postCode: yup.string().length(6).matches(/^[0-9]{6}/).required("Post Code can't be empty"),
  // countryCode: yup.string().required('Country code is required'),
  phoneNo: yup.string().test('validator-custom-name', function (value) {
    if (value) {

      if (!isValidPhoneNumber(value)) {
        return this.createError({
          message: 'Invalid Phone Number',
        });
      }
      else {
        return true;
      }
    } else {
      return this.createError({
        message: 'Phone Number can\'t be isEmpty',
      });
    }
  }),

  cv: yup.mixed(),

  instagram: yup.string(),
  facebook: yup.string().matches(URL, 'Please enter correct url with http:// or https://'),
  twitter: yup.string().matches(URL, 'Please enter correct url with http:// or https://'),
  linkedin: yup.string().matches(URL, 'Please enter correct url with http:// or https://'),
  tiktok: yup.string().matches(URL, 'Please enter correct url with http:// or https://'),
  websiteUrl: yup.string().matches(URL, 'Please enter correct url with http:// or https://'),
});

export const AccountDetailsValidation = yup.object({
  experience: yup.string().matches(
    /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{0,1})?$/,
    'Please provide valid value (e.g 50.6)').required('This field is required'),
  workedForYourself: yup.string().matches(
    /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{0,1})?$/,
    'Please provide valid value (e.g 50.6)').required('This field is required'),
  minDayRate: yup.string().matches(
    /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{0,1})?$/,
    'Please provide valid value (e.g 50.6)').required('This field is required'),
  isTermsAccepted: yup.boolean().oneOf([true], 'You cannot proceed without agreeing the terms'),
});

export const EditProfileValidation = yup.object({
  firstName: yup.string(),
  email: yup.string(),
  catchLine: yup.string(),
  lastName: yup.string(),
  designation: yup.string(),
  description: yup.string(),
  jobTitle: yup.string(),
  timezone: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    ).nullable(),
  skills: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .required('Skills can\'t be empty'),
  brand: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .required('Brand can\'t be empty'),
  lovedBrand: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .required('Loved brand can\'t be empty'),

  language: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .required('Languages can\'t be empty'),

  // countryCode: yup.string().required('Country code is required'),
  phoneNo: yup.string().test('validator-custom-name', function (value) {
    if (value) {

      if (!isValidPhoneNumber(value)) {
        return this.createError({
          message: 'Invalid Phone Number',
        });
      }
      else {
        return true;
      }
    } else {
      return this.createError({
        message: 'Phone Number can\'t be isEmpty',
      });
    }
  }),
  cv: yup.mixed(),
  introVideo: yup.mixed(),
  portfolioVideo: yup.mixed(),

  otherLinks: yup.array().of(
    yup.object().shape({
      label: yup.string(),
      value: yup.string(),
    })
  ),
  location: yup.string(),
  // instagram: yup
  //   .string()
  //   .matches(URL, "Please enter correct url with http:// or https://"),
  instagram: yup.string(),
  facebook: yup.string().matches(URL, 'Please enter correct url with http:// or https://'),
  twitter: yup.string().matches(URL, 'Please enter correct url with http:// or https://'),
  linkedin: yup.string().matches(URL, 'Please enter correct url with http:// or https://'),
  websiteUrl: yup.string().matches(URL, 'Please enter correct url with http:// or https://'),
  tiktok: yup.string().matches(URL, 'Please enter correct url with http:// or https://'),
  websiteUrls: yup.array()
    .of(
      yup.object().shape({
        url: yup.string().matches(URL, 'Please enter correct url with http:// or https://'),
      })
    )
});

export const profilePicValidaiton = yup.object({
  profilePic: yup.mixed(),
});

export const coverPicValidaiton = yup.object({
  profilePic: yup.mixed(),
});

export const traitsValidaiton = yup.object({
  profilePic: yup.string(),
});

export const updateIntroVideoValidation = yup.object({
  introVideo: yup.mixed(),
});
export const updateIntroVideoThreeValidation = yup.object({
  myWorkVideo1: yup.mixed(),
  myWorkVideo2: yup.mixed(),
  myWorkVideo3: yup.mixed(),
});

export const updateBiographyValidation = yup.object({
  biography: yup.string(),
});

export const validationApplyBrief = yup.object({
  availability: yup.string().when('action', {
    is: 'applied',
    then: yup.string().required('This field is required'),
  }).required('This field is required'),
  masherQuotedAmount: yup.array().of(
    yup.object().shape({
      currency: yup.string().required('Currency is required'),
      amount: yup.string().required('Amount is required'),
    })
  ).required(),

});

export const validationSkip = yup.object({
  skipText: yup.string().required('This field is required'),
});

export const validationReferCerative = yup.object({
  email: yup.string().required('This field can\'t be empty'),
  firstName: yup.string().required('This field can\'t be empty'),
  lastName: yup.string().required('This field can\'t be empty'),
});

export const addWokrDetailValidation = yup.object({
  client: yup.string().required('This field can\'t be empty'),
  yourRole: yup.string().required('This field can\'t be empty'),
  description: yup.string().required('This field can\'t be empty'),
  link: yup.string().matches(URL, 'Please enter correct url with http:// or https://'),
  workVideo: yup.mixed().required('This field can\'t be empty'),
});

export const AccountEditValidation = yup.object({
  name: yup.string().required('Name can\'t be empty'),
  email: yup.string().email('Invalid email').required('Email can\'t be empty'),
  phoneNo: yup.string().test('validator-custom-name', function (value) {
    if (value) {

      if (!isValidPhoneNumber(value)) {
        return this.createError({
          message: 'Invalid Phone Number',
        });
      }
      else {
        return true;
      }
    } else {
      return this.createError({
        message: 'Phone Number can\'t be isEmpty',
      });
    }
  }),
  countryCode: yup.object().shape({
    value: yup.string(),
    label: yup.string(),
  }),
  city: yup.string().required('city can\'t be empty'),
  country: yup.string().required('country can\'t be empty'),
  minDayRate: yup.string().matches(
    /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{0,1})?$/,
    'Please provide valid value (e.g 50.6)').required('Minimum Day Rate can\'t be empty'),
  birthDate: yup.string().required('Birth Date can\'t be empty'),


});
export const PortfolioEditValidation = yup.object({
  cv: yup.mixed().required('This field is required'),
  clientsWorkedFor:yup.string().max(1000, 'Only 1000 characters allowed.!')
});
export const ApplyBriefValidation = yup.object({
  deliverables: yup.array().of(
    yup.object().shape({
      value: yup.number().typeError('Must be a number').required('Value is required'),
    })
  ).required('All deliverables must be filled'),
});
export const ProfileEditValidation = yup.object({
  roles: yup.array()
  .min(1, 'Roles can\'t be empty').required('Roles can\'t be empty'),
  skills: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required('Value is required'),
        label: yup.string().required('Label is required'),
      })
    )
    .min(1, 'Select at least one skill')
    .required('Skills can\'t be empty'),
  experience: yup.mixed().required('This field is required'),
  language: yup
  .array()
  .of(
    yup.object().shape({
      value: yup.string().required('Value is required').notOneOf([''], 'Value cannot be empty'),
      label: yup.string().required('Label is required').notOneOf([''], 'Label cannot be empty'),
    })
  )
  .min(1, 'Select at least one language')
  .required('Languages can\'t be empty'),
  websiteUrl: yup
    .string()
    .matches(
      /^(https?:\/\/)/, // Regular expression for checking if URL starts with http:// or https://
      'Please enter a URL starting with http:// or https://'
    ),
  instagram: yup.string(),
  facebook: yup
    .string()
    .matches(
      /^(https?:\/\/)/, // Regular expression for checking if URL starts with http:// or https://
      'Please enter a URL starting with http:// or https://'
    ),
  twitter: yup
    .string()
    .matches(
      /^(https?:\/\/)/, // Regular expression for checking if URL starts with http:// or https://
      'Please enter a URL starting with http:// or https://'
    ),
  linkedin: yup
    .string()
    .matches(
      /^(https?:\/\/)/, // Regular expression for checking if URL starts with http:// or https://
      'Please enter a URL starting with http:// or https://'
    ),
  tiktok: yup
    .string()
    .matches(
      /^(https?:\/\/)/, // Regular expression for checking if URL starts with http:// or https://
      'Please enter a URL starting with http:// or https://'
    ),
  biography: yup.string().max(1500, 'Only 1500 characters allowed').required('This field is required'),
  traits: yup.string().max(1500, 'Only 1500 characters allowed'),
  brand: yup.string().max(1500, 'Only 1500 characters allowed'),
});

