import React, { useContext, useEffect, useState } from 'react';
import NotFound from '../Pages/404';
import PublicRoute from './publicRoute';
import PrivateRoute from './privateRoute';
import { Toaster } from 'react-hot-toast';
import Setting from '../Pages/setting/index';
import Otp from '../Pages/Account/Otp/index';
import Otp2 from '../Pages/Account/Otp2/index';
import Thankyou from '../Pages/Thankyou//index';
// import Briefs from '../Pages/Briefs/BriefView.js';
import Briefs from '../Pages/Briefs/BriefView2.js';
import ProjectView from '../Pages/Briefs/MashProjectView.js';
import { Route, Routes, useLocation } from 'react-router-dom';
import SignIn from '../Pages/Account/SignIn/index';
import SignUp from '../Pages/Account/SignUp/index';
import MashProject from '../Pages/MashProject/index';
import MyMashToolkitPage from '../Pages/MyMashToolkit';
import LoginLinkedIn from '../components/logInLinkedIn';
import MashGlobal from '../Pages/MashGlobalProject/index';
import MashersProfile from '../Pages/Mashers/MashersProfile.js';
import Account from '../Pages/Mashers/Account';
import Portfolio from '../Pages/Mashers/Portfolio.js';
import OtherMasherProfile from '../Pages/Mashers/OtherMasherProfile';
import MasherProjectView from '../Pages/MashProject/MasherProjectView';
import GlobalBrieffView from '../Pages/MashGlobalProject/GlobalBriefView';
import CreateMasherProfile from '../Pages/Account/CreateMasherProfile/index';
import NoMasherProfileFound from '../Pages/NoMasherProfile/NoMasherProfileFound';
import TermsModal from '../components/termsConditions/TermAcceptedWrapper';
import AppContext from '../appContext';
import { updateUserApi } from '../apis';
import toast from 'react-hot-toast';
import { getStorageItem } from '../utils/helperFunctions';


export default function MainRouter() {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const { state } = appContext;
  const { loggedInUser } = state;
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleAcceptTerms = async () => {
    const res = await updateUserApi({ isTermsAccepted: true });
    if ((res.status === 200 || 201)) {
      toast.success('Successfuly updated', { id: 'succcess' });
      setShowModal(false);
    } else {
      toast.success('oops! something went wrong', { id: 'error' });
    }
  };

  useEffect(() => {
    if (loggedInUser?.user && !location?.pathname?.includes('create-profile')) {
      const { isTermsAccepted } = loggedInUser?.user;
      if (isTermsAccepted !== undefined && isTermsAccepted === false) {
        setShowModal(true);
      } else {
        setShowModal(false);
      }
    }
    return () => {
      setShowModal(false);
    };
  }, [loggedInUser]);

  return (
    <>
      <Routes >
        {/* <Route exact path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} /> */}
        <Route exact path="/" element={<PrivateRoute><MashProject /></PrivateRoute>} />
        <Route path="/home" element={<PrivateRoute><MashProject /></PrivateRoute>} />
        <Route path="/create-profile" element={<PrivateRoute><CreateMasherProfile /></PrivateRoute>} />
        <Route path="/thank-you" element={<PrivateRoute><Thankyou /></PrivateRoute>} />
        <Route path="/mashers-profile/:masherId" element={<PrivateRoute><MashersProfile /></PrivateRoute>} />
        <Route path="/account/:masherId" element={<PrivateRoute><Account /></PrivateRoute>} />
        <Route path="/portfolio/:masherId" element={<PrivateRoute><Portfolio /></PrivateRoute>} />
        <Route path="/masher-view/:masherId/*" element={<OtherMasherProfile />} />
        <Route path="/no-masher-found" element={<PrivateRoute><NoMasherProfileFound /></PrivateRoute>} />
        <Route path="/my-mash-toolkit" element={<PrivateRoute><MyMashToolkitPage /></PrivateRoute>} />
        {/* <Route path="/work-detail" element={<PrivateRoute><WorkDetail /></PrivateRoute>} /> */}
        {/* <Route path="/mash-project" element={<PrivateRoute><MashProject /></PrivateRoute>} /> */}
        {/* <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} /> */}
        <Route path="/brief/brief-view/:briefId" element={<PrivateRoute><Briefs /></PrivateRoute>} />
        <Route path="/project/project-view/:projectId" element={<PrivateRoute><ProjectView /></PrivateRoute>} />
        <Route path="/global-brief-view/:briefId" element={<PrivateRoute><GlobalBrieffView /></PrivateRoute>} />
        <Route path="/masher-project-view/:briefId" element={<PrivateRoute><MasherProjectView /></PrivateRoute>} />
        {/* <Route path="/global-project-view/:briefId" element={<PrivateRoute><GlobalProjectView /></PrivateRoute>} /> */}
        <Route path="/mash-global-project" element={<PrivateRoute><MashGlobal /></PrivateRoute>} />
        {/* <Route path="/mash-referral" element={<PrivateRoute><MashReffered /></PrivateRoute>} /> */}
        <Route path="/setting" element={<PrivateRoute><Setting /></PrivateRoute>} />

        <Route path="*" element={<NotFound />} />

        <Route path="/sign-up" element={<PublicRoute><SignUp /></PublicRoute>} />
        <Route path="/otp" element={<PublicRoute><Otp /></PublicRoute>} />
        <Route path="/sign-in" element={<PublicRoute><SignIn /></PublicRoute>} />
        <Route path="/otp-sign-in" element={<PublicRoute><Otp2 /></PublicRoute>} />
        <Route path="/login-linkedin" element={<PublicRoute><LoginLinkedIn /></PublicRoute>} />
      </Routes>
      {/* <button className='floatBtn' onClick={() => window.open("https://thismash.atlassian.net/servicedesk/customer/portal/1", "_blank")}>
        <i class='bi bi-question' style={{ fontSize: "1.75rem", fontWeight: 900 }}></i>
      </button> */}
      {showModal && !location?.pathname?.includes('create-profile') && <PrivateRoute><TermsModal onAccept={handleAcceptTerms} acceptedTerms={acceptedTerms} setAcceptedTerms={setAcceptedTerms} /></PrivateRoute>}
      <Toaster position='top-center' reverseOrder={false} />
    </>
  );
}
