import React from 'react';
import CloseIcon from '../../Assets/img/covers/close.png';

export default function GmailPopup() {
  return (
    <>
      <div
        className="modal fade mailbox-popup"
        id="modalExport"
        tabIndex={-1}
        aria-labelledby="modalExport"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content shadow-3">
            <div className="modal-body">
              <div className="mailbox-top-head">
                <span>New Massage</span>
                <button
                  type="button"
                  className=""
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <img src={CloseIcon} />
                </button>
              </div>
              <div className="to-cc-bcc-div">
                <div className="mail-to col-md-1 col-1">To</div>
                <div className="textarea-div col-md-10 col-9">
                  <textarea></textarea>
                </div>
                <div className="cc-bcc col-md-1 col-2 text-end">
                  <span>Cc</span>
                  <span>Bcc</span>
                </div>
              </div>
              <div className="subject-div">
                <input type="text" placeholder="Subject" />
              </div>
              <div className="editable-box">
                <div contentEditable="true" className="editable-inner"></div>
              </div>
              <div className="mail-footer">
                <div className="send-btn">
                  <button className="btn btn-sm btn_primary_black">Send</button>
                  <i className="bi bi-paperclip"></i>
                </div>
                <div className="trash-box">
                  <div className="print-sec">
                    <div className="dropdown">
                      <a
                        className="text-muted"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="bi bi-three-dots-vertical"></i>
                      </a>
                      <div className="dropdown-menu">
                        <a href="#!" className="dropdown-item">
                          Print
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="trash-inner">
                    <i className="bi bi-trash"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
